.K2XqZTy8UZgBHkBeKgHmh ._2ial8J88_s1LYEa11QkNL8, ._3S9AGeR4983VZaWLD4wUyg a {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

@-webkit-keyframes ZNPSPQsv9K_-y-K4pWPVn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes ZNPSPQsv9K_-y-K4pWPVn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

._2zdZyqq8AUCuffSgO7rGH3 {
  padding: 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.vF1YvyFaCJslp8nHu2i5I {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1245px;
  padding: 3rem 1rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin: 0 auto;
  border-radius: 20px; }
  @media all and (min-width: 802px) {
    .vF1YvyFaCJslp8nHu2i5I {
      padding: 1.5rem; } }
  @media all and (min-width: 1920px) {
    .vF1YvyFaCJslp8nHu2i5I {
      padding: 2.5rem; } }
  .vF1YvyFaCJslp8nHu2i5I._1ncvbgpNppCD6HDD58KqzJ {
    -webkit-animation: ZNPSPQsv9K_-y-K4pWPVn 1s;
            animation: ZNPSPQsv9K_-y-K4pWPVn 1s; }
  .vF1YvyFaCJslp8nHu2i5I._2iTpQ8RqVS1yuIfpVdTGAF {
    background-color: #f3fcfb; }
  .vF1YvyFaCJslp8nHu2i5I._3Cm0MMz0p8IF1cLxhQ0sFY {
    background-color: #f0f9ff; }

.K2XqZTy8UZgBHkBeKgHmh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem; }
  .K2XqZTy8UZgBHkBeKgHmh ._3DIa_5-dwM3adV1xPAoOBt {
    height: 35px; }
    @media all and (min-width: 802px) {
      .K2XqZTy8UZgBHkBeKgHmh ._3DIa_5-dwM3adV1xPAoOBt {
        height: 40px; } }
    @media all and (min-width: 1920px) {
      .K2XqZTy8UZgBHkBeKgHmh ._3DIa_5-dwM3adV1xPAoOBt {
        height: 45px; } }
  .K2XqZTy8UZgBHkBeKgHmh ._2ial8J88_s1LYEa11QkNL8 {
    font-size: 1.2rem; }
    @media all and (min-width: 802px) {
      .K2XqZTy8UZgBHkBeKgHmh ._2ial8J88_s1LYEa11QkNL8 {
        font-size: 1.4rem; } }
    @media all and (min-width: 1920px) {
      .K2XqZTy8UZgBHkBeKgHmh ._2ial8J88_s1LYEa11QkNL8 {
        font-size: 1.6rem; } }

._3tSjmhzZWU0A6cqbF2nOcI {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: center;
  max-width: 755px;
  word-break: break-word; }
  @media all and (min-width: 802px) {
    ._3tSjmhzZWU0A6cqbF2nOcI {
      font-size: 1.8rem; } }
  @media all and (min-width: 1920px) {
    ._3tSjmhzZWU0A6cqbF2nOcI {
      font-size: 2rem; } }

._1F0uTJfzs-71xvCA2PlDdw {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem; }

.FNhN4fTv0oxR_sFSG5WFJ {
  list-style: none;
  padding: 0; }
  .FNhN4fTv0oxR_sFSG5WFJ._1OKPtT6co8pqx0Y20fZf_K {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
    @media all and (min-width: 1012px) {
      .FNhN4fTv0oxR_sFSG5WFJ._1OKPtT6co8pqx0Y20fZf_K {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2; } }
  .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F {
    padding: 0.75rem 1.313rem;
    border-radius: 20px;
    cursor: pointer;
    -webkit-transition: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition-duration: 400ms;
            transition-duration: 400ms;
    -webkit-transition-property: all;
    transition-property: all;
    margin-bottom: 0.5rem; }
    .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F:last-child {
      margin-bottom: 0; }
    .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F._2iTpQ8RqVS1yuIfpVdTGAF._3lwhJtHhHdgb2gCZucfa-A, .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F._2iTpQ8RqVS1yuIfpVdTGAF:hover {
      background-color: #dcf3f2; }
    .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F._3Cm0MMz0p8IF1cLxhQ0sFY._3lwhJtHhHdgb2gCZucfa-A, .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F._3Cm0MMz0p8IF1cLxhQ0sFY:hover {
      background-color: #dcf0fd; }
    .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F ._1knWOg4bTC_gDMKXZdioaa {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      word-break: break-word; }
      @media all and (min-width: 802px) {
        .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F ._1knWOg4bTC_gDMKXZdioaa {
          font-size: 1.4rem; } }
      @media all and (min-width: 1920px) {
        .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F ._1knWOg4bTC_gDMKXZdioaa {
          font-size: 1.5rem; } }
    .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F ._3lckrIi_4LuGJBBNBI1uHR {
      font-size: 1rem;
      line-height: 1.8;
      margin: 0;
      word-break: break-word; }
      @media all and (min-width: 802px) {
        .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F ._3lckrIi_4LuGJBBNBI1uHR {
          font-size: 1.1rem; } }
      @media all and (min-width: 1920px) {
        .FNhN4fTv0oxR_sFSG5WFJ ._3M2egtT6vSc82Ri1XTEe3F ._3lckrIi_4LuGJBBNBI1uHR {
          font-size: 1.15rem; } }

._3OVR3lwuEE4eMNwCmkQU6z {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 1012px) {
    ._3OVR3lwuEE4eMNwCmkQU6z {
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; } }
  ._3OVR3lwuEE4eMNwCmkQU6z._1OKPtT6co8pqx0Y20fZf_K {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
    @media all and (min-width: 1012px) {
      ._3OVR3lwuEE4eMNwCmkQU6z._1OKPtT6co8pqx0Y20fZf_K {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; } }
  ._3OVR3lwuEE4eMNwCmkQU6z img {
    display: none;
    max-width: 100%;
    width: 560px;
    height: auto;
    aspect-ratio: attr(width)/attr(height); }
    @media all and (min-width: 1920px) {
      ._3OVR3lwuEE4eMNwCmkQU6z img {
        font-size: 580rem; } }
    ._3OVR3lwuEE4eMNwCmkQU6z img._3lwhJtHhHdgb2gCZucfa-A {
      display: block; }

._3S9AGeR4983VZaWLD4wUyg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 0.8rem;
  grid-area: unset; }
  ._3S9AGeR4983VZaWLD4wUyg._1OKPtT6co8pqx0Y20fZf_K {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  @media all and (min-width: 400px) {
    ._3S9AGeR4983VZaWLD4wUyg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; } }
  @media all and (min-width: 1012px) {
    ._3S9AGeR4983VZaWLD4wUyg {
      grid-area: span 1 / span 2 / span 1 / span 2;
      grid-gap: 2rem;
      gap: 2rem; } }
  ._3S9AGeR4983VZaWLD4wUyg a {
    margin: 0;
    border-radius: 80px;
    padding: 0.8rem 2rem !important;
    font-size: 1rem;
    text-align: center; }
    @media all and (min-width: 802px) {
      ._3S9AGeR4983VZaWLD4wUyg a {
        font-size: 1.125rem; } }
  ._3S9AGeR4983VZaWLD4wUyg ._3r14I7ojxjP6GK_9d5YjDq {
    background: #161819;
    color: #fff; }
    ._3S9AGeR4983VZaWLD4wUyg ._3r14I7ojxjP6GK_9d5YjDq:hover {
      background: #292d2f; }
    ._3S9AGeR4983VZaWLD4wUyg ._3r14I7ojxjP6GK_9d5YjDq:active {
      background: #2e3234; }
