._2qABc20bQbFPDOPJT7fhnY, ._43loJB09ZlM45RxdRJ1DR {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._1m0mo1vjlBpGjvN6rz_AxY {
  background-color: #fcfcfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 8rem 1.5rem; }
  ._1QI4nz6G4DGegx7VmjB_ML {
    background: #030b0d; }
    ._1QI4nz6G4DGegx7VmjB_ML ._2qABc20bQbFPDOPJT7fhnY {
      color: #f5f5f5; }
    ._1QI4nz6G4DGegx7VmjB_ML ._1ZBb1OHjRe_MdqBqPQaGB8 {
      color: #a9b2be; }
  .onkPtfdBYyFtjs0AkyIZN {
    background: #f2f4f6; }
  ._1YXFd7wA0-LuOu5OX-fYd {
    margin-top: 2.188rem; }
  ._2qABc20bQbFPDOPJT7fhnY {
    max-width: 1200px;
    font-size: 2.5rem;
    line-height: 1.5;
    text-align: center;
    margin: 0;
    padding: 0;
    z-index: 2; }
    ._2qABc20bQbFPDOPJT7fhnY ._1vqFE1VEincFSIo1vNJmMN {
      display: inline-block;
      position: relative; }
      ._2qABc20bQbFPDOPJT7fhnY ._1vqFE1VEincFSIo1vNJmMN:before {
        display: block;
        width: 100%;
        height: 5px;
        background: #adb8e3;
        position: absolute;
        bottom: 0;
        content: ''; }
    @media all and (max-width: 790px) {
      ._2qABc20bQbFPDOPJT7fhnY {
        font-size: 1.75rem;
        line-height: 1.6; } }
  ._1ZBb1OHjRe_MdqBqPQaGB8 {
    margin-bottom: 2rem;
    padding: 0;
    font-size: 1.25rem;
    line-height: 1.8;
    margin-top: 1.563rem;
    color: #16191c;
    max-width: 800px;
    text-align: center;
    z-index: 2; }
    @media all and (max-width: 790px) {
      ._1ZBb1OHjRe_MdqBqPQaGB8 {
        font-size: 1.125rem;
        line-height: 1.6; } }
  ._1Teh93Vxp4IJQwd-A_jDWv {
    color: #2892d0; }
  ._3MZ-1zL2Y3a068heDtS0Yx {
    background: transparent; }
    ._287c1jueTe74VwJolXO5H4 {
      color: #fbb703; }
  @media all and (max-width: 1200px) {
    ._1m0mo1vjlBpGjvN6rz_AxY {
      padding: 2.5rem 1.5rem; } }

._43loJB09ZlM45RxdRJ1DR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._43loJB09ZlM45RxdRJ1DR ._164_ec7U30UYc00ELvYZHl {
    margin: 1rem;
    font-size: 1.2rem;
    line-height: 1.2;
    padding: 0.5rem 3.125rem;
    height: unset; }
    @media all and (min-width: 281px) {
      ._43loJB09ZlM45RxdRJ1DR ._164_ec7U30UYc00ELvYZHl {
        padding: 1.125rem 2.125rem; } }
    @media all and (min-width: 513px) {
      ._43loJB09ZlM45RxdRJ1DR ._164_ec7U30UYc00ELvYZHl {
        font-size: 1.4rem; } }

.SSXXLOg-y90Lmd3Ryde_k {
  position: absolute;
  right: 0;
  margin-top: -7rem;
  width: 100%;
  max-width: 75px; }
  @media (max-width: 991px) {
    .SSXXLOg-y90Lmd3Ryde_k {
      max-width: 35px; } }

.WSC3tZbpDWrU9UwS90vjF {
  position: absolute;
  left: 0;
  margin-top: 5rem;
  width: 100%;
  max-width: 100px; }
  @media (max-width: 991px) {
    .WSC3tZbpDWrU9UwS90vjF {
      max-width: 40px; } }
