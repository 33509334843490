._3kPMyModUvKEsWY61Nr-D4 {
  padding: 2rem;
  max-width: 100%;
  width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  max-height: 90vh;
  overflow: auto; }

._2ijoGKO6I66a0mv6vgtJ3a {
  color: #051c26;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center; }

._1uDBv9E6oHhpddYBKvjFbR {
  margin: 0.5rem; }

._3uAlLF8w_oXwIfwZXMUWty {
  display: block;
  color: #657881;
  line-height: 1.5rem;
  margin: 0.5rem; }
  ._3uAlLF8w_oXwIfwZXMUWty._1l5WTYrck2Ro6yTFv1Du6 {
    display: block;
    color: #ce2a29; }
  ._3uAlLF8w_oXwIfwZXMUWty._3sjF3wDhxj_zZ5GfRno5AC {
    color: #1f8e0d;
    text-align: center; }

._1fmQOJs6kXQ1FctBr4vjGk ._36bSymU7q4CfCkYO2eiZ8e {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 1rem;
  background: #f5f5f5;
  font-size: 1rem;
  line-height: 1.2rem;
  border-radius: 3px; }

._1fmQOJs6kXQ1FctBr4vjGk ._3EpUUnoqlNIK6ATBI2j-Eb {
  background: #008a50;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  display: block;
  padding: 0.5rem; }

._1fmQOJs6kXQ1FctBr4vjGk ._2qTUNSy1qRz7xzfw3pDeuq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1.5rem; }

._1fmQOJs6kXQ1FctBr4vjGk .xf6fb_kfRzpwtio_o5UmQ {
  color: #051c26;
  font-weight: 600;
  width: 35%;
  text-align: left; }

._1fmQOJs6kXQ1FctBr4vjGk ._2Gq7kxGlROF3cE_vfUL-V7 {
  text-align: right; }

._1fmQOJs6kXQ1FctBr4vjGk ._3vEPBWRWFx8HXei3mufWOM {
  margin: 0;
  padding: 0;
  list-style: none; }
  ._1fmQOJs6kXQ1FctBr4vjGk ._3vEPBWRWFx8HXei3mufWOM li {
    margin-bottom: 0.5rem; }

._1fmQOJs6kXQ1FctBr4vjGk ._3cR7RrHZ7tmxe54KUp_Vuv {
  margin: 0 1.5rem;
  border-top: 1px solid #b1b4ba;
  padding: 1rem 0;
  text-align: right; }

._1fmQOJs6kXQ1FctBr4vjGk .NbSnMMb9YOEbYO0SWrLR7 {
  background-color: #00b4d8;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  border: none;
  padding: 10px; }
  ._1fmQOJs6kXQ1FctBr4vjGk .NbSnMMb9YOEbYO0SWrLR7[disabled] {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: not-allowed; }
