.AuwzqSpHrHMOZQIhc47Xr {
  background-color: #ffd15c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8rem 5rem; }
  @media (max-width: 991px) {
    .AuwzqSpHrHMOZQIhc47Xr {
      padding: 3rem 1.25rem; } }
  .AuwzqSpHrHMOZQIhc47Xr p {
    font-size: 1.25rem;
    line-height: 1.8; }
    @media (max-width: 991px) {
      .AuwzqSpHrHMOZQIhc47Xr p {
        line-height: 1.6;
        font-size: 1.125rem; } }
  .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    max-width: 1400px;
    margin: 0 auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._3sf4GvaTmHpJV7w-foyyYt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      margin-bottom: 3rem; }
      @media (max-width: 991px) {
        .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._3sf4GvaTmHpJV7w-foyyYt {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-flow: column;
                  flex-flow: column;
          margin: 0; } }
    .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._5nczrDev02yRNMpjmfQAx {
      padding: 0 3rem; }
      @media (max-width: 991px) {
        .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._5nczrDev02yRNMpjmfQAx {
          padding: 0 0 2rem 0; } }
      .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._5nczrDev02yRNMpjmfQAx .HrLYor46TzXg38M-hiR60 {
        padding-left: 1.2rem;
        margin-bottom: 2rem; }
      .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._5nczrDev02yRNMpjmfQAx ._2Y9QDV3V_ePi_cIFInyVyk {
        border-left: 6px solid #030b0d; }
      .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._5nczrDev02yRNMpjmfQAx .mnlg4r1mLpKgMfffiQUpO {
        padding-left: 2.8rem; }
        .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._5nczrDev02yRNMpjmfQAx .mnlg4r1mLpKgMfffiQUpO li {
          line-height: 1.8;
          font-size: 1.25rem; }
          @media (max-width: 991px) {
            .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._5nczrDev02yRNMpjmfQAx .mnlg4r1mLpKgMfffiQUpO li {
              line-height: 1.6;
              font-size: 1.125rem; } }
      .AuwzqSpHrHMOZQIhc47Xr ._1-91vkQIMrkjKJslX1dsDt ._5nczrDev02yRNMpjmfQAx ._1Fld0XYfiNAkgMHoyTTdOn {
        padding-left: 1.7rem; }
