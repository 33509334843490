.YD1EjFTdJ0iH4PayMS_pq {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .YD1EjFTdJ0iH4PayMS_pq {
      font-size: 2.5rem;
      line-height: 1.7; } }

.jxLkgETT8a-NFO-VBTS_E ._3kgnukOcEbcZLMFeuScnSt ._2bXKQvTySKOQlSfoJ0_Bzk {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .jxLkgETT8a-NFO-VBTS_E ._3kgnukOcEbcZLMFeuScnSt ._2bXKQvTySKOQlSfoJ0_Bzk {
      font-size: 1.25rem;
      line-height: 1.8; } }

._3_W6UtuXA_JoAhoCnS8O1_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 7rem 5rem;
  background-color: #161819; }
  @media (max-width: 991px) {
    ._3_W6UtuXA_JoAhoCnS8O1_ {
      padding: 3rem 1.25rem; } }

._1CkmgkfaIk96F1LU-FfQVz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  max-width: 1400px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.YD1EjFTdJ0iH4PayMS_pq {
  color: #fff;
  margin-bottom: 4rem; }
  @media (max-width: 991px) {
    .YD1EjFTdJ0iH4PayMS_pq {
      text-align: center; } }

._1bWr4-y03TvkQCgpuW78aw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 3.5rem;
  gap: 3.5rem; }
  @media all and (min-width: 780px) {
    ._1bWr4-y03TvkQCgpuW78aw {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row; } }

.jxLkgETT8a-NFO-VBTS_E {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #2e3034;
  border-radius: 12px;
  padding: 1.5rem;
  grid-gap: 2rem;
  gap: 2rem; }
  @media all and (min-width: 320px) {
    .jxLkgETT8a-NFO-VBTS_E {
      padding: 3.5rem; } }
  @media all and (min-width: 1380px) {
    .jxLkgETT8a-NFO-VBTS_E {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row; } }
  .jxLkgETT8a-NFO-VBTS_E ._7V0BPaPVsTOpUubCVWqli {
    display: inline-block;
    width: 100%;
    height: auto; }
    @media all and (min-width: 470px) {
      .jxLkgETT8a-NFO-VBTS_E ._7V0BPaPVsTOpUubCVWqli {
        width: auto;
        height: 170px; } }
  .jxLkgETT8a-NFO-VBTS_E ._3kgnukOcEbcZLMFeuScnSt ._2Sy_uxmWoAHiFzrNBeWs8t,
  .jxLkgETT8a-NFO-VBTS_E ._3kgnukOcEbcZLMFeuScnSt ._2bXKQvTySKOQlSfoJ0_Bzk {
    color: #fff; }
  .jxLkgETT8a-NFO-VBTS_E ._3kgnukOcEbcZLMFeuScnSt ._2Sy_uxmWoAHiFzrNBeWs8t {
    font-size: 2.188rem;
    line-height: 1.5;
    margin-bottom: 0.5rem; }
  .jxLkgETT8a-NFO-VBTS_E ._3kgnukOcEbcZLMFeuScnSt ._2bXKQvTySKOQlSfoJ0_Bzk {
    margin-bottom: 0; }
