._1hDNgyciaPk4VlV95ZuN2Z ._2RvU7Yt9fsADyXcLy7i30Q ._3XieXI1z4fBTSEGkOLAYcK {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._1hDNgyciaPk4VlV95ZuN2Z ._2RvU7Yt9fsADyXcLy7i30Q ._3XieXI1z4fBTSEGkOLAYcK {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._1hDNgyciaPk4VlV95ZuN2Z ._2RvU7Yt9fsADyXcLy7i30Q ._3XieXI1z4fBTSEGkOLAYcK {
      font-size: 2.5rem;
      line-height: 1.7; } }

._1hDNgyciaPk4VlV95ZuN2Z {
  padding: 0 2rem 3rem 2rem;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 768px) {
    ._1hDNgyciaPk4VlV95ZuN2Z {
      padding: 3rem 2rem 6rem 2rem; } }
  ._1hDNgyciaPk4VlV95ZuN2Z ._2RvU7Yt9fsADyXcLy7i30Q {
    background: linear-gradient(95.47deg, #9144f6 6.55%, #625df4 49.84%, #f557c3 99.93%);
    padding: 3rem 0;
    border-radius: 10px;
    text-align: center; }
    @media all and (min-width: 768px) {
      ._1hDNgyciaPk4VlV95ZuN2Z ._2RvU7Yt9fsADyXcLy7i30Q {
        padding: 5rem 0; } }
    ._1hDNgyciaPk4VlV95ZuN2Z ._2RvU7Yt9fsADyXcLy7i30Q ._3XieXI1z4fBTSEGkOLAYcK {
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      display: block;
      margin: 0;
      color: #fff; }
      ._1hDNgyciaPk4VlV95ZuN2Z ._2RvU7Yt9fsADyXcLy7i30Q ._3XieXI1z4fBTSEGkOLAYcK .h1caeJWPIl8uz6g3rokLf {
        color: #fff; }
        ._1hDNgyciaPk4VlV95ZuN2Z ._2RvU7Yt9fsADyXcLy7i30Q ._3XieXI1z4fBTSEGkOLAYcK .h1caeJWPIl8uz6g3rokLf:hover {
          color: #030b0d; }
