._3dd1ueEftVYgXQg_dEonuf {
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #030b0d;
  padding: 8rem 5rem; }
  @media (max-width: 991px) {
    ._3dd1ueEftVYgXQg_dEonuf {
      text-align: center;
      padding: 3rem 1.25rem; } }
  @media all and (max-width: 1200px) {
    ._3dd1ueEftVYgXQg_dEonuf {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding: 2.5rem; } }
  ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU {
    display: grid;
    grid-gap: 0 8vw;
    grid-template-columns: repeat(3, minmax(280px, 420px));
    max-width: 1400px;
    margin: 0 auto; }
    @media (max-width: 991px) {
      ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU ._4SZPGEgCboR-7zNsHn8YG {
      margin: 2.3rem; }
      ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU ._4SZPGEgCboR-7zNsHn8YG ._23-wDciTuFQ60pxkuYFFtQ {
        width: 90px;
        margin: 0 auto;
        margin-bottom: 10px; }
      ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU ._4SZPGEgCboR-7zNsHn8YG ._3dUD7lmTdBGLWCVySi1bYI {
        color: #fafafa;
        margin-bottom: 1.5rem; }
        @media (max-width: 991px) {
          ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU ._4SZPGEgCboR-7zNsHn8YG ._3dUD7lmTdBGLWCVySi1bYI {
            font-size: 1.75rem; } }
      ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU ._4SZPGEgCboR-7zNsHn8YG ._33DAhzhMkb_4rPQLqMvluF {
        font-size: 1.25rem;
        color: #a9b2be;
        font-weight: normal;
        line-height: 1.8; }
        @media (max-width: 991px) {
          ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU ._4SZPGEgCboR-7zNsHn8YG ._33DAhzhMkb_4rPQLqMvluF {
            font-size: 1.125rem;
            width: 60vw;
            margin: 3vh auto;
            line-height: 1.6; } }
      @media (max-width: 991px) {
        ._3dd1ueEftVYgXQg_dEonuf .r7yGrifMIfnVq18ptkSqU ._4SZPGEgCboR-7zNsHn8YG {
          width: 60vw;
          margin: 3vh auto; } }
  ._3dd1ueEftVYgXQg_dEonuf ._3dUD7lmTdBGLWCVySi1bYI {
    color: #f5f5f5;
    margin-bottom: 5rem; }
