._1Sp9aoIAA8nnhJ73NTY_zX {
  width: 100%;
  margin-bottom: 3rem; }

._1ou2uAFsjU3FNympTacIX {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem; }
  @media (max-width: 1490px) {
    ._1ou2uAFsjU3FNympTacIX {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }

._3XhLCmG8f5kuBR6nJNm_pR {
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 2.2rem;
  color: #051c26;
  margin: 0 0.5rem 0.5rem 0; }
  @media (max-width: 1490px) {
    ._3XhLCmG8f5kuBR6nJNm_pR {
      margin-bottom: 1rem; } }

.CXiSbBtynulBQfWR5KvwH {
  font-size: 0.75rem;
  color: #758793; }

._1QMdUxU14xAY0z3PH7vQ6o {
  margin: 0; }

._3TmaaX210t-b3jcbpl5-3U {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 1490px) {
    ._3TmaaX210t-b3jcbpl5-3U {
      -ms-flex-item-align: end;
          align-self: flex-end; } }
  @media (max-width: 1240px) {
    ._3TmaaX210t-b3jcbpl5-3U {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      width: 100%;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
              align-items: stretch; } }
  ._3TmaaX210t-b3jcbpl5-3U ._2VfITSbuDN33NruaBebykt {
    background: #ffffff;
    -webkit-box-shadow: 2px 4px 10px rgba(232, 234, 237, 0.3);
            box-shadow: 2px 4px 10px rgba(232, 234, 237, 0.3);
    border-radius: 3px;
    padding: 10px;
    font-size: 14px;
    margin-right: 0.5rem; }
    @media (max-width: 1240px) {
      ._3TmaaX210t-b3jcbpl5-3U ._2VfITSbuDN33NruaBebykt {
        margin: 0 0 0.5rem 0; } }
    ._3TmaaX210t-b3jcbpl5-3U ._2VfITSbuDN33NruaBebykt input {
      border: none;
      background: transparent;
      margin-left: 5px; }

._2fYd19JDXTHr7G1pM0AeLT {
  margin-right: 0.5rem; }
  ._2fYd19JDXTHr7G1pM0AeLT:last-child {
    margin-right: 0; }
  @media (max-width: 1240px) {
    ._2fYd19JDXTHr7G1pM0AeLT {
      margin-bottom: 0.5rem;
      width: 100%; } }
