._2E0YyMYOjHtbpSVMqhjMAn > .QcFlzwm-BDF-TtA9M6Lkk {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2E0YyMYOjHtbpSVMqhjMAn > .QcFlzwm-BDF-TtA9M6Lkk {
      font-size: 2.5rem;
      line-height: 1.7; } }

._2E0YyMYOjHtbpSVMqhjMAn > .STMMjCGrjx_BtOYWMzJXQ {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2E0YyMYOjHtbpSVMqhjMAn > .STMMjCGrjx_BtOYWMzJXQ {
      font-size: 1.25rem;
      line-height: 1.8; } }

._2E0YyMYOjHtbpSVMqhjMAn {
  display: grid;
  max-width: 1400px;
  padding: 7.5rem 5.5rem;
  margin: 0 auto; }
  @media all and (max-width: 790px) {
    ._2E0YyMYOjHtbpSVMqhjMAn {
      text-align: center; } }
  @media all and (max-width: 1200px) {
    ._2E0YyMYOjHtbpSVMqhjMAn {
      max-width: 90%;
      margin: 0 auto;
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding: 4rem; } }
  @media all and (max-width: 790px) {
    ._2E0YyMYOjHtbpSVMqhjMAn {
      padding: 3rem 2rem; } }
  ._2E0YyMYOjHtbpSVMqhjMAn > .QcFlzwm-BDF-TtA9M6Lkk {
    text-align: center;
    word-break: break-word; }
  ._2E0YyMYOjHtbpSVMqhjMAn > .STMMjCGrjx_BtOYWMzJXQ {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 0; }

._2QjdCt6vcL_6W-1jpsbCFB {
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi img {
    max-width: 100%;
    height: auto;
    line-height: 0; }

._2QjdCt6vcL_6W-1jpsbCFB {
  display: grid;
  max-width: 1350px;
  grid-template-columns: repeat(3, auto);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  grid-gap: 4.5rem;
  gap: 4.5rem;
  margin: 2.5rem auto; }
  ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi ._2y7tuY0N6XG07PCw27Tt3B {
      margin-right: 1.5rem;
      max-width: 100%;
      height: auto; }
      @media all and (max-width: 790px) {
        ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi ._2y7tuY0N6XG07PCw27Tt3B {
          margin-right: 0; } }
    ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi .QcFlzwm-BDF-TtA9M6Lkk {
      font-size: 1.375rem;
      word-break: break-word; }
    ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi ._16Z458g4Relep4NoTzmwR5 {
      font-size: 1.125rem;
      line-height: 1.8;
      margin: 0; }
      @media (max-width: 991px) {
        ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi ._16Z458g4Relep4NoTzmwR5 {
          font-size: 1.125rem;
          line-height: 1.6; } }
    @media all and (max-width: 790px) {
      ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi {
        text-align: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-bottom: 2rem; }
        ._2QjdCt6vcL_6W-1jpsbCFB ._39gIqVnSaAtqEtRJUSwLWi img {
          margin-bottom: 2rem; } }
  @media all and (max-width: 790px) {
    ._2QjdCt6vcL_6W-1jpsbCFB {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      max-width: 475px; } }
  @media (min-width: 700px) and (max-width: 1400px) {
    ._2QjdCt6vcL_6W-1jpsbCFB {
      grid-template-columns: repeat(2, auto); } }

._1P16p4osAN5t184X7zq2Mh section:first-child {
  padding-top: 2rem; }
  @media all and (min-width: 768px) {
    ._1P16p4osAN5t184X7zq2Mh section:first-child {
      padding-top: 7.5rem; } }

._1P16p4osAN5t184X7zq2Mh section:last-child {
  padding-bottom: 2rem; }
  @media all and (min-width: 768px) {
    ._1P16p4osAN5t184X7zq2Mh section:last-child {
      padding-bottom: 7.5rem; } }

._2ON7fheyyzryhIU7bqw3Es {
  margin-bottom: 1rem; }
