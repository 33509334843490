._2sQCLIqZw38780dnsE23mj {
  background-color: #0a1315;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8rem 5rem 6.5rem; }
  @media (max-width: 991px) {
    ._2sQCLIqZw38780dnsE23mj {
      padding: 3rem 1.25rem; } }
  ._2sQCLIqZw38780dnsE23mj .gxDD4lm5B5XmhEM6pKvkd {
    display: grid;
    max-width: 1400px;
    margin: 0 auto; }
    ._2sQCLIqZw38780dnsE23mj .gxDD4lm5B5XmhEM6pKvkd ._1_VM00NCQ6OVi2JpRJXyHA {
      color: #fff;
      text-align: center; }
      @media (max-width: 991px) {
        ._2sQCLIqZw38780dnsE23mj .gxDD4lm5B5XmhEM6pKvkd ._1_VM00NCQ6OVi2JpRJXyHA {
          font-size: 1.75rem;
          line-height: 1.8;
          margin-bottom: 0; } }
    ._2sQCLIqZw38780dnsE23mj .gxDD4lm5B5XmhEM6pKvkd ._2S1SlUq5HcrKklNVgYkU87 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      ._2sQCLIqZw38780dnsE23mj .gxDD4lm5B5XmhEM6pKvkd ._2S1SlUq5HcrKklNVgYkU87 ._3zreTfug1YiH9QfFbBFLFc {
        margin: 2rem 1.5vw; }
        ._2sQCLIqZw38780dnsE23mj .gxDD4lm5B5XmhEM6pKvkd ._2S1SlUq5HcrKklNVgYkU87 ._3zreTfug1YiH9QfFbBFLFc ._1QpDyOiThkUmhw9geNUV1O {
          margin: 2rem;
          max-height: 120px; }
          @media (max-width: 991px) {
            ._2sQCLIqZw38780dnsE23mj .gxDD4lm5B5XmhEM6pKvkd ._2S1SlUq5HcrKklNVgYkU87 ._3zreTfug1YiH9QfFbBFLFc ._1QpDyOiThkUmhw9geNUV1O {
              margin: 0 2rem; } }
        ._2sQCLIqZw38780dnsE23mj .gxDD4lm5B5XmhEM6pKvkd ._2S1SlUq5HcrKklNVgYkU87 ._3zreTfug1YiH9QfFbBFLFc figcaption {
          display: none; }
