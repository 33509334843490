._3zvKxrbdhFYXn8HTuTL_oc ._89G7ZhRPgFNi2sLp1T6_u {
  font-size: 1.75rem;
  line-height: 1.8;
  font-weight: bold; }
  @media all and (min-width: 768px) {
    ._3zvKxrbdhFYXn8HTuTL_oc ._89G7ZhRPgFNi2sLp1T6_u {
      font-size: 2rem;
      line-height: 1.5; } }

._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW {
  -webkit-box-shadow: -8px 7px 15px 1px rgba(229, 233, 241, 0.78);
          box-shadow: -8px 7px 15px 1px rgba(229, 233, 241, 0.78); }

._3zvKxrbdhFYXn8HTuTL_oc {
  padding: 3rem 2rem 8rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%; }
  ._3zvKxrbdhFYXn8HTuTL_oc ._89G7ZhRPgFNi2sLp1T6_u {
    margin-bottom: 5rem;
    word-break: break-word; }
  ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: repeat(1, auto);
    max-width: 1400px;
    margin: 0 auto;
    list-style: none;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0; }
    @media all and (min-width: 768px) {
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq {
        grid-template-columns: repeat(2, auto); } }
    @media all and (min-width: 1270px) {
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq {
        grid-template-columns: repeat(4, auto); } }
    ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW {
      background: #fff;
      border-radius: 10px;
      padding: 2rem 0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      max-width: 100%; }
      @media all and (min-width: 310px) {
        ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW {
          width: 253px; } }
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW:last-of-type ._2uo3YQeXxhJakB9dAzrpDg {
        max-width: unset; }
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW:hover {
        margin-top: -0.313rem;
        margin-bottom: 0.313rem;
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease; }
      @media all and (min-width: 768px) {
        ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW {
          padding: 2rem 1.5rem; } }
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._3jbHq8A-Yl_zeg_owofxa1 {
        text-align: center; }
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._2fDM9B-i7R8oMaLcu0zuqL {
        height: 60px;
        margin-bottom: 1.4rem; }
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._1Vx5WUePdS0C0TAzh_GFaz {
        background: #e57e49;
        font-size: 0.5rem;
        color: #fff;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        padding: 0.125rem 0.125rem 0.125rem 0.25rem;
        position: absolute;
        margin-top: 3.2rem;
        margin-left: -1.1rem;
        border-radius: 3px;
        overflow: hidden; }
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._2uo3YQeXxhJakB9dAzrpDg {
        font-size: 1.4rem;
        margin: auto;
        max-width: 135px;
        line-height: 1.3; }
        @media all and (min-width: 768px) {
          ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._2uo3YQeXxhJakB9dAzrpDg {
            font-size: 1.3rem; } }
      ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._1CQetFvWo1MG4CWtwR6TR0 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 1rem; }
        ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._1CQetFvWo1MG4CWtwR6TR0 ._2SvOCxwZsggwxW99x4NinR {
          border: 1px solid #030b0d;
          color: #030b0d;
          font-weight: 600;
          border-radius: 50%;
          padding: 0.438rem 0.625rem; }
          ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._1CQetFvWo1MG4CWtwR6TR0 ._2SvOCxwZsggwxW99x4NinR:hover {
            background-color: #00b4d8;
            border: 1px solid #00b4d8;
            color: #fff; }
          ._3zvKxrbdhFYXn8HTuTL_oc ._1ogWnJhDGT0QPMg1ehPMLq ._3MUK5TOtfusrAckBuD9gcW ._1CQetFvWo1MG4CWtwR6TR0 ._2SvOCxwZsggwxW99x4NinR .PN_978EMkfOeLXet5dn2P {
            -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
            font-size: 0.875rem; }
