._17GT3zd-O6j_StLRldcRlV {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 8rem; }
  ._1Uvu0AZJPoOV6gVsp3oEaX {
    color: #fff;
    text-align: center;
    margin-bottom: 4rem; }
  ._21zsriQZsDtBupKY6WmN4R {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  @media (max-width: 991px) {
    ._21zsriQZsDtBupKY6WmN4R {
      grid-template-columns: auto; } }
