._2kapmCts33cTR9z94_ymr2 {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2kapmCts33cTR9z94_ymr2 {
      font-size: 1.25rem;
      line-height: 1.8; } }

._3PZhDhAhxjWshbMD9Igluy {
  padding: 6rem 2rem; }
  @media (max-width: 991px) {
    ._3PZhDhAhxjWshbMD9Igluy {
      padding: 3rem 0; } }
  ._3PZhDhAhxjWshbMD9Igluy h3 {
    color: #fff; }

._1E0nNhLVlQSVUu7PsrXyX3 {
  max-width: 1400px;
  margin: 0 auto; }

._33roJsFGaDIhf-9tkk2fH {
  display: grid;
  grid-gap: 3rem;
  gap: 3rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (max-width: 991px) {
    ._33roJsFGaDIhf-9tkk2fH {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      gap: 2rem; } }

._1FCYyMtX3sXjFbGiSt01Sz {
  background-color: #161819;
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 3rem; }
  @media (max-width: 991px) {
    ._1FCYyMtX3sXjFbGiSt01Sz {
      grid-template-columns: 1fr; } }

._18gMBCACqTwEijDb0RjrQW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._18gMBCACqTwEijDb0RjrQW ._3moUPHgnbI96XnXsIbzMc {
    max-width: 100%;
    height: auto; }

._1cih9LanRkcBJjwF4neTEq {
  font-size: 1.6rem;
  line-height: 1.7;
  word-break: break-word; }
  @media (max-width: 991px) {
    ._1cih9LanRkcBJjwF4neTEq {
      font-size: 1.4rem; } }

._2kapmCts33cTR9z94_ymr2 {
  word-break: break-word;
  color: #99a1a7; }
