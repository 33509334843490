._8iPthUxlZM0wJMFmw0Nyy {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #222;
  opacity: .2;
  z-index: 1; }

._3QGZHSh1eNVUtzfJUnCM6 {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute; }

._2ZRcufVuaWWMAZu3Q4V89N {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

._1SwcINmLRCCuKVtT8PjHos {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  -webkit-animation: _1zMz_gRskY0-XCxv37W5eI .5s infinite linear;
          animation: _1zMz_gRskY0-XCxv37W5eI .5s infinite linear;
  border-radius: 100%;
  border-style: solid; }

@-webkit-keyframes _1zMz_gRskY0-XCxv37W5eI {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes _1zMz_gRskY0-XCxv37W5eI {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
