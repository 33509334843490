._2bRzGuT0DJKPkHZGGWprgI {
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  padding: 0.625rem 2rem; }
  @media (max-width: 991px) {
    ._2bRzGuT0DJKPkHZGGWprgI {
      background-size: 550px;
      padding: 0 2rem; } }
  ._2bRzGuT0DJKPkHZGGWprgI .QO29b_vkLHQRSALmjPtKX {
    min-height: calc(100vh - 80px); }
  ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 3rem 0;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 1400px; }
    @media (max-width: 991px) {
      ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 2rem 0;
        margin: 0; } }
    ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._3pOUP8DDm4GL43-MEQ71VZ {
      position: absolute;
      left: 0;
      top: 5%;
      width: 100%;
      max-width: 60px; }
      @media (max-width: 991px) {
        ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._3pOUP8DDm4GL43-MEQ71VZ {
          max-width: 25px;
          top: 0; } }
    ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2LBVmm50zHIK7VPJ79A6dg {
      position: absolute;
      right: 0;
      top: 34%;
      width: 100%;
      max-width: 160px; }
      @media (max-width: 991px) {
        ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2LBVmm50zHIK7VPJ79A6dg {
          max-width: 40px;
          top: 42%; } }
    ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2oXfrRCtKzynOVKCKhhpSc {
      color: #030b0d;
      width: 650px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      z-index: 2;
      padding: 1.5rem; }
      @media (max-width: 991px) {
        ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2oXfrRCtKzynOVKCKhhpSc {
          width: 100%;
          margin: 1rem;
          padding: 0; } }
      ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2oXfrRCtKzynOVKCKhhpSc p {
        line-height: 2;
        font-size: 1.125rem;
        margin-top: 1.25rem; }
        @media (max-width: 991px) {
          ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2oXfrRCtKzynOVKCKhhpSc p {
            font-size: 1.125rem; } }
        ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2oXfrRCtKzynOVKCKhhpSc p a {
          font-weight: 500; }
          ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2oXfrRCtKzynOVKCKhhpSc p a:hover {
            text-decoration: underline; }
      @media (max-width: 991px) {
        ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2oXfrRCtKzynOVKCKhhpSc > h1 {
          font-size: 1.75rem;
          line-height: 1.8;
          margin-bottom: 0; } }
    ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 .xop8-aZCM57mVk08JYDLt {
      width: 45%;
      padding: 1.5rem;
      border-radius: 35px; }
      @media (max-width: 991px) {
        ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 .xop8-aZCM57mVk08JYDLt {
          width: 100%; } }
    @media (max-width: 991px) {
      ._2bRzGuT0DJKPkHZGGWprgI ._1VMrdE_IKske0ZHHA7tM-6 ._2m5zoswgM9FvyfojY8i-G2 {
        display: none; } }
  ._2bRzGuT0DJKPkHZGGWprgI ._1vVOAqY88DFYOUxX__3zh0 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 3rem 2rem; }
    @media (max-width: 991px) {
      ._2bRzGuT0DJKPkHZGGWprgI ._1vVOAqY88DFYOUxX__3zh0 {
        padding: 2rem 0; } }
    ._2bRzGuT0DJKPkHZGGWprgI ._1vVOAqY88DFYOUxX__3zh0 ._3n55pUx3hgWhgvWAL3ry3z {
      background: #fff;
      border-radius: 10px;
      position: relative;
      padding: 2.813rem 4.313rem;
      text-align: center;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-box-shadow: -8px 7px 15px 1px #e9ecf2;
              box-shadow: -8px 7px 15px 1px #e9ecf2; }
      @media (max-width: 991px) {
        ._2bRzGuT0DJKPkHZGGWprgI ._1vVOAqY88DFYOUxX__3zh0 ._3n55pUx3hgWhgvWAL3ry3z {
          padding: 2.813rem 0; } }
      ._2bRzGuT0DJKPkHZGGWprgI ._1vVOAqY88DFYOUxX__3zh0 ._3n55pUx3hgWhgvWAL3ry3z h4 {
        line-height: 1.5;
        margin-bottom: 0; }
        @media (max-width: 991px) {
          ._2bRzGuT0DJKPkHZGGWprgI ._1vVOAqY88DFYOUxX__3zh0 ._3n55pUx3hgWhgvWAL3ry3z h4 {
            margin: 0 3rem; } }
