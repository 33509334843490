._1ajp1Tu4D3s5P7xKQNsp4Q {
  font-family: 'Inter', system-ui, sans-serif; }

._10ona4KU4e-_eBg-PA-UZV {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; }
  ._10ona4KU4e-_eBg-PA-UZV:before {
    position: absolute;
    content: '';
    background: #848993 0% 0% no-repeat padding-box;
    opacity: 0.7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

._1ajp1Tu4D3s5P7xKQNsp4Q {
  background: #fff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  max-width: 90%;
  position: relative; }
  ._1ajp1Tu4D3s5P7xKQNsp4Q .O2-kRuEYvvelhy__5CPk3 {
    color: #758793;
    position: absolute;
    right: 5px;
    top: 5px;
    border: none;
    background: transparent;
    outline: none;
    font-size: 1.875rem;
    cursor: pointer;
    z-index: 1; }
    ._1ajp1Tu4D3s5P7xKQNsp4Q .O2-kRuEYvvelhy__5CPk3:hover {
      color: #5e6d77; }
    ._1ajp1Tu4D3s5P7xKQNsp4Q .O2-kRuEYvvelhy__5CPk3:active {
      color: #505d66; }
