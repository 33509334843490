._3zpEasV7m57o4nNdWv4REg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 991px) {
    ._3zpEasV7m57o4nNdWv4REg {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
  ._3zpEasV7m57o4nNdWv4REg .ZstE-jWG1i8SBCG5tjx-h {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: auto;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    ._3zpEasV7m57o4nNdWv4REg .ZstE-jWG1i8SBCG5tjx-h img {
      width: 100%;
      height: 100%; }
    @media (max-width: 1330px) {
      ._3zpEasV7m57o4nNdWv4REg .ZstE-jWG1i8SBCG5tjx-h {
        display: none; } }
  ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr {
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: calc(100vh - (80px + 3rem));
    padding: 5rem 0 3rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    max-width: 800px; }
    @media (max-width: 991px) {
      ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr {
        width: 85%;
        margin: 10px auto 0; } }
    ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr ._1LsKk30JiH14bOhuK33S64 {
      margin-bottom: 1.5rem; }
      @media (max-width: 991px) {
        ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr ._1LsKk30JiH14bOhuK33S64 {
          font-size: 1.75rem;
          line-height: 1.8; } }
    ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr ._2VBTF6qUEOgzN2X41fq-mw {
      line-height: 1.8;
      font-size: 1.125rem;
      margin-bottom: 2.5rem; }
    ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr .XW81VWcAT9OrIN7cdWP02 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem; }
    ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr .Bndu-2OY8SeW4tszbaZ6e {
      padding: 0 1.5rem;
      margin: 0 0 1.5rem; }
      ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr .Bndu-2OY8SeW4tszbaZ6e ._16LH_bzCjZpgE8SVjd_oeA {
        line-height: 1.8;
        font-size: 1.125rem; }
    ._3zpEasV7m57o4nNdWv4REg ._15xOfOJC-TPI47fKiXVwqr ._1LxRH3LyL1n1uwFRTBLtJl {
      padding: 0.8rem 1.8rem; }
