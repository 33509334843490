._1bNxj-V9jfLfT9bDMaWlDu section:first-child {
  padding-top: 2rem; }
  @media all and (min-width: 768px) {
    ._1bNxj-V9jfLfT9bDMaWlDu section:first-child {
      padding-top: 7.5rem;
      padding-bottom: 5.5rem; } }

._1bNxj-V9jfLfT9bDMaWlDu section:last-child {
  padding-bottom: 2rem; }
  @media all and (min-width: 768px) {
    ._1bNxj-V9jfLfT9bDMaWlDu section:last-child {
      padding-bottom: 7.5rem; } }
