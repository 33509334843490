._3KAp5vvc9DKP_zTgCVAtbk ._2ToXk0Ld5dDBezPnYPpw1f {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

html {
  scroll-behavior: smooth; }

._1sJd4rhEAW7OlTZPbTTV4c section {
  padding-top: 5rem; }
  @media all and (min-width: 768px) {
    ._1sJd4rhEAW7OlTZPbTTV4c section {
      padding-top: 7.5rem; } }

._3KAp5vvc9DKP_zTgCVAtbk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #0f191e;
  padding: 8rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 991px) {
    ._3KAp5vvc9DKP_zTgCVAtbk {
      padding: 4rem 2rem; } }
  ._3KAp5vvc9DKP_zTgCVAtbk ._2ToXk0Ld5dDBezPnYPpw1f {
    background: #00b4d8;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
    display: block;
    color: #fff; }
    ._3KAp5vvc9DKP_zTgCVAtbk ._2ToXk0Ld5dDBezPnYPpw1f:hover {
      background-color: #009bb9;
      color: #fff; }
    ._3KAp5vvc9DKP_zTgCVAtbk ._2ToXk0Ld5dDBezPnYPpw1f:active, ._3KAp5vvc9DKP_zTgCVAtbk ._2ToXk0Ld5dDBezPnYPpw1f:focus {
      background-color: #0092af;
      color: #fff; }
    @media (max-width: 991px) {
      ._3KAp5vvc9DKP_zTgCVAtbk ._2ToXk0Ld5dDBezPnYPpw1f {
        font-size: 1.2rem; } }
