.uLBXeTuwUUtiJMFOqrH-R a, ._1TAyjxAiEBy8VsERZCXcjB {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3Xzi9tO0z9wV9jpG81gge- {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3Xzi9tO0z9wV9jpG81gge- {
      font-size: 2.5rem;
      line-height: 1.7; } }

.uLBXeTuwUUtiJMFOqrH-R._1qGoFQTebmcOCL3AB_3Vix ._1KTEFoASkZET3wRahPRH5J {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .uLBXeTuwUUtiJMFOqrH-R._1qGoFQTebmcOCL3AB_3Vix ._1KTEFoASkZET3wRahPRH5J {
      font-size: 1.25rem;
      line-height: 1.8; } }

._1oldNiXeTVeWJSsWmz_k2s {
  padding: 0 2rem 6rem; }
  @media (max-width: 991px) {
    ._1oldNiXeTVeWJSsWmz_k2s {
      padding: 0; } }
  ._1oldNiXeTVeWJSsWmz_k2s h2,
  ._1oldNiXeTVeWJSsWmz_k2s h3 {
    word-break: break-word;
    color: #fff; }

._17WgS3XzN5kFLkQ_8HvDNm {
  max-width: 1400px;
  margin: 0 auto;
  background: #1f1e1f;
  border-radius: 20px; }
  @media (max-width: 991px) {
    ._17WgS3XzN5kFLkQ_8HvDNm {
      border-radius: 0; } }

._2d2p3qtD6PtutB2W7t2R-m {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 5rem;
  grid-gap: 5rem;
  gap: 5rem; }
  @media (max-width: 991px) {
    ._2d2p3qtD6PtutB2W7t2R-m {
      padding: 2rem;
      grid-gap: 2rem;
      gap: 2rem; } }

._1xwJAE1LOopRllezJA60kj {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 4.5rem;
  gap: 4.5rem;
  margin: 0;
  padding: 0; }
  @media all and (max-width: 1380px) {
    ._1xwJAE1LOopRllezJA60kj {
      grid-template-columns: 0.75fr;
      grid-gap: 3rem;
      gap: 3rem; } }
  @media (max-width: 991px) {
    ._1xwJAE1LOopRllezJA60kj {
      grid-template-columns: 1fr; } }

.uLBXeTuwUUtiJMFOqrH-R {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 2rem;
  gap: 2rem; }
  @media all and (max-width: 1380px) {
    .uLBXeTuwUUtiJMFOqrH-R {
      text-align: center; } }
  .uLBXeTuwUUtiJMFOqrH-R._1qGoFQTebmcOCL3AB_3Vix {
    text-align: center; }
    .uLBXeTuwUUtiJMFOqrH-R._1qGoFQTebmcOCL3AB_3Vix ._1KTEFoASkZET3wRahPRH5J {
      color: #fff; }
  .uLBXeTuwUUtiJMFOqrH-R a {
    margin: 0;
    border-radius: 80px;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    word-break: break-word;
    text-align: center; }
    .uLBXeTuwUUtiJMFOqrH-R a:hover, .uLBXeTuwUUtiJMFOqrH-R a:active, .uLBXeTuwUUtiJMFOqrH-R a:focus {
      color: #fff; }
    @media all and (min-width: 802px) {
      .uLBXeTuwUUtiJMFOqrH-R a {
        font-size: 1.125rem; } }

._3Xzi9tO0z9wV9jpG81gge- {
  text-align: center; }

._1TAyjxAiEBy8VsERZCXcjB {
  font-size: 1.6rem;
  line-height: 1.7;
  margin: 0; }
  @media (max-width: 991px) {
    ._1TAyjxAiEBy8VsERZCXcjB {
      font-size: 1.4rem; } }

._1KTEFoASkZET3wRahPRH5J {
  font-size: 1.125rem;
  line-height: 1.8;
  margin: 0;
  word-break: break-word;
  color: #99a1a7; }

._1EBHtn6sUtlyZOEgxo-L0 {
  max-height: 100%;
  width: auto; }
  @media (max-width: 991px) {
    ._1EBHtn6sUtlyZOEgxo-L0 {
      max-width: 100%;
      height: auto; } }

.rmxgCKyfFmODM-jmCVmJJ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
      align-self: flex-start;
  background: #00a69c;
  color: #fff; }
  .rmxgCKyfFmODM-jmCVmJJ:hover {
    background: #00877f; }
  .rmxgCKyfFmODM-jmCVmJJ:active {
    background: #007d76; }
  @media all and (max-width: 1380px) {
    .rmxgCKyfFmODM-jmCVmJJ {
      -ms-flex-item-align: center;
          align-self: center; } }
  .rmxgCKyfFmODM-jmCVmJJ.hDuvQMPafNIlOzs7gkG9O {
    background: #2591cf; }
    .rmxgCKyfFmODM-jmCVmJJ.hDuvQMPafNIlOzs7gkG9O:hover {
      background: #207fb5; }
    .rmxgCKyfFmODM-jmCVmJJ.hDuvQMPafNIlOzs7gkG9O:active {
      background: #1f79ac; }
