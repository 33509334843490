.GzZ10tp_9MQ_EESPZ_8QV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  padding: 4rem 0 8rem;
  grid-gap: 10rem;
  gap: 10rem; }
  .GzZ10tp_9MQ_EESPZ_8QV ._3fPUaA1aWL6jFcC1e5EM-_ {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-gap: 4rem;
    gap: 4rem; }
  .GzZ10tp_9MQ_EESPZ_8QV ._17ivyJJidUCPcBxympD_mK {
    margin: 0 auto;
    max-width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-gap: 4rem;
    gap: 4rem; }
    @media (max-width: 991px) {
      .GzZ10tp_9MQ_EESPZ_8QV ._17ivyJJidUCPcBxympD_mK {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse; }
        .GzZ10tp_9MQ_EESPZ_8QV ._17ivyJJidUCPcBxympD_mK.LUFTsm4zvOqiAq0zwLEKB {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column; } }
  .GzZ10tp_9MQ_EESPZ_8QV ._2pOnmApwI7s3jq3KSmDUO5 {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .GzZ10tp_9MQ_EESPZ_8QV ._2pOnmApwI7s3jq3KSmDUO5 img {
      width: 100%;
      max-width: 800px;
      height: auto; }
  .GzZ10tp_9MQ_EESPZ_8QV .XoB4CPsEW5tT2PTPv2wsa {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
    @media (max-width: 991px) {
      .GzZ10tp_9MQ_EESPZ_8QV .XoB4CPsEW5tT2PTPv2wsa {
        width: 90%; } }
    .GzZ10tp_9MQ_EESPZ_8QV .XoB4CPsEW5tT2PTPv2wsa img {
      -ms-flex-item-align: center;
          align-self: center;
      max-width: 200px;
      height: auto; }
    .GzZ10tp_9MQ_EESPZ_8QV .XoB4CPsEW5tT2PTPv2wsa h1,
    .GzZ10tp_9MQ_EESPZ_8QV .XoB4CPsEW5tT2PTPv2wsa h2 {
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      text-align: center; }
    .GzZ10tp_9MQ_EESPZ_8QV .XoB4CPsEW5tT2PTPv2wsa span {
      margin-bottom: 1rem;
      display: block; }
    .GzZ10tp_9MQ_EESPZ_8QV .XoB4CPsEW5tT2PTPv2wsa ._10qugeSEUQcdmkyeEaZ0aE a {
      display: block;
      margin-bottom: 0.5rem; }
