._1hHdpIl0cO44018RCAw_NX {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 0 2rem;
  min-height: calc(100vh - 80px); }

._1mQCwpLTViXQNfbfin87Ua {
  max-width: 90%;
  width: 1200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  align-items: center;
  margin-bottom: 2rem; }

._3YThhfhAA7LAcD2qlvgJid {
  text-align: center;
  color: #030b0d;
  font-size: 3.8rem;
  line-height: 5rem;
  letter-spacing: 0.005em;
  margin-bottom: 4rem; }

._22tHOlfKVvzLAmYRiTvIzh {
  text-align: center;
  color: #030b0d;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.005em;
  font-weight: 700;
  margin-bottom: 2rem; }
  ._22tHOlfKVvzLAmYRiTvIzh._3WrNx6pZQEgbNs7FmaImHg {
    border: 1px solid #030b0d;
    border-radius: 5px;
    padding: 1rem; }

._3Mvse6lbd9lCfGft1OpJnA {
  margin: 3rem auto; }

._1lHgWF0wLAmPF_xO8bojv3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  padding: 0.85rem;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 300px;
  margin: 0 auto; }
  ._1lHgWF0wLAmPF_xO8bojv3:hover, ._1lHgWF0wLAmPF_xO8bojv3:active, ._1lHgWF0wLAmPF_xO8bojv3:focus {
    color: #fff; }

.M-liat3KnHIjJ_6ujCOjz {
  background-color: #00a69c; }
  .M-liat3KnHIjJ_6ujCOjz:hover {
    background-color: #00c5b9; }
  .M-liat3KnHIjJ_6ujCOjz:active {
    background-color: #00877f; }

._29QynDlTuZ2ztYkSXCxuS {
  background-color: #2591cf; }
  ._29QynDlTuZ2ztYkSXCxuS:hover {
    background-color: #379fdb; }
  ._29QynDlTuZ2ztYkSXCxuS:active {
    background-color: #207fb5; }

._20EGhHtFPFke6g-S7EuL9v {
  background-color: #f5371b; }
  ._20EGhHtFPFke6g-S7EuL9v:hover {
    background-color: #f65138; }
  ._20EGhHtFPFke6g-S7EuL9v:active {
    background-color: #e7270a; }

._3ycZMydQBUR6IZag7Gddbj {
  background-color: #fbb703; }
  ._3ycZMydQBUR6IZag7Gddbj:hover {
    background-color: #fcc020; }
  ._3ycZMydQBUR6IZag7Gddbj:active {
    background-color: #dda103; }
