._19OdBuv6R9GWVop4y02wzX {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  max-width: 1200px;
  padding: 1rem 1.125rem 10rem 1.125rem;
  margin: 0 auto;
  margin-top: -5rem; }
  @media (max-width: 991px) {
    ._19OdBuv6R9GWVop4y02wzX {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      margin: 0; } }
  ._19OdBuv6R9GWVop4y02wzX p {
    line-height: 2;
    color: #030b0d; }
  ._19OdBuv6R9GWVop4y02wzX h3 {
    font-size: 1.5rem;
    line-height: 1.6;
    color: #030b0d; }
  ._19OdBuv6R9GWVop4y02wzX ol li,
  ._19OdBuv6R9GWVop4y02wzX ul li {
    line-height: 2;
    margin-top: 1.25rem;
    color: #030b0d; }
  ._19OdBuv6R9GWVop4y02wzX h2 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.6;
    color: #030b0d;
    margin-bottom: 1rem; }
    @media (max-width: 991px) {
      ._19OdBuv6R9GWVop4y02wzX h2 {
        font-size: 1.75rem;
        line-height: 1.8; } }
  ._19OdBuv6R9GWVop4y02wzX a {
    color: #030b0d;
    text-decoration: none;
    -webkit-transition: 0.3s color;
    transition: 0.3s color; }
    ._19OdBuv6R9GWVop4y02wzX a:hover {
      color: #2892d0; }
    ._19OdBuv6R9GWVop4y02wzX a:active, ._19OdBuv6R9GWVop4y02wzX a:focus {
      color: #4ea8dd; }
  ._19OdBuv6R9GWVop4y02wzX ._10WlGOUiyHJ2P_NAK-yF08 {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 25%;
    padding-right: 0.625rem;
    height: calc(100vh - 100px);
    overflow-y: auto;
    margin-top: 3.75rem;
    -webkit-transition: left 0.5s ease, top 0.5s ease;
    transition: left 0.5s ease, top 0.5s ease; }
    @media (max-width: 991px) {
      ._19OdBuv6R9GWVop4y02wzX ._10WlGOUiyHJ2P_NAK-yF08 {
        top: 9;
        width: 100%;
        right: auto;
        left: -1800px;
        padding: 0;
        margin: 0;
        height: calc(100vh - 75px);
        position: fixed;
        z-index: 5;
        padding-top: 4.09rem;
        padding-bottom: 2rem;
        background-color: #fff; }
        ._19OdBuv6R9GWVop4y02wzX ._10WlGOUiyHJ2P_NAK-yF08._2tcS_x5uukM_wTn_GiuWCw {
          background-color: #fff; } }
    ._19OdBuv6R9GWVop4y02wzX ._10WlGOUiyHJ2P_NAK-yF08 .lo9_pE6yJNCCzL0Zde6Az {
      list-style: none;
      padding-left: 0.938rem; }
      ._19OdBuv6R9GWVop4y02wzX ._10WlGOUiyHJ2P_NAK-yF08 .lo9_pE6yJNCCzL0Zde6Az li {
        cursor: pointer; }
        ._19OdBuv6R9GWVop4y02wzX ._10WlGOUiyHJ2P_NAK-yF08 .lo9_pE6yJNCCzL0Zde6Az li a {
          color: #030b0d; }
          ._19OdBuv6R9GWVop4y02wzX ._10WlGOUiyHJ2P_NAK-yF08 .lo9_pE6yJNCCzL0Zde6Az li a:hover {
            opacity: 0.5; }
        ._19OdBuv6R9GWVop4y02wzX ._10WlGOUiyHJ2P_NAK-yF08 .lo9_pE6yJNCCzL0Zde6Az li ul li {
          font-size: 1rem; }
    ._19OdBuv6R9GWVop4y02wzX ._3KSS1tbNkeQpQRL-EZ5-QZ {
      background: #030b0d; }
      ._19OdBuv6R9GWVop4y02wzX ._3KSS1tbNkeQpQRL-EZ5-QZ._2tcS_x5uukM_wTn_GiuWCw {
        background: #fff; }
    @media (max-width: 991px) {
      ._19OdBuv6R9GWVop4y02wzX ._37QJv0gqW8frntCS6px5QS ._3CLk8L_4TbPozKQj5UiOLA {
        pointer-events: all;
        opacity: 1; } }
    @media (max-width: 991px) {
      ._19OdBuv6R9GWVop4y02wzX ._37QJv0gqW8frntCS6px5QS {
        left: 0 !important; } }
  ._19OdBuv6R9GWVop4y02wzX ._1E-yNKNe4BTCuMa8wcLk3a {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 72%;
    padding: 3.75rem 1rem 0 1rem;
    margin-left: auto; }
    @media (max-width: 991px) {
      ._19OdBuv6R9GWVop4y02wzX ._1E-yNKNe4BTCuMa8wcLk3a {
        width: 100%;
        padding: 0 1rem 0 1rem; } }
    ._19OdBuv6R9GWVop4y02wzX ._1E-yNKNe4BTCuMa8wcLk3a ._2kL_4Mv8gFl-g0pafRWv1G {
      display: none; }
    ._19OdBuv6R9GWVop4y02wzX ._1E-yNKNe4BTCuMa8wcLk3a p {
      font-size: 1rem; }
  ._19OdBuv6R9GWVop4y02wzX ::-webkit-scrollbar {
    width: 2px; }
  ._19OdBuv6R9GWVop4y02wzX ::-webkit-scrollbar-track {
    background: #f0f0f0; }
  ._19OdBuv6R9GWVop4y02wzX ::-webkit-scrollbar-thumb {
    background: #c4c4c4; }
  ._19OdBuv6R9GWVop4y02wzX ::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4; }
