._11Nfq5IKYOLG5qRv2ZV7tK {
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
  color: #030b0d; }
  ._11Nfq5IKYOLG5qRv2ZV7tK ol li,
  ._11Nfq5IKYOLG5qRv2ZV7tK ul li {
    line-height: 30px;
    margin-top: 20px;
    color: #030b0d; }
  ._11Nfq5IKYOLG5qRv2ZV7tK ._2U5NAKw_QWDs09ZLPjU_lR {
    list-style: none;
    padding-left: 10px;
    cursor: pointer;
    font-weight: bold; }
    ._11Nfq5IKYOLG5qRv2ZV7tK ._2U5NAKw_QWDs09ZLPjU_lR._2yLHDEdYJ1LwX2NQu1Dgqt {
      border-left: 4px solid #14b3d2; }
    ._11Nfq5IKYOLG5qRv2ZV7tK ._2U5NAKw_QWDs09ZLPjU_lR._2Z9bsZGJhuAJ0G9DyXbMVl {
      border-left: 4px solid #ffd15c; }
    ._11Nfq5IKYOLG5qRv2ZV7tK ._2U5NAKw_QWDs09ZLPjU_lR._1VVfxoa6SVldUgQBWnnU_5 {
      border-left: 4px solid #dd512a; }
    ._11Nfq5IKYOLG5qRv2ZV7tK ._2U5NAKw_QWDs09ZLPjU_lR._2UkXIXgQuzLutadcbBdoJI {
      border-left: 4px solid #00a69c; }
    ._11Nfq5IKYOLG5qRv2ZV7tK ._2U5NAKw_QWDs09ZLPjU_lR a {
      color: #030b0d; }
      ._11Nfq5IKYOLG5qRv2ZV7tK ._2U5NAKw_QWDs09ZLPjU_lR a:hover {
        opacity: 0.5; }
  ._11Nfq5IKYOLG5qRv2ZV7tK ._1HX-7nHugOn_awNQL3RSqa {
    list-style: none;
    padding-left: 15px; }
    ._11Nfq5IKYOLG5qRv2ZV7tK ._1HX-7nHugOn_awNQL3RSqa li {
      cursor: pointer; }
      ._11Nfq5IKYOLG5qRv2ZV7tK ._1HX-7nHugOn_awNQL3RSqa li a {
        color: #030b0d; }
        ._11Nfq5IKYOLG5qRv2ZV7tK ._1HX-7nHugOn_awNQL3RSqa li a:hover {
          opacity: 0.5; }
      ._11Nfq5IKYOLG5qRv2ZV7tK ._1HX-7nHugOn_awNQL3RSqa li ul li {
        font-size: 16px; }
