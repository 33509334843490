._1rNfCZsaoxYT8-9jc4BKtN {
  margin-bottom: 20px;
  overflow: auto;
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px; }

._2yHZSFFbaxckrLnLp8_Amg {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }
  ._2yHZSFFbaxckrLnLp8_Amg thead {
    background-color: transparent; }
  ._2yHZSFFbaxckrLnLp8_Amg tr {
    border: none; }
    @media (max-width: 1240px) {
      ._2yHZSFFbaxckrLnLp8_Amg tr {
        display: table;
        border-bottom: 1px solid #f4f4f4;
        width: 100%;
        margin-bottom: 1rem; }
        ._2yHZSFFbaxckrLnLp8_Amg tr th {
          display: none; }
        ._2yHZSFFbaxckrLnLp8_Amg tr td {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          ._2yHZSFFbaxckrLnLp8_Amg tr td:last-child {
            padding-bottom: 1rem; } }
    @media (max-width: 1240px) and (max-width: 1240px) {
      ._2yHZSFFbaxckrLnLp8_Amg tr td {
        padding: 0.5rem 1rem; } }

._3eSBAbxc2p4u7PxzIQUpo2 {
  color: #62737f; }
  ._3eSBAbxc2p4u7PxzIQUpo2._3HpuiuHW3zLB1pI6dy2Dk3 {
    color: #fa1854; }
  ._3eSBAbxc2p4u7PxzIQUpo2:hover {
    background-color: #f6f9fb; }

._3XxYb7d8U86sJ_F1GL9CDn {
  padding: 1rem; }
  ._3XxYb7d8U86sJ_F1GL9CDn ._20JAJSTvVdYaFHDNRp0KOM {
    display: none;
    margin-left: 0.5rem; }
    @media (max-width: 1240px) {
      ._3XxYb7d8U86sJ_F1GL9CDn ._20JAJSTvVdYaFHDNRp0KOM {
        display: block; } }

._1Jnu8mhk1Afybpb4mnKxDs {
  font-size: 1rem;
  font-weight: 600;
  position: relative; }
  ._1Jnu8mhk1Afybpb4mnKxDs label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative; }
    ._1Jnu8mhk1Afybpb4mnKxDs label:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #1167f3;
      border-radius: 3px;
      display: inline-block;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }
    ._1Jnu8mhk1Afybpb4mnKxDs label ._2WsoyfQ7UD8IMRof5Tt93W {
      display: none; }
      ._1Jnu8mhk1Afybpb4mnKxDs label ._2WsoyfQ7UD8IMRof5Tt93W:checked + span:before {
        position: absolute;
        content: '\2713';
        background: #1167f3;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 3px;
        text-align: center;
        line-height: 18px;
        font-size: 12px;
        display: inline-block;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        position: absolute; }
    ._1Jnu8mhk1Afybpb4mnKxDs label span {
      width: 100%;
      text-overflow: ellipsis; }
  ._1Jnu8mhk1Afybpb4mnKxDs.SVWNQDqh1FLweNIU2WYB- {
    color: #1167f3; }
  ._1Jnu8mhk1Afybpb4mnKxDs .qmqchEJIFY8c02qPNmdh4 {
    color: #fa1854; }

._3RQZ21bWMJ28rFd2Sz64Px {
  font-weight: 700;
  text-align: left;
  position: relative;
  padding-left: 20px; }
  ._3RQZ21bWMJ28rFd2Sz64Px:before {
    content: '';
    background: #00b4d8 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    left: 0;
    top: 0.3rem;
    position: absolute; }
    @media (max-width: 1240px) {
      ._3RQZ21bWMJ28rFd2Sz64Px:before {
        left: 0.2rem; } }
  ._2cxQodcwZ8ko0hkrqut7wB:before {
    background-color: #2892d0; }
  ._23M3sEQ7u4fKxTxRYC_3D6:before {
    background-color: #00a69c; }
  ._8TMLUrqf2ppYY7pdGGdlH:before, .g43rEPR7q94eJptzrMJXa:before {
    background-color: #f5371b; }
  ._16Scyv3vKtGqWpBSXfCbEi:before, .sNAwOHX8lHpKpuBPh4uv4:before {
    background-color: #fbb703; }

.ca6kdHryXx3ODqhJO0FIB {
  font-weight: 600;
  font-size: 1rem; }
  .ca6kdHryXx3ODqhJO0FIB.SVWNQDqh1FLweNIU2WYB- {
    color: #1f8e0d; }
  .ca6kdHryXx3ODqhJO0FIB._3HpuiuHW3zLB1pI6dy2Dk3 {
    color: #fa1854; }

._2qRp4JCbN4CoZyYzGn7KCH ._3HSadWASSF9EInMlGFJKAc {
  display: none; }

._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF {
  position: relative;
  width: 41px;
  height: 23px;
  margin-right: 10px;
  display: block;
  margin: 0 auto; }
  @media (max-width: 1240px) {
    ._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF {
      margin: 0; } }
  ._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border-radius: 100px;
    width: 41px;
    height: 23px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease; }
  ._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF:after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    background: #ffffff 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 3px 6px #00000029;
            box-shadow: 0px 3px 6px #00000029;
    border-radius: 130px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease; }
  ._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF._1QY1pha5cWk1r0qtAvN85D:before {
    background: #1791d3 0% 0% no-repeat padding-box; }
  ._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF._1QY1pha5cWk1r0qtAvN85D:after {
    left: 19px; }
  ._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF._9mff0H9l9PMaUG5XuwWu1 {
    -webkit-filter: grayscale(1);
            filter: grayscale(1); }
    ._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF._9mff0H9l9PMaUG5XuwWu1:before, ._2qRp4JCbN4CoZyYzGn7KCH ._2WFpSDXezgAs8rIWQwMzEF._9mff0H9l9PMaUG5XuwWu1:after {
      cursor: not-allowed; }

._2TUodiQhLlFX85d7laLrdQ {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer; }
  ._2TUodiQhLlFX85d7laLrdQ._3WmekVWMPzq8bpXQTSroAd {
    color: #1167f3;
    background-color: #318dbc1c;
    border: 1px solid #1167f3; }
    ._2TUodiQhLlFX85d7laLrdQ._3WmekVWMPzq8bpXQTSroAd:hover {
      background-color: rgba(28, 80, 107, 0.109804); }
  ._2TUodiQhLlFX85d7laLrdQ._2MkClVRB4k4KxYPURoOgAu {
    color: #fa1854;
    background: #fa18541c 0% 0% no-repeat padding-box;
    border: 1px solid #fa1854; }
    ._2TUodiQhLlFX85d7laLrdQ._2MkClVRB4k4KxYPURoOgAu:hover {
      background-color: rgba(252, 124, 158, 0.109804); }
  ._2TUodiQhLlFX85d7laLrdQ[disabled], ._2TUodiQhLlFX85d7laLrdQ[disabled]:hover {
    background-color: #e6eaf2;
    color: #5c6066;
    border: 1px solid #e6eaf2; }

.PgR7MaM1-I9VEqutT2TM6 {
  font-size: 0.8rem; }

._12zAM7N1VHZ_QQYWu_s-r_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    ._12zAM7N1VHZ_QQYWu_s-r_ {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
