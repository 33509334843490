._2sE6Iohggv-FNFToSi0uQG ._2cMC3IQEjX-Zw1WbNtQOzZ, ._2sE6Iohggv-FNFToSi0uQG .Fsgc2CbuOmYwpqStoVSuM, ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7 {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2sE6Iohggv-FNFToSi0uQG ._2cMC3IQEjX-Zw1WbNtQOzZ, ._2sE6Iohggv-FNFToSi0uQG .Fsgc2CbuOmYwpqStoVSuM, ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7 {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2sE6Iohggv-FNFToSi0uQG ._2cMC3IQEjX-Zw1WbNtQOzZ, ._2sE6Iohggv-FNFToSi0uQG .Fsgc2CbuOmYwpqStoVSuM, ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7 {
      font-size: 1.25rem;
      line-height: 1.8; } }

._1K3zLgguIqGz5UIQw4NAPw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin: 5rem auto;
  padding: 0 1.25rem; }
  @media (max-width: 991px) {
    ._1K3zLgguIqGz5UIQw4NAPw {
      margin: 3rem auto; } }

._2sE6Iohggv-FNFToSi0uQG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  max-width: 1400px;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 4rem 3rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #eff2f4;
  border-radius: 12px; }
  @media (max-width: 991px) {
    ._2sE6Iohggv-FNFToSi0uQG {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      padding: 2rem 1rem;
      text-align: center;
      grid-gap: 1rem;
      gap: 1rem; } }
  ._2sE6Iohggv-FNFToSi0uQG ._2cMC3IQEjX-Zw1WbNtQOzZ {
    margin: 0; }
  ._2sE6Iohggv-FNFToSi0uQG .Fsgc2CbuOmYwpqStoVSuM {
    color: #030b0d;
    background-color: #f9ca50;
    padding: 0.2rem 1rem;
    font-weight: 600;
    border-radius: 80px;
    font-size: 0.9rem; }
    @media all and (min-width: 290px) {
      ._2sE6Iohggv-FNFToSi0uQG .Fsgc2CbuOmYwpqStoVSuM {
        font-size: 1.125rem; } }
    ._2sE6Iohggv-FNFToSi0uQG .Fsgc2CbuOmYwpqStoVSuM:hover {
      background: #f7bb1f; }
    ._2sE6Iohggv-FNFToSi0uQG .Fsgc2CbuOmYwpqStoVSuM:active {
      background: #f4b308; }
  ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: start;
        align-self: flex-start;
    color: #030b0d;
    font-weight: 600;
    border-radius: 80px;
    border: 2px solid #030b0d;
    padding: 0.2rem 0.2rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    font-size: 0.9rem; }
    @media all and (min-width: 290px) {
      ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7 {
        grid-gap: 0.3rem;
        gap: 0.3rem;
        padding: 0.2rem 0.5rem;
        font-size: 1.125rem; } }
    @media (max-width: 991px) {
      ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7 {
        -ms-flex-item-align: center;
            align-self: center; } }
    ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7:hover {
      background: #00b4d8;
      border-color: #00b4d8;
      color: #fff; }
    ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7:active, ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7:focus {
      background: #009bb9;
      border-color: #009bb9;
      color: #fff; }
    ._2sE6Iohggv-FNFToSi0uQG ._2wMtWvEZoCPwxeaHtONgf7 .QqUyxcfjrMSY_yTNDcDHu {
      width: 30px;
      height: 30px;
      display: block;
      border-radius: 50%; }
