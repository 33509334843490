._1yRyTzpoHXZSlQJkmGhX5E {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

._3e_gMDmqcPObKlMebtuK8S {
  display: none; }

._3iWNb93GhMysIkq4vNS0cn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border: 1px solid #e6eaf2;
  background: #e6eaf2;
  border-radius: 3px;
  font-size: 14px;
  color: #5c6066;
  padding: 10px;
  cursor: pointer; }
  ._3iWNb93GhMysIkq4vNS0cn ._2PyAp1nztQIbqJLi_igqmd {
    margin-right: .5rem; }
  ._3iWNb93GhMysIkq4vNS0cn ._3F9KXBYoAYe0UIrmplj3ks {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  ._3iWNb93GhMysIkq4vNS0cn:hover {
    border: 1px solid silver; }

._1rz2ZoukU7yVRJVD-XP5JL {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }
