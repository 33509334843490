._3PF9133aBJ_YLYyokTIBHJ {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._3PF9133aBJ_YLYyokTIBHJ:before {
    content: '';
    background: #222;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute; }
