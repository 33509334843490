._2RGoJmaYFWLVDuibJ06ph0 ._3-Rgp_czd8__REE_wcMImZ {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._31h2IjGpZxXb5LVxbiTrXV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden; }

._2RGoJmaYFWLVDuibJ06ph0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 7rem 0 0rem;
  margin: 1rem auto 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._2RGoJmaYFWLVDuibJ06ph0 ._3-Rgp_czd8__REE_wcMImZ {
    font-size: 1.375rem;
    line-height: 1.5; }

._3liGTnQw2-Ef6pWuNvgPJI {
  margin: auto;
  overflow: hidden;
  position: relative; }
  ._3liGTnQw2-Ef6pWuNvgPJI ._1a2jd_YMvn9T9wyCy5Uudk {
    -webkit-animation: _167aRLKUzlWhwUa4PqjMmj 80s linear infinite;
            animation: _167aRLKUzlWhwUa4PqjMmj 80s linear infinite;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(250px * 36);
    list-style: none;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-gap: 5rem;
    gap: 5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  @media (max-width: 991px) {
    ._3liGTnQw2-Ef6pWuNvgPJI {
      grid-gap: 1rem;
      gap: 1rem; } }
  @media all and (min-width: 2000px) {
    ._3liGTnQw2-Ef6pWuNvgPJI {
      grid-gap: 7rem;
      gap: 7rem; } }
  ._3liGTnQw2-Ef6pWuNvgPJI .Rl-ZOYxUX-OUrBCkjg0I1 {
    margin: 1rem;
    max-height: 20px; }
    @media all and (min-width: 802px) {
      ._3liGTnQw2-Ef6pWuNvgPJI .Rl-ZOYxUX-OUrBCkjg0I1 {
        max-height: 50px; } }

@-webkit-keyframes _167aRLKUzlWhwUa4PqjMmj {
  0% {
    -webkit-transform: translateX(-970px);
            transform: translateX(-970px); }
  100% {
    -webkit-transform: translateX(calc(-343px * 18));
            transform: translateX(calc(-343px * 18)); } }

@keyframes _167aRLKUzlWhwUa4PqjMmj {
  0% {
    -webkit-transform: translateX(-970px);
            transform: translateX(-970px); }
  100% {
    -webkit-transform: translateX(calc(-343px * 18));
            transform: translateX(calc(-343px * 18)); } }
