._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw ._2yTteBh6UITpqoItsiiFvi .F1AV_OtXvll5YswuYSrtG, ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw ._2yTteBh6UITpqoItsiiFvi .PY4iMDnPEQWc2peu-949P {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw ._2yTteBh6UITpqoItsiiFvi .F1AV_OtXvll5YswuYSrtG, ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw ._2yTteBh6UITpqoItsiiFvi .PY4iMDnPEQWc2peu-949P {
      font-size: 1.25rem;
      line-height: 1.8; } }

._28jyo7SQ8lfX5ZsOPQwkQc {
  padding: 3rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  background: #030b0d; }
  ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl {
    text-align: center; }
    ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._1M6okKzXbtaxF2Ks6J1IM7 {
      color: #fff;
      margin-bottom: 4rem;
      font-size: 1.2rem;
      line-height: 1.8; }
      @media all and (min-width: 768px) {
        ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._1M6okKzXbtaxF2Ks6J1IM7 {
          font-size: 1.5rem; } }
    ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2rem;
      grid-auto-rows: minmax(100px, auto);
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      text-align: center;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      @media all and (min-width: 768px) {
        ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw {
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 0.625rem; } }
      @media (min-width: 640px) and (max-width: 800px) {
        ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw {
          grid-template-columns: repeat(1, 1fr); } }
      ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw ._2yTteBh6UITpqoItsiiFvi ._3_LxYWWJr8mwrFV3wZu8gJ {
        max-width: 50%; }
      ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw ._2yTteBh6UITpqoItsiiFvi .F1AV_OtXvll5YswuYSrtG {
        color: #fff;
        border: 2px solid #fff;
        padding: 0.3rem 1rem;
        border-radius: 50%;
        font-weight: bold; }
      ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw ._2yTteBh6UITpqoItsiiFvi .PY4iMDnPEQWc2peu-949P {
        color: #fff;
        max-width: 70%;
        margin: auto; }
      ._28jyo7SQ8lfX5ZsOPQwkQc ._7UGgb6POyAS4Rp26XqTHl ._25fRtCoHA-70NWOXQHWpUw ._2yTteBh6UITpqoItsiiFvi p {
        margin: 2.5rem 0; }
  ._28jyo7SQ8lfX5ZsOPQwkQc ._1D71EAQzeXvdkZrax2qqGS {
    background: #10181b;
    border-radius: 0 30px 30px 0;
    margin-right: 0;
    margin-left: -2rem;
    padding: 2rem; }
    @media all and (min-width: 768px) {
      ._28jyo7SQ8lfX5ZsOPQwkQc ._1D71EAQzeXvdkZrax2qqGS {
        margin-right: 5rem;
        padding: 4.5rem 3rem 4.5rem 8.5rem; } }
  ._28jyo7SQ8lfX5ZsOPQwkQc ._1xlJeHcW_JlIqnzJOX7KY9 {
    background: #10181b;
    border-radius: 30px 0 0 30px;
    margin-right: -2rem;
    margin-left: 0;
    padding: 2rem; }
    @media all and (min-width: 768px) {
      ._28jyo7SQ8lfX5ZsOPQwkQc ._1xlJeHcW_JlIqnzJOX7KY9 {
        margin-left: 5rem;
        padding: 4.5rem 8.5rem 4.5rem 3rem; } }
