._3onq6HnXIloIIDhp0w31Zl {
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
  margin-top: -5rem;
  padding-top: 5rem; }
  .rkj3-xAGdqrferInnSGhD {
    display: block;
    width: 100%;
    max-width: 1024px;
    margin-top: 10rem;
    margin-right: auto;
    margin-left: auto; }
    ._1IymUZhu26RBrSdhk_6U_u {
      width: 100%;
      position: relative;
      padding-bottom: 52%;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      ._1IymUZhu26RBrSdhk_6U_u > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        border: none; }
  .OiOST9uLWu4iQbxlavvRQ, ._2ifVtZzSj4opQjuy8m1Csb, ._1wU47Pr9zMIk_GMYG3h8WU {
    margin-left: auto;
    margin-right: auto; }
  ._2ifVtZzSj4opQjuy8m1Csb {
    display: block;
    width: 100%;
    max-width: 800px;
    margin-top: 5rem;
    margin-bottom: 2rem; }
  ._1wU47Pr9zMIk_GMYG3h8WU {
    text-align: center;
    max-width: 800px;
    color: #a9b2be;
    font-size: 1.125rem;
    line-height: 1.6; }
  .OiOST9uLWu4iQbxlavvRQ {
    display: block;
    max-width: 90%; }
  ._1l7pXvutXNvXYOM9SQyOzu {
    display: block;
    margin: 6rem auto 3rem;
    text-align: center;
    color: #fff;
    max-width: 900px; }
  ._2fsai3LAipFaiopGBN99Ng {
    display: block;
    width: 100%;
    height: 150px; }
  .H5rNTUAnQHx9VvHoy_8Ji {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 1.875rem;
    padding: 0 1.25rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: center; }
    ._2Gd0H8K5etOo_Ij6eT3ZDr {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-top: 1.25rem;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      width: 30%; }
      .ICarFQYut7J2Cab_YQ2Jz {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -ms-flex-negative: 1;
            flex-shrink: 1; }
      ._2kfJq_spVF2mgLZ22Pm3Gv, ._2MsnJkn_1tYYfju1CLUkW4 {
        display: block;
        margin: 0;
        padding: 0;
        margin-top: 1.25rem;
        color: #fff; }
        @media all and (max-width: 1000px) {
          ._2kfJq_spVF2mgLZ22Pm3Gv, ._2MsnJkn_1tYYfju1CLUkW4 {
            text-align: center; } }
      ._2kfJq_spVF2mgLZ22Pm3Gv {
        font-weight: bold;
        font-size: 1.375rem;
        line-height: 26px; }
      ._2MsnJkn_1tYYfju1CLUkW4 {
        font-size: 1.125rem;
        line-height: 1.6; }
    @media (max-width: 991px) {
      .H5rNTUAnQHx9VvHoy_8Ji {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
        ._2Gd0H8K5etOo_Ij6eT3ZDr {
          margin-bottom: 3rem;
          width: 100%; } }
  ._3g_EFlWeby45u-CarDtwYz {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 9rem; }
  .U5VJihpCyMN355EiyI-qc {
    margin-top: 3rem;
    width: 100%;
    max-width: 900px; }
