.ggU35xCn-zmjVsBdKghfk {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

.ggU35xCn-zmjVsBdKghfk {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .ggU35xCn-zmjVsBdKghfk {
      font-size: 1.25rem;
      line-height: 1.8; } }

._2L7M1lqRWjpgEJgGvXW6H5 {
  padding: 6rem 2rem; }
  @media (max-width: 991px) {
    ._2L7M1lqRWjpgEJgGvXW6H5 {
      padding: 0; } }

._3dhlI46kmUJoTfqFjqbyuR {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  background: #fafbfc; }
  ._3dhlI46kmUJoTfqFjqbyuR section {
    margin-top: 0; }
  ._3dhlI46kmUJoTfqFjqbyuR.C3NWKMqGPcQJOOnoclPvD section {
    padding: 0; }
    ._3dhlI46kmUJoTfqFjqbyuR.C3NWKMqGPcQJOOnoclPvD section > div {
      padding: 3rem 2rem; }
  ._3dhlI46kmUJoTfqFjqbyuR._3wVT78doXRS8Wk1Gb0Zjal {
    margin-top: 6rem; }
    @media (max-width: 991px) {
      ._3dhlI46kmUJoTfqFjqbyuR._3wVT78doXRS8Wk1Gb0Zjal {
        margin-top: 0; } }
    ._3dhlI46kmUJoTfqFjqbyuR._3wVT78doXRS8Wk1Gb0Zjal section {
      padding: 3rem 2rem; }

.ggU35xCn-zmjVsBdKghfk {
  text-align: center;
  border-radius: 80px;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
  background: #1a70d4;
  background: linear-gradient(127deg, #1a70d4 13%, #2591cf 100%);
  word-break: break-word; }
  .ggU35xCn-zmjVsBdKghfk:hover {
    background: linear-gradient(-127deg, #1a70d4 13%, #2591cf 100%); }
