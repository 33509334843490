._1MIwI_gz29pW8ZaqlCoTjE {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8rem 5rem 6.5rem;
  max-width: 1400px;
  margin: 0 auto; }
  @media (max-width: 991px) {
    ._1MIwI_gz29pW8ZaqlCoTjE {
      padding: 3rem 1.25rem; } }
  ._1MIwI_gz29pW8ZaqlCoTjE p {
    font-size: 1.25rem;
    line-height: 1.8; }
    @media (max-width: 991px) {
      ._1MIwI_gz29pW8ZaqlCoTjE p {
        line-height: 1.6;
        font-size: 1.125rem; } }
  ._1MIwI_gz29pW8ZaqlCoTjE ._3QWhIea5AdZDiiPm05zWJ1 .PCdgBv7JEywrkexk7iztO {
    text-align: center; }
    @media (max-width: 991px) {
      ._1MIwI_gz29pW8ZaqlCoTjE ._3QWhIea5AdZDiiPm05zWJ1 .PCdgBv7JEywrkexk7iztO {
        font-size: 1.75rem; } }
  ._1MIwI_gz29pW8ZaqlCoTjE ._3QWhIea5AdZDiiPm05zWJ1 ._3HNSG3-8D1VO6uWR8NEan_ {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    ._1MIwI_gz29pW8ZaqlCoTjE ._3QWhIea5AdZDiiPm05zWJ1 ._3HNSG3-8D1VO6uWR8NEan_ ._21U4kfTPLZt0gXBDJl0edo {
      padding: 2rem 4.5rem;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      @media (max-width: 991px) {
        ._1MIwI_gz29pW8ZaqlCoTjE ._3QWhIea5AdZDiiPm05zWJ1 ._3HNSG3-8D1VO6uWR8NEan_ ._21U4kfTPLZt0gXBDJl0edo {
          padding: 0; } }
      ._1MIwI_gz29pW8ZaqlCoTjE ._3QWhIea5AdZDiiPm05zWJ1 ._3HNSG3-8D1VO6uWR8NEan_ ._21U4kfTPLZt0gXBDJl0edo ul {
        padding-left: 1rem; }
      ._1MIwI_gz29pW8ZaqlCoTjE ._3QWhIea5AdZDiiPm05zWJ1 ._3HNSG3-8D1VO6uWR8NEan_ ._21U4kfTPLZt0gXBDJl0edo ._34S4XehBuEKVT5kYjkmgfG {
        line-height: 1.8; }
        @media (max-width: 991px) {
          ._1MIwI_gz29pW8ZaqlCoTjE ._3QWhIea5AdZDiiPm05zWJ1 ._3HNSG3-8D1VO6uWR8NEan_ ._21U4kfTPLZt0gXBDJl0edo ._34S4XehBuEKVT5kYjkmgfG {
            font-size: 1.2rem; } }
