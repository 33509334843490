._140riD-kVmwv_yUqYyW8I3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin: 2rem 0; }

._1xQ4mKeibmij2uIqZvR8j0 {
  color: #657881;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: block; }

._1-4u20tdcx4zQogruUJJD4 {
  font-size: 14px;
  font-weight: 600;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  margin-bottom: 0.5rem;
  display: block; }

._3FWWfn0AlMliZzD--1ncJ7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2rem; }
  @media (max-width: 767px) {
    ._3FWWfn0AlMliZzD--1ncJ7 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
  ._3FWWfn0AlMliZzD--1ncJ7 ._3UaWv2ulDiX_fmyaKIvpWf {
    border: none;
    border-radius: 3px;
    background-color: #e6eaf2;
    width: 400px;
    max-width: 100%;
    padding: 10px; }
    ._3FWWfn0AlMliZzD--1ncJ7 ._3UaWv2ulDiX_fmyaKIvpWf ._3YC-lOfo8Chtk10Q32GGcc {
      padding: 10px; }

._3rfFg5evje3G3i-y_E1MqU {
  background: #c0f2bd 0% 0% no-repeat padding-box;
  color: #0a3807;
  border-radius: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 1rem;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 10px; }
  ._3rfFg5evje3G3i-y_E1MqU ._2lKYzWSv4XpRwQrH1hYTkA {
    margin-right: 0.5rem; }
  @media (max-width: 767px) {
    ._3rfFg5evje3G3i-y_E1MqU {
      margin: 1rem 0; } }

._1beKuNj7aiSl2oUGVciikN {
  margin: 0; }
