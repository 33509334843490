._3qGkET63tfHbjmPLa7-dOp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  min-height: calc(100vh - (80px + 3rem));
  padding-bottom: 5rem; }
  @media (max-width: 991px) {
    ._3qGkET63tfHbjmPLa7-dOp {
      padding: 0 1rem 5rem; } }

.NecFEuOmcvNsEbg6R7ukv {
  max-width: 400px; }

._3PFFL9kO1Vl2InKU6TcVnR {
  color: #00b4d8;
  font-weight: 600; }
  ._3PFFL9kO1Vl2InKU6TcVnR:hover {
    color: #00b4d8;
    text-decoration: underline; }

._3Q7Sl0PYn1Mcn3L8va7Zcj {
  color: #f50000;
  font-size: 2.5rem; }
  @media (max-width: 991px) {
    ._3Q7Sl0PYn1Mcn3L8va7Zcj {
      font-size: 1.75rem;
      line-height: 1.8; } }
  .H_Pt7oAWZi4L0aBFz8jIN {
    font-size: 1.1rem; }
