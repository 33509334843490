.Fx-xGFNP17FgdqolZ18Yb, ._3GYm2tqca6G14PYTagB31n ._3SBlnOS0lipyN6vb7YJHOh {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._Ei7NxcYrUwp68VXjEpLq {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._Ei7NxcYrUwp68VXjEpLq {
      font-size: 2.5rem;
      line-height: 1.7; } }

.uH5Rsk4uUrJ8GsMa1eYwR, .Fx-xGFNP17FgdqolZ18Yb, ._3GYm2tqca6G14PYTagB31n ._3SBlnOS0lipyN6vb7YJHOh {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .uH5Rsk4uUrJ8GsMa1eYwR, .Fx-xGFNP17FgdqolZ18Yb, ._3GYm2tqca6G14PYTagB31n ._3SBlnOS0lipyN6vb7YJHOh {
      font-size: 1.25rem;
      line-height: 1.8; } }

.YkWOOrY1rbK2Py3AlYrMa {
  padding: 0 2rem 6rem; }
  @media (max-width: 991px) {
    .YkWOOrY1rbK2Py3AlYrMa {
      padding: 0; } }
  .YkWOOrY1rbK2Py3AlYrMa h2,
  .YkWOOrY1rbK2Py3AlYrMa p {
    color: #fff;
    word-break: break-word; }

.SDAibz4W-NDi-y8BF3oKR {
  max-width: 1400px;
  margin: 0 auto;
  background: #1a70d4;
  background: linear-gradient(36deg, #1a70d4 7%, #2591cf 71%, #2fa6eb 100%); }

.fm_UQC_5h74IrY6jvWyRO {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 3rem 3rem 2rem; }
  @media (max-width: 991px) {
    .fm_UQC_5h74IrY6jvWyRO {
      padding: 3rem 2rem; } }

._Ei7NxcYrUwp68VXjEpLq {
  text-align: center;
  margin-bottom: 0.5rem; }

.uH5Rsk4uUrJ8GsMa1eYwR {
  text-align: center;
  margin-bottom: 1.5rem; }

._3ob2OayNuUSieVVA_vFlz2 {
  position: relative; }

._3bTbWGCAni446hlsbM_QRt {
  max-width: 100%;
  height: auto; }

._2awMMOp_zbDODGJLa2t25s {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  padding: 0;
  margin: 0;
  grid-gap: 2rem;
  gap: 2rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 3rem; }
  @media all and (max-width: 480px) {
    ._2awMMOp_zbDODGJLa2t25s {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      grid-gap: 1rem;
      gap: 1rem; } }

.Fx-xGFNP17FgdqolZ18Yb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-weight: 600; }
  .Fx-xGFNP17FgdqolZ18Yb span {
    margin-left: 0.5rem;
    display: block; }

._36baFUkqDE0xF-hnaB1msy {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: rgba(3, 11, 13, 0.3);
  border: 1px solid #fff;
  border-radius: 50%;
  height: 105px;
  width: 105px;
  cursor: pointer;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: absolute;
  top: 35%;
  left: 52%;
  -webkit-transition: 0.3s -webkit-transform;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform; }
  @media all and (max-width: 1180px) {
    ._36baFUkqDE0xF-hnaB1msy {
      height: 100px;
      width: 100px; } }
  @media all and (max-width: 480px) {
    ._36baFUkqDE0xF-hnaB1msy {
      height: 50px;
      width: 50px; } }
  ._36baFUkqDE0xF-hnaB1msy ._2XbbTHGGedZcEvPA-LZCmM {
    color: #fff;
    font-size: 3.75rem; }
    @media all and (max-width: 480px) {
      ._36baFUkqDE0xF-hnaB1msy ._2XbbTHGGedZcEvPA-LZCmM {
        font-size: 1.75rem; } }
  ._36baFUkqDE0xF-hnaB1msy:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

._3GYm2tqca6G14PYTagB31n {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 2rem 3rem; }
  ._3GYm2tqca6G14PYTagB31n ._3SBlnOS0lipyN6vb7YJHOh {
    text-align: center;
    border-radius: 80px;
    margin: 0;
    background: #161819;
    word-break: break-word; }
    ._3GYm2tqca6G14PYTagB31n ._3SBlnOS0lipyN6vb7YJHOh:hover {
      background: #292d2f; }
    ._3GYm2tqca6G14PYTagB31n ._3SBlnOS0lipyN6vb7YJHOh:active {
      background: #3c4244; }
    ._3GYm2tqca6G14PYTagB31n ._3SBlnOS0lipyN6vb7YJHOh > div {
      padding: 0 2rem; }
