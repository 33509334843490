._3rgh4mhd7PAUEdZ9L6_08V {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 3rem 2rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._3rgh4mhd7PAUEdZ9L6_08V ._3DVDzK56p15ZegdG5QwF_0 {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 100px;
    margin-top: -250px; }
    @media (max-width: 991px) {
      ._3rgh4mhd7PAUEdZ9L6_08V ._3DVDzK56p15ZegdG5QwF_0 {
        max-width: 80px;
        margin-top: -260px; } }
  ._3rgh4mhd7PAUEdZ9L6_08V ._2UN-9LtJdcQTqaKigSairV {
    text-align: center;
    margin-bottom: 0; }
    @media (max-width: 991px) {
      ._3rgh4mhd7PAUEdZ9L6_08V ._2UN-9LtJdcQTqaKigSairV {
        font-size: 1.75rem;
        line-height: 1.8; } }
  ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa {
    width: 100%;
    max-width: 1400px;
    position: relative;
    overflow: hidden;
    margin: 1rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h {
      list-style: none;
      margin: 0;
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      grid-gap: 4.2rem;
      padding: 4rem 0; }
      @media (max-width: 991px) {
        ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h {
          grid-gap: 0; } }
      ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h .nwjtkMioy7AEn0-AlAJwV {
        font-size: 1.2rem;
        cursor: pointer;
        color: #b9b9b9;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h .nwjtkMioy7AEn0-AlAJwV:hover {
          color: #666666; }
        ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h .nwjtkMioy7AEn0-AlAJwV:active,
        ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h .nwjtkMioy7AEn0-AlAJwV :focus {
          color: #8d8d8d; }
        @media (max-width: 991px) {
          ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h .nwjtkMioy7AEn0-AlAJwV._2lzTaIXm7mHLWAF-NvynPT {
            margin-right: 1rem;
            margin-left: 15rem; } }
        @media (max-width: 991px) {
          ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h .nwjtkMioy7AEn0-AlAJwV._3FOSC3ev7CuGgVfqUJctQy {
            margin-left: 1rem;
            margin-right: 15rem; } }
        @media (max-width: 991px) {
          ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h .nwjtkMioy7AEn0-AlAJwV {
            font-size: 2rem; } }
      ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; }
        ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._1V2wLNXTSDifQmzOike1rs {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-flow: column;
                  flex-flow: column;
          background: #fff;
          border-radius: 10px;
          padding: 2rem;
          width: 218px;
          height: 230px;
          color: #030b0d;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          -webkit-box-shadow: 0px 1px 15px 5px #e9ecf2;
                  box-shadow: 0px 1px 15px 5px #e9ecf2; }
          @media (max-width: 991px) {
            ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._1V2wLNXTSDifQmzOike1rs {
              width: 227px;
              height: 250px;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              text-align: center; } }
          ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._1V2wLNXTSDifQmzOike1rs ._2ilOY9ZW8Ajb9rVUNEYcVT {
            width: 50px;
            margin-right: 1rem;
            margin-bottom: 0.625rem;
            -webkit-filter: invert(83%) sepia(39%) saturate(250%) hue-rotate(143deg) brightness(93%) contrast(92%);
                    filter: invert(83%) sepia(39%) saturate(250%) hue-rotate(143deg) brightness(93%) contrast(92%); }
            @media (max-width: 991px) {
              ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._1V2wLNXTSDifQmzOike1rs ._2ilOY9ZW8Ajb9rVUNEYcVT {
                margin: 1rem 0; } }
            ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._1V2wLNXTSDifQmzOike1rs ._2ilOY9ZW8Ajb9rVUNEYcVT._2xfGqzDyBwr4qrNggX5p8z {
              -webkit-filter: invert(4%) sepia(79%) saturate(270%) hue-rotate(145deg) brightness(101%) contrast(103%);
                      filter: invert(4%) sepia(79%) saturate(270%) hue-rotate(145deg) brightness(101%) contrast(103%); }
          ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._1V2wLNXTSDifQmzOike1rs ._28757C2zAGka7aRD0MBbHa {
            font-size: 1.5rem;
            margin-bottom: 0;
            font-weight: 700; }
          ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._1V2wLNXTSDifQmzOike1rs ._3RkXeXpCicmKRgI3ivMtHE {
            line-height: 1.8rem;
            margin-bottom: 0; }
            @media (max-width: 991px) {
              ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._1V2wLNXTSDifQmzOike1rs ._3RkXeXpCicmKRgI3ivMtHE {
                font-size: 1.125rem; } }
        ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr ._3bCMI4QiRdE4WOh2s-Apae,
        ._3rgh4mhd7PAUEdZ9L6_08V .pBz-nCCItW09FtXQvYrGa .WrhPXzTiv5Ht9JS-iyK_h ._2SvhbVuAQgtypcmEI16iUr .N4U2Ucpl1n6xeEFwGQ5fM {
          opacity: 0.5; }
