._3Tph3Ia7IU6PfxtM3SwJue ._39x4qzh36gklfjADM_B1ft > span {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3Tph3Ia7IU6PfxtM3SwJue {
  display: grid;
  grid-template-columns: 1fr 4fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 2rem 0 3rem; }
  ._32H0o1NgxaWq9oXS9ICzal {
    color: #030b0d; }
  ._3gAGpt0M2PiTXfAkSgtnvq .muRijL_pkzojUNeKaJPXX ._39x4qzh36gklfjADM_B1ft:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    -webkit-box-shadow: 0px 0px 9px 2px #d6d7d8;
            box-shadow: 0px 0px 9px 2px #d6d7d8; }
  ._3gAGpt0M2PiTXfAkSgtnvq .muRijL_pkzojUNeKaJPXX ._39x4qzh36gklfjADM_B1ft > span {
    color: #030b0d; }
  .hnHDSLd4xFtdk_ju5tbtB ._2tkiHOotuN2-CTgNbWWtTa {
    color: #fff; }
  .hnHDSLd4xFtdk_ju5tbtB .muRijL_pkzojUNeKaJPXX ._39x4qzh36gklfjADM_B1ft:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    -webkit-box-shadow: 0px 0px 9px 2px #2f2e2b;
            box-shadow: 0px 0px 9px 2px #2f2e2b; }
  .hnHDSLd4xFtdk_ju5tbtB .muRijL_pkzojUNeKaJPXX ._39x4qzh36gklfjADM_B1ft > span {
    color: #fff; }
  ._2tkiHOotuN2-CTgNbWWtTa {
    font-size: 1.5rem;
    margin-bottom: 0;
    text-align: center; }
    @media (max-width: 991px) {
      ._2tkiHOotuN2-CTgNbWWtTa {
        font-size: 1.125rem; } }
    @media all and (max-width: 653px) {
      ._2tkiHOotuN2-CTgNbWWtTa {
        display: none; } }
  .muRijL_pkzojUNeKaJPXX {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    @media all and (max-width: 653px) {
      .muRijL_pkzojUNeKaJPXX {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start; } }
  ._2KIqXb_xPuFjuONcpxDB2M {
    height: 46px; }
  ._3Tph3Ia7IU6PfxtM3SwJue ._39x4qzh36gklfjADM_B1ft {
    background: none;
    border: none;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    outline: none;
    border-radius: 10px;
    padding: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    ._3Tph3Ia7IU6PfxtM3SwJue ._39x4qzh36gklfjADM_B1ft > span {
      margin-left: 1rem;
      font-size: 1.25rem; }
      @media (max-width: 991px) {
        ._3Tph3Ia7IU6PfxtM3SwJue ._39x4qzh36gklfjADM_B1ft > span {
          font-size: 1.125rem; } }
  ._3Tph3Ia7IU6PfxtM3SwJue ._3ZSawMXHNfc6__ShKhEFh3 {
    -webkit-filter: none;
            filter: none; }
  @media (max-width: 991px) {
    ._3Tph3Ia7IU6PfxtM3SwJue {
      margin: 1rem 0; }
      .muRijL_pkzojUNeKaJPXX {
        text-align: center; }
      ._2KIqXb_xPuFjuONcpxDB2M {
        height: 39px; } }
