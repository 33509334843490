._3Rj7sxNB-CeOGOZSHzPruO {
  color: #fff;
  background: #2892d0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.2rem;
  display: block;
  margin-right: 0.5rem;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  width: 100%; }
  ._3Rj7sxNB-CeOGOZSHzPruO:hover {
    background-color: #4ea8dd; }
