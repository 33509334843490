.DLYRR52tCibucCZ7DLovh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  .DLYRR52tCibucCZ7DLovh ._3tK81L__rj75_vCVbGjfEb {
    color: #657881;
    font-size: 0.9rem;
    line-height: 1.3rem;
    margin-bottom: 1rem;
    display: block; }
  .DLYRR52tCibucCZ7DLovh ._1YhbU0j-d9Kbnpq1O83Q0d {
    font-size: 1.2rem;
    font-weight: 600;
    color: #051c26;
    margin-bottom: 0.625rem; }
  .DLYRR52tCibucCZ7DLovh ._3Q8AjoIpgZdhDj4dNx4xJH {
    margin: 0; }
  .DLYRR52tCibucCZ7DLovh .TiIV-65CobvmXa3XVaOwv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap; }
    @media all and (max-width: 1093px) {
      .DLYRR52tCibucCZ7DLovh .TiIV-65CobvmXa3XVaOwv {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column; } }
  .DLYRR52tCibucCZ7DLovh ._2EBzJ8QCAeCXuqoCPR7x3p {
    position: relative;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    background-color: #e6eaf2;
    border-radius: 3px; }
  .DLYRR52tCibucCZ7DLovh ._2aQr5g6ph4RMOcmQCH7E5M {
    position: absolute;
    z-index: -1;
    height: 1px;
    width: 1px; }
  .DLYRR52tCibucCZ7DLovh ._3PNxsG4vn9QxBd0XgC7q-O {
    display: block;
    padding: 0.625rem;
    margin-right: 1.875rem;
    font-size: 0.875rem;
    color: #5c6066;
    height: 16px;
    overflow-x: auto; }
    .DLYRR52tCibucCZ7DLovh ._3PNxsG4vn9QxBd0XgC7q-O._1-nPQepH96SNtQ48f_aLhR:before {
      content: '****************************************************************';
      display: block;
      overflow-x: hidden; }
  .DLYRR52tCibucCZ7DLovh ._1mlL49Ikb4ceshiA0hR8SU {
    right: 0;
    position: absolute;
    font-size: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    padding: 0.625rem; }
  .DLYRR52tCibucCZ7DLovh ._3k12ZbB7je2w4pJv8EID0t {
    margin-bottom: 1rem;
    text-align: right; }
  .DLYRR52tCibucCZ7DLovh ._2hYtEORWnj6ncUwNuJ7NWF {
    height: 36px;
    background: #2892d0 0% 0% no-repeat padding-box;
    color: #fff;
    margin-right: 0.5rem;
    border: unset; }
    .DLYRR52tCibucCZ7DLovh ._2hYtEORWnj6ncUwNuJ7NWF:hover {
      background: #2380b6; }
    .DLYRR52tCibucCZ7DLovh ._2hYtEORWnj6ncUwNuJ7NWF:active {
      background: #217aae; }
    .DLYRR52tCibucCZ7DLovh ._2hYtEORWnj6ncUwNuJ7NWF ._2FRaZnH9WKwqL6MaQnPJg_ {
      -ms-flex-item-align: start;
          align-self: flex-start; }
  .DLYRR52tCibucCZ7DLovh ._2byZCett7oO3HcmTx2JBTy {
    position: absolute;
    bottom: -25px;
    right: 0;
    font-size: 0.8rem;
    padding: 0.313rem 0.313rem 0.25rem;
    color: #fff;
    text-align: center;
    background-color: #030b0d;
    border-radius: 4px;
    opacity: 0; }
    .DLYRR52tCibucCZ7DLovh ._2byZCett7oO3HcmTx2JBTy:before {
      content: ' ';
      position: absolute;
      bottom: 100%;
      /* At the top of the tooltip */
      left: 60%;
      margin-left: -0.3rem;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #030b0d transparent; }
    .DLYRR52tCibucCZ7DLovh ._2byZCett7oO3HcmTx2JBTy._3e0PKzZrqzzAUarERwo25d {
      opacity: 1; }
