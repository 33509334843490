.u3VTmxZS_kOZ3rts0QksE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 40px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1200px;
  padding: 1.875rem 1.25rem;
  margin: 0 auto; }
  .u3VTmxZS_kOZ3rts0QksE h1,
  .u3VTmxZS_kOZ3rts0QksE h2,
  .u3VTmxZS_kOZ3rts0QksE h3,
  .u3VTmxZS_kOZ3rts0QksE h4,
  .u3VTmxZS_kOZ3rts0QksE h5,
  .u3VTmxZS_kOZ3rts0QksE h6,
  .u3VTmxZS_kOZ3rts0QksE p,
  .u3VTmxZS_kOZ3rts0QksE ol,
  .u3VTmxZS_kOZ3rts0QksE ul {
    display: block;
    width: 100%; }
  .u3VTmxZS_kOZ3rts0QksE h1,
  .u3VTmxZS_kOZ3rts0QksE h2,
  .u3VTmxZS_kOZ3rts0QksE h3,
  .u3VTmxZS_kOZ3rts0QksE h4,
  .u3VTmxZS_kOZ3rts0QksE h5,
  .u3VTmxZS_kOZ3rts0QksE h6,
  .u3VTmxZS_kOZ3rts0QksE p,
  .u3VTmxZS_kOZ3rts0QksE ol li,
  .u3VTmxZS_kOZ3rts0QksE ul li {
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
    line-height: 30px;
    margin-top: 1.25rem; }
  .u3VTmxZS_kOZ3rts0QksE h1,
  .u3VTmxZS_kOZ3rts0QksE h2,
  .u3VTmxZS_kOZ3rts0QksE h3,
  .u3VTmxZS_kOZ3rts0QksE h4,
  .u3VTmxZS_kOZ3rts0QksE h5,
  .u3VTmxZS_kOZ3rts0QksE h6 {
    font-weight: bold;
    margin: 2.5rem 0 1rem; }
  .u3VTmxZS_kOZ3rts0QksE h1 {
    font-size: 2.4rem; }
  .u3VTmxZS_kOZ3rts0QksE h2 {
    font-size: 2rem; }
  .u3VTmxZS_kOZ3rts0QksE h3 {
    font-size: 1.6rem; }
  .u3VTmxZS_kOZ3rts0QksE h4 {
    font-size: 1.2rem; }
  .u3VTmxZS_kOZ3rts0QksE h5 {
    font-size: 8rem; }
  .u3VTmxZS_kOZ3rts0QksE a {
    color: #2892d0;
    text-decoration: none;
    -webkit-transition: 0.3s color;
    transition: 0.3s color; }
    .u3VTmxZS_kOZ3rts0QksE a:hover {
      color: #4ea8dd; }
    .u3VTmxZS_kOZ3rts0QksE a:active, .u3VTmxZS_kOZ3rts0QksE a:focus {
      color: #399ed9; }

._1vwS4PNOYd2Cjlac2N1pD1 h1,
._1vwS4PNOYd2Cjlac2N1pD1 h2,
._1vwS4PNOYd2Cjlac2N1pD1 h3,
._1vwS4PNOYd2Cjlac2N1pD1 h4,
._1vwS4PNOYd2Cjlac2N1pD1 h5,
._1vwS4PNOYd2Cjlac2N1pD1 h6,
._1vwS4PNOYd2Cjlac2N1pD1 p,
._1vwS4PNOYd2Cjlac2N1pD1 ol li,
._1vwS4PNOYd2Cjlac2N1pD1 ul li {
  color: #030b0d; }
