._3lSEDJFzwjmhkoD0xxfwgG a, ._2EZiGtwTOEmokonFCEvwcR, ._14h92LUZjH7HnT4h1xPm_l .VyWtcd_34xlScfH5eWfwB {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3Uz808Pc1ygrqCZ3te945u {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: calc(100vh - 80px);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

._1dPqqR9CAf9sjfcdW4DqmI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-flow: column-reverse;
          flex-flow: column-reverse;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 2rem 2rem 5rem 2rem;
  max-width: 1400px;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 1089px) {
    ._1dPqqR9CAf9sjfcdW4DqmI {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      grid-gap: 5rem;
      gap: 5rem; } }

._317HF1Z7xD1DXZX6jeB2q9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative; }
  ._317HF1Z7xD1DXZX6jeB2q9 .zO4MjySAxGwfR0DkAihra {
    z-index: 3; }
    ._317HF1Z7xD1DXZX6jeB2q9 .zO4MjySAxGwfR0DkAihra._33kGeEeBhtwuozwaN1nTgR {
      display: none; }
  ._317HF1Z7xD1DXZX6jeB2q9 ._2p8rVvVA1iZC-zsJsfh_j9 {
    display: none;
    z-index: 2; }
    ._317HF1Z7xD1DXZX6jeB2q9 ._2p8rVvVA1iZC-zsJsfh_j9._33kGeEeBhtwuozwaN1nTgR {
      display: block; }
  ._317HF1Z7xD1DXZX6jeB2q9 .zO4MjySAxGwfR0DkAihra,
  ._317HF1Z7xD1DXZX6jeB2q9 ._2p8rVvVA1iZC-zsJsfh_j9 {
    position: absolute;
    -o-object-fit: cover;
       object-fit: cover;
    width: calc(75vw + 7px);
    border-radius: 6px;
    height: 240px; }
    @media all and (min-width: 768px) {
      ._317HF1Z7xD1DXZX6jeB2q9 .zO4MjySAxGwfR0DkAihra,
      ._317HF1Z7xD1DXZX6jeB2q9 ._2p8rVvVA1iZC-zsJsfh_j9 {
        height: 245px; } }
    @media all and (min-width: 642px) {
      ._317HF1Z7xD1DXZX6jeB2q9 .zO4MjySAxGwfR0DkAihra,
      ._317HF1Z7xD1DXZX6jeB2q9 ._2p8rVvVA1iZC-zsJsfh_j9 {
        height: 430px; } }
    @media all and (min-width: 1018px) {
      ._317HF1Z7xD1DXZX6jeB2q9 .zO4MjySAxGwfR0DkAihra,
      ._317HF1Z7xD1DXZX6jeB2q9 ._2p8rVvVA1iZC-zsJsfh_j9 {
        height: 412px; } }
    @media all and (min-width: 1089px) {
      ._317HF1Z7xD1DXZX6jeB2q9 .zO4MjySAxGwfR0DkAihra,
      ._317HF1Z7xD1DXZX6jeB2q9 ._2p8rVvVA1iZC-zsJsfh_j9 {
        width: 33vw;
        height: 400px; } }
    @media all and (min-width: 1344px) {
      ._317HF1Z7xD1DXZX6jeB2q9 .zO4MjySAxGwfR0DkAihra,
      ._317HF1Z7xD1DXZX6jeB2q9 ._2p8rVvVA1iZC-zsJsfh_j9 {
        width: 633px; } }
  ._317HF1Z7xD1DXZX6jeB2q9 .RiljFUy5CP-5I5hKR47Oq {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #f5f5f5;
    z-index: 1; }
  ._317HF1Z7xD1DXZX6jeB2q9 img,
  ._317HF1Z7xD1DXZX6jeB2q9 .RiljFUy5CP-5I5hKR47Oq {
    border-radius: 6px;
    -o-object-fit: cover;
       object-fit: cover;
    width: calc(75vw + 7px);
    height: 295px; }
    @media all and (min-width: 642px) {
      ._317HF1Z7xD1DXZX6jeB2q9 img,
      ._317HF1Z7xD1DXZX6jeB2q9 .RiljFUy5CP-5I5hKR47Oq {
        height: 450px; } }
    @media all and (min-width: 1089px) {
      ._317HF1Z7xD1DXZX6jeB2q9 img,
      ._317HF1Z7xD1DXZX6jeB2q9 .RiljFUy5CP-5I5hKR47Oq {
        width: 33vw;
        height: 590px; } }
    @media all and (min-width: 1344px) {
      ._317HF1Z7xD1DXZX6jeB2q9 img,
      ._317HF1Z7xD1DXZX6jeB2q9 .RiljFUy5CP-5I5hKR47Oq {
        width: 633px; } }

._3hE5lcHdL_BFWo7ubFcf48 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._1XByIQtv1azMass8aeT9cD {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.5rem; }
  ._1XByIQtv1azMass8aeT9cD ._1qLas434HrlXjDcy2pAGmx {
    height: 45px;
    margin-right: 1rem; }
    @media all and (min-width: 768px) {
      ._1XByIQtv1azMass8aeT9cD ._1qLas434HrlXjDcy2pAGmx {
        height: 50px; } }
  ._1XByIQtv1azMass8aeT9cD .jPliEnUyip0AdXWK8byko {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0; }
    @media all and (min-width: 768px) {
      ._1XByIQtv1azMass8aeT9cD .jPliEnUyip0AdXWK8byko {
        font-size: 1.7rem; } }

._3M3ZdPK-wjY_WGTzCmbB97 {
  font-size: 1.75rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  max-width: 620px; }
  @media all and (min-width: 768px) {
    ._3M3ZdPK-wjY_WGTzCmbB97 {
      font-size: 3rem; } }

._3DAHmjEtdWWP4dzQBgBs9a {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #555565; }

._3lSEDJFzwjmhkoD0xxfwgG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center; }
  ._3lSEDJFzwjmhkoD0xxfwgG a {
    font-size: 1.125rem;
    line-height: 1.8;
    border-radius: 80px;
    padding: 0.5rem 1rem; }

._2EZiGtwTOEmokonFCEvwcR {
  text-transform: uppercase; }

._14h92LUZjH7HnT4h1xPm_l {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  border: 1px solid #161819;
  border-radius: 80px;
  padding: 0.625rem;
  font-size: 0.875rem;
  margin-bottom: -1px; }
  ._14h92LUZjH7HnT4h1xPm_l._2-oNxqL_h6eH1wOt9Na6mX {
    border-radius: 12px 12px 0 0; }
  ._14h92LUZjH7HnT4h1xPm_l input {
    border: none;
    background: transparent;
    margin-left: 0.5rem;
    width: 100%; }
    ._14h92LUZjH7HnT4h1xPm_l input:focus-visible {
      outline: unset; }
  ._14h92LUZjH7HnT4h1xPm_l .VyWtcd_34xlScfH5eWfwB {
    color: #444c53;
    background-color: unset;
    border: unset; }
    ._14h92LUZjH7HnT4h1xPm_l .VyWtcd_34xlScfH5eWfwB:hover {
      color: #606b75; }

._1-VXkt_SWQwHuRBHq3XtuH {
  display: none;
  border-radius: 0 0 12px 12px;
  background: #fff;
  border: 1px solid #161819;
  z-index: 2;
  padding: 1rem;
  position: absolute;
  width: calc(100% - 2rem);
  top: 2.4rem;
  left: -1px; }
  ._1-VXkt_SWQwHuRBHq3XtuH._17VR9Y_BCF7eqU-LSp56VG {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column; }
  ._1-VXkt_SWQwHuRBHq3XtuH ._18No_b8FYG62cwr8UOq7FD {
    font-size: 0.875rem;
    color: #878d97; }
    ._1-VXkt_SWQwHuRBHq3XtuH ._18No_b8FYG62cwr8UOq7FD ._2frkjegyN9CpqkfVcEbejo a {
      color: #00b4d8; }
      ._1-VXkt_SWQwHuRBHq3XtuH ._18No_b8FYG62cwr8UOq7FD ._2frkjegyN9CpqkfVcEbejo a:hover {
        text-decoration: underline;
        color: #0092af;
        font-weight: 600; }
  ._1-VXkt_SWQwHuRBHq3XtuH ._11U894VhLUj4KARQQ6fZU8 {
    text-align: center;
    font-size: 0.875rem; }

.epDZdWN65A3EDnnCqqUXz {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, auto);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  grid-gap: 1rem;
  gap: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 0;
  padding: 0; }
  @media all and (min-width: 362px) {
    .epDZdWN65A3EDnnCqqUXz {
      grid-template-columns: repeat(2, auto); } }
  @media all and (min-width: 520px) {
    .epDZdWN65A3EDnnCqqUXz {
      grid-template-columns: repeat(3, auto); } }
  @media all and (min-width: 655px) {
    .epDZdWN65A3EDnnCqqUXz {
      grid-template-columns: repeat(4, auto); } }
  .epDZdWN65A3EDnnCqqUXz ._3bhIFZde8_20_9pXL6GS8q {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .epDZdWN65A3EDnnCqqUXz ._3bhIFZde8_20_9pXL6GS8q ._3uVDyfIHJdSw2Kn8V_D218 {
      -o-object-fit: cover;
         object-fit: cover;
      width: 129px;
      height: 81px;
      cursor: pointer; }
      .epDZdWN65A3EDnnCqqUXz ._3bhIFZde8_20_9pXL6GS8q ._3uVDyfIHJdSw2Kn8V_D218:hover {
        -webkit-filter: brightness(0.7);
                filter: brightness(0.7); }
      .epDZdWN65A3EDnnCqqUXz ._3bhIFZde8_20_9pXL6GS8q ._3uVDyfIHJdSw2Kn8V_D218._14fafmySX90xw1hxTK4Ero {
        -webkit-filter: brightness(0.5);
                filter: brightness(0.5); }

.VwKcF66nz55AyWJ9QK7sv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 374px) {
    .VwKcF66nz55AyWJ9QK7sv {
      grid-gap: 1rem;
      gap: 1rem; } }
  .VwKcF66nz55AyWJ9QK7sv ._3xnHbQwQ8rQhqmSKPfibe6 {
    -o-object-fit: cover;
       object-fit: cover;
    width: 39px;
    height: 39px;
    border-radius: 3px;
    cursor: pointer; }
    @media all and (min-width: 374px) {
      .VwKcF66nz55AyWJ9QK7sv ._3xnHbQwQ8rQhqmSKPfibe6 {
        width: 55px;
        height: 55px; } }
    @media all and (min-width: 492px) {
      .VwKcF66nz55AyWJ9QK7sv ._3xnHbQwQ8rQhqmSKPfibe6 {
        width: 79px; } }
    .VwKcF66nz55AyWJ9QK7sv ._3xnHbQwQ8rQhqmSKPfibe6:first-child {
      display: none; }
    .VwKcF66nz55AyWJ9QK7sv ._3xnHbQwQ8rQhqmSKPfibe6:hover {
      -webkit-filter: brightness(0.7);
              filter: brightness(0.7); }
    .VwKcF66nz55AyWJ9QK7sv ._3xnHbQwQ8rQhqmSKPfibe6._14fafmySX90xw1hxTK4Ero {
      -webkit-filter: brightness(0.5);
              filter: brightness(0.5); }
  .VwKcF66nz55AyWJ9QK7sv ._3DyjoeCCH98m9suElPip0K {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid #444c53;
    border-radius: 50%;
    font-size: 0.4px;
    cursor: pointer; }
    .VwKcF66nz55AyWJ9QK7sv ._3DyjoeCCH98m9suElPip0K::after {
      content: '';
      width: 9px;
      height: 1px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -4px;
      background-color: #444c53;
      border-radius: 3px; }
    .VwKcF66nz55AyWJ9QK7sv ._3DyjoeCCH98m9suElPip0K:hover {
      border-color: #9a9c9d; }
      .VwKcF66nz55AyWJ9QK7sv ._3DyjoeCCH98m9suElPip0K:hover::after {
        background-color: #9a9c9d; }
    @media all and (min-width: 493px) {
      .VwKcF66nz55AyWJ9QK7sv ._3DyjoeCCH98m9suElPip0K {
        width: 50px;
        height: 50px;
        border: 2px solid #444c53; }
        .VwKcF66nz55AyWJ9QK7sv ._3DyjoeCCH98m9suElPip0K::after {
          width: 14px;
          height: 2px;
          margin-left: -7px; } }
