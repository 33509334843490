._29_3eFdyy4CDGu-dY7Kel9 .agb7inFpLp_-viKGmHg39, ._2biBC-FY4jLxVgqvx16Nkn > a {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

@-webkit-keyframes _2wxPxyBKy7e6jMXkCe2s46 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes _2wxPxyBKy7e6jMXkCe2s46 {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

._3c-YSZWIJArDYsZwwBGeGi {
  background: -webkit-gradient(linear, left top, right top, color-stop(40%, #eef6fb), color-stop(60%, #fff));
  background: linear-gradient(90deg, #eef6fb 40%, #fff 60%);
  margin: 2rem 0 0; }
  @media all and (min-width: 802px) {
    ._3c-YSZWIJArDYsZwwBGeGi {
      padding: 0 2rem;
      margin: 2rem 0 4rem; } }
  ._3c-YSZWIJArDYsZwwBGeGi._1XR7igeyDSHT1CsAkCVYi {
    -webkit-animation: _2wxPxyBKy7e6jMXkCe2s46 1s;
            animation: _2wxPxyBKy7e6jMXkCe2s46 1s; }

._2f5gsJowul495Y6cRQiSSe {
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  border-radius: 0;
  background-color: #eef6fb;
  grid-column-gap: 4rem;
  -webkit-column-gap: 4rem;
     -moz-column-gap: 4rem;
          column-gap: 4rem;
  max-width: 1215px;
  padding: 3rem;
  margin: 0 auto; }
  @media all and (min-width: 1012px) {
    ._2f5gsJowul495Y6cRQiSSe {
      padding: 4rem 3rem; } }
  @media all and (min-width: 802px) {
    ._2f5gsJowul495Y6cRQiSSe {
      border-radius: 0 20px 20px 0; } }
  @media all and (min-width: 1920px) {
    ._2f5gsJowul495Y6cRQiSSe {
      padding: 6rem 3rem; } }

.Jbmq8Y4-kxYTPrX85TVjU {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 0; }
  @media all and (min-width: 802px) {
    .Jbmq8Y4-kxYTPrX85TVjU {
      grid-gap: 2rem;
      gap: 2rem; } }
  @media all and (min-width: 1012px) {
    .Jbmq8Y4-kxYTPrX85TVjU {
      margin-top: -3rem; } }

._29_3eFdyy4CDGu-dY7Kel9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 1rem;
  gap: 1rem; }
  @media all and (min-width: 1012px) {
    ._29_3eFdyy4CDGu-dY7Kel9 {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  ._29_3eFdyy4CDGu-dY7Kel9 .iZGavCDxFEtWqaaYE4aZP {
    height: 35px; }
    @media all and (min-width: 802px) {
      ._29_3eFdyy4CDGu-dY7Kel9 .iZGavCDxFEtWqaaYE4aZP {
        height: 40px; } }
    @media all and (min-width: 1920px) {
      ._29_3eFdyy4CDGu-dY7Kel9 .iZGavCDxFEtWqaaYE4aZP {
        height: 45px; } }
  ._29_3eFdyy4CDGu-dY7Kel9 .agb7inFpLp_-viKGmHg39 {
    font-size: 1.2rem; }
    @media all and (min-width: 802px) {
      ._29_3eFdyy4CDGu-dY7Kel9 .agb7inFpLp_-viKGmHg39 {
        font-size: 1.4rem; } }
    @media all and (min-width: 1920px) {
      ._29_3eFdyy4CDGu-dY7Kel9 .agb7inFpLp_-viKGmHg39 {
        font-size: 1.6rem; } }

.Ibj1J8pNqxyPJ0emblVkh {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.5;
  max-width: 755px;
  text-align: center;
  word-break: break-word; }
  @media all and (min-width: 802px) {
    .Ibj1J8pNqxyPJ0emblVkh {
      font-size: 1.8rem; } }
  @media all and (min-width: 1012px) {
    .Ibj1J8pNqxyPJ0emblVkh {
      text-align: left; } }
  @media all and (min-width: 1920px) {
    .Ibj1J8pNqxyPJ0emblVkh {
      font-size: 2rem; } }

._32QFF9mSGCY6xBz4xgGCJU {
  font-size: 1rem;
  line-height: 1.8;
  margin: 0;
  word-break: break-word; }
  @media all and (min-width: 802px) {
    ._32QFF9mSGCY6xBz4xgGCJU {
      font-size: 1.1rem; } }
  @media all and (min-width: 1920px) {
    ._32QFF9mSGCY6xBz4xgGCJU {
      font-size: 1.15rem; } }

._2biBC-FY4jLxVgqvx16Nkn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  grid-area: unset;
  margin-top: 0; }
  @media all and (min-width: 1012px) {
    ._2biBC-FY4jLxVgqvx16Nkn {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      grid-area: span 1 / span 2 / span 1 / span 2; } }
  @media all and (min-width: 1180px) {
    ._2biBC-FY4jLxVgqvx16Nkn {
      margin-top: -3rem; } }
  @media all and (min-width: 1520px) {
    ._2biBC-FY4jLxVgqvx16Nkn {
      margin-top: -9rem; } }
  @media all and (min-width: 1920px) {
    ._2biBC-FY4jLxVgqvx16Nkn {
      margin-top: -6rem; } }
  ._2biBC-FY4jLxVgqvx16Nkn > a {
    margin: 0;
    border-radius: 80px;
    padding: 0.8rem 2rem !important;
    font-size: 1rem; }
    @media all and (min-width: 802px) {
      ._2biBC-FY4jLxVgqvx16Nkn > a {
        font-size: 1.125rem; } }
  ._2biBC-FY4jLxVgqvx16Nkn .rVDIhBycTUzlDyhK8rs5b {
    background: #161819;
    color: #fff;
    text-align: center; }
    ._2biBC-FY4jLxVgqvx16Nkn .rVDIhBycTUzlDyhK8rs5b:hover {
      background: #292d2f; }
    ._2biBC-FY4jLxVgqvx16Nkn .rVDIhBycTUzlDyhK8rs5b:active {
      background: #2e3234; }

._2FTFhC-N5dhN_RtcvLTeQf {
  text-align: center; }
  @media all and (min-width: 1012px) {
    ._2FTFhC-N5dhN_RtcvLTeQf {
      text-align: left; } }
  ._2FTFhC-N5dhN_RtcvLTeQf img {
    max-width: 100%;
    width: 580px;
    height: auto;
    aspect-ratio: attr(width)/attr(height); }
