._3TfadmkY0R9E341rVbf4H4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  overflow: auto; }

._32C9aN1BHKIrMjneTr01fX {
  color: #657881;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: block; }

.BdoTp-PTs5deoB1GCDfVV {
  margin-bottom: 10px; }

.nSUab7cyaHLgdpN4NEo8W {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px; }

._2tNMRl-7V0XacRZ5zZDhaK {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-bottom: 5px; }
  @media all and (max-width: 660px) {
    ._2tNMRl-7V0XacRZ5zZDhaK {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  ._2tNMRl-7V0XacRZ5zZDhaK button {
    font-size: 14px;
    color: #ffffff;
    border: none;
    padding: 10px;
    background-color: #1791d3;
    border-radius: 3px;
    margin: 0 5px 5px 0;
    cursor: pointer; }
    ._2tNMRl-7V0XacRZ5zZDhaK button:hover {
      background-color: #34a9e9; }

._2tj3XwMvLpNa7okTYg4eKB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  @media all and (max-width: 660px) {
    ._2tj3XwMvLpNa7okTYg4eKB {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }

._3NBdjlfNqrJpJkSbI_a0Q8 {
  font-size: 14px;
  color: #5c6066;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: block;
  margin: 0 10px 5px 0; }
  ._3NBdjlfNqrJpJkSbI_a0Q8 input {
    display: none; }
  ._3NBdjlfNqrJpJkSbI_a0Q8 span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    ._3NBdjlfNqrJpJkSbI_a0Q8 span:after {
      content: ' ';
      width: 0px;
      height: 0px;
      margin-left: 5px; }
  ._3NBdjlfNqrJpJkSbI_a0Q8 input:checked + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  ._3NBdjlfNqrJpJkSbI_a0Q8 input:not(:checked) + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }

._3_MLDCbtYw8jAdCu9ZvOXM {
  background: #f6f9fb 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 5px; }
  ._3_MLDCbtYw8jAdCu9ZvOXM input {
    border: none;
    background: transparent;
    margin-left: 5px; }

._1k-BRSho7lTrqS8DDvXKJ3 {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem; }

._35cTOg6sbjav-PE8PyOaKu {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }
  ._35cTOg6sbjav-PE8PyOaKu ._21m0wzsb94TpT09STaMUuB {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    color: #fa1854;
    background: #fa18541c 0% 0% no-repeat padding-box;
    border: 1px solid #fa1854;
    margin-right: 10px; }
    ._35cTOg6sbjav-PE8PyOaKu ._21m0wzsb94TpT09STaMUuB:hover {
      background-color: rgba(252, 124, 158, 0.109804); }
    ._35cTOg6sbjav-PE8PyOaKu ._21m0wzsb94TpT09STaMUuB._18laxXzGQQ1wlQOgIPGg8S, ._35cTOg6sbjav-PE8PyOaKu ._21m0wzsb94TpT09STaMUuB.oNK0ITzshlU6cZ2Rd5zoL {
      color: #328ebd;
      background-color: #318dbc1c;
      border: 1px solid #328ebd; }
      ._35cTOg6sbjav-PE8PyOaKu ._21m0wzsb94TpT09STaMUuB._18laxXzGQQ1wlQOgIPGg8S:hover, ._35cTOg6sbjav-PE8PyOaKu ._21m0wzsb94TpT09STaMUuB.oNK0ITzshlU6cZ2Rd5zoL:hover {
        background-color: rgba(28, 80, 107, 0.109804); }
    ._35cTOg6sbjav-PE8PyOaKu ._21m0wzsb94TpT09STaMUuB[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }

._42la4X0k6dViYCi03c18e {
  padding: 20px 20px; }

._3r-CK1c-zZHFCIsW8vl761 ._31ZYNB4wQ2s72-N5oA_ErU {
  margin: 45px 60px; }
  @media (max-width: 991px) {
    ._3r-CK1c-zZHFCIsW8vl761 ._31ZYNB4wQ2s72-N5oA_ErU {
      margin: 1.5rem; } }
  ._3r-CK1c-zZHFCIsW8vl761 ._31ZYNB4wQ2s72-N5oA_ErU .TzwGOzmyCbchyprwhmDuv {
    font-size: 24px;
    font-weight: 700;
    color: #051c26;
    display: block;
    margin-bottom: 1rem; }
  ._3r-CK1c-zZHFCIsW8vl761 ._31ZYNB4wQ2s72-N5oA_ErU ._2d8fMQzyLTe_yQTfIq6_6J {
    display: block;
    margin-bottom: 1rem; }
  ._3r-CK1c-zZHFCIsW8vl761 ._31ZYNB4wQ2s72-N5oA_ErU ._3FKSzbg0NhRPgcRre7Ra1Y {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem; }
    ._3r-CK1c-zZHFCIsW8vl761 ._31ZYNB4wQ2s72-N5oA_ErU ._3FKSzbg0NhRPgcRre7Ra1Y li {
      margin-bottom: 0.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
          -ms-flex-align: baseline;
              align-items: baseline; }
      ._3r-CK1c-zZHFCIsW8vl761 ._31ZYNB4wQ2s72-N5oA_ErU ._3FKSzbg0NhRPgcRre7Ra1Y li span:before {
        margin-right: 0.5rem;
        color: #ce2a29;
        font-size: 0.8rem; }

._3r-CK1c-zZHFCIsW8vl761 ._3DF9HKlAvCkQMmwabPwEd2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 0.5rem; }
  ._3r-CK1c-zZHFCIsW8vl761 ._3DF9HKlAvCkQMmwabPwEd2 .RNbSIpNapqy-Vj1OFE8z6 {
    margin-bottom: 0.5rem;
    font-weight: 700; }
  ._3r-CK1c-zZHFCIsW8vl761 ._3DF9HKlAvCkQMmwabPwEd2 ._3jeRpfphUDBj8y-_z43aK8 {
    border: 1px solid #e6e7e8;
    border-radius: 3px;
    padding: 5px; }

._3r-CK1c-zZHFCIsW8vl761 ._2tNMRl-7V0XacRZ5zZDhaK {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._3r-CK1c-zZHFCIsW8vl761 ._2tNMRl-7V0XacRZ5zZDhaK ._21m0wzsb94TpT09STaMUuB {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer; }
    ._3r-CK1c-zZHFCIsW8vl761 ._2tNMRl-7V0XacRZ5zZDhaK ._21m0wzsb94TpT09STaMUuB:hover {
      border: 1px solid silver; }
    ._3r-CK1c-zZHFCIsW8vl761 ._2tNMRl-7V0XacRZ5zZDhaK ._21m0wzsb94TpT09STaMUuB[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  ._3r-CK1c-zZHFCIsW8vl761 ._2tNMRl-7V0XacRZ5zZDhaK ._3aLuFKG2csyWX3pN8lRdkT {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._3r-CK1c-zZHFCIsW8vl761 ._2tNMRl-7V0XacRZ5zZDhaK ._3aLuFKG2csyWX3pN8lRdkT:hover {
      background: #34a9e9;
      color: #fff; }

._3r-CK1c-zZHFCIsW8vl761 ._3wc5eL3mQ1DOUDB_hAYg6V {
  background: transparent;
  border: none;
  color: #2892d0;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0; }
  ._3r-CK1c-zZHFCIsW8vl761 ._3wc5eL3mQ1DOUDB_hAYg6V:hover {
    color: #4ea8dd; }

._3r-CK1c-zZHFCIsW8vl761 ._3LNIS5buiavqW9nu7Axc5u {
  color: #ce2a29;
  margin: 0.5rem 0; }
