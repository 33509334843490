._28lhuSQ1xoxEIOBar8j6pC ._30BRoInmKQLdVtLLbLCapP {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._28lhuSQ1xoxEIOBar8j6pC ._30BRoInmKQLdVtLLbLCapP {
      font-size: 1.25rem;
      line-height: 1.8; } }

._2sBbyi546TYzQ0nzcnGRv6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 900px;
  margin: 0 auto;
  min-height: calc(100vh - 80px); }

._28lhuSQ1xoxEIOBar8j6pC {
  margin: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center; }
  ._28lhuSQ1xoxEIOBar8j6pC ._2w0bSLI4Pm3LvXXECPpaKO {
    font-size: 2.5rem;
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      ._28lhuSQ1xoxEIOBar8j6pC ._2w0bSLI4Pm3LvXXECPpaKO {
        font-size: 1.8rem;
        margin-top: 0; } }
  ._28lhuSQ1xoxEIOBar8j6pC ._30BRoInmKQLdVtLLbLCapP {
    margin: 0 6rem; }
    @media (max-width: 767px) {
      ._28lhuSQ1xoxEIOBar8j6pC ._30BRoInmKQLdVtLLbLCapP {
        margin: 0; } }
  ._28lhuSQ1xoxEIOBar8j6pC ._3dT5WsY01gEnXfHJSTYv_h {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-height: 140px;
    margin: 2.5rem auto; }
