.LhngzI_YmctWmULEgFEqq ._1KXdf7eywegHADKgf_A3yd {
  margin-bottom: 2rem; }

.LhngzI_YmctWmULEgFEqq .sAcUkMUMFr3nU57cVqAdf {
  margin: 2.5rem 0 1rem 0;
  font-size: 1rem; }

.LhngzI_YmctWmULEgFEqq ._197sxdHuk11ZJ2SgzRMSKm {
  margin-bottom: 1rem;
  display: block; }

.LhngzI_YmctWmULEgFEqq .OrOXkpvCzY7ogtL55g8qB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 1rem;
  font-size: 14px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.LhngzI_YmctWmULEgFEqq ._2pwS6ZmmOxOwWzJ86F5xIm {
  font-weight: 600;
  margin: 0 0 0.5rem;
  display: block; }

.LhngzI_YmctWmULEgFEqq ._1r9CHmgIRaZTou1OXgDKhF {
  background-color: #f6f9fb;
  border: none;
  padding: 10px;
  border-radius: 3px;
  color: #5c6066;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 1.2rem; }
  .LhngzI_YmctWmULEgFEqq ._1r9CHmgIRaZTou1OXgDKhF._36akWW_4MKJ4l4aw1H1qlM {
    padding: 0;
    height: 1px;
    width: 1px; }

.LhngzI_YmctWmULEgFEqq ._241P6qapwCGPB6mWcFoHOM {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  .LhngzI_YmctWmULEgFEqq ._241P6qapwCGPB6mWcFoHOM ._1r9CHmgIRaZTou1OXgDKhF {
    padding-right: 35px;
    text-overflow: ellipsis; }

.LhngzI_YmctWmULEgFEqq ._3pxUMV6aiPFdNfodl-ODqA {
  right: 10px;
  position: absolute;
  font-size: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer; }

.LhngzI_YmctWmULEgFEqq .o1DGW519k2DolW6NtyuLL {
  margin-left: 5px; }

._1nJVia2P03u1eqY6C2Qvrg {
  position: absolute;
  bottom: -25px;
  right: 0;
  font-size: 0.8rem;
  padding: 5px 5px 4px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  opacity: 0; }
  ._1nJVia2P03u1eqY6C2Qvrg:before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 60%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent; }
  ._1nJVia2P03u1eqY6C2Qvrg._3TVD4LycTpqbzWrSFbF6h4 {
    opacity: 1; }

._34Ar6na7vp-9fkUsOUN0mq:disabled {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  cursor: not-allowed; }
