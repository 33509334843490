._1DELbEGLtNLWgZTp1KMQ7Y {
  list-style: none; }

.CL1ma_jnhXGCFNDW8BRoI {
  padding-top: 6rem;
  margin-top: -5.5rem; }
  @media (max-width: 991px) {
    .CL1ma_jnhXGCFNDW8BRoI {
      padding-top: 6rem;
      margin-top: -6rem; } }

._3N6ksF-B9OE5xLujNPDLbE {
  padding-top: 5rem; }
  @media (max-width: 991px) {
    ._3N6ksF-B9OE5xLujNPDLbE {
      padding-top: 6rem; } }
  ._3N6ksF-B9OE5xLujNPDLbE a {
    color: #00b4d8; }

._1T3L6q12vRoJDJpXPXzqZ0 {
  font-size: 2rem;
  line-height: 1.6;
  margin-bottom: 1rem; }
  @media (max-width: 991px) {
    ._1T3L6q12vRoJDJpXPXzqZ0 {
      font-size: 1.75rem;
      line-height: 1.8;
      margin-bottom: 1rem; } }
