.l8pNDqGUClugIiXeWoYfz {
  padding-bottom: 3rem;
  color: #2c2c2c;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj {
    border-collapse: collapse;
    color: #62737f; }
    .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj thead {
      background-color: transparent; }
    .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj tbody {
      border-top: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4; }
    .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj tr {
      border: none; }
      .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj tr td:last-child {
        text-align: right; }
      @media (max-width: 767px) {
        .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj tr {
          display: table;
          border-bottom: 1px solid #f4f4f4;
          width: 100%; }
          .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj tr th {
            display: none; }
          .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj tr td {
            display: block;
            padding: 0.5rem; } }
    @media (max-width: 767px) and (max-width: 991px) {
      .l8pNDqGUClugIiXeWoYfz ._395kAfl-RLY0P2y3ncVWnj tr td {
        padding: 0.5rem 1rem; } }
  ._3FEud3eS4H_oroApawUalc {
    color: #62737f; }
  ._2psIvnAQwSd97Am9lyxeD_ {
    font-weight: 600;
    color: #030b0d; }
  ._1x12M3sQbIGD4PHxnOaReg {
    font-weight: 500;
    color: #007ed8; }
  ._3iniKHpKCt60Oh-tki1Z7Z {
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem; }
  .yep04kXeq859KwuWKUOfx {
    font-weight: 600; }
  ._3uk1hYEQmKrnMaZ2Zjz_dA {
    display: block; }
  ._2Llk8fs6PW_8Da83s-yKE2 {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    font-weight: 600;
    opacity: 0.75;
    min-width: 70%;
    display: block;
    text-align: center;
    background-color: #eff0f5;
    color: #0a3807; }
    ._1swOSrFUVt8vCX4PYaQZa2, ._1ps5k5KVqAjsVSeLMvryfQ, ._1QjFan172XKMIBLiayUap0 {
      background: rgba(250, 24, 84, 0.11);
      color: #fa1854; }
    ._1wbjgdNBao2Ocv76W8Aosv {
      background-color: #e8e8e8;
      color: #5a5a5c; }
    .EZcsxuVg623ztIfk3lbWw, ._2DWA6KWWyWpW3MZCEGd3Td {
      background-color: #c0f2bd;
      color: #0a3807; }
  ._27ssOvOFPWA06vXUaieLO9 {
    margin-top: 1.25rem;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  ._3RtdgHD0Mr8TXFsFbOpEep {
    padding: 1.875rem; }

._1j_Apf6pGbAiMaU_qhwi_H {
  color: #ce2a29;
  text-align: center; }

.xEn8GM7PR37-Kcvux1EQ9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    .xEn8GM7PR37-Kcvux1EQ9 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
