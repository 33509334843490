._1hxvjPMMlV0b3DgZKNxXeN {
  margin: 1.25rem 0.75rem; }
  ._1hxvjPMMlV0b3DgZKNxXeN .lCC7DZg2c2iqnZ9OPy000 {
    width: 300px;
    height: 168.75px;
    text-align: center;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    margin-bottom: 1rem;
    padding: 0;
    position: relative;
    overflow: hidden; }
  ._1hxvjPMMlV0b3DgZKNxXeN ._1ZGD6Q1NzZEnSBbE6kb5LW {
    color: red; }
  ._1hxvjPMMlV0b3DgZKNxXeN img {
    position: absolute;
    margin: auto; }
  ._1hxvjPMMlV0b3DgZKNxXeN ._3oqUuI9LcapwjDGe5ufX-J {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    ._1hxvjPMMlV0b3DgZKNxXeN ._3oqUuI9LcapwjDGe5ufX-J ._2QwZJg044W_wAeRb1LvKlM {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      ._1hxvjPMMlV0b3DgZKNxXeN ._3oqUuI9LcapwjDGe5ufX-J ._2QwZJg044W_wAeRb1LvKlM span {
        margin-left: 3.125rem;
        color: #4b4c4d;
        font-size: 16px;
        font-weight: 400; }
    ._1hxvjPMMlV0b3DgZKNxXeN ._3oqUuI9LcapwjDGe5ufX-J ._249GkfZbQLNZqERv6MwW3v {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      margin-left: 1.375rem; }
      ._1hxvjPMMlV0b3DgZKNxXeN ._3oqUuI9LcapwjDGe5ufX-J ._249GkfZbQLNZqERv6MwW3v img {
        height: 1.125rem;
        width: 1.125rem; }

._17jwEl8CbUE7iHO-M_yiV_ {
  opacity: 0.6; }
  ._17jwEl8CbUE7iHO-M_yiV_._25RlQwtbZ9vvzYfwfZKxqn {
    opacity: 1; }
