._3s3l5-ya6FS70GJDB5BKhh {
  min-height: calc(100vh - (80px + 3rem));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  ._3Az4hr64CEMzb2E3JwzrXB {
    background-color: #030b0d; }
  ._3Gv5RPkLFgmJFlbPrC3yPh {
    background-color: #fff; }

.Z_liBPv96JAlPuYjbxaW {
  padding-top: 5rem; }
  .Z_liBPv96JAlPuYjbxaW ._1EXx9_c5h7yoWPd5tCCeMK {
    display: none; }
    @media (max-width: 991px) {
      .Z_liBPv96JAlPuYjbxaW ._1EXx9_c5h7yoWPd5tCCeMK {
        display: block;
        position: fixed;
        width: 100%;
        height: 80px;
        top: 0;
        z-index: 3;
        background-color: #030b0d; } }
  .Z_liBPv96JAlPuYjbxaW .ihH6ed17h0Y9jpgJI0bjR {
    display: none; }
    @media (max-width: 991px) {
      .Z_liBPv96JAlPuYjbxaW .ihH6ed17h0Y9jpgJI0bjR {
        display: block;
        position: fixed;
        width: 100%;
        height: 80px;
        top: 0;
        z-index: 3;
        background-color: #fff; } }
  .Z_liBPv96JAlPuYjbxaW ._2cOZAMpZ6Vw25fg4V1J79k {
    display: none;
    z-index: 3; }
    @media (max-width: 991px) {
      .Z_liBPv96JAlPuYjbxaW ._2cOZAMpZ6Vw25fg4V1J79k {
        display: block;
        width: 120px;
        position: fixed;
        top: 0.9rem;
        left: 1.5rem; } }

.pE_8_GBdLN6XLR9HoScgG {
  height: 100vh;
  display: grid;
  grid-template-columns: 500px 2fr; }
  ._1f-UJBwKO-mX9mH8oNB6xh {
    padding-right: 4rem;
    padding-left: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  ._1nJn5BP07bchZrrnqW5Men, ._1fkMeLDj1ox-1yPlYSfKq2 {
    color: #fff;
    margin-top: auto;
    margin-bottom: auto; }
  ._1nJn5BP07bchZrrnqW5Men {
    font-size: 2.5rem; }
  ._1fkMeLDj1ox-1yPlYSfKq2 span {
    margin-left: 0.5rem; }
  ._69olw6NklH9iE1hJ6a8N_ {
    background: #2892d0; }

.TLlD36FY1aa8u8b_FRvn1 {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._20EgDbdRQWT4sUuPfBogJN {
    height: 400px;
    margin-bottom: 2rem; }
  ._3hjH8rvK8g8x8v7VYeFQCX {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3rem; }
  .NPqUK4kk1zRdjcwqfnLDA {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  ._3JCtnzSVCNrpjlKoKsS4oM {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    margin-right: 2rem; }
    ._2_opH2v7Laf5C7bPQq7SSJ {
      background-color: #fff; }
