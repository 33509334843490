._3ycGu_KuFuqdBJwmQaqWEs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin: 0;
  padding: 6rem 5rem;
  background-color: #1f2021; }
  @media (max-width: 991px) {
    ._3ycGu_KuFuqdBJwmQaqWEs {
      padding: 3rem 1.25rem; } }

.oMPlAzNBH8qm8-wVQLVNu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  max-width: 1400px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._1dAlQcIgpROb8a3PUx5YzA {
  color: #fff;
  margin-bottom: 4rem;
  font-size: 1.625rem;
  line-height: 1.5; }
  @media (max-width: 991px) {
    ._1dAlQcIgpROb8a3PUx5YzA {
      text-align: center; } }

._2wsy0w3nfaKCmTVyi0-e8c {
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 2.5rem;
  gap: 2.5rem; }
  @media all and (min-width: 768px) {
    ._2wsy0w3nfaKCmTVyi0-e8c {
      grid-template-columns: repeat(3, auto); } }
  @media all and (min-width: 1400px) {
    ._2wsy0w3nfaKCmTVyi0-e8c {
      grid-template-columns: repeat(5, auto); } }

.p-UdwdX0GldGkTEdXCjPQ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #2e3034;
  border-radius: 12px;
  padding: 2rem;
  grid-gap: 2rem;
  gap: 2rem;
  width: 175px;
  height: 175px; }
  @media (max-width: 991px) {
    .p-UdwdX0GldGkTEdXCjPQ {
      width: 150px;
      height: 150px; } }
  .p-UdwdX0GldGkTEdXCjPQ ._3SpvLz1UrnGyyXtyZyI2BR {
    display: inline-block;
    height: 45%; }
  .p-UdwdX0GldGkTEdXCjPQ ._1G52jcvoB9SAes5SO4sO8c {
    color: #fff;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 0; }
