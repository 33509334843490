._1oVwRXMQldMEvAsGoX0gRy {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

._1yUcKHA8z7FFJepDc5-4NK,
._3fXFO4N4S-I4t1jxcYzIE9,
._3vocHO8wbvZuDzfETx1TJV,
._25LCMR5fwCpBCPhmqongIZ,
._1zkohbOyLkZYHkr8w5Ixaf,
.CQtlszl9xu0pZyWt2eCDD {
  margin: 0 2px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  color: #758793;
  cursor: pointer;
  min-width: 24px; }
  ._1yUcKHA8z7FFJepDc5-4NK a,
  ._3fXFO4N4S-I4t1jxcYzIE9 a,
  ._3vocHO8wbvZuDzfETx1TJV a,
  ._25LCMR5fwCpBCPhmqongIZ a,
  ._1zkohbOyLkZYHkr8w5Ixaf a,
  .CQtlszl9xu0pZyWt2eCDD a {
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: block; }

._3fXFO4N4S-I4t1jxcYzIE9,
._3vocHO8wbvZuDzfETx1TJV {
  min-width: 24px;
  height: 24px;
  margin: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e9eb;
  border-radius: 3px;
  color: #1791d3; }
  ._3fXFO4N4S-I4t1jxcYzIE9:hover:not(.CQtlszl9xu0pZyWt2eCDD),
  ._3vocHO8wbvZuDzfETx1TJV:hover:not(.CQtlszl9xu0pZyWt2eCDD) {
    border-color: #cad0d4;
    color: #34a9e9; }
  ._3fXFO4N4S-I4t1jxcYzIE9.CQtlszl9xu0pZyWt2eCDD,
  ._3vocHO8wbvZuDzfETx1TJV.CQtlszl9xu0pZyWt2eCDD {
    color: #758793;
    cursor: not-allowed; }

._1yUcKHA8z7FFJepDc5-4NK:hover,
._25LCMR5fwCpBCPhmqongIZ {
  color: #34a9e9;
  border-bottom: 1px solid #1791d3; }

.CQtlszl9xu0pZyWt2eCDD {
  color: #758793; }
