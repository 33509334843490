._1o-1a4W7DowYmpQfMzuXk5 {
  width: 100%;
  margin-bottom: 3rem; }

.jepxviXCmRu_NAPfPvt07 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem; }
  @media (max-width: 1490px) {
    .jepxviXCmRu_NAPfPvt07 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }

._6n1IClXWsSsdX33YsUYJB {
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 2.2rem;
  color: #051c26;
  margin: 0 0.5rem 0.5rem 0; }
  @media (max-width: 1490px) {
    ._6n1IClXWsSsdX33YsUYJB {
      margin-bottom: 1rem; } }

._1a7K1B7ANG2_S-jZ_htYYR {
  font-size: 0.75rem;
  color: #758793; }

._2InmZ_w5Pdgmzbwvky_4m0 {
  margin: 0; }

._2Hrlh8O2-gx32qd4XkxutF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 1490px) {
    ._2Hrlh8O2-gx32qd4XkxutF {
      -ms-flex-item-align: end;
          align-self: flex-end; } }
  @media (max-width: 1240px) {
    ._2Hrlh8O2-gx32qd4XkxutF {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      width: 100%;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
              align-items: stretch; } }
  ._2Hrlh8O2-gx32qd4XkxutF ._28B71XBVS7ZTCgcxAFBtip {
    background: #fff;
    -webkit-box-shadow: 2px 4px 10px rgba(232, 234, 237, 0.3);
            box-shadow: 2px 4px 10px rgba(232, 234, 237, 0.3);
    border-radius: 3px;
    padding: 0.625rem;
    font-size: 0.875rem;
    margin-right: 0.5rem; }
    @media (max-width: 1240px) {
      ._2Hrlh8O2-gx32qd4XkxutF ._28B71XBVS7ZTCgcxAFBtip {
        margin: 0 0 0.5rem 0; } }
    ._2Hrlh8O2-gx32qd4XkxutF ._28B71XBVS7ZTCgcxAFBtip input {
      border: none;
      background: transparent;
      margin-left: 0.313rem; }
      ._2Hrlh8O2-gx32qd4XkxutF ._28B71XBVS7ZTCgcxAFBtip input:focus {
        outline: none; }

._1pblCbEg1sFKJL1-cDwkca {
  margin-right: 0.5rem; }
  ._1pblCbEg1sFKJL1-cDwkca:last-child {
    margin-right: 0; }
  @media (max-width: 1240px) {
    ._1pblCbEg1sFKJL1-cDwkca {
      margin-bottom: 0.5rem;
      width: 100%; } }
