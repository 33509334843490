._10hCK1YGMb0HzgF-UVAXYU {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin-top: -80px;
  position: relative; }
  .J8a9JZEclKcvQdOFVRo5V {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    z-index: 1; }
    ._3h4eugebXlcQUt9D58Pepw ._3EBnGgALPpCzt5RH1ZvZCQ, ._3h4eugebXlcQUt9D58Pepw .DsUo2FRR7sJaluzCyW6QH {
      opacity: 1; }
    ._3h4eugebXlcQUt9D58Pepw .xX2W4zmJGqReg6BcauIP7 {
      opacity: 0.6; }
    ._3h4eugebXlcQUt9D58Pepw .wl1Z8NWnIyfOgSLU1C8C4 {
      font-weight: 300; }
    ._3h4eugebXlcQUt9D58Pepw ._3Dqcv46GXG6Yj11SoN8Jff {
      display: block; }
    ._1I1ZOvOKDV-Sns0Dkv2Hrj ._1EwtkzxWuy_pkE5omZMg7G {
      opacity: 0.6; }
    ._1I1ZOvOKDV-Sns0Dkv2Hrj ._2GPM_86ulCHeYmDs6BMV7j, ._1I1ZOvOKDV-Sns0Dkv2Hrj ._3FhYOo4gUHGPtyHFkoawMY {
      opacity: 1; }
    ._1I1ZOvOKDV-Sns0Dkv2Hrj ._3O9XSH5A2r6CriYC_ushoX {
      display: block; }
    ._1I1ZOvOKDV-Sns0Dkv2Hrj .hlEiPWHJtEC4I_w0sLz6u, ._1I1ZOvOKDV-Sns0Dkv2Hrj ._2aebooyyEG9Rrnm9f5sD9I, ._1I1ZOvOKDV-Sns0Dkv2Hrj ._26Z3PWkShGTP_VamNGH2EV, ._1I1ZOvOKDV-Sns0Dkv2Hrj ._3Pq9Aa8e7J4Dd-EGRJI5uA {
      opacity: 0.5; }
    ._34Itf_i_PppzeWz-_nN7SS .lLkpoGyHMfHy8L9IKW7aJ, ._34Itf_i_PppzeWz-_nN7SS ._3ssXdidaNaqF1lEUBaD31Q, ._34Itf_i_PppzeWz-_nN7SS ._31SuhcgI243SUPs-a2qStu, ._34Itf_i_PppzeWz-_nN7SS ._34F34sLCOJoeZfllfj-WM-, ._34Itf_i_PppzeWz-_nN7SS ._3YuyOxkRDr2z-6Ll7CWhqp, ._34Itf_i_PppzeWz-_nN7SS .Kfp95K6qtFmFYcdljFJ0B, ._3CgU3qsiUaeC7PgOPhisWO .lLkpoGyHMfHy8L9IKW7aJ, ._3CgU3qsiUaeC7PgOPhisWO ._3ssXdidaNaqF1lEUBaD31Q, ._3CgU3qsiUaeC7PgOPhisWO ._31SuhcgI243SUPs-a2qStu, ._3CgU3qsiUaeC7PgOPhisWO ._34F34sLCOJoeZfllfj-WM-, ._3CgU3qsiUaeC7PgOPhisWO ._3YuyOxkRDr2z-6Ll7CWhqp, ._3CgU3qsiUaeC7PgOPhisWO .Kfp95K6qtFmFYcdljFJ0B {
      opacity: 1; }
    ._34Itf_i_PppzeWz-_nN7SS ._3vDQ9TLfudG9AuscWRuWo7, ._34Itf_i_PppzeWz-_nN7SS ._1NqIA_528FSj5BavzKSzsX, ._3CgU3qsiUaeC7PgOPhisWO ._3vDQ9TLfudG9AuscWRuWo7, ._3CgU3qsiUaeC7PgOPhisWO ._1NqIA_528FSj5BavzKSzsX {
      display: block; }
    ._34Itf_i_PppzeWz-_nN7SS .OKURRZL0rW9BDfcohDPbO, ._34Itf_i_PppzeWz-_nN7SS ._26Z3PWkShGTP_VamNGH2EV, ._34Itf_i_PppzeWz-_nN7SS ._3Pq9Aa8e7J4Dd-EGRJI5uA, ._3CgU3qsiUaeC7PgOPhisWO .OKURRZL0rW9BDfcohDPbO, ._3CgU3qsiUaeC7PgOPhisWO ._26Z3PWkShGTP_VamNGH2EV, ._3CgU3qsiUaeC7PgOPhisWO ._3Pq9Aa8e7J4Dd-EGRJI5uA {
      opacity: 0.5; }
    ._39yiaAWDNce89RoC3GcOnI ._27lXr8jbv07S9dps5F_bDD, ._39yiaAWDNce89RoC3GcOnI .KDmo6-pxDZJnJBaXhOgRd, ._39yiaAWDNce89RoC3GcOnI ._2FHmppuwyTuLFIWRto6zZ7 {
      opacity: 1; }
    ._39yiaAWDNce89RoC3GcOnI ._1ExkYaTPzt4C1VJs6NS6Nx {
      display: block; }
    ._39yiaAWDNce89RoC3GcOnI .OKURRZL0rW9BDfcohDPbO, ._39yiaAWDNce89RoC3GcOnI .hlEiPWHJtEC4I_w0sLz6u, ._39yiaAWDNce89RoC3GcOnI ._2aebooyyEG9Rrnm9f5sD9I, ._39yiaAWDNce89RoC3GcOnI ._3Pq9Aa8e7J4Dd-EGRJI5uA {
      opacity: 0.5; }
    ._1Ko2HuKvYcrg0FFYQCtb3j ._3LDqLfNxBe7zuda5LBdo8b, ._1Ko2HuKvYcrg0FFYQCtb3j ._3_b3wPKfBDrzlWV_uZhDDv, ._1Ko2HuKvYcrg0FFYQCtb3j ._37Iwz6VAIC_GiwbRFSOPAr {
      opacity: 1; }
    ._1Ko2HuKvYcrg0FFYQCtb3j ._2055gYGht_2FZm7KLabg9e {
      display: block; }
    ._1Ko2HuKvYcrg0FFYQCtb3j .OKURRZL0rW9BDfcohDPbO, ._1Ko2HuKvYcrg0FFYQCtb3j .hlEiPWHJtEC4I_w0sLz6u, ._1Ko2HuKvYcrg0FFYQCtb3j ._2aebooyyEG9Rrnm9f5sD9I, ._1Ko2HuKvYcrg0FFYQCtb3j ._26Z3PWkShGTP_VamNGH2EV {
      opacity: 0.5; }
    ._1ZUXHYR1HXZfxTwJEKElkn .G8w8KFLTi-5w-9foFk6sd, ._8j1YckoviFvYlW09llKgS .G8w8KFLTi-5w-9foFk6sd, .xwUHAWMirzO1SWtg7e-1k .G8w8KFLTi-5w-9foFk6sd, ._1h1Bohr_2BaVppBeDrJPT_ .G8w8KFLTi-5w-9foFk6sd, ._2rlzcnD-Q8CpJ7htSdlmAK .G8w8KFLTi-5w-9foFk6sd, .umbrPjEGtSojjrdFzQnwK .G8w8KFLTi-5w-9foFk6sd {
      background-image: url(/_next/static/images/curves-partners-1b51fbc9b65e3de42247c7b3d8affe0a.png); }
      ._1ZUXHYR1HXZfxTwJEKElkn .G8w8KFLTi-5w-9foFk6sd:before, ._1ZUXHYR1HXZfxTwJEKElkn .G8w8KFLTi-5w-9foFk6sd:after, ._8j1YckoviFvYlW09llKgS .G8w8KFLTi-5w-9foFk6sd:before, ._8j1YckoviFvYlW09llKgS .G8w8KFLTi-5w-9foFk6sd:after, .xwUHAWMirzO1SWtg7e-1k .G8w8KFLTi-5w-9foFk6sd:before, .xwUHAWMirzO1SWtg7e-1k .G8w8KFLTi-5w-9foFk6sd:after, ._1h1Bohr_2BaVppBeDrJPT_ .G8w8KFLTi-5w-9foFk6sd:before, ._1h1Bohr_2BaVppBeDrJPT_ .G8w8KFLTi-5w-9foFk6sd:after, ._2rlzcnD-Q8CpJ7htSdlmAK .G8w8KFLTi-5w-9foFk6sd:before, ._2rlzcnD-Q8CpJ7htSdlmAK .G8w8KFLTi-5w-9foFk6sd:after, .umbrPjEGtSojjrdFzQnwK .G8w8KFLTi-5w-9foFk6sd:before, .umbrPjEGtSojjrdFzQnwK .G8w8KFLTi-5w-9foFk6sd:after {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA4AAAAABCAMAAAC47jCFAAAAPFBMVEUAAAAbS2wSeqY7PHUBOWoBOmwgHyAOExkwSHgOEhkAOmwfHyAFb6hHL3MdhacegqRGLnAFbqYgHx8GbaX8GfTpAAAACHRSTlMACc7O/M7OziGcOS4AAAB4SURBVCjPtdHZCoAgEAXQad/UHPv/f22kQsRr5kMHcUEYdC6VNP1aKykxVwPv2F8ZAH6HmQ++KFbKTyJsUgQ1SzVQxOZoq2UguZw25zZxL4GTER3je/rHsEIEdDNEqdbAoAlo+WOWUzGroNM4FMJG32z3BCBb1PYTL8kXgjoJV+YAAAAASUVORK5CYII=); }
    ._1ZUXHYR1HXZfxTwJEKElkn ._30k6hyf5HDx-4_pBLnLcox {
      background: #1f201f; }
      ._1ZUXHYR1HXZfxTwJEKElkn .G8w8KFLTi-5w-9foFk6sd {
        background-position: -1698px 0; }
        ._1ZUXHYR1HXZfxTwJEKElkn .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -449px; }
        ._1ZUXHYR1HXZfxTwJEKElkn .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -859px; }
    ._1ZUXHYR1HXZfxTwJEKElkn .wL37fG4rqECcqxRa_WqiL {
      background: linear-gradient(241deg, #282828 0px, #0d0d0d 580px); }
    ._1ZUXHYR1HXZfxTwJEKElkn ._2PMepIPxwzidV0hA7wh3vT {
      font-weight: normal; }
    ._8j1YckoviFvYlW09llKgS ._30k6hyf5HDx-4_pBLnLcox {
      background: #483073; }
      ._8j1YckoviFvYlW09llKgS .G8w8KFLTi-5w-9foFk6sd {
        background-position: -1408px 0; }
        ._8j1YckoviFvYlW09llKgS .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -373px; }
        ._8j1YckoviFvYlW09llKgS .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -810px; }
    ._8j1YckoviFvYlW09llKgS .wL37fG4rqECcqxRa_WqiL {
      background: linear-gradient(64deg, #1c1333 0px, #6441a5 1500px); }
    ._8j1YckoviFvYlW09llKgS ._2PMepIPxwzidV0hA7wh3vT {
      font-weight: normal; }
    .xwUHAWMirzO1SWtg7e-1k ._30k6hyf5HDx-4_pBLnLcox {
      background: #314879; }
      .xwUHAWMirzO1SWtg7e-1k .G8w8KFLTi-5w-9foFk6sd {
        background-position: -286px 0; }
        .xwUHAWMirzO1SWtg7e-1k .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -90px; }
        .xwUHAWMirzO1SWtg7e-1k .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -640px; }
    .xwUHAWMirzO1SWtg7e-1k .wL37fG4rqECcqxRa_WqiL {
      background: linear-gradient(61deg, #1f335a 0px, #3b5998 580px); }
    .xwUHAWMirzO1SWtg7e-1k ._2PMepIPxwzidV0hA7wh3vT {
      font-weight: normal; }
    ._1h1Bohr_2BaVppBeDrJPT_ ._30k6hyf5HDx-4_pBLnLcox {
      background: #1e86a8; }
      ._1h1Bohr_2BaVppBeDrJPT_ .G8w8KFLTi-5w-9foFk6sd {
        background-position: -569px 0; }
        ._1h1Bohr_2BaVppBeDrJPT_ .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -159px; }
        ._1h1Bohr_2BaVppBeDrJPT_ .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -682px; }
    ._1h1Bohr_2BaVppBeDrJPT_ .wL37fG4rqECcqxRa_WqiL {
      background: linear-gradient(58deg, #0b3f50 0px, #1fbaed 2000px); }
    ._1h1Bohr_2BaVppBeDrJPT_ ._2PMepIPxwzidV0hA7wh3vT {
      font-weight: normal; }
    ._2rlzcnD-Q8CpJ7htSdlmAK ._30k6hyf5HDx-4_pBLnLcox {
      background: #0e1219; }
      ._2rlzcnD-Q8CpJ7htSdlmAK .G8w8KFLTi-5w-9foFk6sd {
        background-position: -1135px 0; }
        ._2rlzcnD-Q8CpJ7htSdlmAK .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -303px; }
        ._2rlzcnD-Q8CpJ7htSdlmAK .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -766px; }
    ._2rlzcnD-Q8CpJ7htSdlmAK .wL37fG4rqECcqxRa_WqiL {
      background: linear-gradient(58deg, #0b0d12 0px, #151b26 580px); }
    ._2rlzcnD-Q8CpJ7htSdlmAK ._2PMepIPxwzidV0hA7wh3vT {
      font-weight: normal; }
    .umbrPjEGtSojjrdFzQnwK ._30k6hyf5HDx-4_pBLnLcox {
      background: #0671a8; }
      .umbrPjEGtSojjrdFzQnwK .G8w8KFLTi-5w-9foFk6sd {
        background-position: -852px 0; }
        .umbrPjEGtSojjrdFzQnwK .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -230px; }
        .umbrPjEGtSojjrdFzQnwK .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -725px; }
    .umbrPjEGtSojjrdFzQnwK .wL37fG4rqECcqxRa_WqiL {
      background: linear-gradient(58deg, #005683 0px, #068ed6 1500px); }
    .umbrPjEGtSojjrdFzQnwK ._2PMepIPxwzidV0hA7wh3vT {
      font-weight: normal; }
    @media all and (max-width: 1100px) {
      .J8a9JZEclKcvQdOFVRo5V {
        padding: 80px 0;
        min-height: 0; } }
  ._3VT1oiK1SVapxGiComxZX7 {
    -ms-flex-item-align: end;
        align-self: flex-end;
    z-index: 5;
    margin: 0 auto 85px;
    -webkit-transition: visibility 0.5s ease, opacity 0.5s ease;
    transition: visibility 0.5s ease, opacity 0.5s ease; }
    ._12yKfTTGW6t0Sh9sRyH5Pq {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      height: auto;
      padding: 20px;
      border-radius: 20px;
      background: #000a0f;
      -webkit-box-shadow: 0 77px 50px 0 rgba(0, 0, 0, 0.5);
              box-shadow: 0 77px 50px 0 rgba(0, 0, 0, 0.5);
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly;
      flex-direction: row;
      -webkit-transition: background 0.5s ease, flex-direction 0.5s ease, padding 0.5s ease, -webkit-box-shadow 0.5s ease, -webkit- 2009flex-direction 0.5s ease;
      transition: background 0.5s ease, flex-direction 0.5s ease, padding 0.5s ease, -webkit-box-shadow 0.5s ease, -webkit- 2009flex-direction 0.5s ease;
      transition: box-shadow 0.5s ease, background 0.5s ease, flex-direction 0.5s ease, padding 0.5s ease;
      transition: box-shadow 0.5s ease, background 0.5s ease, flex-direction 0.5s ease, padding 0.5s ease, -webkit-box-shadow 0.5s ease, -webkit- 2009flex-direction 0.5s ease, -ms-flex-direction 0.5s ease; }
      ._1XS4o2O_MiCGxPAk6KWiNz {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        margin: 0 auto; }
        ._37WilRUCMFLzhhKj7GgNX- img {
          -webkit-transform: scale(0.9);
                  transform: scale(0.9); }
        ._1XS4o2O_MiCGxPAk6KWiNz img {
          display: inline-block;
          width: auto;
          height: auto;
          max-height: 95px;
          margin: 0 auto;
          cursor: pointer; }
          @media all and (max-width: 1100px) {
            ._1XS4o2O_MiCGxPAk6KWiNz img {
              width: 100%; } }
      ._19ih6LhqflSN_493Pk6d_c {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: auto;
        height: auto;
        text-align: center;
        border-radius: 0;
        margin: 0 10px;
        padding: 0;
        background: transparent;
        -webkit-transition: opacity 0.25s ease, border-radius 0.5s ease, background 0.5s ease, margin-top 0.5s ease, padding 0.5s ease, -webkit-flex-basis 0.5s ease;
        transition: opacity 0.25s ease, border-radius 0.5s ease, background 0.5s ease, margin-top 0.5s ease, padding 0.5s ease, -webkit-flex-basis 0.5s ease;
        transition: opacity 0.25s ease, border-radius 0.5s ease, background 0.5s ease, margin-top 0.5s ease, flex-basis 0.5s ease, padding 0.5s ease;
        transition: opacity 0.25s ease, border-radius 0.5s ease, background 0.5s ease, margin-top 0.5s ease, flex-basis 0.5s ease, padding 0.5s ease, -webkit-flex-basis 0.5s ease, -ms-flex-preferred-size 0.5s ease; }
        ._19ih6LhqflSN_493Pk6d_c.Vm8igg5e_PIZbHUMLslqN {
          display: none; }
        ._19ih6LhqflSN_493Pk6d_c ._1fWAahARmQx_5_ZSebiHjM {
          width: 100%;
          max-width: 216px; }
          @media all and (max-width: 1100px) {
            ._19ih6LhqflSN_493Pk6d_c ._1fWAahARmQx_5_ZSebiHjM {
              display: none; } }
        @media all and (max-width: 1100px) {
          .OKURRZL0rW9BDfcohDPbO {
            background: linear-gradient(300deg, #2379ae 0%, #003b5b 100%); } }
        @media all and (max-width: 1100px) {
          .hlEiPWHJtEC4I_w0sLz6u, ._2aebooyyEG9Rrnm9f5sD9I {
            background: linear-gradient(300deg, #3f3089 0%, #101839 100%); } }
        @media all and (max-width: 1100px) {
          ._26Z3PWkShGTP_VamNGH2EV {
            background: linear-gradient(300deg, #00947c 0%, #02453a 100%); } }
        @media all and (max-width: 1100px) {
          ._3Pq9Aa8e7J4Dd-EGRJI5uA {
            background: linear-gradient(72deg, #0f3883 0px, #011b48 1200px); } }
        ._19ih6LhqflSN_493Pk6d_c:first-child {
          margin-top: 0; }
        @media all and (max-width: 1100px) {
          ._19ih6LhqflSN_493Pk6d_c {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-preferred-size: 90px;
                flex-basis: 90px;
            border-radius: 20px;
            margin-top: 35px;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            padding: 20px; } }
      ._1199DfJU1cV1p4lNJsGnDl {
        display: inline-block;
        width: 100%;
        height: auto;
        text-align: center;
        color: #ffffff;
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
        border-radius: 0px;
        border: none;
        padding: 0;
        -webkit-transition: padding 0.5s ease, border 0.5s ease, border-radius 0.5s ease;
        transition: padding 0.5s ease, border 0.5s ease, border-radius 0.5s ease; }
      @media all and (-ms-high-contrast: none) {
        ._12yKfTTGW6t0Sh9sRyH5Pq {
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between; } }
      @supports (-ms-ime-align: auto) {
        ._12yKfTTGW6t0Sh9sRyH5Pq {
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between; } }
      @media all and (max-width: 1100px) {
        ._12yKfTTGW6t0Sh9sRyH5Pq {
          -webkit-box-shadow: none;
                  box-shadow: none;
          background: transparent;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          padding: 0; } }
    @media all and (max-width: 1100px) {
      ._3VT1oiK1SVapxGiComxZX7 {
        margin-bottom: 0; } }
  .wL37fG4rqECcqxRa_WqiL {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 2;
    background: linear-gradient(57deg, #010e1a 0px, #002740 1920px);
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease; }
    ._2GPM_86ulCHeYmDs6BMV7j {
      background: linear-gradient(73deg, #2379ae 0px, #003b5b 1300px); }
    .lLkpoGyHMfHy8L9IKW7aJ, ._34F34sLCOJoeZfllfj-WM- {
      background: linear-gradient(76deg, #3f3089 0px, #101839 1300px); }
    ._27lXr8jbv07S9dps5F_bDD {
      background: linear-gradient(72deg, #00947c 0px, #02453a 1200px); }
    ._3LDqLfNxBe7zuda5LBdo8b {
      background: linear-gradient(72deg, #0f3883 0px, #011b48 1200px); }
    ._3BiTA3-OYYl8hX6JZdmSWb {
      z-index: 1; }
  ._2JYZGol9n1vRdKPkMfbBjY {
    width: 100%;
    height: auto;
    position: relative;
    -webkit-transition: min-height 0.5s ease;
    transition: min-height 0.5s ease; }
    @media all and (max-width: 1100px) {
      ._2JYZGol9n1vRdKPkMfbBjY {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    ._2PMepIPxwzidV0hA7wh3vT, .wl1Z8NWnIyfOgSLU1C8C4, ._2JJsflOT338EviTVqQ88v9 {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      margin-top: 10px; }
      ._2PMepIPxwzidV0hA7wh3vT:first-child, .wl1Z8NWnIyfOgSLU1C8C4:first-child, ._2JJsflOT338EviTVqQ88v9:first-child {
        margin-top: 0; }
    ._2PMepIPxwzidV0hA7wh3vT, .wl1Z8NWnIyfOgSLU1C8C4, ._2N2Idfe5HECZ05rQMY9auv {
      color: #ffffff;
      text-align: left;
      -webkit-transition: font-size 0.5s ease, line-height 0.5s ease, text-align 0.5s ease;
      transition: font-size 0.5s ease, line-height 0.5s ease, text-align 0.5s ease; }
      @media all and (max-width: 1100px) {
        ._2PMepIPxwzidV0hA7wh3vT, .wl1Z8NWnIyfOgSLU1C8C4, ._2N2Idfe5HECZ05rQMY9auv {
          text-align: center; } }
    ._2PMepIPxwzidV0hA7wh3vT {
      font-size: 50px;
      line-height: 55px;
      font-weight: bold; }
      @media all and (min-width: 1550px) and (max-width: 1900px) {
        ._2PMepIPxwzidV0hA7wh3vT {
          font-size: 40px;
          line-height: 45px; } }
      @media all and (min-width: 1300px) and (max-width: 1550px) {
        ._2PMepIPxwzidV0hA7wh3vT {
          font-size: 35px;
          line-height: 40px; } }
      @media all and (min-width: 1100px) and (max-width: 1300px) {
        ._2PMepIPxwzidV0hA7wh3vT {
          font-size: 25px;
          line-height: 30px; } }
      @media all and (min-height: 830px) and (max-height: 890px) and (min-width: 1400px) {
        ._2PMepIPxwzidV0hA7wh3vT {
          font-size: 25px;
          line-height: 30px; } }
      @media all and (max-width: 1100px) {
        ._2PMepIPxwzidV0hA7wh3vT {
          font-size: 20px;
          line-height: 25px; } }
      @media all and (max-height: 830px) and (min-width: 1400px) {
        ._2PMepIPxwzidV0hA7wh3vT {
          font-size: 20px;
          line-height: 25px; } }
    .wl1Z8NWnIyfOgSLU1C8C4 {
      font-size: 30px;
      line-height: 39px;
      font-weight: 500;
      letter-spacing: -1px; }
      @media all and (min-width: 1550px) and (max-width: 1900px) {
        .wl1Z8NWnIyfOgSLU1C8C4 {
          font-size: 30px;
          line-height: 35px; } }
      @media all and (min-width: 1300px) and (max-width: 1550px) {
        .wl1Z8NWnIyfOgSLU1C8C4 {
          font-size: 25px;
          line-height: 30px; } }
      @media all and (min-width: 1100px) and (max-width: 1300px) {
        .wl1Z8NWnIyfOgSLU1C8C4 {
          font-size: 20px;
          line-height: 25px; } }
      @media all and (min-height: 830px) and (max-height: 890px) and (min-width: 1400px) {
        .wl1Z8NWnIyfOgSLU1C8C4 {
          font-size: 20px;
          line-height: 25px; } }
      @media all and (max-width: 1100px) {
        .wl1Z8NWnIyfOgSLU1C8C4 {
          font-size: 15px;
          line-height: 20px; } }
      @media all and (max-height: 830px) and (min-width: 1400px) {
        .wl1Z8NWnIyfOgSLU1C8C4 {
          font-size: 15px;
          line-height: 20px; } }
    ._2JJsflOT338EviTVqQ88v9 {
      padding-left: 15px; }
      ._2N2Idfe5HECZ05rQMY9auv {
        display: block;
        width: 100%;
        height: auto;
        font-size: 20px;
        line-height: 30px;
        margin-top: 5px;
        font-weight: 300;
        position: relative; }
        @media all and (min-width: 1550px) and (max-width: 1900px) {
          ._2N2Idfe5HECZ05rQMY9auv {
            font-size: 20px;
            line-height: 25px; } }
        @media all and (min-width: 1300px) and (max-width: 1550px) {
          ._2N2Idfe5HECZ05rQMY9auv {
            font-size: 20px;
            line-height: 25px; } }
        @media all and (min-width: 1100px) and (max-width: 1300px) {
          ._2N2Idfe5HECZ05rQMY9auv {
            font-size: 15px;
            line-height: 20px; } }
        @media all and (min-height: 830px) and (max-height: 890px) and (min-width: 1400px) {
          ._2N2Idfe5HECZ05rQMY9auv {
            font-size: 15px;
            line-height: 20px; } }
        @media all and (max-width: 1100px) {
          ._2N2Idfe5HECZ05rQMY9auv {
            font-size: 15px;
            line-height: 20px;
            margin-top: 5px; } }
        @media all and (max-height: 830px) and (min-width: 1400px) {
          ._2N2Idfe5HECZ05rQMY9auv {
            font-size: 15px;
            line-height: 20px;
            margin-top: 5px; } }
        ._2N2Idfe5HECZ05rQMY9auv:before {
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #ffffff;
          position: absolute;
          left: -15px;
          top: 50%;
          margin-top: -4px;
          content: ''; }
        ._2N2Idfe5HECZ05rQMY9auv:first-child {
          margin-top: 0; }
    ._3JIlvvwM3eQWQ8ATf_VNlM {
      position: absolute;
      top: 0;
      right: 0; }
      ._3JIlvvwM3eQWQ8ATf_VNlM img {
        max-height: 100px;
        height: 100%; }
      ._3JIlvvwM3eQWQ8ATf_VNlM .VTrNG_mhatj-w552pvNS8 {
        max-width: 600px; }
        @media all and (min-height: 712px) and (max-height: 890px) {
          ._3JIlvvwM3eQWQ8ATf_VNlM .VTrNG_mhatj-w552pvNS8 {
            max-height: 50px; } }
        @media all and (min-width: 1100px) and (min-height: 712px) and (max-height: 890px) {
          ._3JIlvvwM3eQWQ8ATf_VNlM .VTrNG_mhatj-w552pvNS8 {
            max-height: 40px; } }
        @media all and (max-width: 1100px) {
          ._3JIlvvwM3eQWQ8ATf_VNlM .VTrNG_mhatj-w552pvNS8 {
            width: 100%; } }
      @media all and (min-width: 1100px) {
        ._3b8gxctrBwRcrKQYDk4NKW {
          position: relative;
          top: auto;
          right: auto;
          margin-bottom: 5px;
          -ms-flex-preferred-size: 100%;
              flex-basis: 100%; } }
      @media all and (max-width: 1100px) {
        ._3JIlvvwM3eQWQ8ATf_VNlM {
          position: relative;
          top: auto;
          right: auto;
          margin: 0 auto 20px;
          max-width: 80%;
          height: auto; }
          ._3JIlvvwM3eQWQ8ATf_VNlM img {
            width: auto;
            max-width: 100%;
            margin: 0 auto; } }
    .PqCChivC-WoNuHK7N3eVb {
      display: none;
      height: auto;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
    ._3Igh8rB5hpkA2GsgupHw_L {
      margin: 10px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media all and (max-width: 1100px) {
        ._3Igh8rB5hpkA2GsgupHw_L {
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; } }
    @media all and (min-width: 1100px) and (max-width: 1550px) {
      ._2JYZGol9n1vRdKPkMfbBjY {
        min-height: 210px; } }
    @media all and (min-height: 830px) and (max-height: 890px) and (min-width: 1400px) {
      ._2JYZGol9n1vRdKPkMfbBjY {
        min-height: 210px; } }
    @media all and (max-width: 1100px) {
      ._2JYZGol9n1vRdKPkMfbBjY {
        min-height: 0;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column; } }
    @media all and (max-height: 830px) and (min-width: 1400px) {
      ._2JYZGol9n1vRdKPkMfbBjY {
        min-height: 0; } }
  ._2YbG72XCjWMGATUAeqfL3m {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .AjtLAON5PGlxWFdWQb0Vt {
    display: block;
    width: 100%;
    height: 18px;
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
    opacity: 1;
    margin: 15px auto;
    overflow: hidden;
    -webkit-transition: margin-top 0.5s ease, height 0.5s ease, opacity 0.5s ease;
    transition: margin-top 0.5s ease, height 0.5s ease, opacity 0.5s ease;
    text-align: center; }
    @media all and (max-width: 1100px) {
      .AjtLAON5PGlxWFdWQb0Vt {
        height: 0;
        opacity: 0;
        margin-top: 0; } }
  ._35fSlOzRG4t5kRqVdzuGBJ {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    max-width: 1780px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    position: relative;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media screen and (min-width: 1100px) {
      ._35fSlOzRG4t5kRqVdzuGBJ {
        width: 85%; } }
  ._3wj-M44S0BUX_8kwYjXqyw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    z-index: 10;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -webkit-transition: -webkit-flex-basis 0.5s ease;
    transition: -webkit-flex-basis 0.5s ease;
    transition: flex-basis 0.5s ease;
    transition: flex-basis 0.5s ease, -webkit-flex-basis 0.5s ease, -ms-flex-preferred-size 0.5s ease;
    -ms-flex-item-align: start;
        align-self: flex-start;
    margin-top: 130px; }
    @media all and (min-width: 1550px) {
      ._3wj-M44S0BUX_8kwYjXqyw {
        width: 60%; } }
    @media all and (min-width: 1100px) and (max-width: 1550px) {
      ._3wj-M44S0BUX_8kwYjXqyw {
        width: 70%; } }
    @media all and (max-width: 1100px) {
      ._3wj-M44S0BUX_8kwYjXqyw {
        margin-top: 0;
        width: 100%;
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0; } }
    @media all and (min-width: 1100px) and (min-height: 712px) and (max-height: 890px) {
      ._3wj-M44S0BUX_8kwYjXqyw {
        width: 70%;
        margin-top: 70px; } }
  ._30k6hyf5HDx-4_pBLnLcox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(50% + 800px);
    height: 300%;
    position: absolute;
    top: calc(-100% - 25px);
    right: -1014px;
    background: linear-gradient(to right, #042b43 0px, #12577f 580px);
    opacity: 0;
    margin-left: 0;
    z-index: 4;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    pointer-events: none;
    -webkit-transform-origin: left middle;
            transform-origin: left middle;
    -webkit-transition: opacity 0.5s ease, top 0.5s ease, right 0.5s ease, margin-left 0.5s ease, -webkit-transform 0.5s ease;
    transition: opacity 0.5s ease, top 0.5s ease, right 0.5s ease, margin-left 0.5s ease, -webkit-transform 0.5s ease;
    transition: opacity 0.5s ease, top 0.5s ease, right 0.5s ease, transform 0.5s ease, margin-left 0.5s ease;
    transition: opacity 0.5s ease, top 0.5s ease, right 0.5s ease, transform 0.5s ease, margin-left 0.5s ease, -webkit-transform 0.5s ease; }
    ._3pqj5FEY474shqkY19D7j1 {
      display: block;
      width: 100%;
      max-width: 550px;
      height: auto;
      margin-left: 100px;
      position: relative;
      opacity: 1;
      z-index: 2;
      -webkit-transition: margin-left 0.5s ease, opacity 0.5s ease;
      transition: margin-left 0.5s ease, opacity 0.5s ease;
      margin-top: -150px; }
      @media all and (min-width: 1100px) and (max-width: 1550px) {
        ._3pqj5FEY474shqkY19D7j1 {
          margin-left: 0; } }
      @media all and (-ms-high-contrast: none) {
        ._3pqj5FEY474shqkY19D7j1 {
          height: auto;
          width: auto;
          margin-left: 0;
          max-width: none; } }
      @media all and (max-width: 1100px) {
        ._3pqj5FEY474shqkY19D7j1 {
          opacity: 0; } }
      @media all and (min-height: 712px) and (max-height: 890px) {
        ._3pqj5FEY474shqkY19D7j1 {
          max-width: 450px; } }
      @media all and (min-width: 1100px) and (min-height: 712px) and (max-height: 890px) {
        ._3pqj5FEY474shqkY19D7j1 {
          max-width: 375px;
          margin-left: 50px;
          margin-top: -175px; } }
    .G8w8KFLTi-5w-9foFk6sd {
      display: block;
      width: 270px;
      height: 768px;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: -270px;
      margin-top: -384px;
      background: url(/_next/static/images/curves-products-26596e96470853e005df4e82c00936de.png) no-repeat top left transparent; }
      .G8w8KFLTi-5w-9foFk6sd:before, .G8w8KFLTi-5w-9foFk6sd:after {
        display: block;
        height: 500px;
        right: 0;
        position: absolute;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAkAAAAABCAMAAAAB3+HkAAAAPFBMVEUAAAANOlUrIGMTWIAtIWkEIVUEKkIDfGYAgmkDKkITWYEEIlYBfWcsIWsBgWoEIVQqIGYSVn4BeWMEIljnlvUaAAAACXRSTlMACfzOzs7OwGg3AuUCAAAAX0lEQVQoz5XQ2w6AIAgGYDoXePb937V0a1OSmd+UcQXjh1+maxif4L/QI2KqgnrA7ATa6Qa+P9RUUBKjTKEZh61R/vR2XCzAkL2T7dJJbpWDOfjVpROyjYgsNcXnQXIDpnUS6zNvZ9wAAAAASUVORK5CYII=) repeat-y top left transparent;
        content: ''; }
      .G8w8KFLTi-5w-9foFk6sd:before {
        width: 60px;
        bottom: 50%;
        margin-bottom: 384px;
        background-position-x: -25px; }
      .G8w8KFLTi-5w-9foFk6sd:after {
        width: 25px;
        top: 50%;
        margin-top: 384px;
        background-position-x: -408px; }
    ._30k6hyf5HDx-4_pBLnLcox:before {
      display: block;
      width: 568px;
      height: 768px;
      position: absolute;
      background: url(/_next/static/images/circuit-portrait-ea2331cae02d08bda7669fe1c74fe5b6.png) no-repeat center transparent;
      left: 0;
      top: 50%;
      margin-top: -384px;
      opacity: 0.3;
      content: ''; }
    ._3FhYOo4gUHGPtyHFkoawMY {
      background: linear-gradient(to right, #135982 0px, #2690ce 580px); }
      ._3FhYOo4gUHGPtyHFkoawMY .G8w8KFLTi-5w-9foFk6sd {
        background-position-x: -281px;
        background-position-y: top; }
        ._3FhYOo4gUHGPtyHFkoawMY .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -90px; }
        ._3FhYOo4gUHGPtyHFkoawMY .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -440px; }
    ._3ssXdidaNaqF1lEUBaD31Q, ._3YuyOxkRDr2z-6Ll7CWhqp {
      background: linear-gradient(to right, #2d226c 0px, #5441e5 580px); }
      ._3ssXdidaNaqF1lEUBaD31Q .G8w8KFLTi-5w-9foFk6sd, ._3YuyOxkRDr2z-6Ll7CWhqp .G8w8KFLTi-5w-9foFk6sd {
        background-position-x: -557px;
        background-position-y: bottom; }
        ._3ssXdidaNaqF1lEUBaD31Q .G8w8KFLTi-5w-9foFk6sd:before, ._3YuyOxkRDr2z-6Ll7CWhqp .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -156px; }
        ._3ssXdidaNaqF1lEUBaD31Q .G8w8KFLTi-5w-9foFk6sd:after, ._3YuyOxkRDr2z-6Ll7CWhqp .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -472px; }
    .KDmo6-pxDZJnJBaXhOgRd {
      background: linear-gradient(to right, #017f68 0px, #07edc1 580px); }
      .KDmo6-pxDZJnJBaXhOgRd .G8w8KFLTi-5w-9foFk6sd {
        background-position-x: -831px;
        background-position-y: bottom; }
        .KDmo6-pxDZJnJBaXhOgRd .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -219px; }
        .KDmo6-pxDZJnJBaXhOgRd .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -499px; }
    ._3_b3wPKfBDrzlWV_uZhDDv {
      background: linear-gradient(to right, #052357 0px, #0f3883 580px); }
      ._3_b3wPKfBDrzlWV_uZhDDv .G8w8KFLTi-5w-9foFk6sd {
        background-position-x: -1117px;
        background-position-y: bottom; }
        ._3_b3wPKfBDrzlWV_uZhDDv .G8w8KFLTi-5w-9foFk6sd:before {
          background-position-x: -301px; }
        ._3_b3wPKfBDrzlWV_uZhDDv .G8w8KFLTi-5w-9foFk6sd:after {
          background-position-x: -541px; }
    .uqiaou_rN_JCe0-1w3GDw {
      z-index: 3; }
    @media all and (max-width: 1100px) {
      ._30k6hyf5HDx-4_pBLnLcox {
        top: calc(-200% - 100px);
        left: 50%;
        margin-left: calc(-25% + -300px);
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg); } }
    @media all and (max-width: 635px) {
      ._30k6hyf5HDx-4_pBLnLcox {
        top: calc(-200% - 50px); } }
  ._2U7z3JZHd5hV4GxnLVUuaX {
    display: block;
    width: 100%;
    height: 60px;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    background: #000a0f;
    position: relative;
    z-index: 2; }
    ._1nxHmVgkdWo_fUZl47nc5K {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 50px;
      height: 50px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      position: absolute;
      top: -15px;
      left: 50%;
      margin-left: -25px;
      border-radius: 50%;
      background: #000a0f;
      border: 1px solid #2b9ed8;
      color: #ffffff;
      text-decoration: none;
      -webkit-box-shadow: 0 0 6px 0 #258ec8;
              box-shadow: 0 0 6px 0 #258ec8; }
      ._3OSLRYcVyjnh051zMkAEZb {
        -webkit-animation: _1YhpMW5X8rIXFHgrUIdUk0 0.75s infinite alternate;
                animation: _1YhpMW5X8rIXFHgrUIdUk0 0.75s infinite alternate; }

@-webkit-keyframes _1YhpMW5X8rIXFHgrUIdUk0 {
  from {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  to {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px); } }

@keyframes _1YhpMW5X8rIXFHgrUIdUk0 {
  from {
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  to {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px); } }
