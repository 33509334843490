._12iHuO8Pa1JI25fsWwnGds {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2xLAI9C4IBgjtihjViq6wd {
  padding: 0 2rem 6rem; }
  @media (max-width: 991px) {
    ._2xLAI9C4IBgjtihjViq6wd {
      padding: 0; } }

._2Gjg_5JctZJWqmeEp_YVLW {
  max-width: 1400px;
  margin: 0 auto;
  background: #f4f7fa;
  border-radius: 20px; }
  @media (max-width: 991px) {
    ._2Gjg_5JctZJWqmeEp_YVLW {
      border-radius: 0; } }

._2b1fnsUmNyjgaY3r8QKHNO {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 4rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (max-width: 991px) {
    ._2b1fnsUmNyjgaY3r8QKHNO {
      padding: 4rem 2rem;
      grid-gap: 2rem;
      gap: 2rem; } }

._12iHuO8Pa1JI25fsWwnGds {
  font-size: 1.4rem;
  line-height: 1.7;
  font-weight: 600;
  text-align: center;
  word-break: break-word;
  margin: 0; }
  @media (max-width: 991px) {
    ._12iHuO8Pa1JI25fsWwnGds {
      font-size: 1.2rem; } }

._3jRXnJGJUfWwsMPzFztYLH {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 6rem;
  gap: 6rem;
  margin: 0;
  padding: 0; }
  @media (max-width: 991px) {
    ._3jRXnJGJUfWwsMPzFztYLH {
      grid-gap: 2rem;
      gap: 2rem; } }

._2ghTqwyWzw-Yh2ynHHhahE {
  max-height: 100%;
  width: auto; }
