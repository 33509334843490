._2PSY4Dp4SdrdwwB__Zwj0Y {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2PSY4Dp4SdrdwwB__Zwj0Y {
      font-size: 2.5rem;
      line-height: 1.7; } }

._2PSY4Dp4SdrdwwB__Zwj0Y {
  text-align: center;
  margin: 7rem 0 2rem; }
  @media (max-width: 991px) {
    ._2PSY4Dp4SdrdwwB__Zwj0Y {
      margin: 3rem 0 0;
      padding: 0 1.25rem; } }

@media (max-width: 991px) {
  ._1BV_gAd-veLOMz5W8XzcYn section:last-child {
    padding-top: 0; } }
