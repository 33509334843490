.AV3vwARqb_KpGafem1o_1 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative; }
  .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy {
    border-collapse: collapse;
    color: #62737f; }
    .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy thead {
      background-color: transparent;
      border-bottom: 1px solid #f4f4f4; }
    .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy tbody {
      border-bottom: 1px solid #f4f4f4; }
    .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy tr {
      border: none; }
      .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy tr td:last-child {
        text-align: right; }
      @media (max-width: 767px) {
        .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy tr {
          display: table;
          border-bottom: 1px solid #f4f4f4;
          width: 100%; }
          .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy tr th {
            display: none; }
          .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy tr td {
            display: block;
            padding: 0.5rem; } }
    @media (max-width: 767px) and (max-width: 991px) {
      .AV3vwARqb_KpGafem1o_1 ._3fmOkUe-FtHsyb0zf2fgfy tr td {
        padding: 0.5rem 1rem; } }

._1LpOyk0D7YH0EZxAO400Q4 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 1rem 0; }
  @media (max-width: 991px) {
    ._1LpOyk0D7YH0EZxAO400Q4 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  ._1LpOyk0D7YH0EZxAO400Q4 ._3UeO1isiqxwdcG6LhAsx2- {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #fa1854;
    color: #fa1854; }

._2ZwrI0tMhzV77vU4GAFpQa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    ._2ZwrI0tMhzV77vU4GAFpQa {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 1rem; } }
