.V8dJkZ_Xf82vTVa8tw1ic {
  -webkit-box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1);
          box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1); }

.V8dJkZ_Xf82vTVa8tw1ic {
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 2rem; }
  ._2nw-glAwlSd2O1xT2H2fxG {
    border: none;
    width: 100%;
    min-height: 500px;
    background-color: #161616;
    display: none; }
    .ejJqu4ijHuhXXZnk7JBTp {
      display: block; }
