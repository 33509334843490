._3yJrO3DgQChoQU1tZP_jNc {
  position: relative;
  min-width: 200px;
  max-width: 100%; }

._1tNS-edP2GhERiYD_ffsXE {
  font-size: 0.875rem;
  color: #5c6066;
  font-weight: 600;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  border: 1px solid #e6eaf2;
  border-radius: 3px;
  padding: 0.625rem;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }
  ._1tNS-edP2GhERiYD_ffsXE:hover {
    background: #d2d9e8; }
  ._1tNS-edP2GhERiYD_ffsXE:active {
    background: #cbd3e4; }

.QPmsUxjNx-IopYHE_jo5- {
  font-size: 0.875rem;
  color: #5c6066;
  font-weight: 600;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  top: 40px;
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
  border: 1px solid #e6eaf2;
  border-radius: 3px;
  z-index: 2;
  display: none; }
  @media all and (max-width: 660px) {
    .QPmsUxjNx-IopYHE_jo5- {
      width: 100%; } }
  .QPmsUxjNx-IopYHE_jo5-._1HTMgmeMIUwzmnr2cyjbcw {
    display: block; }
  .QPmsUxjNx-IopYHE_jo5- li {
    color: #2c2c2c;
    padding: 0.625rem 1.25rem;
    cursor: pointer; }
    .QPmsUxjNx-IopYHE_jo5- li:hover {
      background-color: #f0f2f7; }

._13J-K43KDCPOFRIndxwMK2 {
  border: none;
  background: transparent;
  text-align: left;
  display: block;
  width: 100%; }
  ._13J-K43KDCPOFRIndxwMK2[disabled] {
    cursor: not-allowed; }

.OapkFWe6d9arPx6QQh_j4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .OapkFWe6d9arPx6QQh_j4:after {
    content: ' ';
    margin-left: 0.313rem; }
  .OapkFWe6d9arPx6QQh_j4._2p28_2YvM9n9lBKbHnG9eX:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  .OapkFWe6d9arPx6QQh_j4._1VAkxjEHCY6vfvuvO8ztKm:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }

._2jg8ZYAw9CqH58LhyxUutX {
  padding: 3.125rem;
  font-size: 0.875rem;
  color: #5c6066;
  font-weight: 600; }
