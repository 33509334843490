._2U_FFzmK3Y3D_2rPnkt-IE, ._20oJzLwgPJxogWpjG-6j5d {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2r5qxMV6p1MBlvtXsgcKOj {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2rem 0;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  @media (max-width: 991px) {
    ._2r5qxMV6p1MBlvtXsgcKOj {
      margin: 2.5rem 0; } }

._1ikR5ycYbtHjOcEU7Yltqp {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 1.5rem;
  gap: 1.5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: auto; }
  @media (max-width: 991px) {
    ._1ikR5ycYbtHjOcEU7Yltqp {
      grid-template-columns: repeat(1, auto);
      margin: auto; } }
  @media (min-width: 560px) and (max-width: 990px) {
    ._1ikR5ycYbtHjOcEU7Yltqp {
      grid-template-columns: repeat(2, auto); } }

._19oPWJRLrFEHWNf6kKZgOz {
  width: 100%; }

._2U_FFzmK3Y3D_2rPnkt-IE {
  color: #2591cf;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0; }
  ._2U_FFzmK3Y3D_2rPnkt-IE:hover {
    text-decoration: underline; }

._1Ej5EbMm7crotwR98kw3Yi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  background: #f5f7f8;
  padding: 1.3rem;
  border-radius: 10px; }

._20oJzLwgPJxogWpjG-6j5d {
  margin-bottom: 0.5rem;
  font-size: 1rem; }

._1Y4a75lBexesF9wRUdhgAu {
  font-weight: 400; }
