._1gVGp984Omh58EZpgtrb6- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media all and (max-width: 660px) {
    ._1gVGp984Omh58EZpgtrb6- {
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }

._1wiy-ARkQ2eZHJ32rG3n7y {
  border: none;
  background: none;
  cursor: pointer;
  color: #051c26;
  font-size: 16px;
  opacity: 0.4;
  font-weight: 700;
  outline: none;
  position: relative;
  padding: 10px 0;
  margin-bottom: 20px;
  margin-right: 20px; }
  ._1wiy-ARkQ2eZHJ32rG3n7y[data-selected] {
    opacity: 1; }
    ._1wiy-ARkQ2eZHJ32rG3n7y[data-selected]:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: #1791d3;
      border-radius: 30px;
      position: absolute;
      bottom: 0;
      left: 0; }

._2lLX3jDGHBmRUCKnrpeilF * {
  outline: none; }
