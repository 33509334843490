._1pwG5RIx8r74pxW9zFJYfv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 991px) {
    ._1pwG5RIx8r74pxW9zFJYfv {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
  ._1pwG5RIx8r74pxW9zFJYfv ._34-RWHfw4zVlo2idTGKLSv {
    margin: 5rem auto; }

._2OlXckJS8zJ-l368HyYvGT {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: auto;
  height: calc(100vh - 70px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: -2.2rem; }
  ._2OlXckJS8zJ-l368HyYvGT img {
    width: 100%;
    height: 100%; }
  @media (max-width: 1282px) {
    ._2OlXckJS8zJ-l368HyYvGT {
      display: none; } }

._5EeRfZAjYeDSv51Q9iEyr {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100vh - (80px + 4rem));
  overflow-y: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 500px;
  padding: 0 4rem; }
  @media (max-width: 1282px) {
    ._5EeRfZAjYeDSv51Q9iEyr {
      margin: 2rem auto; } }
  @media (max-width: 991px) {
    ._5EeRfZAjYeDSv51Q9iEyr {
      width: auto;
      margin: 2rem auto;
      padding: 0 1.25rem; } }
  ._5EeRfZAjYeDSv51Q9iEyr::-webkit-scrollbar {
    width: 2px; }
  ._5EeRfZAjYeDSv51Q9iEyr::-webkit-scrollbar-track {
    background: #f0f0f0; }
  ._5EeRfZAjYeDSv51Q9iEyr::-webkit-scrollbar-thumb {
    background: #c4c4c4; }
  ._5EeRfZAjYeDSv51Q9iEyr::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4; }
  ._5EeRfZAjYeDSv51Q9iEyr .irbUBMApRQJ2MlJRjSwp7 {
    font-size: 1.9rem;
    margin-bottom: 1.5rem; }
  ._5EeRfZAjYeDSv51Q9iEyr ._1n0WAYa1zHzjn0wkCadFf6 {
    line-height: 1.8;
    font-size: 1.125rem;
    margin-bottom: 0; }
