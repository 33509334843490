._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB ._2MRm7qsKcTZq5i3lbXdGxt {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB ._2MRm7qsKcTZq5i3lbXdGxt {
      font-size: 1.25rem;
      line-height: 1.8; } }

._2td0KttO3W1P_UHpI89RC- {
  padding: 3rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%; }
  @media all and (min-width: 768px) {
    ._2td0KttO3W1P_UHpI89RC- {
      padding: 6rem 2rem 3rem 2rem; } }
  ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    list-style: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-width: 1300px;
    margin: 0 auto;
    grid-gap: 4vw;
    padding: 0; }
    @media (max-width: 991px) {
      ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm {
        grid-template-columns: auto;
        grid-gap: 2rem;
        width: 100%; } }
    ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB {
      border-radius: 10px;
      padding: 2rem;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      max-width: 100%;
      background: #fff;
      -webkit-box-shadow: -8px 7px 15px 1px rgba(229, 233, 241, 0.78);
              box-shadow: -8px 7px 15px 1px rgba(229, 233, 241, 0.78); }
      @media all and (min-width: 768px) {
        ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB {
          width: 350px; } }
      ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB ._3tnSUVLDSb0XmxKO6O8med {
        height: 285px; }
        ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB ._3tnSUVLDSb0XmxKO6O8med .TJ3k7XG96hLnzdlYIt5tB {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row;
                  flex-flow: row;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin-bottom: 1rem; }
          ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB ._3tnSUVLDSb0XmxKO6O8med .TJ3k7XG96hLnzdlYIt5tB ._2VlobnvQivdyXdfOKnAMOQ {
            height: 40px;
            margin-right: 1rem; }
          ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB ._3tnSUVLDSb0XmxKO6O8med .TJ3k7XG96hLnzdlYIt5tB .cqMWSsk4X54uxbGmp0mg4 {
            font-size: 1.4rem;
            margin-bottom: 0; }
            @media all and (min-width: 768px) {
              ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB ._3tnSUVLDSb0XmxKO6O8med .TJ3k7XG96hLnzdlYIt5tB .cqMWSsk4X54uxbGmp0mg4 {
                font-size: 1.2rem; } }
      ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .NYuEUYvqcvZc6MYrvz9sl {
        line-height: 1.8; }
      ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column; }
        ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE > ._3xUe-uwUsf0hYvE5u_hHLI,
        ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE > a {
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          text-align: center; }
        ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE a:first-child {
          margin-bottom: 1rem; }
        ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE > div a:first-child {
          margin: 0; }
        @media (max-width: 991px) {
          ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE > div {
            -webkit-box-pack: space-evenly;
                -ms-flex-pack: space-evenly;
                    justify-content: space-evenly; }
            ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE > div a {
              margin: 0; } }
        ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE .fTiZBIwpi_PN3co3QbXbs {
          background: #e9ebf0;
          color: #030b0d;
          font-weight: 600;
          border-radius: 5px;
          padding: 0.625rem 0.938rem; }
          ._2td0KttO3W1P_UHpI89RC- .V0QXkeZwyV_xcxA2xQEvm ._3LFoQu4oLGEo6PdlOWDFgB .JCOQrE1qG1OhVfpM8WBPE .fTiZBIwpi_PN3co3QbXbs:hover {
            background: #d7dae4; }
