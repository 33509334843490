._26aXp9RZrkU7-dL5SCqLfq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 100px; }

._1rlcvRDsjpWhc2z4KsUZOp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media all and (max-width: 660px) {
    ._1rlcvRDsjpWhc2z4KsUZOp {
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }

._4BVqrNiUtY-sATUpAdqbh {
  border: none;
  background: none;
  cursor: pointer;
  color: #051c26;
  font-size: 16px;
  opacity: 0.4;
  font-weight: 700;
  outline: none;
  position: relative;
  padding: 10px 0;
  margin-bottom: 20px;
  margin-right: 20px; }
  ._4BVqrNiUtY-sATUpAdqbh[data-selected] {
    opacity: 1; }
    ._4BVqrNiUtY-sATUpAdqbh[data-selected]:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: #1791d3;
      border-radius: 30px;
      position: absolute;
      bottom: 0;
      left: 0; }

.pdbsNRiBe8-OUvdwRPwG- * {
  outline: none; }
