._2zgA7ZHZmFpo0ME1jgaGYF {
  background: #030b0d;
  border-radius: 22px;
  padding: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative; }
  ._2zgA7ZHZmFpo0ME1jgaGYF._3ondWDEalq1wI6uLDb7AJn ._2_HVlAjXdO5AKPLxi0FvBg,
  ._2zgA7ZHZmFpo0ME1jgaGYF._3ondWDEalq1wI6uLDb7AJn ._1-MHQ_ZtUD6ulv3I2Nnlq- {
    color: #00a69c; }
  ._2zgA7ZHZmFpo0ME1jgaGYF._3ondWDEalq1wI6uLDb7AJn ._2T8Pq03ReYjt4bNEL3gOP ._2mdoYjRKAvqtZ-twJURRKh:before {
    background: #00a69c; }
  @media (max-width: 991px) {
    ._2zgA7ZHZmFpo0ME1jgaGYF {
      padding: 2rem; } }

._1FazsZhvVT6hWYIHO1GfHd {
  position: absolute;
  right: 2rem;
  top: -3rem;
  max-width: 40%; }
  @media (max-width: 991px) {
    ._1FazsZhvVT6hWYIHO1GfHd {
      display: none; } }

._1BfVMcgEjKV-0EbNN0oeEb,
._1-MHQ_ZtUD6ulv3I2Nnlq- {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
  width: calc(100% - 250px);
  color: #fff; }

._2_HVlAjXdO5AKPLxi0FvBg,
._1-MHQ_ZtUD6ulv3I2Nnlq-,
._28RwvZHGNVtUNCrWgCtpBM {
  color: #2892d0; }

._28RwvZHGNVtUNCrWgCtpBM {
  font-size: 1.125rem;
  line-height: 1.8;
  font-weight: 600; }

._2T8Pq03ReYjt4bNEL3gOP {
  font-size: 1.125rem;
  line-height: 1.8;
  padding: 0;
  margin: 0 0 2rem 0;
  list-style: none; }
  ._2T8Pq03ReYjt4bNEL3gOP ._2mdoYjRKAvqtZ-twJURRKh {
    position: relative;
    padding-left: 1rem;
    color: #fff; }
    ._2T8Pq03ReYjt4bNEL3gOP ._2mdoYjRKAvqtZ-twJURRKh .lpBIIkQC_AvWrHnK9mtTK {
      color: #2892d0; }
    ._2T8Pq03ReYjt4bNEL3gOP ._2mdoYjRKAvqtZ-twJURRKh:before {
      content: '';
      background: #fff;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      position: absolute;
      left: 0;
      top: 10px;
      display: block; }

._1MuluoaVfiMhbNr66iIRMn {
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.8;
  margin-bottom: 2.5rem; }

._1qV0JkrdoKwZOWKYILQjp_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto; }

._1oMfqcoS6E2cLm0L0IKn93 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.8; }
  ._1oMfqcoS6E2cLm0L0IKn93:hover {
    text-decoration: underline; }

.t8Pfq0ptsAVuOtWVC7ZWd {
  margin: 0; }

._3S1cwdrYAA6qJrrS8WD-Zi {
  padding: 1rem 3rem;
  margin-bottom: .5rem; }
