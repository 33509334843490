._3ICKDCkjXhXJgh_QA6HTz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  ._125T1eQGP0E3h0v4L50keL {
    margin-right: 2rem; }
  ._1kLU8K-GOCwBKPOCUc-MC4 {
    max-width: 300px; }
  .ZqdM-l12wRkHhVjWzVp4G, ._2pMGxSE6AMQ9WN2jBP057Y {
    color: #fff; }
  .ZqdM-l12wRkHhVjWzVp4G {
    font-size: 1.5rem;
    margin-bottom: 1rem; }
  ._2pMGxSE6AMQ9WN2jBP057Y {
    line-height: 1.5; }
