.iG7eMFh5s20Zx1aYhi-eb {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative; }

._3fqjmOL-lpXf5Wx-Y6IyvT {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._1RSrmn8RgDiGrW4VK_CoBN {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

._1CQz1zZynnO-i1QZpiTVUD {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._1CQz1zZynnO-i1QZpiTVUD ._3VFUhimJsow94Wm1OODO0p {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer; }
    ._1CQz1zZynnO-i1QZpiTVUD ._3VFUhimJsow94Wm1OODO0p:hover {
      border: 1px solid silver; }
    ._1CQz1zZynnO-i1QZpiTVUD ._3VFUhimJsow94Wm1OODO0p[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  ._1CQz1zZynnO-i1QZpiTVUD ._2pxPPVaC1F9e57DXEsZ0Ky {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._1CQz1zZynnO-i1QZpiTVUD ._2pxPPVaC1F9e57DXEsZ0Ky:hover {
      background: #34a9e9;
      color: #fff; }

._1wlAii9OQSQ32m30Wm2-Ee {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._2VrXEhhyjMK5YqEy6Re3 {
  background: #f6f9fb;
  border: #f6f9fb;
  color: #758793;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  max-width: 100%;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5em; }

.WWXriHtfS4JypHgbZUCw {
  display: none; }

.qr7fCerADYVME4V2kKBjk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border: 1px solid #e6eaf2;
  background: #e6eaf2;
  border-radius: 3px;
  font-size: 14px;
  color: #5c6066;
  padding: 10px;
  cursor: pointer; }
  .qr7fCerADYVME4V2kKBjk .Jlez27ov-hr750FtSwjKR {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .qr7fCerADYVME4V2kKBjk:hover {
    border: 1px solid silver; }

._1GzHgnr_hhhJlHm8CforlU {
  width: 12px;
  height: 12px;
  margin-right: 10px; }

.ECGH417cIig6ldSGR0BCA {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }

._3M5qulmDvq2_LSGdcJYG1Q {
  font-size: 14px;
  font-weight: 600;
  color: #051c26;
  margin-bottom: 10px; }

._3PC_ytErfzeP2iP6biA_8e {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
