._3-3wDhk6UhgjKGQSG-XAa6 {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3-3wDhk6UhgjKGQSG-XAa6 {
      font-size: 1.25rem;
      line-height: 1.8; } }

._8dzBVnV-JeBUnS2HQrBb0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._3-3wDhk6UhgjKGQSG-XAa6 {
  max-width: 885px;
  margin: 0 auto;
  color: #f4f6f6;
  display: block;
  text-align: center;
  padding: 7.5rem 0; }
  ._3-3wDhk6UhgjKGQSG-XAa6 strong {
    color: #fff; }

._36WhWf3XzSZyLSHFUiGoRj section:first-child {
  padding-top: 2rem; }
  @media all and (min-width: 768px) {
    ._36WhWf3XzSZyLSHFUiGoRj section:first-child {
      padding-top: 7.5rem; } }

._36WhWf3XzSZyLSHFUiGoRj section:last-child {
  padding-bottom: 2rem; }
  @media all and (min-width: 768px) {
    ._36WhWf3XzSZyLSHFUiGoRj section:last-child {
      padding-bottom: 7.5rem; } }

._3kzRh87IKU4skQDJS4690o {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  ._3kzRh87IKU4skQDJS4690o._3aTJHimpZ-Z3jrPEiKxDm3 {
    background-color: #030b0d;
    max-width: 100%; }
  ._3kzRh87IKU4skQDJS4690o._1V9wn8DPwSsBvTjDpd3bMN {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 3.125rem auto;
    max-width: 300px; }
  @media all and (max-width: 1200px) {
    ._3kzRh87IKU4skQDJS4690o {
      padding: 0.625rem; } }

._2vsnh00VTUjO9XlOrKmVH4 {
  background-color: #030b0d;
  padding: 0.5rem 2rem;
  margin-top: -0.063rem; }
  @media all and (min-width: 768px) {
    ._2vsnh00VTUjO9XlOrKmVH4 {
      padding: 4.5rem 8.5rem; } }

._2knrAiGKxFQwvoilM1FH43 {
  padding: 0rem 3rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 3rem auto;
  max-width: 1200px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  padding: 2rem; }
  @media all and (min-width: 768px) {
    ._2knrAiGKxFQwvoilM1FH43 {
      margin: 0 auto; } }
  @media all and (min-width: 768px) {
    ._2knrAiGKxFQwvoilM1FH43 {
      padding: 4rem; } }

._2l780E9DsEv0Evo08dITJY {
  position: absolute;
  left: 0;
  width: 100%;
  max-width: 25px;
  margin-top: 18rem; }
  @media all and (min-width: 768px) {
    ._2l780E9DsEv0Evo08dITJY {
      max-width: 75px; } }

._2N7NXAkDfftwkGxOVhm9UM {
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 25px;
  margin-top: -25rem; }
  @media all and (min-width: 768px) {
    ._2N7NXAkDfftwkGxOVhm9UM {
      max-width: 75px; } }
