._2kgK3nBYey8ieKmJB_hfbs ._2oY3FRCyM3El5rdiXaiIFA, ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ, .CPmhraDNdDOrOCywGjIj9 ._3Wpu4iB2FKuQV6vglPaz4O {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW, ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5, ._2kgK3nBYey8ieKmJB_hfbs ._2uiMTHkodjUWa1bZgKT5bv, ._2kgK3nBYey8ieKmJB_hfbs ._2oY3FRCyM3El5rdiXaiIFA {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW, ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5, ._2kgK3nBYey8ieKmJB_hfbs ._2uiMTHkodjUWa1bZgKT5bv, ._2kgK3nBYey8ieKmJB_hfbs ._2oY3FRCyM3El5rdiXaiIFA {
      font-size: 1.25rem;
      line-height: 1.8; } }

._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW, ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: start;
      align-self: flex-start;
  color: #030b0d;
  font-weight: 600;
  border-radius: 80px; }
  @media (max-width: 991px) {
    ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW, ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5 {
      -ms-flex-item-align: center;
          align-self: center; } }

@-webkit-keyframes nPo-e3lD2APTnsqxnj3rW {
  0% {
    z-index: 3;
    opacity: 0;
    -webkit-transform: scale(0) translate(0px, 0);
    transform: scale(0) translate(0px, 0); }
  50% {
    z-index: 3;
    opacity: 0.5;
    -webkit-transform: scale(0.5) translate(0px, -19px);
    transform: scale(0.5) translate(0px, -19px); }
  100% {
    z-index: 3;
    opacity: 1;
    -webkit-transform: scale(1) translate(0px, -38px);
    transform: scale(1) translate(0px, -38px); } }

@keyframes nPo-e3lD2APTnsqxnj3rW {
  0% {
    z-index: 3;
    opacity: 0;
    -webkit-transform: scale(0) translate(0px, 0);
    transform: scale(0) translate(0px, 0); }
  50% {
    z-index: 3;
    opacity: 0.5;
    -webkit-transform: scale(0.5) translate(0px, -19px);
    transform: scale(0.5) translate(0px, -19px); }
  100% {
    z-index: 3;
    opacity: 1;
    -webkit-transform: scale(1) translate(0px, -38px);
    transform: scale(1) translate(0px, -38px); } }

@-webkit-keyframes _2Blsbs3FDAuNfMbE-zANY1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes _2Blsbs3FDAuNfMbE-zANY1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.qo79S2Jh22TnHUvCjJc3y {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding-top: 8.563rem;
  padding-bottom: 1.063rem;
  margin-top: -5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

._1Mz9vH0IclwxWNlPR2cmqx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 0 1.25rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  max-width: 1400px;
  position: relative;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 1120px) {
    ._1Mz9vH0IclwxWNlPR2cmqx {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      padding: 0 5rem; } }

._2kgK3nBYey8ieKmJB_hfbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 535px;
  text-align: center; }
  ._2kgK3nBYey8ieKmJB_hfbs .BtntZj-xRUWHe-FWpNWHg {
    margin-bottom: 1.5rem;
    padding: 0;
    color: #030b0d;
    font-weight: bold; }
    @media (max-width: 991px) {
      ._2kgK3nBYey8ieKmJB_hfbs .BtntZj-xRUWHe-FWpNWHg {
        font-size: 1.75rem;
        line-height: 1.6; } }
  ._2kgK3nBYey8ieKmJB_hfbs ._2uiMTHkodjUWa1bZgKT5bv {
    margin-bottom: 1.5rem;
    padding: 0; }
  ._2kgK3nBYey8ieKmJB_hfbs ._2oY3FRCyM3El5rdiXaiIFA {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0.5rem; }
  ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    grid-gap: 1rem;
    gap: 1rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center; }
    @media all and (min-width: 505px) {
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row; } }
    @media all and (min-width: 1120px) {
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; } }
    ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW {
      background-color: #f9ca50;
      border: 2px solid #f9ca50;
      padding: 0.2rem 1rem;
      font-size: 0.9rem;
      text-align: center; }
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW:hover {
        background: #f8c132; }
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW:active {
        background: #f8be29; }
      @media all and (min-width: 290px) {
        ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW {
          font-size: 1.125rem; } }
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW:hover {
        background: #f7bb1f;
        border-color: #f7bb1f; }
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._219Zv0OzlzjPGa2UyFcCqW:active {
        background: #f4b308;
        border-color: #f4b308; }
    ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5 {
      border: 2px solid #030b0d;
      grid-gap: 0;
      gap: 0;
      padding: 0.2rem 0.2rem;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      text-align: center;
      font-size: 0.9rem; }
      @media all and (min-width: 290px) {
        ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5 {
          grid-gap: 0.3rem;
          gap: 0.3rem;
          padding: 0.2rem 0.5rem;
          font-size: 1.125rem; } }
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5:hover {
        background: #00b4d8;
        border-color: #00b4d8;
        color: #fff; }
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5:active, ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5:focus {
        background: #009bb9;
        border-color: #009bb9;
        color: #fff; }
      ._2kgK3nBYey8ieKmJB_hfbs ._1SyWA6SqBwruVyJgRpHKTQ ._2pAaIIlneb8y7wwefAQIA5 ._1G5Q9Ni_scYgnOt_IK5A_r {
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 50%; }
  @media all and (min-width: 1120px) {
    ._2kgK3nBYey8ieKmJB_hfbs {
      text-align: left; } }

.CPmhraDNdDOrOCywGjIj9 {
  display: none;
  padding-left: 2rem; }
  .CPmhraDNdDOrOCywGjIj9 ._1Gnx5CJrhXC67idgaMzZc6,
  .CPmhraDNdDOrOCywGjIj9 ._1vWG5e0cU1AsPIacwYTmhk {
    display: inline-block; }
    @media all and (min-width: 1020px) {
      .CPmhraDNdDOrOCywGjIj9 ._1Gnx5CJrhXC67idgaMzZc6,
      .CPmhraDNdDOrOCywGjIj9 ._1vWG5e0cU1AsPIacwYTmhk {
        width: 438px; } }
    @media all and (min-width: 1300px) {
      .CPmhraDNdDOrOCywGjIj9 ._1Gnx5CJrhXC67idgaMzZc6,
      .CPmhraDNdDOrOCywGjIj9 ._1vWG5e0cU1AsPIacwYTmhk {
        width: 618px; } }
    @media all and (min-width: 1420px) {
      .CPmhraDNdDOrOCywGjIj9 ._1Gnx5CJrhXC67idgaMzZc6,
      .CPmhraDNdDOrOCywGjIj9 ._1vWG5e0cU1AsPIacwYTmhk {
        width: 738px; } }
  .CPmhraDNdDOrOCywGjIj9 ._1Gnx5CJrhXC67idgaMzZc6 {
    z-index: 2; }
  .CPmhraDNdDOrOCywGjIj9 ._1vWG5e0cU1AsPIacwYTmhk {
    position: absolute;
    z-index: 1; }
  @media all and (min-width: 1120px) {
    .CPmhraDNdDOrOCywGjIj9 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
  .CPmhraDNdDOrOCywGjIj9 ._3Wpu4iB2FKuQV6vglPaz4O {
    background-color: #00a69c;
    color: #fff;
    border-radius: 80px;
    z-index: 4;
    font-weight: 500;
    padding: 0.5rem 1rem; }
    .CPmhraDNdDOrOCywGjIj9 ._3Wpu4iB2FKuQV6vglPaz4O:hover {
      background: #00877f; }
    .CPmhraDNdDOrOCywGjIj9 ._3Wpu4iB2FKuQV6vglPaz4O:active {
      background: #007d76; }
    .CPmhraDNdDOrOCywGjIj9 ._3Wpu4iB2FKuQV6vglPaz4O:hover {
      -webkit-animation-name: _2Blsbs3FDAuNfMbE-zANY1;
              animation-name: _2Blsbs3FDAuNfMbE-zANY1;
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-direction: normal;
              animation-direction: normal;
      -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear; }
      .CPmhraDNdDOrOCywGjIj9 ._3Wpu4iB2FKuQV6vglPaz4O:hover._39cH8_Tb0Vqtn1vRQnOCKc {
        -webkit-animation: unset;
                animation: unset; }
  .CPmhraDNdDOrOCywGjIj9 ._3DKsm8NiVc1ysJ-qL6Xqqu {
    -webkit-animation-name: nPo-e3lD2APTnsqxnj3rW;
            animation-name: nPo-e3lD2APTnsqxnj3rW;
    -webkit-animation-duration: 0.15s;
            animation-duration: 0.15s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear; }
