._2ou1FG2rQl2B1JzvL3GjiT {
  padding: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 1024px;
  overflow: auto;
  max-height: 80vh; }

.GCalHUiXfIwwk18aUZE1D {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._2SMwvP_vv9x7K0Vwa2cqon {
  font-size: 12px;
  color: #657881;
  margin-bottom: 10px; }

._2MYsVDXC2yweoAcVrjdNrY {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._11gYfHeBKv5nyOaRWoSyYM {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._1vvNxlSAlO6cF1W2Pszn8Y {
  margin-right: 0.5rem; }

._1SLd5GPiG8usfc5qqyRL7h {
  max-width: 50vw;
  -ms-flex-item-align: center;
      align-self: center;
  max-height: 60vh; }

._2oObHkwGfzedrTinxRGMNn {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }

._1ggezlqb1mZDKUC2dYe0Q ._13XabVkMp-oMABwSApsb_R {
  color: #2c2c2c;
  font-size: 0.9rem;
  margin-right: 0.5rem; }

._1ggezlqb1mZDKUC2dYe0Q .Qhz7UA_A4hlCCaV2JVH_- {
  color: #758793;
  font-size: 0.9rem;
  border: none;
  cursor: pointer; }

._1r9ZiU0ux6Fpe8E31Kfb-I {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._1r9ZiU0ux6Fpe8E31Kfb-I ._3ifLCB98CpoL6pWpNSGSdg {
    min-width: 100px; }
    ._1r9ZiU0ux6Fpe8E31Kfb-I ._3ifLCB98CpoL6pWpNSGSdg.PQDbKuGLC-5IbfKEzgd7d {
      color: #fa1854;
      background: #fa18541c 0% 0% no-repeat padding-box;
      border: 1px solid #fa1854;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    ._1r9ZiU0ux6Fpe8E31Kfb-I ._3ifLCB98CpoL6pWpNSGSdg:first-child {
      margin-right: 0.5rem; }
