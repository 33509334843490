._3gq8w5aqY7Urot0L1xmVr8 {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 750px;
  overflow: auto;
  max-height: 80vh; }

._3bBRI5hrvc84Y8Esp2QbIP {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._2LEo0KEU4YyWcvzAkKFzOr {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

._3z7NnOKQZNfGC6sg6mkGKp {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._3z7NnOKQZNfGC6sg6mkGKp ._1FMNuGGnJvztw126liLAJk {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer; }
    ._3z7NnOKQZNfGC6sg6mkGKp ._1FMNuGGnJvztw126liLAJk:hover {
      border: 1px solid silver; }
    ._3z7NnOKQZNfGC6sg6mkGKp ._1FMNuGGnJvztw126liLAJk[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  ._3z7NnOKQZNfGC6sg6mkGKp ._2VbbS65AzEET0MCyZb3otD {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._3z7NnOKQZNfGC6sg6mkGKp ._2VbbS65AzEET0MCyZb3otD:hover {
      background: #34a9e9;
      color: #fff; }

.nqiF6E1ZZ1RnuFq28k44t {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._1I5ylWJ_-vHgtRoonUAQYm {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 10px 0; }

.KH0X5ioyzJC5IFyBPZy_S {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .KH0X5ioyzJC5IFyBPZy_S .yTF6kKamHTMftjYqp081d {
    font-size: 14px;
    font-weight: 600;
    color: #051c26;
    margin-right: 10px; }
  .KH0X5ioyzJC5IFyBPZy_S ._3D-bCBYcHIpZ47dJigchEv {
    font-size: 14px;
    font-weight: 600;
    color: #1791d3;
    background: #f6f9fb;
    padding: 5px;
    border: none; }

.LLKFKO26gtkrKqrOidd0J {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }
  .LLKFKO26gtkrKqrOidd0J .yTF6kKamHTMftjYqp081d {
    font-size: 16px;
    font-weight: 700;
    color: #2c2c2c;
    margin-right: 10px; }

._3hJAlGzL4_yaZlvOYNpW0W {
  color: #1167f3;
  padding: 5px;
  background-color: #318dbc1c;
  border: 1px solid #1167f3;
  border-radius: 3px;
  cursor: pointer; }
  ._3hJAlGzL4_yaZlvOYNpW0W:hover {
    background-color: rgba(28, 80, 107, 0.109804); }

._1xX8dTsouLA0hZvC7Vz4uv {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem;
  width: 100%; }

.BCxMF06s4eblqh0SmhcBF {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: auto;
  border-collapse: collapse; }
  .BCxMF06s4eblqh0SmhcBF ._1LDIaIG0zqBCEcVGE8M9NW {
    font-size: 14px;
    color: #657881;
    border-bottom: 1px solid #758793;
    padding: 10px 20px;
    line-height: 2em; }
  .BCxMF06s4eblqh0SmhcBF ._2nw7sOwfruDnLTcjAFKxzu {
    color: #2c2c2c;
    font-weight: 700;
    padding-left: 40px;
    position: relative; }
    @media all and (min-width: 1100px) {
      .BCxMF06s4eblqh0SmhcBF ._2nw7sOwfruDnLTcjAFKxzu:before {
        content: '';
        background: #1791d3 0% 0% no-repeat padding-box;
        border-radius: 4px;
        width: 15px;
        height: 15px;
        display: inline-block;
        left: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        position: absolute; }
      .BCxMF06s4eblqh0SmhcBF ._809rpVA1ENZgFNAtedVSE:before {
        background-color: #00a69c; }
      .BCxMF06s4eblqh0SmhcBF ._1B8u3w2f0NvHtGkohOriRt:before {
        background-color: #f5371b; }
      .BCxMF06s4eblqh0SmhcBF .GLSK3Po1Q9ncl9A_v5bpT:before {
        background-color: #fbb703; } }
  .BCxMF06s4eblqh0SmhcBF ._3g_lBITEemPP_mgOtnQYfY {
    padding: 10px;
    background: #e6eaf2;
    border: none;
    max-width: 50px; }
    .BCxMF06s4eblqh0SmhcBF ._3g_lBITEemPP_mgOtnQYfY:invalid {
      color: #fa1854; }
    .BCxMF06s4eblqh0SmhcBF ._3g_lBITEemPP_mgOtnQYfY[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }

.EGlfE7mYm8othCH2AASxL {
  border-radius: 3px;
  background-color: #f6f9fb;
  color: #758793;
  padding: 10px;
  width: 100%; }
