._20joQ1U4LKRzFFAMrirBKO {
  margin-top: 2.5rem !important; }

.fkLyft_fTHSAizEX55XZX {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100% !important;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  font-size: 0; }
  .fkLyft_fTHSAizEX55XZX:first-child {
    margin-top: 0; }
  .PHsg0M1dxUq6FTEOj-ld- {
    display: block;
    width: calc(25% - 10px);
    height: auto;
    margin-top: 0.625rem;
    margin-left: 0.625rem !important;
    position: relative; }
    .PHsg0M1dxUq6FTEOj-ld-:before {
      display: block;
      width: 100%;
      height: auto;
      padding-bottom: 100%;
      content: ''; }
    @media screen and (min-width: 700px) {
      .PHsg0M1dxUq6FTEOj-ld-:nth-child(4n + 1) {
        margin-left: 0 !important; } }
    @media screen and (min-width: 551px) and (max-width: 700px) {
      .PHsg0M1dxUq6FTEOj-ld-:nth-child(3n + 1) {
        margin-left: 0 !important; } }
    @media screen and (max-width: 550px) {
      .PHsg0M1dxUq6FTEOj-ld-:nth-child(2n + 1) {
        margin-left: 0 !important; } }
    ._3wlXvhzL_AvBB230cp87sy {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 1.563rem;
      width: calc(100% - 50px);
      height: calc(100% - 50px);
      position: absolute;
      top: 0;
      left: 0;
      background: #1f1f1f;
      border: 1px solid #0a0a0a;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      ._3wlXvhzL_AvBB230cp87sy:hover {
        background: #393939; }
    ._1IXQjYrR8jdGwXUtlB0TDe {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 90%; }
    @media screen and (min-width: 800px) {
      .PHsg0M1dxUq6FTEOj-ld- {
        text-align: left;
        max-width: 1080px; } }
    @media screen and (max-width: 700px) {
      .PHsg0M1dxUq6FTEOj-ld- {
        width: calc(33% - 5px); } }
    @media screen and (min-width: 551px) and (max-width: 700px) {
      .PHsg0M1dxUq6FTEOj-ld- {
        width: calc(33% - 5px); } }
    @media screen and (max-width: 550px) {
      .PHsg0M1dxUq6FTEOj-ld- {
        width: calc(50% - 5px); } }
  @media screen and (min-width: 800px) {
    .fkLyft_fTHSAizEX55XZX {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
