._3wGgWPWDlndciy755eCZLG {
  margin-bottom: 20px;
  overflow: auto;
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px; }

._1VajbsYF-7uafY9i1GmxEH {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }
  ._1VajbsYF-7uafY9i1GmxEH thead {
    background-color: transparent; }
  ._1VajbsYF-7uafY9i1GmxEH tr {
    border: none; }
    @media (max-width: 1240px) {
      ._1VajbsYF-7uafY9i1GmxEH tr {
        display: table;
        border-bottom: 1px solid #f4f4f4;
        width: 100%;
        margin-bottom: 1rem; }
        ._1VajbsYF-7uafY9i1GmxEH tr th {
          display: none; }
        ._1VajbsYF-7uafY9i1GmxEH tr td {
          display: block; }
          ._1VajbsYF-7uafY9i1GmxEH tr td:last-child {
            padding-bottom: 1rem; } }

._37TYRB4-dYZQE9AD-7-Uh7:hover {
  background-color: #f6f9fb; }

._39g1AHjPx30EFRo2e_-gau {
  font-size: 14px;
  color: #758793;
  padding: 0.5rem 1rem;
  line-height: 2em; }
  @media (max-width: 1240px) {
    ._39g1AHjPx30EFRo2e_-gau {
      padding: 0 1rem; } }

._1gjwUiyQ1H1SG04_xN7EY6 {
  display: none;
  margin-right: 0.5rem; }
  @media (max-width: 1240px) {
    ._1gjwUiyQ1H1SG04_xN7EY6 {
      display: inline-block; } }

._52RHfa9PcZYExDpXkpO-5 {
  color: #2c2c2c;
  font-weight: 700;
  text-align: left;
  position: relative;
  padding-left: 40px; }
  ._52RHfa9PcZYExDpXkpO-5:before {
    content: '';
    background: #00b4d8 0% 0% no-repeat padding-box;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute; }
  .VOY2cixsLXL05Amcn8v_:before {
    background-color: #2892d0; }
  .h1IfmxsODOuOydsxPJrYh:before {
    background-color: #00a69c; }
  .j8QiGbzzrm0lzX4VqN-xC:before, ._3y_g5kyHvVRhN1M4jc7drH:before {
    background-color: #f5371b; }
  ._2jcG8pVUWRbAgYwn6HStEz:before, .vutf0JAtVMWX-H6dYQ8va:before {
    background-color: #fbb703; }
