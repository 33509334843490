._2Z1hurFihNnWwSDWBr0T94 {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  overflow: auto; }

._1VWBQ8b8Pd2FPQ4khOMu5B {
  color: #657881;
  font-size: 0.9rem;
  margin-bottom: 3rem;
  display: block; }

.fHqgnvHBWl6a6jJhKjsRV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 20px; }

._2ODdksIdsPzyR4yVjEB9O6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media all and (max-width: 660px) {
    ._2ODdksIdsPzyR4yVjEB9O6 {
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly; } }

._3qnnKY4SyhnncpEg3WKAxW {
  border: none;
  background: none;
  cursor: pointer;
  color: #051c26;
  font-size: 16px;
  opacity: 0.4;
  font-weight: 700;
  outline: none;
  position: relative;
  padding: 10px 0;
  margin-bottom: 20px;
  margin-right: 20px; }
  ._3qnnKY4SyhnncpEg3WKAxW[data-selected] {
    opacity: 1; }

._2OEjuYDpX6uT-3_JkbMcQy * {
  outline: none; }
