._1_XuFbOsTfcsNxpTE8uqz_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding-bottom: 80px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._3Qbwx2X3_uuPwQGT15U9WJ {
    font-size: 20px;
    line-height: 38px;
    color: #f9f9f9;
    font-weight: bold;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin: 0;
    margin-top: 20px; }
  ._2GwEW1D6aGmUWyxDLNU4_w {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    padding: 0;
    margin: 0; }
    ._2Cef7kHiYlTeAueg4t6nCo {
      display: block;
      background-image: url(/_next/static/images/broadcast-to-a1b9d2803a05e577ce342175d70ad0e4.png);
      background-repeat: no-repeat;
      background-color: transparent;
      margin-top: 20px;
      margin-left: 20px; }
      .M65rsuN_YDDEHNi0JtuZQ {
        display: block;
        width: 100%;
        height: 100%; }
      ._3COQGTfcJjuheRVr_KIeFi {
        width: 121px;
        height: 36px;
        background-position: -0 -175px; }
        ._3COQGTfcJjuheRVr_KIeFi:hover, ._3COQGTfcJjuheRVr_KIeFi:active {
          background-position: -184px -115px; }
      ._2OIoUv0jPoRteK1R2yqUI- {
        width: 184px;
        height: 35px;
        background-position: -0 -35px; }
        ._2OIoUv0jPoRteK1R2yqUI-:hover, ._2OIoUv0jPoRteK1R2yqUI-:active {
          background-position: -0 -0; }
      .HfTISosuy2-owEtIXfkq5 {
        width: 182px;
        height: 35px;
        background-position: -0 -105px; }
        .HfTISosuy2-owEtIXfkq5:hover, .HfTISosuy2-owEtIXfkq5:active {
          background-position: -0 -70px; }
      ._2nNkoAEJiex_Azx89slwdu {
        width: 157px;
        height: 35px;
        background-position: -184px -0; }
        ._2nNkoAEJiex_Azx89slwdu:hover, ._2nNkoAEJiex_Azx89slwdu:active {
          background-position: -0 -140px; }
      .stKXRTKE42bued1JkOvqa {
        width: 121px;
        height: 40px;
        background-position: -184px -75px; }
        .stKXRTKE42bued1JkOvqa:hover, .stKXRTKE42bued1JkOvqa:active {
          background-position: -184px -35px; }
      @media all and (max-width: 900px) {
        ._2Cef7kHiYlTeAueg4t6nCo {
          margin-left: 10px; } }
    @supports (-ms-ime-align: auto) {
      ._2GwEW1D6aGmUWyxDLNU4_w {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin-left: 60px; } }
    @media all and (-ms-high-contrast: none) {
      ._2GwEW1D6aGmUWyxDLNU4_w {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin-left: 60px; } }
  ._272pcuXdpZ2BbzVMCOnbbL {
    height: 150px;
    padding-bottom: 20px;
    background: #fafafa; }
    ._272pcuXdpZ2BbzVMCOnbbL ._3Qbwx2X3_uuPwQGT15U9WJ {
      color: #707274; }
    ._272pcuXdpZ2BbzVMCOnbbL ._3COQGTfcJjuheRVr_KIeFi:hover, ._272pcuXdpZ2BbzVMCOnbbL ._3COQGTfcJjuheRVr_KIeFi:active {
      background-position: -0 -175px; }
    ._272pcuXdpZ2BbzVMCOnbbL ._2OIoUv0jPoRteK1R2yqUI-:hover, ._272pcuXdpZ2BbzVMCOnbbL ._2OIoUv0jPoRteK1R2yqUI-:active {
      background-position: -0 -35px; }
    ._272pcuXdpZ2BbzVMCOnbbL .HfTISosuy2-owEtIXfkq5:hover, ._272pcuXdpZ2BbzVMCOnbbL .HfTISosuy2-owEtIXfkq5:active {
      background-position: -0 -105px; }
    ._272pcuXdpZ2BbzVMCOnbbL ._2nNkoAEJiex_Azx89slwdu:hover, ._272pcuXdpZ2BbzVMCOnbbL ._2nNkoAEJiex_Azx89slwdu:active {
      background-position: -184px -0; }
    ._272pcuXdpZ2BbzVMCOnbbL .stKXRTKE42bued1JkOvqa:hover, ._272pcuXdpZ2BbzVMCOnbbL .stKXRTKE42bued1JkOvqa:active {
      background-position: -184px -75px; }
    @media all and (max-width: 900px) {
      ._272pcuXdpZ2BbzVMCOnbbL {
        height: auto;
        margin-top: 0 !important;
        background: transparent !important; } }
  @media all and (max-width: 900px) {
    ._1_XuFbOsTfcsNxpTE8uqz_ {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-top: 50px;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-top: 30px;
      padding-bottom: 30px;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(125, 185, 232, 0)), color-stop(40%, rgba(0, 0, 0, 0.75)));
      background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, rgba(0, 0, 0, 0.75) 40%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#000000',GradientType=0 ); } }
  @media all and (max-height: 750px) and (min-width: 800px) {
    ._1_XuFbOsTfcsNxpTE8uqz_ {
      padding-bottom: 20px; } }
