.dZsNOi2tyZ6Z73X0uS12W p, ._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt ._2bUqcPWEpqdwf__mboLtbq {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3xCqbzcHMwJRJHmnD1aImh {
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
  padding: 2rem 0 0; }
  ._3xCqbzcHMwJRJHmnD1aImh._2j1FNoW_o6B9wsGzvlTZiq {
    display: none; }

._3XmNnBB4n4fNkB9een1-Nn {
  border-spacing: 0 15px;
  width: 100%; }
  @media (max-width: 991px) {
    ._3XmNnBB4n4fNkB9een1-Nn {
      overflow-x: auto;
      width: 100%;
      display: block; } }

.dZsNOi2tyZ6Z73X0uS12W {
  text-align: left;
  background-color: #e4e8ed; }
  .dZsNOi2tyZ6Z73X0uS12W ._1Cn7gYpc-1YwjLTfBeEa_n {
    padding: 1rem 2rem; }
  .dZsNOi2tyZ6Z73X0uS12W p {
    font-size: 1.2rem;
    margin: 0; }
    @media (max-width: 991px) {
      .dZsNOi2tyZ6Z73X0uS12W p {
        font-size: 1rem; } }

._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt {
  background-color: #f2f4f7;
  padding-bottom: 1rem; }
  ._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt ._2bUqcPWEpqdwf__mboLtbq {
    font-weight: 600;
    font-size: 1rem;
    min-width: 150px;
    line-height: 1.5; }
  ._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt ._1jYyRgBKyCoJyNAIjoTxp6 {
    line-height: 1.5; }
    @media (max-width: 991px) {
      ._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt ._1jYyRgBKyCoJyNAIjoTxp6 {
        min-width: 185px; } }
    ._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt ._1jYyRgBKyCoJyNAIjoTxp6 span {
      display: block; }
      ._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt ._1jYyRgBKyCoJyNAIjoTxp6 span:nth-child(2), ._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt ._1jYyRgBKyCoJyNAIjoTxp6 span:nth-child(3) {
        margin-top: 1rem; }
  ._1E1tYveNwhRj4IVc9yiZXZ ._2cw8EjHKHLqhkcLaXtCFGt td {
    padding: 1.2rem 2rem; }

.V1D12hCzCjrGNkEEppz9w {
  padding: 0.7rem;
  text-align: center;
  margin-top: 2rem; }
  ._1XlyjMWkqr5lrXn3ZlVMPa {
    background-color: #00adef; }
  ._3d0PNvBeOJNWYC9r7E9EFl {
    background-color: black; }
  .gf6N5jdYhRJKM5em7_V1a {
    max-height: 25px; }
