.A2bsMFOhuHRdLH4vDwOAQ {
  padding: 2rem 0 0;
  font-family: Hind; }
  .A2bsMFOhuHRdLH4vDwOAQ .dvh9K2zdvd5Bn0blP-RXu {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #030b0d; }
    @media (max-width: 991px) {
      .A2bsMFOhuHRdLH4vDwOAQ .dvh9K2zdvd5Bn0blP-RXu {
        font-size: 1.75rem;
        line-height: 1.6; } }
  .A2bsMFOhuHRdLH4vDwOAQ ._1caPdcepZq-Z2dEXvoDQDU {
    line-height: 1.5rem;
    color: #030b0d; }

._3XcFZjcUTNW77rxYIcXVHG {
  padding: 2rem 2rem 0; }
  ._3XcFZjcUTNW77rxYIcXVHG .dvh9K2zdvd5Bn0blP-RXu {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #4b4c4d; }
  ._3XcFZjcUTNW77rxYIcXVHG ._1caPdcepZq-Z2dEXvoDQDU {
    line-height: 1.5rem;
    color: #62737f; }
