._1MIxROxVMrFP5kt437MElk a {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2V4Gb2sUofrOnLVX0XLqZz {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2V4Gb2sUofrOnLVX0XLqZz {
      font-size: 2.5rem;
      line-height: 1.7; } }

._1MIxROxVMrFP5kt437MElk a {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._1MIxROxVMrFP5kt437MElk a {
      font-size: 1.25rem;
      line-height: 1.8; } }

@-webkit-keyframes _1kRWF8OgH1HJDUny5zyLw7 {
  0% {
    -webkit-transform: translateX(calc(0px * 10));
            transform: translateX(calc(0px * 10)); }
  100% {
    -webkit-transform: translateX(calc(-270px * 10));
            transform: translateX(calc(-270px * 10)); } }

@keyframes _1kRWF8OgH1HJDUny5zyLw7 {
  0% {
    -webkit-transform: translateX(calc(0px * 10));
            transform: translateX(calc(0px * 10)); }
  100% {
    -webkit-transform: translateX(calc(-270px * 10));
            transform: translateX(calc(-270px * 10)); } }

._3t-evLG6Wjw9AqXrLJPu4y {
  background: #e5ebf0;
  padding: 6rem 2rem;
  overflow: hidden; }
  @media (max-width: 991px) {
    ._3t-evLG6Wjw9AqXrLJPu4y {
      padding: 3rem 2rem; } }

._3yKN7MCkHOoo_5TYeu4g5l {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  grid-gap: 3rem;
  gap: 3rem;
  -webkit-animation: _1kRWF8OgH1HJDUny5zyLw7 35s linear infinite;
          animation: _1kRWF8OgH1HJDUny5zyLw7 35s linear infinite;
  width: calc(494px * 10); }
  ._3yKN7MCkHOoo_5TYeu4g5l:hover {
    -webkit-animation-play-state: paused;
            animation-play-state: paused; }

._2V4Gb2sUofrOnLVX0XLqZz {
  text-align: center;
  word-break: break-word;
  margin-bottom: 4rem; }
  @media (max-width: 991px) {
    ._2V4Gb2sUofrOnLVX0XLqZz {
      margin-bottom: 2rem; } }

._1MIxROxVMrFP5kt437MElk {
  margin-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._1MIxROxVMrFP5kt437MElk a {
    text-align: center;
    border-radius: 80px;
    margin: 0;
    background: #1a70d4;
    background: linear-gradient(127deg, #1a70d4 13%, #2591cf 100%);
    word-break: break-word; }
    ._1MIxROxVMrFP5kt437MElk a:hover {
      background: linear-gradient(-127deg, #1a70d4 13%, #2591cf 100%); }
    ._1MIxROxVMrFP5kt437MElk a > div {
      padding: 0 2rem; }
