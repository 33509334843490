.PtEP8HCGyXPDFxeh5dzRi {
  color: #b1b2b5;
  font-weight: 600; }
  .PtEP8HCGyXPDFxeh5dzRi._2pOoSa6Xp_kczRF4URN7y9 {
    color: #1f8e0d; }

._2-0MhgzWSy0bG5nSd8U0J_ {
  text-align: right;
  width: 135px; }
  ._2-0MhgzWSy0bG5nSd8U0J_ ._1EDBHoQu0AtHpXCzWX9KQz {
    cursor: pointer;
    color: #fa1854;
    font-weight: 500; }
    ._2-0MhgzWSy0bG5nSd8U0J_ ._1EDBHoQu0AtHpXCzWX9KQz:hover {
      color: #e90542;
      font-weight: 600; }
  @media (max-width: 991px) {
    ._2-0MhgzWSy0bG5nSd8U0J_ {
      width: auto; } }

.ULyqGaAHjWR2x38SiFPb6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .ULyqGaAHjWR2x38SiFPb6 ._209QDssGx_sdcKsOYYmXhY {
    height: 16px;
    width: auto;
    margin-right: 0.625rem;
    display: inline; }
