.ym1e8kdljVs7IU5BwUMLl .VUIscJoxchi8YnLnsSU-M, .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf ._3MobyHVucSqn2m4SAJmJoo {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .ym1e8kdljVs7IU5BwUMLl .VUIscJoxchi8YnLnsSU-M, .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf ._3MobyHVucSqn2m4SAJmJoo {
      font-size: 1.25rem;
      line-height: 1.8; } }

.ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf > ._3lwziIEACE2VZloRbm8Gg6 {
  font-size: 1.75rem;
  line-height: 1.8;
  font-weight: bold; }
  @media all and (min-width: 768px) {
    .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf > ._3lwziIEACE2VZloRbm8Gg6 {
      font-size: 2rem;
      line-height: 1.5; } }

.ym1e8kdljVs7IU5BwUMLl {
  max-width: 992px;
  width: calc(100% - 4rem);
  padding: 4rem 0rem;
  margin: 0 auto; }
  @media all and (min-width: 360px) {
    .ym1e8kdljVs7IU5BwUMLl {
      width: calc(100% - 8rem);
      padding: 4rem 1.5rem; } }
  .ym1e8kdljVs7IU5BwUMLl ._TGEBdLFsmYA_QVTq8s1Z {
    margin-bottom: 0.5rem;
    font-size: 2.5rem; }
    @media all and (min-width: 360px) {
      .ym1e8kdljVs7IU5BwUMLl ._TGEBdLFsmYA_QVTq8s1Z {
        font-size: 3rem; } }
    .ym1e8kdljVs7IU5BwUMLl ._TGEBdLFsmYA_QVTq8s1Z span {
      color: #00b4d8; }
  .ym1e8kdljVs7IU5BwUMLl .VUIscJoxchi8YnLnsSU-M {
    margin-bottom: 2rem; }
  .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf {
    display: grid;
    grid-gap: 0 8rem;
    grid-template-columns: repeat(auto-fit, minmax(15rem, -webkit-max-content));
    grid-template-columns: repeat(auto-fit, minmax(15rem, max-content)); }
    .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf {
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content;
      display: grid;
      grid-gap: 2rem;
      grid-auto-flow: row; }
      .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf > ._3lwziIEACE2VZloRbm8Gg6 {
        padding-top: 2rem;
        margin-bottom: 1rem;
        position: relative; }
        .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf > ._3lwziIEACE2VZloRbm8Gg6::before {
          display: block;
          position: absolute;
          top: 44px;
          left: -48px; }
      .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf ._3MobyHVucSqn2m4SAJmJoo a[href^='tel:'] {
        color: #030b0d; }
        .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf ._3MobyHVucSqn2m4SAJmJoo a[href^='tel:']:hover {
          color: #00b4d8;
          text-decoration: underline; }
      .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf ._3PuOsB2v1wr7QZIe0Q3yb9 {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 0.5rem; }
      .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf:nth-of-type(1) > ._3lwziIEACE2VZloRbm8Gg6::before {
        content: '';
        background-color: #d6731a;
        width: 25px;
        height: 25px;
        border-radius: 50px; }
      .ym1e8kdljVs7IU5BwUMLl ._3aVskVqvGP9paoS1vqB0Tf ._2c2IGRMlY-wh50KQzL1Vpf:nth-of-type(2) > ._3lwziIEACE2VZloRbm8Gg6::before {
        content: '';
        height: 0;
        width: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 23px solid #ffd15c; }

.dLo3Lauo-Q-gYfCd_YTMb {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: auto;
  grid-gap: 1rem;
  list-style: none;
  padding: 0;
  margin: 0; }
  .dLo3Lauo-Q-gYfCd_YTMb ._1qh9dhpvvXXGXYy7K7-D0c {
    border: 2px solid #030b0d;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .dLo3Lauo-Q-gYfCd_YTMb ._1qh9dhpvvXXGXYy7K7-D0c:hover {
      background-color: #00b4d8;
      border: 2px solid #00b4d8; }
      .dLo3Lauo-Q-gYfCd_YTMb ._1qh9dhpvvXXGXYy7K7-D0c:hover ._2UB24oRwFhCGUSEWlRL6E6 {
        color: #fff; }
    .dLo3Lauo-Q-gYfCd_YTMb ._1qh9dhpvvXXGXYy7K7-D0c ._2UB24oRwFhCGUSEWlRL6E6 {
      padding: 0.25rem;
      color: #030b0d; }
