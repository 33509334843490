._36cPjP1WhDFsh1Y7KzcQLr .uVyP_S1WjYANRisVUCVoE {
  font-family: 'Inter', system-ui, sans-serif; }

._36cPjP1WhDFsh1Y7KzcQLr .uVyP_S1WjYANRisVUCVoE {
  font-size: 0.875rem;
  color: #657881;
  margin: 0.625rem 0 1.25rem;
  display: block;
  max-width: 550px;
  font-weight: 600; }

._36cPjP1WhDFsh1Y7KzcQLr ._3LeTNkhOBFqayuNQvE5Ogx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 991px) {
    ._36cPjP1WhDFsh1Y7KzcQLr ._3LeTNkhOBFqayuNQvE5Ogx {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse;
      -webkit-box-align: unset;
          -ms-flex-align: unset;
              align-items: unset; }
      ._36cPjP1WhDFsh1Y7KzcQLr ._3LeTNkhOBFqayuNQvE5Ogx button {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 1rem; }
      ._36cPjP1WhDFsh1Y7KzcQLr ._3LeTNkhOBFqayuNQvE5Ogx button:last-child {
        margin-bottom: 1rem; } }
  ._36cPjP1WhDFsh1Y7KzcQLr ._3LeTNkhOBFqayuNQvE5Ogx button {
    font-size: 0.875rem;
    padding: 0.625rem; }
  ._36cPjP1WhDFsh1Y7KzcQLr ._3LeTNkhOBFqayuNQvE5Ogx button:first-child {
    margin-right: 1.25rem; }
    ._36cPjP1WhDFsh1Y7KzcQLr ._3LeTNkhOBFqayuNQvE5Ogx button:first-child:hover {
      background: #f0f0f0; }
    @media (max-width: 991px) {
      ._36cPjP1WhDFsh1Y7KzcQLr ._3LeTNkhOBFqayuNQvE5Ogx button:first-child {
        margin: 0; } }
