.a4EzLtrY_c_xuWQSkFxoD {
  background-color: #030b0d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 8rem 10rem;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 991px) {
    .a4EzLtrY_c_xuWQSkFxoD {
      padding: 3rem 1.25rem 3rem 3.25rem; } }
  .a4EzLtrY_c_xuWQSkFxoD ._1NSESJmjRw2W_OGYVVjLff,
  .a4EzLtrY_c_xuWQSkFxoD ._2PPen0EGHVxm_54nAjPQC_ {
    color: #fff; }
  .a4EzLtrY_c_xuWQSkFxoD ._1NSESJmjRw2W_OGYVVjLff {
    margin-bottom: 2rem; }
  .a4EzLtrY_c_xuWQSkFxoD ._2PPen0EGHVxm_54nAjPQC_ {
    font-size: 1.25rem;
    line-height: 1.8; }
    @media (max-width: 991px) {
      .a4EzLtrY_c_xuWQSkFxoD ._2PPen0EGHVxm_54nAjPQC_ {
        line-height: 1.6;
        font-size: 1.125rem; } }
  .a4EzLtrY_c_xuWQSkFxoD ._1VNi9OzO4zMdIsv66js6vs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    max-width: 1250px;
    margin: 0 auto; }
