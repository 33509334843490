._3xSHdzyzVz_eSeSX3H5X0Q ._2fpgo2po0U00SuGeDbcR9W {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3xSHdzyzVz_eSeSX3H5X0Q ._2fpgo2po0U00SuGeDbcR9W {
      font-size: 1.25rem;
      line-height: 1.8; } }

._2n1yDTljq0dNYjFY_5CHCg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin: 0;
  padding: 3rem 1.25rem; }
  @media all and (min-width: 992px) {
    ._2n1yDTljq0dNYjFY_5CHCg {
      margin: 8rem 0;
      padding: 0 0 0 5rem; } }
  @media all and (min-width: 1921px) {
    ._2n1yDTljq0dNYjFY_5CHCg {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      padding: 0 5rem; } }
  ._2n1yDTljq0dNYjFY_5CHCg._2aKI5ACkvF47CSLOx5kZQN {
    padding: 0; }
    ._2n1yDTljq0dNYjFY_5CHCg._2aKI5ACkvF47CSLOx5kZQN ._2PvEt6gOn0P54V79dPEY-B {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-flow: row-reverse;
              flex-flow: row-reverse; }
      @media (max-width: 991px) {
        ._2n1yDTljq0dNYjFY_5CHCg._2aKI5ACkvF47CSLOx5kZQN ._2PvEt6gOn0P54V79dPEY-B {
          -webkit-box-orient: vertical;
          -webkit-box-direction: reverse;
              -ms-flex-flow: column-reverse;
                  flex-flow: column-reverse; } }
      ._2n1yDTljq0dNYjFY_5CHCg._2aKI5ACkvF47CSLOx5kZQN ._2PvEt6gOn0P54V79dPEY-B .EaYyTGd1gqsneo1TZP-S_ {
        padding: 0 0 0 4rem; }
        @media (max-width: 991px) {
          ._2n1yDTljq0dNYjFY_5CHCg._2aKI5ACkvF47CSLOx5kZQN ._2PvEt6gOn0P54V79dPEY-B .EaYyTGd1gqsneo1TZP-S_ {
            padding: 0; } }
      ._2n1yDTljq0dNYjFY_5CHCg._2aKI5ACkvF47CSLOx5kZQN ._2PvEt6gOn0P54V79dPEY-B ._3qWGG9PHAzj5RDnFWg6-ji ._3niTv73uzsff9skp9IjRZC {
        border-radius: 0px 12px 12px 0px; }
    @media (max-width: 991px) {
      ._2n1yDTljq0dNYjFY_5CHCg._2aKI5ACkvF47CSLOx5kZQN {
        padding: 3rem 1.25rem; } }

._2PvEt6gOn0P54V79dPEY-B {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-flow: column-reverse;
          flex-flow: column-reverse;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 992px) {
    ._2PvEt6gOn0P54V79dPEY-B {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row; } }
  @media all and (min-width: 1921px) {
    ._2PvEt6gOn0P54V79dPEY-B {
      width: unset; } }

.EaYyTGd1gqsneo1TZP-S_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin: 0 auto;
  padding: 0;
  max-width: 100%; }
  @media all and (min-width: 992px) {
    .EaYyTGd1gqsneo1TZP-S_ {
      max-width: 520px;
      padding: 0 4rem 0 0; } }
  @media all and (min-width: 1495px) {
    .EaYyTGd1gqsneo1TZP-S_ {
      max-width: 608px; } }
  @media all and (min-width: 1921px) {
    .EaYyTGd1gqsneo1TZP-S_ {
      padding: 0 6rem 0 0; } }

._3qWGG9PHAzj5RDnFWg6-ji {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._3qWGG9PHAzj5RDnFWg6-ji ._3niTv73uzsff9skp9IjRZC {
    display: inline-block;
    width: 100%;
    border-radius: 12px 0px 0px 12px;
    margin-bottom: 2rem; }
    @media all and (min-width: 992px) {
      ._3qWGG9PHAzj5RDnFWg6-ji ._3niTv73uzsff9skp9IjRZC {
        margin: 0; } }
    @media all and (min-width: 1921px) {
      ._3qWGG9PHAzj5RDnFWg6-ji ._3niTv73uzsff9skp9IjRZC {
        width: 680px; } }

._3AzWyZfBwQA7j_DC1T8AEg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2rem; }
  ._3AzWyZfBwQA7j_DC1T8AEg .BdgH2pQtTrBf0h95mjF9j {
    width: 40px; }
  ._3AzWyZfBwQA7j_DC1T8AEg .m2xcxX1-nc5SB0HUWzyXA {
    margin: 0 0 0 1rem;
    font-size: 1.4rem;
    line-height: 1.5; }

._3xSHdzyzVz_eSeSX3H5X0Q {
  margin-bottom: 2rem; }
  ._3xSHdzyzVz_eSeSX3H5X0Q:last-child {
    margin: 0; }
  ._3xSHdzyzVz_eSeSX3H5X0Q ._39LrxYsCxZRgnFz92zHlBv {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-bottom: 0.5rem; }
  ._3xSHdzyzVz_eSeSX3H5X0Q ._2fpgo2po0U00SuGeDbcR9W {
    margin: 0; }
