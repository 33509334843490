.vi_OWeJRkZcQGOM8_XA3S {
  padding: 2rem;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  text-align: center; }

._13Nv0tGlNIKSDQLVorn3H {
  color: #051c26;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem; }

._3oZ3l2U745VBqupT4fFC5O {
  color: #657881;
  line-height: 1.5rem;
  margin: 0.5rem; }
  ._3oZ3l2U745VBqupT4fFC5O._1Lnd2RH_7LKZq5QeMua8z0 {
    display: block;
    color: #ce2a29;
    text-align: right; }
