.RtfrUQ8GWUSWGbfPfmPi4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 991px) {
    .RtfrUQ8GWUSWGbfPfmPi4 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
  .RtfrUQ8GWUSWGbfPfmPi4 p {
    font-size: 1.125rem;
    line-height: 2; }
  @media (max-width: 991px) {
    .RtfrUQ8GWUSWGbfPfmPi4 ._38SvTtC9LRPO02a6lRK48W {
      font-size: 1.75rem;
      line-height: 1.8;
      text-align: center; } }
  .RtfrUQ8GWUSWGbfPfmPi4 i {
    font-weight: 600; }
  .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt {
    width: 100%;
    padding: 10rem 5rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (max-width: 991px) {
      .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt {
        width: 100%;
        padding: 5rem 2rem; } }
    .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt ._31cSsiycy3XtV3bUtQREs- {
      z-index: 2;
      margin-left: 4.8rem; }
      @media (max-width: 991px) {
        .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt ._31cSsiycy3XtV3bUtQREs- {
          margin-left: 0; } }
      .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt ._31cSsiycy3XtV3bUtQREs- h5,
      .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt ._31cSsiycy3XtV3bUtQREs- ._2gvx4LE0yCpQ6lJCgx1k1I {
        font-size: 1.5rem; }
        @media (max-width: 991px) {
          .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt ._31cSsiycy3XtV3bUtQREs- h5,
          .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt ._31cSsiycy3XtV3bUtQREs- ._2gvx4LE0yCpQ6lJCgx1k1I {
            padding-top: 0;
            font-size: 1.2rem; } }
      .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt ._31cSsiycy3XtV3bUtQREs- ._3J6KWDi3rit3_UwRBWnRlY {
        padding-top: 2rem; }
    .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt._2r1By-PT2epapneVIlgTz7 {
      background-color: #ffd15c;
      margin: 0 auto; }
      @media (max-width: 991px) {
        .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt._2r1By-PT2epapneVIlgTz7 ._3fbDfwp9ef-xAax_-RT_GA {
          position: relative;
          margin: 0 auto;
          right: unset;
          max-width: 210px; } }
    .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt._3P47DWLZrp2qjC8VSoQcbb {
      background-color: #dd512a; }
      .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt._3P47DWLZrp2qjC8VSoQcbb ._3fbDfwp9ef-xAax_-RT_GA {
        position: absolute;
        margin-top: -1.563rem;
        width: 100%;
        max-width: 450px;
        right: 0; }
        @media (max-width: 991px) {
          .RtfrUQ8GWUSWGbfPfmPi4 ._3sPkwt6rysbXasSKjJNBpt._3P47DWLZrp2qjC8VSoQcbb ._3fbDfwp9ef-xAax_-RT_GA {
            position: relative;
            margin: 0 auto;
            right: unset;
            max-width: 310px; } }
