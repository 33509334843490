._3o9BRRpCC7z_bK1xG31Hnb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0 3.125rem;
  max-width: 1400px;
  margin-top: 10rem;
  margin-left: auto;
  margin-right: auto; }
  ._2l6_9RtrMK0tATZztIdBeX > * {
    margin-top: 1.25rem; }
    ._2l6_9RtrMK0tATZztIdBeX > *:first-child {
      margin-top: 1rem; }
  .Bj7wou1rQbj75HWDhNL7l {
    display: block;
    max-width: 700px; }
  ._1OuGmJm0_7vgkBYUQVSysf {
    display: block;
    width: 100%;
    position: relative;
    padding-bottom: 52%;
    margin: 0; }
    ._2W5jzfZlV6GH16dfHvZ-U- {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      border: none; }
  ._11w5P5ykxYCfaEY7q3DBlx {
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto; }
  ._3XqS96A5EMEGV4uOvLPZ_w {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%; }
  ._3-qk6tbeN1OFLZGLwLWD-i, ._2dlvFx5pnZ3Byu8WrImtcw, ._2ES25tU8j_MXEZ8GwnhVrJ {
    display: block;
    margin: 0;
    padding: 0;
    margin-top: 2.5rem; }
    ._3-qk6tbeN1OFLZGLwLWD-i:first-child, ._2dlvFx5pnZ3Byu8WrImtcw:first-child, ._2ES25tU8j_MXEZ8GwnhVrJ:first-child {
      margin-top: 0; }
    @media all and (max-width: 1000px) {
      ._3-qk6tbeN1OFLZGLwLWD-i, ._2dlvFx5pnZ3Byu8WrImtcw, ._2ES25tU8j_MXEZ8GwnhVrJ {
        text-align: center; } }
  ._3-qk6tbeN1OFLZGLwLWD-i {
    color: #fff; }
  ._2dlvFx5pnZ3Byu8WrImtcw, .CMy-sdB6KUcxeZIIyT5P7 {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #a9b2be; }
  ._2dlvFx5pnZ3Byu8WrImtcw > span:first-child {
    display: block;
    margin-bottom: 1rem; }
  .CMy-sdB6KUcxeZIIyT5P7:not(:last-child) {
    margin-bottom: 2rem; }
  ._3gy1dOpQ2CyrUjNsyFx9vP {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-left: 6.25rem; }
    ._3gy1dOpQ2CyrUjNsyFx9vP:first-child {
      margin-left: 0; }
      @media all and (max-width: 1000px) {
        ._3gy1dOpQ2CyrUjNsyFx9vP:first-child {
          margin-top: 0; } }
    ._3FdqBrWBwesm9KAzYHFGjX {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      width: 40%;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
    @media all and (max-width: 1000px) {
      ._3gy1dOpQ2CyrUjNsyFx9vP {
        margin-left: 0;
        margin-top: 1.875rem;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }
    @media all and (max-width: 1000px) {
      ._3gy1dOpQ2CyrUjNsyFx9vP {
        width: 40%; } }
    @media all and (max-width: 800px) {
      ._3gy1dOpQ2CyrUjNsyFx9vP {
        width: 60%; } }
    @media all and (max-width: 600px) {
      ._3gy1dOpQ2CyrUjNsyFx9vP {
        width: 80%; } }
    @media all and (max-width: 450px) {
      ._3gy1dOpQ2CyrUjNsyFx9vP {
        width: 90%; } }
  ._3UdO-dnSDtV1yCOScJhpqI {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
    ._3UdO-dnSDtV1yCOScJhpqI ._3gy1dOpQ2CyrUjNsyFx9vP:first-child {
      margin-left: 6.25rem; }
      @media all and (max-width: 1000px) {
        ._3UdO-dnSDtV1yCOScJhpqI ._3gy1dOpQ2CyrUjNsyFx9vP:first-child {
          margin-left: 0; } }
    ._3UdO-dnSDtV1yCOScJhpqI ._3gy1dOpQ2CyrUjNsyFx9vP:last-child {
      margin-left: 0; }
    @media all and (max-width: 1000px) {
      ._3UdO-dnSDtV1yCOScJhpqI {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .sMIXgkPoXEfXm4OTPCEVE {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .sMIXgkPoXEfXm4OTPCEVE ._3gy1dOpQ2CyrUjNsyFx9vP {
      text-align: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-left: 0; }
      .sMIXgkPoXEfXm4OTPCEVE ._3gy1dOpQ2CyrUjNsyFx9vP:first-child {
        display: none; }
    .sMIXgkPoXEfXm4OTPCEVE .CMy-sdB6KUcxeZIIyT5P7 {
      list-style: none; }
  @media all and (max-width: 1000px) {
    ._3o9BRRpCC7z_bK1xG31Hnb {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media (max-width: 991px) {
    ._3o9BRRpCC7z_bK1xG31Hnb {
      margin-top: 5rem; }
      ._3-qk6tbeN1OFLZGLwLWD-i {
        font-size: 1.75rem;
        line-height: 1.8; } }
