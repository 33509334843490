._3n-K8kMS2aCKjCK-kfJ6iz {
  font-size: 1rem;
  color: #858585;
  line-height: 1.5rem;
  margin: 1rem 0;
  display: block; }

._3t4JqV0F6f3BPoiubVUxCJ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 1rem 0; }

._2MbVubuMWK2MAmdMLvpel8 {
  width: 530px;
  height: 298.13px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MjggMjQwLjAwMSI+PHBhdGggZD0iTTQyOCAyNDBIMzAyLjIxNGMtMTcuNzg1LTQxLjEyMi01MS40LTY2LjY2Ny04Ny43MTQtNjYuNjY3UzE0NC41NzEgMTk4Ljg3OSAxMjYuNzg2IDI0MEgwVjBoNDI4djI0MHpNMjEzLjkwOSA0M2MtMzQuMjI4IDAtNjIuMDc0IDI2LjkyNS02Mi4wNzQgNjAuMDIxczI3Ljg0NiA2MC4wMjMgNjIuMDc0IDYwLjAyMyA2Mi4wNzMtMjYuOTI2IDYyLjA3My02MC4wMjNTMjQ4LjEzNiA0MyAyMTMuOTA5IDQzeiIgZmlsbD0iI2U2ZWFmMiIvPjwvc3ZnPg==);
  background-size: cover;
  margin: 0 1rem 0 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  position: relative; }
  ._2MbVubuMWK2MAmdMLvpel8 .IEC99nHUUC3udbn8y35sU {
    -webkit-filter: 'none';
            filter: 'none';
    position: absolute;
    margin: auto; }
    ._2MbVubuMWK2MAmdMLvpel8 .IEC99nHUUC3udbn8y35sU._2DOIy1msekzHi8XUEAZBms {
      display: none; }
  ._2MbVubuMWK2MAmdMLvpel8 ._3n-K8kMS2aCKjCK-kfJ6iz {
    position: absolute;
    bottom: 0;
    background: rgba(5, 5, 5, 0.4);
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    font-size: .8rem; }
    ._2MbVubuMWK2MAmdMLvpel8 ._3n-K8kMS2aCKjCK-kfJ6iz p {
      color: #fff;
      margin: 0;
      padding: 0.75rem 0; }
    ._2MbVubuMWK2MAmdMLvpel8 ._3n-K8kMS2aCKjCK-kfJ6iz img {
      margin: auto 1rem auto 1.25rem; }

._1-ySgqsChoodIwGKxROQ7k {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  ._1-ySgqsChoodIwGKxROQ7k ._1xYuySrdmuQBpcaEOWift8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 0.5rem; }
    ._1-ySgqsChoodIwGKxROQ7k ._1xYuySrdmuQBpcaEOWift8.X9NPo-JANyCbJxcXo64Nd {
      margin-bottom: 1rem; }
  ._1-ySgqsChoodIwGKxROQ7k ._2BUE_pmZNEARWLpZnlkUlW {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    margin-right: 1rem; }
  ._1-ySgqsChoodIwGKxROQ7k ._3Biu9tn5iQ0VG8IHdy_Vb7 {
    margin-right: 10px;
    color: #051c26;
    font-weight: 600; }
  ._1-ySgqsChoodIwGKxROQ7k ._3xEGjwr0DxLcbGttq7UFb- {
    display: block;
    width: 60px;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border: none;
    color: #767676;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 10px;
    margin-bottom: 5px;
    outline: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

.LneP4CT3MFt3bL0enZcZr ._3xEGjwr0DxLcbGttq7UFb-:invalid {
  border: 1px solid #ce2a29; }

.IZ_9UyIF89xgavMtlNq-o ._3Biu9tn5iQ0VG8IHdy_Vb7 {
  margin-right: 5px; }

.IZ_9UyIF89xgavMtlNq-o ._2BUE_pmZNEARWLpZnlkUlW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._1AdcdELX3k9lIH4qmi99N9 {
  -ms-flex-item-align: end;
      align-self: flex-end; }

._2oLw5k_SXlyO45MohUUTce {
  font-size: 0.5rem;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #c0cbd2;
  color: #fff;
  line-height: 15px;
  padding: 0px 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: help; }
