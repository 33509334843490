._1B-l14lh470TaB8Pmz9CQk {
  margin: 50px; }

._2GPI4OfNfVpAAsaEe0qEYG {
  margin: 20px; }
  ._2K7OmroG2qZoZxwyXTsU8f, ._2e3ODuYeKsKJLZ_gpoCLt2 {
    font-size: 2rem;
    color: #fff;
    text-align: center;
    margin-bottom: 20px; }

._15YRSeAIao0eT1-oom77zV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._3WALfL03sgDSjKy_IqlJOY {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin: 10px 50px; }
    ._3WALfL03sgDSjKy_IqlJOY ._3-5kFuMG098uYUtzsCTxrg {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      margin: 20px auto;
      max-width: 1024px; }
      ._3WALfL03sgDSjKy_IqlJOY ._3-5kFuMG098uYUtzsCTxrg ._1t6enRJtrV9Zopj1STav7I {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 10px;
        -ms-flex-preferred-size: 50%;
            flex-basis: 50%; }
