._3m_7UTlgaAC9e_l7DZzaOf {
  background: #f5f7fa;
  position: relative;
  min-height: calc(100vh - 80px); }

._1c2pEc2DRvfuehIYbZMO8- {
  color: #030b0d;
  font-size: 2rem;
  margin: 2rem 3rem 1rem; }
  @media (max-width: 991px) {
    ._1c2pEc2DRvfuehIYbZMO8- {
      font-size: 1.75rem;
      line-height: 1.6;
      margin: 1rem 1.25rem; } }

._3ur-JfgZqhJrl8EYzCFFZK {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

._1dg5Ce_TNZbawlwZgHrGbV {
  background: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px;
  padding: 3rem;
  margin: 2rem; }
