.MStt4t-jbrkAa4tT42ohW b {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3nfLtfCI3IZYRYNR2mj3RA {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3nfLtfCI3IZYRYNR2mj3RA {
      font-size: 1.25rem;
      line-height: 1.8; } }

.MStt4t-jbrkAa4tT42ohW {
  padding: 0 2rem 6rem; }
  .MStt4t-jbrkAa4tT42ohW p {
    color: #fff;
    word-break: break-word; }
  .MStt4t-jbrkAa4tT42ohW b {
    font-size: 1.125rem; }

.zijGN5HbvyG0uwLsDqEYM {
  max-width: 1400px;
  margin: 0 auto; }

._3m9lhjmEQqM1FRMsnYJXng {
  display: grid;
  grid-gap: 3rem;
  gap: 3rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media all and (max-width: 1180px) {
    ._3m9lhjmEQqM1FRMsnYJXng {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      gap: 2rem; } }
  @media all and (max-width: 600px) {
    ._3m9lhjmEQqM1FRMsnYJXng {
      grid-template-columns: 1fr; } }

._1xDw2Z0C4SkWheq5RLyl0N {
  background-color: #161819;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 2rem; }

._2DlnElpU7CsxCct4ML-tUO {
  max-height: 100%;
  width: auto; }

._3nfLtfCI3IZYRYNR2mj3RA {
  margin: 0; }
