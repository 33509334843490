._26UHKftZpfGgXLRnHtiMKM {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 1024px;
  overflow: auto;
  max-height: 80vh; }

.D2GpByC5jKTkpgiDz3rGk {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._3R0iImQQ5EaWG4OzbJ9POY {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

._2YP07UAqm4QRvz-wtTvuaM {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 50px; }
  ._2YP07UAqm4QRvz-wtTvuaM ._1SaIlrzyf3xuef6cYk22kj ._3bwm43FQ3f5j7h1BYzhnIv:first-child {
    margin-left: 0; }
  ._2YP07UAqm4QRvz-wtTvuaM ._1SaIlrzyf3xuef6cYk22kj ._3bwm43FQ3f5j7h1BYzhnIv:last-child {
    margin-right: 0; }
  ._2YP07UAqm4QRvz-wtTvuaM ._3bwm43FQ3f5j7h1BYzhnIv {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer; }
    ._2YP07UAqm4QRvz-wtTvuaM ._3bwm43FQ3f5j7h1BYzhnIv:hover {
      border: 1px solid silver; }
    ._2YP07UAqm4QRvz-wtTvuaM ._3bwm43FQ3f5j7h1BYzhnIv[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  ._2YP07UAqm4QRvz-wtTvuaM ._1b08bIXd46regfMeTdIo_6 {
    font-weight: 600;
    color: #328ebd;
    background-color: #318dbc1c;
    border: 1px solid #328ebd; }
    ._2YP07UAqm4QRvz-wtTvuaM ._1b08bIXd46regfMeTdIo_6._3itId0MJSO34KD5ib6DnCX {
      background: #fa18541c 0% 0% no-repeat padding-box;
      border: 1px solid #fa1854;
      color: #fa1854; }
  ._2YP07UAqm4QRvz-wtTvuaM ._1JtG24aVTr4bgp2bU49SVH {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._2YP07UAqm4QRvz-wtTvuaM ._1JtG24aVTr4bgp2bU49SVH:hover {
      background: #34a9e9;
      color: #fff; }

._3P7JE9SNH2Cg0k8765l5UT {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._2nwTesm-SvKL6K9hWC6vQm {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

.enMpPSxsMMzE6qCXxX197 {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 10px 0; }

._1GSLBEJaqnnUB8Jyo8K0Cm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-right: 1.5rem; }
  ._1GSLBEJaqnnUB8Jyo8K0Cm ._2u2IgiIYGBYJ_R21rTxKyT {
    font-size: 14px;
    font-weight: 600;
    color: #051c26;
    margin-bottom: 10px; }

._1rF-95kCW1WpU5SOg7JZgo {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }
  ._1rF-95kCW1WpU5SOg7JZgo ._2u2IgiIYGBYJ_R21rTxKyT {
    font-size: 16px;
    font-weight: 700;
    color: #2c2c2c;
    margin-right: 10px; }

.VatdXgbs_cT9b3hqC20Er {
  color: #328ebd;
  padding: 5px;
  background-color: #318dbc1c;
  border: 1px solid #328ebd;
  border-radius: 3px;
  cursor: pointer; }
  .VatdXgbs_cT9b3hqC20Er:hover {
    background-color: rgba(28, 80, 107, 0.109804); }

._1xVQ14mF8I9A19Nk5Bd1a_ {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem; }

._2aQ8BfuxX--Dst7UkQNOOJ {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: auto;
  border-collapse: collapse; }
  ._2aQ8BfuxX--Dst7UkQNOOJ ._1qc4d3IpCEYsf7X0C0j_Rs {
    font-size: 14px;
    color: #657881;
    border-bottom: 1px solid #758793;
    padding: 10px 20px;
    line-height: 2em; }
  ._2aQ8BfuxX--Dst7UkQNOOJ ._3vbej0ERBKUjOgHFMK_tiE {
    color: #2c2c2c;
    font-weight: 700;
    padding-left: 40px;
    position: relative; }
    @media all and (min-width: 1100px) {
      ._2aQ8BfuxX--Dst7UkQNOOJ ._3vbej0ERBKUjOgHFMK_tiE:before {
        content: '';
        background: #1791d3 0% 0% no-repeat padding-box;
        border-radius: 4px;
        width: 15px;
        height: 15px;
        display: inline-block;
        left: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        position: absolute; }
      ._2aQ8BfuxX--Dst7UkQNOOJ ._2EHYWE0L3-Xs7BJpNkR4pW:before {
        background-color: #00a69c; }
      ._2aQ8BfuxX--Dst7UkQNOOJ ._13vEx90qUnYlLFkFGn6ODG:before {
        background-color: #f5371b; }
      ._2aQ8BfuxX--Dst7UkQNOOJ ._13duKjhBAaGEDR9bjsSMDb:before {
        background-color: #fbb703; } }
  ._2aQ8BfuxX--Dst7UkQNOOJ ._1D6j39z7-H1T-Nl_5hkeIk {
    padding: 10px;
    background: #e6eaf2;
    border: none;
    max-width: 50px; }
    ._2aQ8BfuxX--Dst7UkQNOOJ ._1D6j39z7-H1T-Nl_5hkeIk:invalid {
      color: #fa1854; }
    ._2aQ8BfuxX--Dst7UkQNOOJ ._1D6j39z7-H1T-Nl_5hkeIk[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }

.lUYKXFzPz7FsON4wyfgJb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

._50mf4m3rZIrOpn0xMhTGp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  position: relative; }
  ._50mf4m3rZIrOpn0xMhTGp ._3XBnYH3yrM5heH0-lY0pfo {
    background-color: #e6eaf2;
    border: none;
    padding: 10px;
    border-radius: 3px;
    font-size: 14px;
    color: #5c6066; }
  ._50mf4m3rZIrOpn0xMhTGp ._33lY38ynwoOGCQ4JodvZDn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    right: 10px;
    height: 100%; }
    ._50mf4m3rZIrOpn0xMhTGp ._33lY38ynwoOGCQ4JodvZDn:after {
      content: ' ';
      margin-left: 5px; }
    ._50mf4m3rZIrOpn0xMhTGp ._33lY38ynwoOGCQ4JodvZDn._2BFy4ryIugwWbuvCpIFa:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #2f2f2f; }
    ._50mf4m3rZIrOpn0xMhTGp ._33lY38ynwoOGCQ4JodvZDn.JxhL8ECMM_qsby1b4mbbC:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #2f2f2f; }

._2cyl8pnIyt2weWQuf0Xc6d {
  display: none;
  position: absolute;
  left: 0;
  top: 35px;
  width: 100%;
  background: #fff;
  border: 1px solid #f4f5f8;
  border-radius: 3px;
  z-index: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  ._2cyl8pnIyt2weWQuf0Xc6d._2BFy4ryIugwWbuvCpIFa {
    display: block; }
  ._2cyl8pnIyt2weWQuf0Xc6d ._2uoDPQ7LMawzGU3BxVVUiA {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 150px;
    overflow: auto; }
  ._2cyl8pnIyt2weWQuf0Xc6d ._1qc4d3IpCEYsf7X0C0j_Rs {
    font-size: 14px;
    color: #5c6066;
    padding: 10px 18px;
    cursor: pointer; }
    ._2cyl8pnIyt2weWQuf0Xc6d ._1qc4d3IpCEYsf7X0C0j_Rs:hover {
      color: #1167f3; }
