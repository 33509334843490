._16tcCfU92GuylLCr9HSEju ._1v6i1hcjTlSCtCM_zJhMyj {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._16tcCfU92GuylLCr9HSEju ._1v6i1hcjTlSCtCM_zJhMyj {
      font-size: 2.5rem;
      line-height: 1.7; } }

._16tcCfU92GuylLCr9HSEju ._1zGiXiScQrHnrzZmkvBZya ._3RYJZkkkTIrXC-AUrWvabu {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._16tcCfU92GuylLCr9HSEju ._1zGiXiScQrHnrzZmkvBZya ._3RYJZkkkTIrXC-AUrWvabu {
      font-size: 1.25rem;
      line-height: 1.8; } }

._3xzJTrMon3rwbBVIri49El {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin: 0;
  padding: 5rem;
  background-color: #161819; }
  @media (max-width: 991px) {
    ._3xzJTrMon3rwbBVIri49El {
      padding: 3rem 1.25rem; } }

._16tcCfU92GuylLCr9HSEju {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  max-width: 1400px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._16tcCfU92GuylLCr9HSEju ._1ImScK2hXeyMbhoN7iskxu,
  ._16tcCfU92GuylLCr9HSEju ._1v6i1hcjTlSCtCM_zJhMyj,
  ._16tcCfU92GuylLCr9HSEju ._360sPw3T-fOVybfNsaUuwa {
    color: #fff; }
  ._16tcCfU92GuylLCr9HSEju ._1ImScK2hXeyMbhoN7iskxu {
    height: 90px;
    margin-bottom: 2rem; }
  @media (max-width: 991px) {
    ._16tcCfU92GuylLCr9HSEju ._1v6i1hcjTlSCtCM_zJhMyj {
      text-align: center; } }
  ._16tcCfU92GuylLCr9HSEju ._360sPw3T-fOVybfNsaUuwa {
    font-size: 1.375rem;
    line-height: 1.5; }
  ._16tcCfU92GuylLCr9HSEju ._1zGiXiScQrHnrzZmkvBZya {
    margin-bottom: 0; }
    ._16tcCfU92GuylLCr9HSEju ._1zGiXiScQrHnrzZmkvBZya ._3RYJZkkkTIrXC-AUrWvabu {
      color: #fff; }
