._2zBYu5q1BWwu7XOT6vV21R {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 7.5rem 0;
  background: #030b0d;
  margin-top: -0.063rem; }
  ._3j1rYNWE6kqEMxrfN_6reF {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0; }
    ._1Q2MN4-11jibOm6kDnNHdu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-top: 2.688rem;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      ._1gTeMgkDVcAtK4VhvE5wyp {
        display: block;
        font-size: 1.5rem;
        font-weight: 500;
        color: #f9f9f9;
        margin-bottom: 0.938rem; }
      ._1Q2MN4-11jibOm6kDnNHdu:first-child {
        margin-top: 0; }
      @media all and (max-width: 790px) {
        ._1Q2MN4-11jibOm6kDnNHdu {
          margin-top: 1.25rem; } }
  ._2zBYu5q1BWwu7XOT6vV21R ._1Voz-kJp3YP6hAyetTc4Gs p {
    margin: 0;
    padding: 0; }
  ._1Q2MN4-11jibOm6kDnNHdu,
  ._2zBYu5q1BWwu7XOT6vV21R ._1Voz-kJp3YP6hAyetTc4Gs p {
    font-size: 1.25rem;
    line-height: 1.8;
    color: #b3b3b3; }
    @media all and (max-width: 1200px) {
      ._1Q2MN4-11jibOm6kDnNHdu,
      ._2zBYu5q1BWwu7XOT6vV21R ._1Voz-kJp3YP6hAyetTc4Gs p {
        text-align: center; } }
    @media all and (max-width: 790px) {
      ._1Q2MN4-11jibOm6kDnNHdu,
      ._2zBYu5q1BWwu7XOT6vV21R ._1Voz-kJp3YP6hAyetTc4Gs p {
        font-size: 1.125rem;
        line-height: 1.6; } }
  ._10sVNAZkSpmNKRDlidOcTH {
    list-style-type: disc;
    margin: -1.875rem 0;
    padding-left: 1.375rem; }
    ._1e4ZFZ71R3-edDSjAHVTSA {
      font-size: 1.25rem;
      font-weight: bold;
      color: #fafafa;
      line-height: 1.8; }
      @media all and (max-width: 1200px) {
        ._1e4ZFZ71R3-edDSjAHVTSA {
          text-align: left; } }
      @media all and (max-width: 790px) {
        ._1e4ZFZ71R3-edDSjAHVTSA {
          line-height: 26px; } }
    @media all and (max-width: 1200px) {
      ._10sVNAZkSpmNKRDlidOcTH {
        margin: auto; } }
  ._3j1rYNWE6kqEMxrfN_6reF,
  ._2zBYu5q1BWwu7XOT6vV21R ._1Voz-kJp3YP6hAyetTc4Gs p {
    margin-top: 1.875rem; }
  ._3j1rYNWE6kqEMxrfN_6reF:first-child {
    margin-top: 0; }
  ._309SfzrnLEd91Szc9hAZkS {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1400px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 0 1.25rem; }
    @media all and (max-width: 1200px) {
      ._309SfzrnLEd91Szc9hAZkS {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
  ._39vrzwvqNAJxnLrlIc1bWH {
    display: block;
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 1.7;
    font-weight: bold;
    margin-bottom: 1.875;
    color: #f9f9f9;
    font-family: 'Poppins', sans-serif; }
    @media all and (max-width: 1200px) {
      ._39vrzwvqNAJxnLrlIc1bWH {
        text-align: center; } }
    @media all and (max-width: 790px) {
      ._39vrzwvqNAJxnLrlIc1bWH {
        font-size: 1.75rem;
        line-height: 1.8;
        margin-bottom: 0; } }
  ._3iBi5lYqLMNcn3FOPmB39G, ._1Voz-kJp3YP6hAyetTc4Gs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  ._3iBi5lYqLMNcn3FOPmB39G {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    padding-right: 8.75rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    ._3_paLWwDqZqXqwlrGUiRhF {
      display: inline-block;
      width: auto;
      max-width: 100%;
      height: auto; }
    @media all and (max-width: 1200px) {
      ._3iBi5lYqLMNcn3FOPmB39G {
        display: block;
        padding-left: 15%;
        padding-right: 15%;
        padding-bottom: 2.5rem;
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        text-align: center; } }
  ._1Voz-kJp3YP6hAyetTc4Gs {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 550px; }
    @media all and (max-width: 1200px) {
      ._1Voz-kJp3YP6hAyetTc4Gs {
        width: auto; } }
  ._3v92qBLzIIVVdBAgcF5-rM {
    background: #fafafa; }
    ._3v92qBLzIIVVdBAgcF5-rM ._1Q2MN4-11jibOm6kDnNHdu,
    ._3v92qBLzIIVVdBAgcF5-rM ._1Voz-kJp3YP6hAyetTc4Gs p,
    ._3v92qBLzIIVVdBAgcF5-rM ._1Voz-kJp3YP6hAyetTc4Gs p > * {
      color: #454545; }
    ._3v92qBLzIIVVdBAgcF5-rM ._1gTeMgkDVcAtK4VhvE5wyp {
      color: #16191c; }
    ._3v92qBLzIIVVdBAgcF5-rM ._39vrzwvqNAJxnLrlIc1bWH {
      color: #16191c; }
  ._3zo9IF2HC7FZiiESkwcv0K {
    background: #0f191e; }
  .O9VhoXkUWxT8jTj0MrZjM ._309SfzrnLEd91Szc9hAZkS {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
    @media all and (max-width: 1200px) {
      .O9VhoXkUWxT8jTj0MrZjM ._309SfzrnLEd91Szc9hAZkS {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .O9VhoXkUWxT8jTj0MrZjM ._3iBi5lYqLMNcn3FOPmB39G {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-right: 0;
    padding-left: 8.75rem; }
    @media all and (max-width: 1200px) {
      .O9VhoXkUWxT8jTj0MrZjM ._3iBi5lYqLMNcn3FOPmB39G {
        padding-left: 15%;
        padding-right: 15%; } }
  @media all and (max-width: 1200px) {
    ._2zBYu5q1BWwu7XOT6vV21R {
      padding: 3.125rem 0; } }
