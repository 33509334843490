._2uOfWfi_osJKroZ3_zeq-T {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._3R-b4uBs3SAfc8jQza0dL9 {
  font-size: 14px;
  color: #758793; }

._2mL5afEAnvU7qsIXq48ls5 {
  margin: 0; }

._3w05bJT1naXKuEB4Qj5qs0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px; }

.J3p1jvp6otCPzL6pbOpqd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  .J3p1jvp6otCPzL6pbOpqd ._3kUqCIq8_bOwsGvjOOOrmA {
    font-size: 40px;
    color: #1167f3; }

._2lQAalnAs1--LTOjaR3_eb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._2lQAalnAs1--LTOjaR3_eb ._2dkRWGpi8d8O9-iHPlRcGN {
    font-size: 16px;
    color: #ffffff;
    border: none;
    padding: 10px;
    background-color: #1791d3;
    border-radius: 3px;
    cursor: pointer; }
    ._2lQAalnAs1--LTOjaR3_eb ._2dkRWGpi8d8O9-iHPlRcGN:hover {
      background-color: #34a9e9; }

.mPhBIW5YndGIDP6e1tGjq {
  color: #328ebd;
  padding: 5px;
  background-color: #318dbc1c;
  border: 1px solid #328ebd;
  border-radius: 3px;
  cursor: pointer; }
  .mPhBIW5YndGIDP6e1tGjq:hover {
    background-color: rgba(28, 80, 107, 0.109804); }
