._3pyWdUmhF1_yNqdE8_URw5 {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  overflow: auto; }

.JbYrSTK1Kh6PLRg1xdHj- {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.24px;
  color: #051c26; }

.ZaQpg_FDPpUa_blWrdHDC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px; }

._2Piuiqc-A689Mg_ScmYwfI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media all and (max-width: 660px) {
    ._2Piuiqc-A689Mg_ScmYwfI {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }

._2hLmr96BV_94zl20QaXULT {
  font-size: 14px;
  color: #5c6066;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: block;
  margin: 0 10px 5px 0; }
  ._2hLmr96BV_94zl20QaXULT input {
    display: none; }
  ._2hLmr96BV_94zl20QaXULT span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    ._2hLmr96BV_94zl20QaXULT span:after {
      content: ' ';
      width: 0px;
      height: 0px;
      margin-left: 5px; }
  ._2hLmr96BV_94zl20QaXULT input:checked + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  ._2hLmr96BV_94zl20QaXULT input:not(:checked) + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }

.B6mmQd-LK8nRGAE9Vgczu {
  background: #f6f9fb 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 5px; }
  .B6mmQd-LK8nRGAE9Vgczu input {
    border: none;
    background: transparent;
    margin-left: 5px; }

._1goVqNV8JttBKf6lZr_toQ {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem; }

._1NUQ_GvKLQy-_KyeBNWIrY {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }

.l5reLoE9kGrbe7fnCiyen {
  color: #657881;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: block; }

.arzlJfuKLA-uEdkUxOyHC {
  font-size: 14px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  color: #fa1854;
  background: #fa18541c 0% 0% no-repeat padding-box;
  border: 1px solid #fa1854; }
  .arzlJfuKLA-uEdkUxOyHC:hover {
    background-color: rgba(252, 124, 158, 0.109804); }
  .arzlJfuKLA-uEdkUxOyHC[disabled] {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: not-allowed; }
