.b0MZw7dy0jSoaxI1HQOXq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin: auto; }

._3qnHLS_4Viqr-qZIJlJN31 {
  margin-bottom: 1.5rem; }
  @media (max-width: 991px) {
    ._3qnHLS_4Viqr-qZIJlJN31 {
      font-size: 1.75rem;
      line-height: 1.8; } }

._2DY9LdpGYtzEkaiNHtCfGH {
  line-height: 1.8;
  font-size: 1.125rem; }

._2F_cSTfYGqkS1nRKp7pULg {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._2F_cSTfYGqkS1nRKp7pULg ._2COnGgiIRiVQU0L4p3O87S {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column wrap;
            flex-flow: column wrap;
    margin-bottom: 1.5rem; }
    @media (max-width: 991px) {
      ._2F_cSTfYGqkS1nRKp7pULg ._2COnGgiIRiVQU0L4p3O87S {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column;
        -webkit-box-align: stretch;
            -ms-flex-align: stretch;
                align-items: stretch; } }
    ._2F_cSTfYGqkS1nRKp7pULg ._2COnGgiIRiVQU0L4p3O87S ._1mi9JR0WX2x8N2IHAid66x {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      padding-top: 0.5rem; }
    ._2F_cSTfYGqkS1nRKp7pULg ._2COnGgiIRiVQU0L4p3O87S > ._3F2GijOSUQcEri59FmL_tC,
    ._2F_cSTfYGqkS1nRKp7pULg ._2COnGgiIRiVQU0L4p3O87S ._2dWHBj4PQ8kbIMxSfLW5kj {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; }
    ._2F_cSTfYGqkS1nRKp7pULg ._2COnGgiIRiVQU0L4p3O87S ._3F2GijOSUQcEri59FmL_tC {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
      padding: 0.5rem;
      border: 1px solid #c7ccda;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-radius: 3px;
      margin-bottom: 0.5rem;
      width: 100%; }
      ._2F_cSTfYGqkS1nRKp7pULg ._2COnGgiIRiVQU0L4p3O87S ._3F2GijOSUQcEri59FmL_tC:focus {
        -webkit-box-shadow: -3px 5px 11px 1px rgba(229, 233, 241, 0.42);
                box-shadow: -3px 5px 11px 1px rgba(229, 233, 241, 0.42);
        background-color: #fdfdfd;
        outline: none; }
  ._2F_cSTfYGqkS1nRKp7pULg ._2GQUpyQ-JhkYOdtCtBs1gQ {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
    ._2F_cSTfYGqkS1nRKp7pULg ._2GQUpyQ-JhkYOdtCtBs1gQ ._3F2GijOSUQcEri59FmL_tC {
      padding-right: 35px; }
    ._2F_cSTfYGqkS1nRKp7pULg ._2GQUpyQ-JhkYOdtCtBs1gQ ._1qmPcADsPOY3hAri434zr5 {
      color: #5c6066;
      background-color: #e6eaf2; }
      ._2F_cSTfYGqkS1nRKp7pULg ._2GQUpyQ-JhkYOdtCtBs1gQ ._1qmPcADsPOY3hAri434zr5:focus {
        color: #5c6066;
        background-color: #e6eaf2; }
    ._2F_cSTfYGqkS1nRKp7pULg ._2GQUpyQ-JhkYOdtCtBs1gQ ._2wxGYWZ-8UgMBbjqpSM4rN {
      right: 10px;
      position: absolute;
      font-size: 1rem;
      top: 40%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      cursor: pointer; }
    ._2F_cSTfYGqkS1nRKp7pULg ._2GQUpyQ-JhkYOdtCtBs1gQ ._3ukV8O5W86pPGg2N0oNSli {
      position: absolute;
      bottom: -25px;
      right: 0;
      font-size: 0.8rem;
      padding: 5px 5px 4px;
      color: #fff;
      text-align: center;
      background-color: #000;
      border-radius: 4px;
      opacity: 0; }
      ._2F_cSTfYGqkS1nRKp7pULg ._2GQUpyQ-JhkYOdtCtBs1gQ ._3ukV8O5W86pPGg2N0oNSli:before {
        content: ' ';
        position: absolute;
        bottom: 100%;
        /* At the top of the tooltip */
        left: 60%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent; }
      ._2F_cSTfYGqkS1nRKp7pULg ._2GQUpyQ-JhkYOdtCtBs1gQ ._3ukV8O5W86pPGg2N0oNSli._38syyWCTQsSfJTCpV9u-a1 {
        opacity: 1; }
  ._2F_cSTfYGqkS1nRKp7pULg ._1yVOxaxjjsrePfnww6_ZlW {
    margin-top: 15px;
    line-height: 1.5rem;
    color: #fa5050; }
  ._2F_cSTfYGqkS1nRKp7pULg .WgEUbhIpET-5HzWYn6kAT {
    line-height: 1.5rem;
    color: #9a9c9d; }
    ._2F_cSTfYGqkS1nRKp7pULg .WgEUbhIpET-5HzWYn6kAT ul {
      margin-bottom: 0; }
  ._2F_cSTfYGqkS1nRKp7pULg ._3BqKHnzrF3y4wy98yMjZkS {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-bottom: 20px; }
    ._2F_cSTfYGqkS1nRKp7pULg ._3BqKHnzrF3y4wy98yMjZkS ._3qE1o-_PWxG2XuUzy7frcK {
      margin-left: 10px; }
  ._2F_cSTfYGqkS1nRKp7pULg .rSHF2GC16E6tEiUQ-nB-8 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 5px; }

._1pellepwYB4eRaUbYhC2sB {
  display: block;
  color: #ce2a29; }
  ._1pellepwYB4eRaUbYhC2sB._3ynbkV_w8cvE4pWb9TVaOC {
    text-align: center; }
