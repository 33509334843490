._3IkMkqsnYUKX0wfirUfw_p {
  position: relative;
  width: 100%; }
  ._3IkMkqsnYUKX0wfirUfw_p img {
    width: 1rem; }
  ._3IkMkqsnYUKX0wfirUfw_p select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: none;
    border: 1px solid #b1b4ba;
    padding: .5rem 2rem .5rem .5rem;
    border-radius: 3px; }
    @media (max-width: 991px) {
      ._3IkMkqsnYUKX0wfirUfw_p select[disabled] {
        padding-left: 0; } }
  ._1Fq4bYLe-e_ae1i5FCyFJJ {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #4a5568;
    color: #4a5568; }
