._2Qvn5tLA9yO2mxxuer1hrU {
  font-size: 14px;
  font-weight: 400;
  color: #657881;
  border-bottom: 1px solid #758793;
  padding: 20px 20px;
  line-height: 2em;
  position: relative; }
  ._2Qvn5tLA9yO2mxxuer1hrU._1VjAWyC2flW2EXKWpUcVYu {
    color: #1791d3;
    font-weight: 600; }
  ._2Qvn5tLA9yO2mxxuer1hrU ._3FXpoQz7MfX38rQwc5vKML {
    display: none; }
    ._2Qvn5tLA9yO2mxxuer1hrU ._3FXpoQz7MfX38rQwc5vKML:checked + ._1g__JYmdYoB1tCaFV9VDbR:before {
      position: absolute;
      content: '\2713';
      background: #2392d0;
      color: #fff;
      height: 18px;
      width: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      display: inline-block;
      left: 21px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }

._1VjAWyC2flW2EXKWpUcVYu {
  position: relative; }
  ._1VjAWyC2flW2EXKWpUcVYu label {
    padding-left: 45px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    ._1VjAWyC2flW2EXKWpUcVYu label:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #2392d0;
      border-radius: 3px;
      display: inline-block;
      left: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }

._3EoWnSUhpmWFPe-zRNwscZ {
  background-color: #f3fbff; }

.w7-Pad8usa2WvLxYSDgET {
  display: block;
  color: #fa1854; }
