._18UDLbF009fvrfUrpaoahS {
  color: #141414;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 1100px) {
    ._18UDLbF009fvrfUrpaoahS:before {
      content: '';
      background: #1791d3 0% 0% no-repeat padding-box;
      border-radius: 4px;
      width: 15px;
      height: 15px;
      margin-right: 10px; }
    ._1MEI5paO_5H2fzRzcSdaMe:before {
      background-color: #00a69c; }
    ._2al6t3wTJs3WAuAXm2XLPc:before {
      background-color: #f5371b; }
    ._1Rmsx-C-jn-r3c_6YdC_vA:before {
      background-color: #fbb703; } }

._1v9Q3IJgLlpF7knKr6K4SZ {
  border-radius: 17px;
  padding: 10px;
  font-weight: 600;
  font-size: .8rem; }
  ._1v9Q3IJgLlpF7knKr6K4SZ._1yvbFdZNdLcJ9DMlxHiJZy {
    background: #c0f2bd;
    color: #0a3807; }
  ._1v9Q3IJgLlpF7knKr6K4SZ._39D3yftNraDsy0gLtsMPNd {
    background: #c7ebff;
    color: #0d4f73; }
  ._1v9Q3IJgLlpF7knKr6K4SZ._2EGEQyJ6aZnynpFFt9gqaO {
    background: #d6dcc8;
    color: #5b5c5d; }
  ._1v9Q3IJgLlpF7knKr6K4SZ.f-jTvOpfjMUoFZmjjcsT1 {
    background: #eff0f5;
    color: #5b5c5d; }
  ._1v9Q3IJgLlpF7knKr6K4SZ._1uUdesmiiL1XmQks1IuBHb {
    background: #ffe5ec;
    color: #fa1854; }

._2z3rIsxOu3eK6OPTdT9YEP {
  display: none; }

._1gqO-zlJEBaCqmUSB97dzx {
  position: relative; }
  ._1gqO-zlJEBaCqmUSB97dzx ._2uz3Qjdh63VzuMjtUjWE1Y {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    background: #22272b;
    border-radius: 3px;
    color: #fff;
    font-size: 0.6rem;
    line-height: 0.8rem;
    padding: 0.5rem;
    max-width: 90vw;
    width: 200px;
    top: 100%;
    left: 50%;
    margin-left: -100px; }
  ._1gqO-zlJEBaCqmUSB97dzx:hover ._2uz3Qjdh63VzuMjtUjWE1Y {
    visibility: visible; }

._1bDLmnsGPEXTkB60DxnxrU {
  position: relative;
  width: 41px;
  height: 23px;
  margin-right: 10px;
  display: block; }
  ._1bDLmnsGPEXTkB60DxnxrU:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border-radius: 100px;
    width: 41px;
    height: 23px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease; }
  ._1bDLmnsGPEXTkB60DxnxrU:after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    background: #ffffff 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 3px 6px #00000029;
            box-shadow: 0px 3px 6px #00000029;
    border-radius: 130px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease; }
  ._1bDLmnsGPEXTkB60DxnxrU._3XDZEjh-95T3DqxiSqYP8Q:before {
    background: #1791d3 0% 0% no-repeat padding-box; }
  ._1bDLmnsGPEXTkB60DxnxrU._3XDZEjh-95T3DqxiSqYP8Q:after {
    left: 19px; }
  ._1bDLmnsGPEXTkB60DxnxrU.HF69PxfKGNBM0MORCh7j8 {
    -webkit-filter: grayscale(1);
            filter: grayscale(1); }
    ._1bDLmnsGPEXTkB60DxnxrU.HF69PxfKGNBM0MORCh7j8:before, ._1bDLmnsGPEXTkB60DxnxrU.HF69PxfKGNBM0MORCh7j8:after {
      cursor: not-allowed; }

._2NGPBg1z6-rH3H94Bpfo93.k0k1UmLckyp4W_5Q5VGzr {
  font-style: italic; }

._1ugAoTvGghjXDot8qnbXf5 {
  font-size: .8rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer; }
  ._1ugAoTvGghjXDot8qnbXf5.AY2T9bcLZ6n_XUBzchhjW {
    color: #328ebd;
    background-color: #318dbc1c;
    border: 1px solid #328ebd; }
    ._1ugAoTvGghjXDot8qnbXf5.AY2T9bcLZ6n_XUBzchhjW:hover {
      background-color: rgba(28, 80, 107, 0.109804); }
  ._1ugAoTvGghjXDot8qnbXf5._3i6WXfhmpkNsx0ZM4PNMbM {
    color: #fa1854;
    background: #fa18541c 0% 0% no-repeat padding-box;
    border: 1px solid #fa1854; }
    ._1ugAoTvGghjXDot8qnbXf5._3i6WXfhmpkNsx0ZM4PNMbM:hover {
      background-color: rgba(252, 124, 158, 0.109804); }
  ._1ugAoTvGghjXDot8qnbXf5[disabled], ._1ugAoTvGghjXDot8qnbXf5[disabled]:hover {
    background-color: #e6eaf2;
    color: #5c6066;
    border: 1px solid #e6eaf2; }

._2RRXAMstSHs1dcLW8V6zb0 {
  font-size: 0.8rem; }
