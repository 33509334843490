._20PcQM8Rpy7opu3zSHXas_ {
  height: calc(100vh - 73px);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 991px) {
    ._20PcQM8Rpy7opu3zSHXas_ {
      height: calc(100vh - 69px); } }
  ._1S9Zn7bZHHCVHNlhIbu9Rg {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    border: none; }
