._1tdxUFJku5AbFOH8-JB0Mv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  height: auto;
  margin-top: -5rem;
  padding: 12rem 4rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 991px) {
    ._1tdxUFJku5AbFOH8-JB0Mv {
      padding: 0 2rem 4rem 2rem;
      margin: 1rem;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }

._1PaHHcH9idTn_hDL6tWNee {
  max-width: 350px;
  height: auto; }
  @media (max-width: 991px) {
    ._1PaHHcH9idTn_hDL6tWNee {
      display: none; } }
  @media (min-width: 640px) and (max-width: 1300px) {
    ._1PaHHcH9idTn_hDL6tWNee {
      max-width: 200px; } }

._1OtphioScE0slF_CKMCTEM {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  text-align: center; }

.wXVKMXJwQ7a0aVS2Lz-Mx {
  font-size: 1.75rem;
  line-height: 1.8;
  word-break: break-word; }
  @media all and (min-width: 768px) {
    .wXVKMXJwQ7a0aVS2Lz-Mx {
      font-size: 4rem;
      font-weight: 700; } }
  @media (min-width: 640px) and (max-width: 1400px) {
    .wXVKMXJwQ7a0aVS2Lz-Mx {
      font-size: 3rem; } }

.go9n3-JshTF_lq3xZSbLk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  grid-gap: 2rem; }
  @media all and (min-width: 768px) {
    .go9n3-JshTF_lq3xZSbLk {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; } }
  .go9n3-JshTF_lq3xZSbLk ._1BuMHz_dL5O8-gTTqDb6QD {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    background: #fff;
    -webkit-box-shadow: -8px 7px 15px 1px rgba(229, 233, 241, 0.78);
            box-shadow: -8px 7px 15px 1px rgba(229, 233, 241, 0.78);
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer; }
    .go9n3-JshTF_lq3xZSbLk ._1BuMHz_dL5O8-gTTqDb6QD:hover {
      margin-top: -0.313rem;
      margin-bottom: 0.313rem;
      -webkit-transition: 0.2s ease;
      transition: 0.2s ease; }
      .go9n3-JshTF_lq3xZSbLk ._1BuMHz_dL5O8-gTTqDb6QD:hover ._1cYIZgpyGYiPOx1NJWdpY {
        color: #00b4d8; }
    .go9n3-JshTF_lq3xZSbLk ._1BuMHz_dL5O8-gTTqDb6QD ._1cYIZgpyGYiPOx1NJWdpY {
      color: #030b0d; }
    .go9n3-JshTF_lq3xZSbLk ._1BuMHz_dL5O8-gTTqDb6QD:last-child {
      border: none; }
    @media (max-width: 991px) {
      .go9n3-JshTF_lq3xZSbLk ._1BuMHz_dL5O8-gTTqDb6QD {
        margin-bottom: 0.313rem; }
        .go9n3-JshTF_lq3xZSbLk ._1BuMHz_dL5O8-gTTqDb6QD:hover {
          margin-top: 0; } }
