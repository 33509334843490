._1cOduyLAVzODzXosPrBEwp {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2Yjvace0pswnxCBqCd9G0O {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2Yjvace0pswnxCBqCd9G0O {
      font-size: 2.5rem;
      line-height: 1.7; } }

._1C4_rwWWbGpjiGC0G3EdQk {
  padding: 0 2rem 6rem; }
  @media (max-width: 991px) {
    ._1C4_rwWWbGpjiGC0G3EdQk {
      padding: 0 2rem 3rem; } }
  ._1C4_rwWWbGpjiGC0G3EdQk h2,
  ._1C4_rwWWbGpjiGC0G3EdQk h3 {
    color: #fff;
    word-break: break-word; }

._31xx-ViyWHV7A6RERVr4BV {
  max-width: 1400px;
  margin: 0 auto; }

._2Yjvace0pswnxCBqCd9G0O {
  text-align: center;
  margin-bottom: 4rem; }
  @media (max-width: 991px) {
    ._2Yjvace0pswnxCBqCd9G0O {
      margin-bottom: 1rem; } }

._2LSJFJBeCKu2SJYzMD39FO {
  display: grid;
  grid-gap: 3rem;
  gap: 3rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (max-width: 991px) {
    ._2LSJFJBeCKu2SJYzMD39FO {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      gap: 2rem; } }

._2zJPXSy-0Bb9vJN5MIsqn6 {
  background-color: #161819;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._1cOduyLAVzODzXosPrBEwp {
  font-size: 1.6rem;
  line-height: 1.7;
  text-align: center;
  background: #1a70d4;
  background: linear-gradient(36deg, #1a70d4 7%, #2591cf 71%, #2fa6eb 100%);
  padding: 1rem;
  margin: 0; }
  @media (max-width: 991px) {
    ._1cOduyLAVzODzXosPrBEwp {
      font-size: 1.4rem; } }

.TNgELAcxQ5VEkvTlNb0td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style: none;
  grid-gap: 3.5rem;
  gap: 3.5rem;
  padding: 2rem 1rem;
  margin: 0; }

._1G2h8TafZbkOEEq6vTR9_0 {
  max-height: 100%;
  width: auto; }
