._10QvYoKln4SS0wboahLf-o {
  width: 100%;
  margin-bottom: 3rem; }

._24edG229XwtUziM1bvG-h_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem; }
  @media (max-width: 1490px) {
    ._24edG229XwtUziM1bvG-h_ {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }

.XrCVseyzYjpp0swPZQM17 {
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 2.2rem;
  color: #051c26;
  margin: 0 0.5rem 0.5rem 0; }
  @media (max-width: 1490px) {
    .XrCVseyzYjpp0swPZQM17 {
      margin-bottom: 1rem; } }

._1csdxEBWlkoHKf85hqMU4t {
  font-size: 0.75rem;
  color: #758793; }

._1FqK9aSAe3sX7Ycnmyhe6R {
  margin: 0; }

._1Xft7QW2sPHjgxZkRPVCEi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 1490px) {
    ._1Xft7QW2sPHjgxZkRPVCEi {
      -ms-flex-item-align: end;
          align-self: flex-end; } }
  @media (max-width: 1240px) {
    ._1Xft7QW2sPHjgxZkRPVCEi {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      width: 100%;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
              align-items: stretch; } }
  ._1Xft7QW2sPHjgxZkRPVCEi ._1ikqH9uauDKzH32-j67_i9 {
    background: #fff;
    -webkit-box-shadow: 2px 4px 10px rgba(232, 234, 237, 0.3);
            box-shadow: 2px 4px 10px rgba(232, 234, 237, 0.3);
    border-radius: 3px;
    padding: 0.625rem;
    font-size: 0.875rem;
    margin-right: 0.5rem; }
    @media (max-width: 1240px) {
      ._1Xft7QW2sPHjgxZkRPVCEi ._1ikqH9uauDKzH32-j67_i9 {
        margin: 0 0 0.5rem 0; } }
    ._1Xft7QW2sPHjgxZkRPVCEi ._1ikqH9uauDKzH32-j67_i9 input {
      border: none;
      background: transparent;
      margin-left: 0.313rem; }
      ._1Xft7QW2sPHjgxZkRPVCEi ._1ikqH9uauDKzH32-j67_i9 input:focus {
        outline: none; }

._3JOSvkNt0dPKI53tcVOBrg {
  margin-right: 0.5rem; }
  ._3JOSvkNt0dPKI53tcVOBrg:last-child {
    margin-right: 0; }
  @media (max-width: 1240px) {
    ._3JOSvkNt0dPKI53tcVOBrg {
      margin-bottom: 0.5rem;
      width: 100%; } }
