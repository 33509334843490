._1lKIN9ksi9pXgGt-kFKfY8 {
  max-width: 90vw;
  margin: 0 auto;
  padding: 3.5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -ms-flex-item-align: start;
      align-self: start;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 991px) {
    ._1lKIN9ksi9pXgGt-kFKfY8 {
      min-width: 100%;
      padding: 0; } }
  ._1lKIN9ksi9pXgGt-kFKfY8._3Y12m1jNzLI14fiDo5bmKL ._1cCc9k53iAIcW0mrAp0LbG a,
  ._1lKIN9ksi9pXgGt-kFKfY8._3Y12m1jNzLI14fiDo5bmKL ._1GyOOurgksXWhktgcUXI2z ._2WKPLNV50KJxg7mBln9gCX,
  ._1lKIN9ksi9pXgGt-kFKfY8._3Y12m1jNzLI14fiDo5bmKL ._3oOqABWOVGQdyEsbwNSWdW {
    color: #00a69c; }

._1KAhbNYklnuoQHVIczPpi6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 3rem 2rem;
  max-width: 800px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 991px) {
    ._1KAhbNYklnuoQHVIczPpi6 {
      min-width: 100%;
      padding: 2rem; } }

._3oeK8AwCX5Zkf_dKIgrAZG {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 3rem 2rem;
  max-width: 790px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 991px) {
    ._3oeK8AwCX5Zkf_dKIgrAZG {
      min-width: 100%;
      padding: 2rem 2rem 4rem 2rem; } }

._3IgEcEC5cNkNbIQtrzkhMu {
  font-size: 3rem;
  margin-bottom: 2rem; }

._29ySXc52LfTxD8A6ZVqize {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 2rem; }

._1cCc9k53iAIcW0mrAp0LbG {
  font-size: 1.125rem;
  line-height: 1.8;
  max-width: 690px; }
  ._1cCc9k53iAIcW0mrAp0LbG > a {
    font-weight: 600; }
    ._1cCc9k53iAIcW0mrAp0LbG > a:hover {
      text-decoration: underline; }

._1_TqcMpwhqjVH4n4Iy9-Tc {
  background: #7289da 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #f9f9f9;
  max-width: 350px;
  width: auto;
  margin-bottom: 2rem;
  padding: 0.6rem 1.5rem; }
  ._1_TqcMpwhqjVH4n4Iy9-Tc ._2i16Go-vO5ViX7kW8SBIea {
    margin-right: 0.5rem; }
  ._1_TqcMpwhqjVH4n4Iy9-Tc:hover {
    color: #f9f9f9;
    background-color: #5a75d4; }
  ._1_TqcMpwhqjVH4n4Iy9-Tc:active, ._1_TqcMpwhqjVH4n4Iy9-Tc:focus {
    color: #f9f9f9;
    background-color: #526ed2; }
