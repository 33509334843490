._1yu9zzabF811DSLaN-KQ10 {
  font-size: .8rem;
  font-weight: 400;
  color: #657881;
  border-bottom: 1px solid #758793;
  padding: 20px 10px;
  line-height: 2em;
  position: relative; }
  ._1yu9zzabF811DSLaN-KQ10 ._6BA7luPgyDidfQjmtcADy {
    display: none; }
    ._1yu9zzabF811DSLaN-KQ10 ._6BA7luPgyDidfQjmtcADy:checked + ._1sPhnRbcBbqtPwfz5gBfL9:before {
      position: absolute;
      content: '\2713';
      background: #2392d0;
      color: #fff;
      height: 18px;
      width: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      display: inline-block;
      left: 21px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }

._2PktcKn25M0WPjBGov-qQy {
  margin: 0; }

._1R4EzLngWY7tERrcjTbZTd {
  background-color: #f3fbff; }

._2U6U-lNQfB9KsAvST-EZYr {
  position: relative; }
  ._2U6U-lNQfB9KsAvST-EZYr label {
    padding-left: 45px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    ._2U6U-lNQfB9KsAvST-EZYr label:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #2392d0;
      border-radius: 3px;
      display: inline-block;
      left: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }

._3LuqLrxYjyZPXr5Yc7uiYW._3NjriNYceNtmq9-nK96rOv, ._3LuqLrxYjyZPXr5Yc7uiYW.w-g3pIleFHQSaPZjeTfDL:not(.-Ud0jtMfw41PRz3ZQYTFs) {
  color: #1791d3;
  font-weight: 600; }

._3LuqLrxYjyZPXr5Yc7uiYW.w-g3pIleFHQSaPZjeTfDL.-Ud0jtMfw41PRz3ZQYTFs, ._3LuqLrxYjyZPXr5Yc7uiYW._1AyIfPgMmvjhaITOPuM_s2 {
  font-size: 0.8rem; }

._3LuqLrxYjyZPXr5Yc7uiYW.g_w6X3iwi0eOYBI_EwnYO {
  font-weight: 600; }

._3LuqLrxYjyZPXr5Yc7uiYW._1AyIfPgMmvjhaITOPuM_s2 {
  color: #fa1854; }

._3LuqLrxYjyZPXr5Yc7uiYW ._3RGMn19LI8WM1p3Mqk_V1j {
  background: #e6eaf2 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%; }

._3LuqLrxYjyZPXr5Yc7uiYW .FfpntpDGPx7WX9dWDx1Ox {
  display: none; }

._3LuqLrxYjyZPXr5Yc7uiYW ._1-elBlL8stGt-WJ3zGgmTM {
  color: #fa1854; }

.G95lXJ7HQDdYi2IoY7Ty9 {
  font-size: .8rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer; }
  .G95lXJ7HQDdYi2IoY7Ty9._3SDIouAaCP_W5QRmSAs9OL {
    color: #328ebd;
    background-color: #318dbc1c;
    border: 1px solid #328ebd; }
    .G95lXJ7HQDdYi2IoY7Ty9._3SDIouAaCP_W5QRmSAs9OL:hover {
      background-color: rgba(28, 80, 107, 0.109804); }
  .G95lXJ7HQDdYi2IoY7Ty9._1LIdKUIsGZTgz-NWBgTRoR {
    color: #fa1854;
    background: #fa18541c 0% 0% no-repeat padding-box;
    border: 1px solid #fa1854; }
    .G95lXJ7HQDdYi2IoY7Ty9._1LIdKUIsGZTgz-NWBgTRoR:hover {
      background-color: rgba(252, 124, 158, 0.109804); }
  .G95lXJ7HQDdYi2IoY7Ty9[disabled], .G95lXJ7HQDdYi2IoY7Ty9[disabled]:hover {
    background-color: #e6eaf2;
    color: #5c6066;
    border: 1px solid #e6eaf2; }
