._2UKzYjeBUWOaa3peWo5Ea- {
  padding: 3rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#00b4d8), color-stop(65%, #00b4d8), color-stop(65%, #00b4d8), color-stop(60%, #030b0d), to(#030b0d));
  background: linear-gradient(to bottom, #00b4d8 0%, #00b4d8 65%, #00b4d8 65%, #030b0d 60%, #030b0d 100%); }
  ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto; }
    ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      list-style: none;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 3rem 2rem 4rem 2rem; }
      @media (max-width: 991px) {
        ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi {
          grid-template-columns: auto;
          grid-gap: 2rem;
          width: 100%;
          padding: 0; } }
      ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._2lfJPWoRhy4VjNUcaZ1YJt {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column;
        background: #fff;
        border-radius: 10px;
        padding: 1.5rem;
        width: 244px;
        height: 244px;
        margin: 0 1rem;
        color: #030b0d;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        text-align: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        @media (max-width: 991px) {
          ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._2lfJPWoRhy4VjNUcaZ1YJt {
            width: 280px;
            height: 280px; } }
        ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._2lfJPWoRhy4VjNUcaZ1YJt:nth-child(even) {
          margin-top: -3.125rem; }
          @media (max-width: 991px) {
            ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._2lfJPWoRhy4VjNUcaZ1YJt:nth-child(even) {
              margin-top: 0; } }
        ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._2lfJPWoRhy4VjNUcaZ1YJt > h3 {
          margin-bottom: 0.313rem; }
          @media (max-width: 991px) {
            ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._2lfJPWoRhy4VjNUcaZ1YJt > h3 {
              font-size: 1.75rem;
              line-height: 1.8; } }
        ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._2lfJPWoRhy4VjNUcaZ1YJt > p {
          font-size: 1.2rem;
          margin: 0; }
      ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._1BivxJN9Y5yjusiEGpUxUB {
        max-height: 90px;
        margin-bottom: 1.25rem; }
        @media (max-width: 991px) {
          ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._1BivxJN9Y5yjusiEGpUxUB {
            max-height: 45%;
            margin-bottom: 1rem; } }
      ._2UKzYjeBUWOaa3peWo5Ea- ._2qkmSplYzn-T059ibSDn8d ._3zW1P6DAlfyVJkNWtjhbYi ._3_ovmvOCmRuGp7Fp2mbSlB {
        font-size: 1.5rem;
        margin-bottom: 0;
        font-weight: 700; }
  ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; }
    @media (max-width: 991px) {
      ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 {
        padding-top: 2rem; } }
    ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 ._7QFhIWbLLshED6ZBoFMVi {
      background: #fff;
      border-radius: 10px;
      padding: 7rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      @media (max-width: 991px) {
        ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 ._7QFhIWbLLshED6ZBoFMVi {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-flow: column;
                  flex-flow: column;
          padding: 2rem; } }
      ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 ._7QFhIWbLLshED6ZBoFMVi ._2FxG8bBmQuYKoE3fnqCgRR {
        color: #030b0d;
        margin-bottom: 0;
        margin-right: auto;
        width: 35%;
        line-height: 1.5; }
        @media (max-width: 991px) {
          ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 ._7QFhIWbLLshED6ZBoFMVi ._2FxG8bBmQuYKoE3fnqCgRR {
            text-align: center;
            width: 100%;
            margin-bottom: 2rem; } }
      ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 ._7QFhIWbLLshED6ZBoFMVi .p40_Zo7GNMAKqn86aHlrV {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: 65%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding-left: 5rem; }
        @media (max-width: 991px) {
          ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 ._7QFhIWbLLshED6ZBoFMVi .p40_Zo7GNMAKqn86aHlrV {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-flow: column;
                    flex-flow: column;
            width: 100%;
            padding: 0;
            text-align: center; } }
        ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 ._7QFhIWbLLshED6ZBoFMVi .p40_Zo7GNMAKqn86aHlrV ._1BivxJN9Y5yjusiEGpUxUB {
          width: 180px; }
    @media (max-width: 991px) {
      ._2UKzYjeBUWOaa3peWo5Ea- ._1K5TN34LNLXq-HANSefCQ8 ._1BivxJN9Y5yjusiEGpUxUB {
        width: 100%; } }
