._1AyAWzHE8swPpnLNoXcrKi, .LsV6hVdppmupDNXPvl3wd {
  font-family: 'Inter', system-ui, sans-serif; }

._1AyAWzHE8swPpnLNoXcrKi {
  margin: 2.813rem 3.75rem; }
  @media (max-width: 991px) {
    ._1AyAWzHE8swPpnLNoXcrKi {
      margin: 1.5rem; } }
  ._1kMJj6jAjWSo1XRE021Vlr {
    width: 90vw;
    margin: 0 auto;
    -webkit-transform: translate(5%, 0);
            transform: translate(5%, 0);
    color: #030b0d; }
  ._3oTOB3wnYkYama91WCjgKP {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    ._3oTOB3wnYkYama91WCjgKP img {
      margin: 0 1.25rem; }
  .LsV6hVdppmupDNXPvl3wd {
    font-size: 1.5rem; }
  .vdtl8_gqM9xQU0zKIgS3I {
    font-size: 0.875rem;
    color: #657881;
    margin: 0.625rem 0 1.25rem 0;
    display: block;
    max-width: 550px; }
  ._7_OjJ9N3iNLKBAb1jvc_r {
    font-weight: bold; }
  ._1SSZpS8b-VF8rAYfJzVgje {
    color: #ce2a29; }
  ._3D9M955dgBLqeNqPHd6owq {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .y_u77m1fYXi-rC5NBTOk7 {
    color: red;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly; }
  .EbQ3FL98uEGO2yDsw3O4u {
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
    list-style: none;
    padding: 0.625rem 0;
    text-align: center;
    border: 1px solid gray; }
    .EbQ3FL98uEGO2yDsw3O4u li {
      margin: 5px 0; }
  ._1pMLEzPVq8cYD27vOp3usc {
    display: block;
    margin: 0.313rem auto 0; }
  ._2_skEPN0OmZd_qlEO-8hXa {
    margin-left: 0.625rem; }
    ._2_skEPN0OmZd_qlEO-8hXa label {
      font-size: 0.875rem; }
  ._9OOx0PIbjf60P2V0tskYb {
    margin: 0.625rem 0.313rem;
    font-size: 35px;
    padding: 0.5rem 0.3rem; }
  ._3JzK5HSYW-wN5WOAftazrR {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    ._3JzK5HSYW-wN5WOAftazrR button {
      margin-left: 1.25rem; }
    @media (max-width: 991px) {
      ._3JzK5HSYW-wN5WOAftazrR {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse; }
        ._3JzK5HSYW-wN5WOAftazrR button {
          margin: 0 0 1rem; } }
  ._1AyAWzHE8swPpnLNoXcrKi ._3-tuxKo-HjxNHNeQOzj-bI {
    background: transparent;
    border: none;
    color: #2392d0;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    margin-top: 1.25rem;
    width: 100%; }
    ._1AyAWzHE8swPpnLNoXcrKi ._3-tuxKo-HjxNHNeQOzj-bI:hover {
      color: #46a9e0; }
  @media (max-width: 991px) {
    ._3D9M955dgBLqeNqPHd6owq {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }
