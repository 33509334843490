._3Zq7Bxu1g_1qXEtIjbfK3i {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3AbTEodJm-mVpMOMpN87ha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin: 4rem 0 2rem;
  border-bottom: 1px solid #030b0d;
  padding-bottom: 3rem; }
  ._2ZMXzng7rNUYgOo3b6Yryi {
    height: 45px;
    width: 100%;
    padding: 0 1rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: #d6e7f0;
    border: 1px solid #2591cf;
    border-radius: 4px;
    -webkit-transition: 0.3s all;
    transition: 0.3s all; }
    ._2ZMXzng7rNUYgOo3b6Yryi::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.5); }
    ._2ZMXzng7rNUYgOo3b6Yryi::-moz-placeholder {
      color: rgba(255, 255, 255, 0.5); }
    ._2ZMXzng7rNUYgOo3b6Yryi:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5); }
    ._2ZMXzng7rNUYgOo3b6Yryi::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.5); }
    ._2ZMXzng7rNUYgOo3b6Yryi::placeholder {
      color: rgba(255, 255, 255, 0.5); }
    ._2ZMXzng7rNUYgOo3b6Yryi:hover {
      background-color: #c0dae8; }
    ._2ZMXzng7rNUYgOo3b6Yryi:active, ._2ZMXzng7rNUYgOo3b6Yryi:focus {
      border: 1px solid #00b4d8;
      outline: 1px solid #00b4d8; }
  ._3Zq7Bxu1g_1qXEtIjbfK3i {
    font-size: 1.2rem;
    display: block;
    margin-bottom: 1rem;
    font-weight: 600; }
  ._1G71_OEmyPzEnN0-Lqcx34 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row; }
    ._1G71_OEmyPzEnN0-Lqcx34 > a {
      margin-left: auto;
      text-align: left;
      color: #2591cf; }
      ._1G71_OEmyPzEnN0-Lqcx34 > a:hover {
        text-decoration: underline; }
      @media (max-width: 991px) {
        ._1G71_OEmyPzEnN0-Lqcx34 > a {
          margin: 0;
          padding-bottom: 1rem; } }
    @media (max-width: 991px) {
      ._1G71_OEmyPzEnN0-Lqcx34 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-flow: column-reverse;
                flex-flow: column-reverse; } }

._2feoOtYtxN-8uaiwgAakB8 {
  margin-top: 1.5rem;
  text-align: right; }
  ._2feoOtYtxN-8uaiwgAakB8 ._1w1UYD1MtsXx-ZzLLU93k[disabled] {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    cursor: not-allowed; }

._21j7qov3tVbdOHL5jFkiW- {
  color: #fa1854;
  line-height: 1.2em;
  margin-top: 1rem; }
  ._21j7qov3tVbdOHL5jFkiW- > a {
    color: #2591cf; }
    ._21j7qov3tVbdOHL5jFkiW- > a:hover {
      text-decoration: underline; }

._2ON-xf-h71Jb4v23o3W4aj {
  margin-top: 1rem;
  text-align: right; }
  ._2ew-2suzIWYl06p5u1HHxy {
    margin-bottom: 0; }
    ._2ew-2suzIWYl06p5u1HHxy span {
      margin-left: 0.3rem;
      color: #2591cf;
      font-weight: 600; }
      ._2ew-2suzIWYl06p5u1HHxy span:hover {
        text-decoration: underline; }
