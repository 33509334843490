._3EjD248ifRRLp6IBVPMgre {
  margin-bottom: 20px;
  overflow: auto;
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px; }

.klVLm7NPNuWeLfmtQzzVC {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }
  .klVLm7NPNuWeLfmtQzzVC thead {
    background-color: transparent; }
  .klVLm7NPNuWeLfmtQzzVC tr {
    border: none; }
    @media (max-width: 1240px) {
      .klVLm7NPNuWeLfmtQzzVC tr {
        display: table;
        border-bottom: 1px solid #f4f4f4;
        width: 100%;
        margin-bottom: 1rem; }
        .klVLm7NPNuWeLfmtQzzVC tr th {
          display: none; }
        .klVLm7NPNuWeLfmtQzzVC tr td {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .klVLm7NPNuWeLfmtQzzVC tr td:last-child {
            padding-bottom: 1rem; } }
    @media (max-width: 1240px) and (max-width: 1240px) {
      .klVLm7NPNuWeLfmtQzzVC tr td {
        padding: 0.5rem 1rem; } }

._3SWG5Dm1kdIaAjcP1rDDxr {
  color: #62737f; }
  ._3SWG5Dm1kdIaAjcP1rDDxr._1WemF6BmpLYo4zdmhqgs6R {
    color: #fa1854; }
  ._3SWG5Dm1kdIaAjcP1rDDxr:hover {
    background-color: #f6f9fb; }

._3uLkn2UfHKh-2UIqjEWsXS {
  padding: 1rem;
  color: #62737f; }
  ._3uLkn2UfHKh-2UIqjEWsXS ._1yxYBjYdYfvYl0jCfjGnGA {
    display: none;
    margin-left: 0.5rem; }
    @media (max-width: 1240px) {
      ._3uLkn2UfHKh-2UIqjEWsXS ._1yxYBjYdYfvYl0jCfjGnGA {
        display: block; } }

._346zHQX9ehetmSrjBZ__9p {
  font-size: 1rem;
  font-weight: 600;
  position: relative; }
  ._346zHQX9ehetmSrjBZ__9p label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative; }
    ._346zHQX9ehetmSrjBZ__9p label:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #1167f3;
      border-radius: 3px;
      display: inline-block;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }
    ._346zHQX9ehetmSrjBZ__9p label ._3CMqmsTQLzGYHH5EVs2D2g {
      display: none; }
      ._346zHQX9ehetmSrjBZ__9p label ._3CMqmsTQLzGYHH5EVs2D2g:checked + span:before {
        position: absolute;
        content: '\2713';
        background: #1167f3;
        color: #fff;
        height: 20px;
        width: 20px;
        text-align: center;
        line-height: 18px;
        font-size: 12px;
        display: inline-block;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        position: absolute;
        border-radius: 3px; }
    ._346zHQX9ehetmSrjBZ__9p label span {
      width: 100%;
      text-overflow: ellipsis; }
  ._346zHQX9ehetmSrjBZ__9p._36SreK8wpfTrQHcyCl3E0Z {
    color: #1167f3; }
  ._346zHQX9ehetmSrjBZ__9p ._3yfNkQjBsc3iCU37JUoaRK {
    color: #fa1854; }

._1lIsFtQgiYQY8z7Ap3LuTj {
  color: #8c8c8c;
  font-weight: 600;
  font-size: 1rem; }
  ._1lIsFtQgiYQY8z7Ap3LuTj._36SreK8wpfTrQHcyCl3E0Z {
    color: #1a1a1a; }
  ._1lIsFtQgiYQY8z7Ap3LuTj._1WemF6BmpLYo4zdmhqgs6R {
    color: #fa1854; }

.opSj7skKu7ry5MEjVtkq5 {
  color: #8c8c8c;
  font-weight: 600;
  font-size: 1rem; }
  .opSj7skKu7ry5MEjVtkq5._36SreK8wpfTrQHcyCl3E0Z {
    color: #1f8e0d; }
  .opSj7skKu7ry5MEjVtkq5._1WemF6BmpLYo4zdmhqgs6R {
    color: #fa1854; }

._2OXB9VrWvDVg147X1s6pvI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 1240px) {
    ._2OXB9VrWvDVg147X1s6pvI {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }

._1mqIHhS2kdQ_2nsOldRxS1 {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer; }
  ._1mqIHhS2kdQ_2nsOldRxS1.aIon3zXFCOR98TnFtneRE, ._1mqIHhS2kdQ_2nsOldRxS1._13QPQFmUiVmjmv0qJSL2Ia {
    color: #1167f3;
    background-color: #318dbc1c;
    border: 1px solid #1167f3; }
    ._1mqIHhS2kdQ_2nsOldRxS1.aIon3zXFCOR98TnFtneRE:hover, ._1mqIHhS2kdQ_2nsOldRxS1._13QPQFmUiVmjmv0qJSL2Ia:hover {
      background-color: rgba(28, 80, 107, 0.109804); }
  ._1mqIHhS2kdQ_2nsOldRxS1._1ce9kfuWo96MlEBJk4MhKX {
    color: #fa1854;
    background: #fa18541c 0% 0% no-repeat padding-box;
    border: 1px solid #fa1854; }
    ._1mqIHhS2kdQ_2nsOldRxS1._1ce9kfuWo96MlEBJk4MhKX:hover {
      background-color: rgba(252, 124, 158, 0.109804); }
  ._1mqIHhS2kdQ_2nsOldRxS1[disabled], ._1mqIHhS2kdQ_2nsOldRxS1[disabled]:hover {
    background-color: #e6eaf2;
    color: #5c6066;
    border: 1px solid #e6eaf2; }
  ._1mqIHhS2kdQ_2nsOldRxS1:last-child {
    margin-left: 1rem; }
  ._1mqIHhS2kdQ_2nsOldRxS1:only-child {
    margin-left: 0; }

._2Q5Ovng6t7wiBUk33TT_kI {
  font-size: 0.8rem; }

._1pxoaRJaOMiafDqdz-mfk_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    ._1pxoaRJaOMiafDqdz-mfk_ {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }

.xPOjCOGMLuywycAy0aEOK ._1VnP7M9VHGsQfWj_qn2FLE {
  padding: 3rem 2rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative; }

.xPOjCOGMLuywycAy0aEOK ._1pqo6sE8zGe-2Q-Ko7UBiy {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

.xPOjCOGMLuywycAy0aEOK .YFFl9n1g4mcq7y-lNDd4f {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

.xPOjCOGMLuywycAy0aEOK ._3l--eI9aUaqUQNwSX6keny {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem; }
  .xPOjCOGMLuywycAy0aEOK ._3l--eI9aUaqUQNwSX6keny li {
    margin-bottom: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline; }
    .xPOjCOGMLuywycAy0aEOK ._3l--eI9aUaqUQNwSX6keny li span:before {
      margin-right: 0.5rem;
      color: #ce2a29;
      font-size: 0.8rem; }

.xPOjCOGMLuywycAy0aEOK ._23Qki64QNP_fEZwYX9khqj {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 0.5rem; }
  .xPOjCOGMLuywycAy0aEOK ._23Qki64QNP_fEZwYX9khqj ._2f4wPcxAASqKbhVxFv33xu {
    margin-bottom: 0.5rem;
    font-weight: 700; }
  .xPOjCOGMLuywycAy0aEOK ._23Qki64QNP_fEZwYX9khqj ._3x0ljDraqdSEN5izNs5vKj {
    border: 1px solid #e6e7e8;
    border-radius: 3px;
    padding: 5px; }

.xPOjCOGMLuywycAy0aEOK ._1rWh9i8Kd4n9sqUoOhl9qy {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 1rem 2rem; }
  .xPOjCOGMLuywycAy0aEOK ._1rWh9i8Kd4n9sqUoOhl9qy ._1mqIHhS2kdQ_2nsOldRxS1 {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer;
    display: block; }
    .xPOjCOGMLuywycAy0aEOK ._1rWh9i8Kd4n9sqUoOhl9qy ._1mqIHhS2kdQ_2nsOldRxS1:hover {
      border: 1px solid silver; }
    .xPOjCOGMLuywycAy0aEOK ._1rWh9i8Kd4n9sqUoOhl9qy ._1mqIHhS2kdQ_2nsOldRxS1[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  .xPOjCOGMLuywycAy0aEOK ._1rWh9i8Kd4n9sqUoOhl9qy ._3ZgtfMMwjHnsRI82zsRhYW {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    .xPOjCOGMLuywycAy0aEOK ._1rWh9i8Kd4n9sqUoOhl9qy ._3ZgtfMMwjHnsRI82zsRhYW:hover {
      background: #34a9e9;
      color: #fff; }

.xPOjCOGMLuywycAy0aEOK ._3yfNkQjBsc3iCU37JUoaRK {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }
