._2EfYtza9dQtplpqPbweusU {
  -webkit-box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1);
          box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1); }

._2EfYtza9dQtplpqPbweusU {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fff;
  padding: 1.5rem 1rem;
  border-radius: 5px;
  margin-bottom: 0.6rem;
  max-width: 100%; }
  @media all and (min-width: 320px) {
    ._2EfYtza9dQtplpqPbweusU {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row; } }
  ._2EfYtza9dQtplpqPbweusU > div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
    ._2EfYtza9dQtplpqPbweusU > div:last-child {
      text-align: end; }
  ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 1rem; }
    @media all and (min-width: 320px) {
      ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk {
        margin-bottom: 0; } }
    ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv {
      height: 45px;
      width: 45px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: #f2f2f2;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv img {
        max-width: 100%; }
      ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv._3qcQsKTguUVTyUd80Su4wQ {
        background-color: #1877f2; }
      ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv.ZjG7C7CTzteL30WRj0Hwl {
        background-color: #1da1f2; }
      ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv._34kaBNbngS5KMVIp5C1M61 {
        background-color: #f2f2f2; }
      ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv._2xyhao3shrTN0Gh8CDRRCU {
        background-color: #9146ff; }
      ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv.OmTX47C0X8L8_5GlG8N69 img, ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv._3N_tf8G6TcdXB9LtK00hUY img, ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv._18hfMr_oShnEUlsXtITjuZ img, ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv.vZG-jBqikwabyhECBdQj6 img, ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._1oH8azYQjfgD4zSU0S9Pvv._3hAKtvNjUXcSZkqCytBXdB img {
        height: 30px; }
    ._2EfYtza9dQtplpqPbweusU ._3qAc07Vomlhb92v-x7-Srk ._27_v1wDshhuxBF7NOCWGx_ {
      padding-left: 1.25rem;
      font-weight: 600;
      width: 80px; }
  ._2EfYtza9dQtplpqPbweusU ._3L7mAhcLeeKwlyu2vbfPf8 {
    color: #62737f; }
    @media (max-width: 991px) {
      ._2EfYtza9dQtplpqPbweusU ._3L7mAhcLeeKwlyu2vbfPf8 {
        display: none; } }
    ._2EfYtza9dQtplpqPbweusU ._3L7mAhcLeeKwlyu2vbfPf8._2WyCm2WC0rY8isvXJfSpbE {
      font-weight: 600;
      color: #1f8e0d; }
