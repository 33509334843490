._15aWhO_7Yo5gdgApLc77u1 {
  padding: 3rem 3rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 1024px;
  overflow: auto;
  max-height: 80vh; }

._1hO61OuNXcuCQHtc_Y-T7v {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._1J2hohgKbYslHApxDcSee6 {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

._2l7AGkluwV4SlSn1fJAjem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._3-KxI_lGRWy0_OP4uPTTos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._1nFs4x-SAmqiHhkmyf6LGQ {
  margin-right: 0.5rem; }

.b6mrMnsvIHSCDlZCrYZ48 {
  max-height: 50vh;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 2rem 0; }

._1IIVIj1DclNo0WhxIS33_q {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }

._1LpfCsvyxo9wFZHtjhtfrC ._7K8ffykjlo490MUzzsSIX {
  color: #2c2c2c;
  font-size: 0.9rem;
  font-weight: 600;
  margin-right: 0.5rem; }

._1LpfCsvyxo9wFZHtjhtfrC ._1fOKe5VagUmrqo59VIhsdZ {
  color: #758793;
  font-size: 0.8rem;
  border: none;
  cursor: pointer; }

._1Y7Kol7HQohlQNaiMu0mhS {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._1Y7Kol7HQohlQNaiMu0mhS ._3ZEtlviD4VyqyiJJNZ2VyB:first-child {
    margin-right: 0.5rem; }
