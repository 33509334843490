._3LnePZa4ZqiW9_Z3CYTRWO {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12rem 1.25rem;
  background: #1a1d25;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#272a36', endColorstr='#1c1e26',GradientType=0 );
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  ._3LnePZa4ZqiW9_Z3CYTRWO._2gzaw_4VkzQrSezY-4DC9G {
    background: #030b0d; }
  .WuevRh4beVoB5uf1l4tEa {
    font-size: 2.938rem;
    line-height: 1.7;
    color: #fff;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    text-align: center; }
    @media all and (max-width: 790px) {
      .WuevRh4beVoB5uf1l4tEa {
        font-size: 1.75rem;
        line-height: 1.6; } }
  .Aw0Z2HHcYUzKNPMIIsuRj {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 3.125rem; }
    .Aw0Z2HHcYUzKNPMIIsuRj:first-child {
      margin-top: 0; }
    ._2K7rlhzB1iIKMHV0BQt_-3 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: 400px;
          flex-basis: 400px;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-left: 2.5rem; }
      .PtCQo8LSJX6HybQkxnpuW {
        display: block;
        width: 100%;
        margin-top: 1rem;
        text-align: center;
        color: #fff;
        opacity: 0.6; }
        @media all and (max-width: 500px) {
          .PtCQo8LSJX6HybQkxnpuW {
            margin-top: 0.625rem; } }
      ._2K7rlhzB1iIKMHV0BQt_-3:first-child {
        margin-left: 0; }
      @media all and (max-width: 900px) {
        ._2K7rlhzB1iIKMHV0BQt_-3 {
          -ms-flex-preferred-size: 0;
              flex-basis: 0;
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1;
          -ms-flex-negative: 1;
              flex-shrink: 1; } }
      @media all and (max-width: 800px) {
        ._2K7rlhzB1iIKMHV0BQt_-3 {
          -webkit-box-flex: 0;
              -ms-flex-positive: 0;
                  flex-grow: 0;
          -ms-flex-negative: 0;
              flex-shrink: 0;
          -ms-flex-preferred-size: auto;
              flex-basis: auto;
          margin-left: 0;
          margin-top: 1.875rem; } }
    @media all and (max-width: 800px) {
      .Aw0Z2HHcYUzKNPMIIsuRj {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        max-width: 400px;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 1.25rem; } }
    @media all and (max-width: 500px) {
      .Aw0Z2HHcYUzKNPMIIsuRj {
        margin-top: 1.875rem; } }
    @media all and (max-height: 750px) and (min-width: 800px) {
      .Aw0Z2HHcYUzKNPMIIsuRj {
        margin-top: 1.875rem; } }
  @media all and (max-width: 800px) {
    ._3LnePZa4ZqiW9_Z3CYTRWO {
      padding: 4rem 2rem; } }
