.hC_to-fDB5WDC6g_e0yr3 {
  position: relative;
  margin-right: 10px;
  display: block;
  cursor: pointer; }
  .hC_to-fDB5WDC6g_e0yr3:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border-radius: 100px;
    width: 39px;
    height: 21px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease; }
  .hC_to-fDB5WDC6g_e0yr3:after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    background: #ffffff 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 3px 6px #00000029;
            box-shadow: 0px 3px 6px #00000029;
    border-radius: 130px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease; }
  .hC_to-fDB5WDC6g_e0yr3._28UdTQi2mgy5ljbSwQq7KF:before {
    background: #1791d3 0% 0% no-repeat padding-box; }
  .hC_to-fDB5WDC6g_e0yr3._28UdTQi2mgy5ljbSwQq7KF:after {
    left: 19px; }
  .hC_to-fDB5WDC6g_e0yr3 .-_myAm3JJ8fsxYRmVjWae {
    display: none; }
  .hC_to-fDB5WDC6g_e0yr3 .zmYGutVQ_Mt_EmOpII_rE {
    margin-left: 45px;
    display: block; }
  .hC_to-fDB5WDC6g_e0yr3 ._3FzuxxyyRPfXLuDsCE4K7y {
    color: #051c26;
    font-weight: 600; }
  .hC_to-fDB5WDC6g_e0yr3[disabled]:before, .hC_to-fDB5WDC6g_e0yr3._1d4pjzO_9nQyAM4Hx0oPQ3:before {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }
