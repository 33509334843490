._1O2fLa3HoaBOdKFE1KOerX {
  height: 50px; }

._3h6ZPI8XlC6fYzNvACS5iy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2rem 2rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.olLBM7xaGTL1nun5VQVXe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._1cep5fL7N3MrkI6jLsSVaR {
  margin-bottom: 0;
  margin-left: 0.5rem; }

._1cep5fL7N3MrkI6jLsSVaR, ._3bSSxsgp2kIGu7cat_xalU {
  font-weight: 700; }

._2raZqBAdwm2OmutpZYKBeQ {
  color: #1db953; }

.yzQhxqYqCz4fFSGidXX13 {
  margin-bottom: 0;
  font-size: 0.875rem; }

._1uB5i7OV6ooH2JHi4-VKf1 {
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700; }

@media (max-width: 991px) {
  ._3h6ZPI8XlC6fYzNvACS5iy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 2rem 2rem; }
  .olLBM7xaGTL1nun5VQVXe {
    margin-bottom: 1rem; }
  ._1dwMQmFFrEajliGe_u7AFg {
    margin-top: 1rem;
    margin-left: 0; } }
