.ngOI9P6olRkxk2fNs6clF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 10px;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }

._3eb6Xw7NZt84Abbsb0_rKz {
  color: #657881;
  position: relative;
  padding-left: 25px; }
  ._3eb6Xw7NZt84Abbsb0_rKz:before {
    content: '';
    height: 18px;
    width: 18px;
    border: 1px solid #2392D0;
    border-radius: 3px;
    display: block;
    position: absolute;
    left: 0; }

._7GCEoxtktGBjFh0theO2h {
  width: 5px;
  top: 0;
  margin-left: 5px;
  position: absolute;
  opacity: 0; }
  ._7GCEoxtktGBjFh0theO2h:checked + span:before {
    content: '\2713';
    background: #2392D0;
    color: #fff;
    text-align: center;
    line-height: 18px;
    font-size: 12px; }
