._1f_F5-NEhYpwFIuZUoVky5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin: 2rem 0; }

._3bdUWNwvwF06W0Hjmcx1B_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column; }
    ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2lHvRxKI_cElSnVD9Ss1O9 {
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 1.5rem; }
    ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column wrap;
              flex-flow: column wrap;
      margin-bottom: 1.5rem; }
      @media (max-width: 991px) {
        ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-flow: column;
                  flex-flow: column;
          -webkit-box-align: stretch;
              -ms-flex-align: stretch;
                  align-items: stretch; } }
      ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 .GtjaF4ajbHIIvRbuRRPT1 {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        padding-top: 0.5rem; }
      ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 > .Os2p-YfXh-t_al5xU1bhc,
      ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 ._1-gkI9EwRgo6Wzykk7SrUK {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column; }
        ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 > .Os2p-YfXh-t_al5xU1bhc ._3BU_3KZ0O3r2kVHYSoQwxG,
        ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 ._1-gkI9EwRgo6Wzykk7SrUK ._3BU_3KZ0O3r2kVHYSoQwxG {
          line-height: 1.5rem;
          margin-top: 1rem;
          color: #9a9c9d; }
      ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 .Os2p-YfXh-t_al5xU1bhc {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        padding: 0.5rem;
        border: 1px solid #c7ccda;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-radius: 3px;
        margin-bottom: 0.5rem;
        width: 100%; }
        ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 .Os2p-YfXh-t_al5xU1bhc:focus {
          -webkit-box-shadow: -3px 5px 11px 1px rgba(229, 233, 241, 0.42);
                  box-shadow: -3px 5px 11px 1px rgba(229, 233, 241, 0.42);
          background-color: #fdfdfd;
          outline: none; }
        ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 .Os2p-YfXh-t_al5xU1bhc._3yTo_SikyYJgAG6Ntul6M1 {
          border: 1px solid #00b4d8;
          color: #00b4d8; }
        ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._2EMM63PiY-R1LcvvnjXQy7 .Os2p-YfXh-t_al5xU1bhc._1QLiyyhwZxMForQ5i6ntLs {
          border: 1px solid #fa5050;
          color: #fa5050; }
    ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._1MNsDCYew5KW982hoZDpW5 {
      color: #ce2a29;
      margin-bottom: 1.5rem; }
      ._3bdUWNwvwF06W0Hjmcx1B_ ._1lTHowrJ4rhKyao_jN5PBl ._1MNsDCYew5KW982hoZDpW5._2noxfeUhhjMzZ2R_b7hEKe {
        text-align: center; }
  ._3bdUWNwvwF06W0Hjmcx1B_ ._3fHbf3F__9rLFZF3tkbdfk {
    -ms-flex-item-align: end;
        align-self: flex-end;
    padding: 0.8rem 1.8rem; }
