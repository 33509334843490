._1_yX0fiLNZ7FIwVtQqhu9D, ._3NrJjMx-CvFRFhAp2oCqUu {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3NrJjMx-CvFRFhAp2oCqUu {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3NrJjMx-CvFRFhAp2oCqUu {
      font-size: 2.5rem;
      line-height: 1.7; } }

._3m4Kumkd3NiWDbG5HWBXlD {
  padding: 0 2rem 6rem; }
  @media (max-width: 991px) {
    ._3m4Kumkd3NiWDbG5HWBXlD {
      padding: 0 0 3rem; } }
  ._3m4Kumkd3NiWDbG5HWBXlD p,
  ._3m4Kumkd3NiWDbG5HWBXlD h2 {
    color: #fff;
    word-break: break-word;
    text-align: center; }

._2it1GkUuh_oO-Y2ghvR_pR {
  max-width: 1400px;
  margin: 0 auto;
  background: #00a69c;
  border-radius: 20px; }
  @media (max-width: 991px) {
    ._2it1GkUuh_oO-Y2ghvR_pR {
      border-radius: 0; } }

._2WawVixg5g1y3lH6nujj1t {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 4rem 9rem; }
  @media (max-width: 991px) {
    ._2WawVixg5g1y3lH6nujj1t {
      padding: 2rem; } }

._1_yX0fiLNZ7FIwVtQqhu9D {
  font-size: 1.4rem;
  line-height: 1.7;
  font-weight: 500;
  margin: 0; }
  @media (max-width: 991px) {
    ._1_yX0fiLNZ7FIwVtQqhu9D {
      font-size: 1.2rem; } }

._3NrJjMx-CvFRFhAp2oCqUu {
  margin: 0; }
