.DTAIRkujO9jgpss_uyprA {
  position: relative;
  max-width: 650px; }

.zgP_AgRx_5NGFRvwE5ZlF {
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

.Ra4FSe3RfYP4oCoLu1ExP {
  margin-bottom: 1rem; }

._3PQ6pMOO9AKrRq_lE-MwjN {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem; }

.xT-mC00h5O-okwZqug1JK {
  font-weight: 700;
  margin-bottom: 1rem; }

._38YiGE2rHHpFlcoFAD02iv {
  margin-bottom: 1rem; }

._1ab-DrPY4URUOGDC2XP2n4 {
  margin: 1rem 0; }

.sHx2LywrgaovY3d8lBqj0 {
  background-color: #fafafa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  @media all and (max-width: 800px) {
    .sHx2LywrgaovY3d8lBqj0 {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  .sHx2LywrgaovY3d8lBqj0 ._3rKuIe7JCE6CwDBxJn3-wD {
    border: 1px solid #9a9c9d;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #9a9c9d;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer;
    font-weight: bold; }
    .sHx2LywrgaovY3d8lBqj0 ._3rKuIe7JCE6CwDBxJn3-wD[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
    .sHx2LywrgaovY3d8lBqj0 ._3rKuIe7JCE6CwDBxJn3-wD:not([disabled]):hover {
      border: 1px solid #808384;
      color: #808384; }
  .sHx2LywrgaovY3d8lBqj0 ._32qKPGAqgcSM8VZra4cXyN {
    background: #00b4d8 0% 0% no-repeat padding-box;
    color: #ffffff; }
    .sHx2LywrgaovY3d8lBqj0 ._32qKPGAqgcSM8VZra4cXyN:not([disabled]):hover {
      background: #0cd7ff;
      color: #fff; }
