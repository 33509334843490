.nns63lyEsDXXft27U0Vkz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
  margin-top: -5rem;
  padding-top: 5rem; }
  ._1b03lfZHPkkGQ2LQA37URy {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 3.125rem; }
  ._3JIJVUKzbVcXoK9RZu-nT6 {
    display: block;
    width: 100%;
    height: 150px; }
