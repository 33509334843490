._1YzZROjR18H_jX8IbZk7Sv ._323Y6hWX0ekXRYaXFEgO5u {
  color: #051c26;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
  position: relative; }

._1YzZROjR18H_jX8IbZk7Sv .ArisiHhXS2AZzaIKskWCi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1.5rem; }
  ._1YzZROjR18H_jX8IbZk7Sv .ArisiHhXS2AZzaIKskWCi span {
    margin-left: 3.125rem;
    color: #4b4c4d;
    font-size: 16px;
    font-weight: 400; }
