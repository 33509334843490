._3lfoqtw7tHfI1J2GjY4etd {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #222;
  opacity: .2;
  z-index: 1; }

._3u0PqX_pXu3uLvbCmIgwEH {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute; }

.E4QCmlf0Z-Yq5axJ19H8R {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

._1OLzx0kz4MkNBNTxjHPJpF {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  -webkit-animation: _2Up27JUulTSCpRfD4Q3kIo .5s infinite linear;
          animation: _2Up27JUulTSCpRfD4Q3kIo .5s infinite linear;
  border-radius: 100%;
  border-style: solid; }

@-webkit-keyframes _2Up27JUulTSCpRfD4Q3kIo {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes _2Up27JUulTSCpRfD4Q3kIo {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
