._2p_OdSg7_c5Nef_bYRmdCt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000; }
  ._1PAayV_NjluT-KMjt3QEhr, ._3S1-c0wjTiJxNZGONgWSJB {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    position: absolute;
    font-size: 0.938rem;
    margin-right: -52rem;
    margin-top: -30rem;
    color: #fff;
    background-color: #00b4d8;
    border-radius: 50%;
    z-index: 2; }
    ._1PAayV_NjluT-KMjt3QEhr:hover, ._3S1-c0wjTiJxNZGONgWSJB:hover {
      background-color: #009bb9; }
    ._1PAayV_NjluT-KMjt3QEhr:active, ._1PAayV_NjluT-KMjt3QEhr:focus, ._3S1-c0wjTiJxNZGONgWSJB:active, ._3S1-c0wjTiJxNZGONgWSJB:focus {
      background-color: #0092af; }
  @media all and (max-width: 865px) {
    ._3S1-c0wjTiJxNZGONgWSJB {
      margin-right: unset;
      right: 0; } }
  @media all and (max-width: 415px) {
    ._3S1-c0wjTiJxNZGONgWSJB {
      margin-top: -15rem; } }
  @media all and (max-width: 280px) {
    ._3S1-c0wjTiJxNZGONgWSJB {
      margin-top: -9rem; } }
  ._21uvZCepV4vU2goiRLwZgU {
    font-weight: bold; }
  ._1PAayV_NjluT-KMjt3QEhr {
    right: 10px; }
  ._1qKkl0tcPlOwGxcim8ivZL {
    display: inline-block;
    max-width: calc(100% - 40px);
    width: auto;
    height: auto;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 3px;
    position: relative;
    background: #030b0d; }
    ._1qKkl0tcPlOwGxcim8ivZL iframe {
      max-width: 100%; }
      @media all and (max-width: 415px) {
        ._1qKkl0tcPlOwGxcim8ivZL iframe {
          max-height: 230px; } }
      @media all and (max-width: 280px) {
        ._1qKkl0tcPlOwGxcim8ivZL iframe {
          height: auto; } }
  .hjC8QlRx-Ey8kp8GWFMir {
    padding: 4rem 2rem 1rem; }
  ._1vQ1CgGiM-WMWWH0dnOuxT, ._1XithJv5mJ_h6S0DA7uEXC {
    color: #fff;
    text-align: left; }
  ._1OUoeiiTEeWPf2xkUZC-ul {
    color: #a9b2be;
    margin-bottom: 0.5rem; }
  ._2AZ7Pwvt1DIts6lUGUq3z7 {
    color: #a9b2be;
    font-size: 0.9rem;
    margin-bottom: 0; }
  ._1vQ1CgGiM-WMWWH0dnOuxT {
    text-align: center; }
  ._1KoivKOTdbgtaBKGRcIWsr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #2892d0;
    background: #071a25;
    margin-bottom: 1rem;
    padding: 1.5rem 2rem;
    width: 700px; }
    @media (max-width: 991px) {
      ._1KoivKOTdbgtaBKGRcIWsr {
        width: auto; } }
  ._3k7jd4JjfhGN_MivxD_hQU {
    height: 32px;
    width: 32px;
    margin-right: 2rem; }
  ._4NJbxZvQpZsfs27z7Yj8c {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.6);
    -webkit-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease; }
