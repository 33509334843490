._2kXjv_WwSB47npWbgBi7GV {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 500px; }

._15BR12v0G_oGkzWtWwfg2s {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._3KbGJBik5287VWbhXN9lse {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px;
  line-height: 1.4em; }

._3Ln0X9Bv6hDMy83ZSZX76x {
  color: #fa1854; }

.u7F3Tjv0OS1R9jaaAV0Do {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  .u7F3Tjv0OS1R9jaaAV0Do ._1jnH7UeV8WGKvG8V3Uj9rT {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    background: #f0f0f0;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer;
    text-decoration: none;
    text-align: center; }
    .u7F3Tjv0OS1R9jaaAV0Do ._1jnH7UeV8WGKvG8V3Uj9rT:hover {
      border: 1px solid silver; }
  .u7F3Tjv0OS1R9jaaAV0Do ._1MjeOrJxYOVc1um2vTAtxX {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    .u7F3Tjv0OS1R9jaaAV0Do ._1MjeOrJxYOVc1um2vTAtxX:hover {
      background: #34a9e9;
      color: #fff; }

._2SkkYx1p5vO9oPR13J9Mqv {
  margin: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._2SkkYx1p5vO9oPR13J9Mqv ._3KbGJBik5287VWbhXN9lse,
  ._2SkkYx1p5vO9oPR13J9Mqv ._3Q11S5RM_9NeZpguoNWxim {
    margin: 1rem 0;
    text-align: center; }
