.yCzy6MfizrgMienqQCj1c {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

.yCzy6MfizrgMienqQCj1c {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .yCzy6MfizrgMienqQCj1c {
      font-size: 1.25rem;
      line-height: 1.8; } }

.tNWXYJ0Xw24-8ZyWejdxr {
  padding: 0 2rem 6rem; }
  @media (max-width: 991px) {
    .tNWXYJ0Xw24-8ZyWejdxr {
      padding: 0 2rem 3rem; } }

._1Ok_6mgZ6TjU7t3iF_OfpD {
  max-width: 1400px;
  margin: 0 auto; }

._3-xOSyOW2bYPMPkU5duviG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 5rem;
  gap: 5rem; }
  @media (max-width: 991px) {
    ._3-xOSyOW2bYPMPkU5duviG {
      grid-gap: 2rem;
      gap: 2rem; } }

.yCzy6MfizrgMienqQCj1c {
  color: #515a61;
  font-weight: 500;
  margin: 0; }

._153cc1PYMSVKfLiaWv8aj- {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 5rem;
  gap: 5rem;
  margin: 0;
  padding: 0; }
  @media (max-width: 991px) {
    ._153cc1PYMSVKfLiaWv8aj- {
      grid-gap: 2rem;
      gap: 2rem; } }

._3alENznBmMUMeD82yGgmRP {
  max-height: 100%;
  width: auto; }
