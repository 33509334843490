._1rLOJJFu33f-dYaDiX9lGR {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 1024px;
  overflow: auto;
  max-height: 80vh; }

._1olp-wo_Nb96H9jdM7INUj {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._1-yPs7Et2o3MROHp4ILr_d {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

._2kfs2jE9D1WFsAqWVf1Cv9 {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._2kfs2jE9D1WFsAqWVf1Cv9 ._3R7XwXHEsI0uhldtaf6b-v {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer; }
    ._2kfs2jE9D1WFsAqWVf1Cv9 ._3R7XwXHEsI0uhldtaf6b-v:hover {
      border: 1px solid silver; }
    ._2kfs2jE9D1WFsAqWVf1Cv9 ._3R7XwXHEsI0uhldtaf6b-v[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  ._2kfs2jE9D1WFsAqWVf1Cv9 ._3y4O0SFIheAipdwwdsiI9p {
    font-weight: 600;
    color: #1167f3;
    background-color: #318dbc1c;
    border: 1px solid #1167f3; }
    ._2kfs2jE9D1WFsAqWVf1Cv9 ._3y4O0SFIheAipdwwdsiI9p._1SlqwUEht2pGRPt35YoVEg {
      background: #fa18541c 0% 0% no-repeat padding-box;
      border: 1px solid #fa1854;
      color: #fa1854; }
  ._2kfs2jE9D1WFsAqWVf1Cv9 .m00uIk5lq8eirO_vRgufc {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._2kfs2jE9D1WFsAqWVf1Cv9 .m00uIk5lq8eirO_vRgufc:hover {
      background: #34a9e9;
      color: #fff; }

._3Mczc5j5z9r3JWujhOFBGk {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._2vfp_6dN79acVPQbSR7ayU {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._2qgUjqzmRRXrsHHAmS3sfx {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 10px 0; }

._8x-OWVP36xDBdT3jb0j9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 1.5rem; }
  ._8x-OWVP36xDBdT3jb0j9 ._3G9RYIWxacwcCX67B3Pttq {
    font-size: 14px;
    font-weight: 600;
    color: #051c26;
    margin-right: 0.5rem; }

._1ZGIzUDZDm80fEPJhPWSRU {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }
  ._1ZGIzUDZDm80fEPJhPWSRU ._3G9RYIWxacwcCX67B3Pttq {
    font-size: 16px;
    font-weight: 700;
    color: #2c2c2c;
    margin-right: 10px; }

._2mIGECC8VIfgB2iY5BQ7WY {
  color: #1167f3;
  padding: 5px;
  background-color: #318dbc1c;
  border: 1px solid #1167f3;
  border-radius: 3px;
  cursor: pointer; }
  ._2mIGECC8VIfgB2iY5BQ7WY:hover {
    background-color: rgba(28, 80, 107, 0.109804); }

._24XlJ95YOU-m9Z2cjBPm7P {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem; }

._1ENrzmd0YxOjlirQ3qEi3v {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: auto;
  border-collapse: collapse; }
  ._1ENrzmd0YxOjlirQ3qEi3v ._3IqSIE0DJtFiZ4DtaQwQP4 {
    font-size: 14px;
    color: #657881;
    border-bottom: 1px solid #758793;
    padding: 10px 20px;
    line-height: 2em; }
  ._1ENrzmd0YxOjlirQ3qEi3v ._3iIUTlWrFDruENP30WzfcT {
    color: #2c2c2c;
    font-weight: 700;
    padding-left: 40px;
    position: relative; }
    @media all and (min-width: 1100px) {
      ._1ENrzmd0YxOjlirQ3qEi3v ._3iIUTlWrFDruENP30WzfcT:before {
        content: '';
        background-color: #00b4d8;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        display: inline-block;
        left: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        position: absolute; }
      ._1ENrzmd0YxOjlirQ3qEi3v ._3Ln5MqXJ1G_zBzYqFxfF5L:before {
        background-color: #2892d0; }
      ._1ENrzmd0YxOjlirQ3qEi3v .MIOsfHCmfSrArj2DkMPWA:before {
        background-color: #00a69c; }
      ._1ENrzmd0YxOjlirQ3qEi3v ._2PtfJ9AspZVBo3n7Md4tSY:before, ._1ENrzmd0YxOjlirQ3qEi3v ._2o0z6lrox89QBiQpha8AXI:before {
        background-color: #f5371b; }
      ._1ENrzmd0YxOjlirQ3qEi3v ._1Ar2ZYApcfJBenAXYtgbv:before, ._1ENrzmd0YxOjlirQ3qEi3v ._2_Y01n2sgMDEhDP800ZKXI:before {
        background-color: #fbb703; } }
  ._1ENrzmd0YxOjlirQ3qEi3v .ZgKsdYCkMK0yYjAqxIu6O {
    padding: 10px;
    background: #e6eaf2;
    border: none;
    max-width: 50px; }
    ._1ENrzmd0YxOjlirQ3qEi3v .ZgKsdYCkMK0yYjAqxIu6O:invalid {
      color: #fa1854; }
    ._1ENrzmd0YxOjlirQ3qEi3v .ZgKsdYCkMK0yYjAqxIu6O[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }

._3ThVrB74Q0wDxtnLgJ508g {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
