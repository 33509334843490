._2iOh_0pUI82g3iIOPbmq88 {
  padding: 10px 0;
  border-top: 1px solid #eaeaea; }

.jkdfdHJh0jw8M-fR9sZg7 {
  color: #051c26;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
  position: relative; }

._1IKO1Xlo6Ehw2yfG7vpSWC {
  color: #657881;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  display: block;
  position: relative; }

._1-GCdGi_weBzB3lQlDF8wq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row; }

._2HGb4OlD_-2uO7rYrtMJ5M ._22uEOxZU26yaVzECKHDPaI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2.5rem; }
  ._2HGb4OlD_-2uO7rYrtMJ5M ._22uEOxZU26yaVzECKHDPaI span {
    margin-left: 70px;
    color: #4b4c4d;
    font-size: 16px; }

._2HGb4OlD_-2uO7rYrtMJ5M .OKVDCGM4Pqz2ej-T2UW_5 ._1ILRBvnThcrqgi-J2AMexF {
  margin: 1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20rem; }
  ._2HGb4OlD_-2uO7rYrtMJ5M .OKVDCGM4Pqz2ej-T2UW_5 ._1ILRBvnThcrqgi-J2AMexF .iR-roPXT9sxuoIoPHzkBG {
    margin: 0 1.25rem;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit; }
    ._2HGb4OlD_-2uO7rYrtMJ5M .OKVDCGM4Pqz2ej-T2UW_5 ._1ILRBvnThcrqgi-J2AMexF .iR-roPXT9sxuoIoPHzkBG ._17bwLZIXvTmfoNU-Bpqlxb {
      color: red; }

._2HGb4OlD_-2uO7rYrtMJ5M .gcpSRnJok9EZnUGkLYxvv {
  width: 18.75rem;
  height: 10.625rem;
  text-align: center;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin-bottom: 1rem; }

._2HGb4OlD_-2uO7rYrtMJ5M img {
  height: 100%; }

._1iM3kJxQxNNmkMZ8ERUrFS {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 2rem; }
  ._1iM3kJxQxNNmkMZ8ERUrFS ._3VT3S-PyrwTR2jFw8QLxPX span {
    padding-left: 1.5rem;
    color: #051c26;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem; }
