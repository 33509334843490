._2EFS2PwUMAesCqNJd1ZmK3 {
  width: 740px;
  height: 650px;
  max-width: 100%;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #22272b; }

._3T03wrImcWufgxi95ATRdr {
  display: none;
  width: 740px;
  height: 650px;
  max-width: 100%;
  max-height: 100%; }
  ._3T03wrImcWufgxi95ATRdr.aBcuOko4YitpMVvWHrRLM {
    display: block; }
