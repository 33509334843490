._2VYYNvIYK3hU4wWLSmJ86m {
  color: #b1b2b5;
  font-weight: 600; }
  ._2VYYNvIYK3hU4wWLSmJ86m.-nd54tEdpSUfgqz65TAkC {
    color: #1f8e0d; }

._3DZO8H3cC608VPR9f1Acoz {
  font-size: 14px;
  font-weight: 400;
  color: #657881;
  padding: 1rem;
  line-height: 2em;
  position: relative; }
  ._3DZO8H3cC608VPR9f1Acoz ._2Y8o-zHjPTadP1Fs7fzs-f {
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative; }
    ._3DZO8H3cC608VPR9f1Acoz ._2Y8o-zHjPTadP1Fs7fzs-f:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #2392d0;
      border-radius: 3px;
      display: inline-block;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }
  ._3DZO8H3cC608VPR9f1Acoz ._25cm-FyXGLFDgjh-ihvPQt {
    display: none; }
    ._3DZO8H3cC608VPR9f1Acoz ._25cm-FyXGLFDgjh-ihvPQt:checked + ._2Y8o-zHjPTadP1Fs7fzs-f:before {
      position: absolute;
      content: '\2713';
      background: #2392d0;
      color: #fff;
      height: 18px;
      width: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      display: block; }
    ._3DZO8H3cC608VPR9f1Acoz ._25cm-FyXGLFDgjh-ihvPQt[disabled] + ._2Y8o-zHjPTadP1Fs7fzs-f {
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
      cursor: not-allowed; }

._1tTIQkvBuu3So7sa9oT1Z6 {
  cursor: pointer;
  color: #ce2a29;
  font-weight: 500;
  background: none;
  border: none;
  padding: 0;
  margin: 0; }
  ._1tTIQkvBuu3So7sa9oT1Z6:hover {
    color: #ce2a29, 70%; }
  ._1tTIQkvBuu3So7sa9oT1Z6[disabled] {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    cursor: not-allowed; }

._2PIYxaHmJnEtra5tfA_t2G {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._1vyWhZJKqDXbCpxFT8aQfC {
  margin-left: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._1vyWhZJKqDXbCpxFT8aQfC .AHgTl5myjMnGcrm36Brm1 {
    height: 16px;
    width: auto;
    margin-right: 10px;
    display: inline; }

._1IivR66QfvvGpuQepCaQSR {
  font-style: italic; }
