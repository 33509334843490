._1KlYWqKL9zA8KxhBWKdvEA {
  background: #dff2ff;
  border: 1px solid #6fc4ff;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  color: #030b0d; }

._1PvLP4A1tUz53JQnlkkngA {
  color: #00b4d8;
  font-weight: 700;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._1PvLP4A1tUz53JQnlkkngA ._2hR5mrQ_p11LobSBTsfN7S {
    border-radius: 50%;
    background-color: #00b4d8;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 0.5rem; }

._1qL-OiLBNcGBP5D9KLIsWE {
  font-weight: 700;
  margin-bottom: 0.5rem; }

._128iHi3RtzyFMXltyKrLPc {
  padding: 10px;
  margin: 10px 0; }
