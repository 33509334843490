._2w6hRGUghgtk1jqkZl9hMJ {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._3FEjoc5U67admzra_QypOu {
  text-align: right;
  margin: 1rem; }
