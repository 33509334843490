.Qa1wRg9gbN40FfoKz5bF6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin: 2rem 0; }

.xxRIKzGgWEFyEkfhSH1W8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column; }
    .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._2cpVDZv4EDe765ffPiSol2 {
      font-size: 1.3rem;
      font-weight: 600;
      margin-bottom: 1.5rem; }
    .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._1zjdcIw8R32B1OhsO8IC7D {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column wrap;
              flex-flow: column wrap;
      margin-bottom: 1rem; }
      @media (max-width: 991px) {
        .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._1zjdcIw8R32B1OhsO8IC7D {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-flow: column;
                  flex-flow: column;
          -webkit-box-align: stretch;
              -ms-flex-align: stretch;
                  align-items: stretch; } }
      .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._1zjdcIw8R32B1OhsO8IC7D ._2KPbZkyCEXDEUJTCDUpavS {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        padding-top: 0.5rem; }
      .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._1zjdcIw8R32B1OhsO8IC7D ._2c1t-RsUEak58GG-FVgEG4 {
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        padding: 0.5rem;
        border: 1px solid #c7ccda;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        border-radius: 3px;
        margin-bottom: 0.5rem; }
        .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._1zjdcIw8R32B1OhsO8IC7D ._2c1t-RsUEak58GG-FVgEG4:focus {
          -webkit-box-shadow: -3px 5px 11px 1px rgba(229, 233, 241, 0.42);
                  box-shadow: -3px 5px 11px 1px rgba(229, 233, 241, 0.42);
          background-color: #fdfdfd;
          outline: none; }
        .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._1zjdcIw8R32B1OhsO8IC7D ._2c1t-RsUEak58GG-FVgEG4._1CHeF5NmU6AIb4RpllhS5P {
          border: 1px solid #00b4d8;
          color: #00b4d8; }
        .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._1zjdcIw8R32B1OhsO8IC7D ._2c1t-RsUEak58GG-FVgEG4.UjbwpUtGdBJsWT3bkaNkL {
          border: 1px solid #fa5050;
          color: #fa5050; }
    .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._15hVWPEuyKNBhnwPyvYDcc {
      color: #ce2a29; }
      .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._15hVWPEuyKNBhnwPyvYDcc.LOQcSCK31GphYR8y7aD8- {
        text-align: center; }
    .xxRIKzGgWEFyEkfhSH1W8 ._3bWoawHB_qgeVFNcVgNlbq ._1o8jchV-ie-pbsLEFUqhq8 {
      -ms-flex-item-align: end;
          align-self: flex-end;
      padding: 0.8rem 1.8rem; }
