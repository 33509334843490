._2uCN3rqOqfcj4bQS40IoDZ {
  -webkit-box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1);
          box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1); }

._2uCN3rqOqfcj4bQS40IoDZ {
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 2rem; }
  ._2IKcCcHmCWcc6k8vdXFvgE {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 2rem 2rem; }
  ._1j4v16yMGkYC0m0e7Fe92 {
    padding: 1rem 2rem;
    display: block;
    background: #00b4d8; }
    .cPC616bqzULwYlV9OOMGF {
      background: #2892d0; }
    ._2woQHgufLnzwwV4_W6TkNL {
      background: #00a69c; }
    ._3qHbpqDLBetAabaBbLgTmE, ._2BZMqLERxOI-QIgF1B8wW3 {
      background: #f5371b; }
    ._1tjgScn83xJ-bvoEivk6dk {
      background: #fbb703; }
  ._1j4v16yMGkYC0m0e7Fe92 ~ ._2IKcCcHmCWcc6k8vdXFvgE {
    padding: 1rem 2rem; }
  ._155OYGquaXt5QZ4HQA3W2N {
    margin-bottom: 0;
    font-size: 1.5rem;
    color: #fff; }
  .xxeuwGCaiuZXSdGKNVRhp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 300px; }
  .upgZEZCBkhSGfnMThMw4e {
    margin-bottom: 0;
    margin-left: 0.5rem; }
  .upgZEZCBkhSGfnMThMw4e, ._3L2dFdj49IBk37gZ-hAws3 {
    font-weight: 700; }
  ._3gW6uqt5-RyyTU7t5BE62N {
    color: #1db953; }
  ._3Ptme4TTfLhlduR87NAalF {
    margin-bottom: 0;
    font-size: 0.875rem; }
  ._1Hybk14aNZ_LMUGGUsczdg {
    display: inline-block;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: 700; }
    ._1Hybk14aNZ_LMUGGUsczdg:hover {
      text-decoration: underline;
      color: #2380b6; }
    ._1Hybk14aNZ_LMUGGUsczdg:active, ._1Hybk14aNZ_LMUGGUsczdg:focus {
      color: #217aae; }
  ._3O_JfZTejJZ18SHDHW1XnY {
    margin-left: auto; }
    ._3O_JfZTejJZ18SHDHW1XnY a:focus {
      color: #2892d0; }
  @media (max-width: 991px) {
    ._2IKcCcHmCWcc6k8vdXFvgE {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      padding: 2rem 2rem; }
    .xxeuwGCaiuZXSdGKNVRhp {
      margin-bottom: 1rem; }
    ._3O_JfZTejJZ18SHDHW1XnY {
      margin-top: 1rem;
      margin-left: 0; } }

._36ZZtjNzlwPXdYsTeOLUk7 {
  color: #ce2a29;
  text-align: center; }
