._2IWlqav5b6IIBJORWtSdad {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 0.625rem;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin: 0;
  cursor: pointer; }

.SlXUEOFi13Xo8MzimV7Se {
  color: #62737f;
  position: relative;
  padding-left: 1.563rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .SlXUEOFi13Xo8MzimV7Se:before {
    content: '';
    height: 18px;
    width: 18px;
    border: 1px solid #62737f;
    border-radius: 3px;
    display: block;
    position: absolute;
    left: 0; }

._3nWveu_VKY_Y59bZVTrcbc {
  width: 5px;
  top: 0;
  margin-left: 0.313rem;
  position: absolute;
  opacity: 0; }
  ._3nWveu_VKY_Y59bZVTrcbc:checked + span:before {
    content: '\2713';
    background: #00b4d8;
    border: 1px solid #00b4d8;
    color: #fff;
    text-align: center;
    line-height: 18px;
    font-size: 0.75rem; }
  ._3nWveu_VKY_Y59bZVTrcbc[disabled] + span:before {
    background: transparent;
    border: 1px solid #62737f;
    color: #62737f; }
