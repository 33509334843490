._3zYMOIVPIo9E51_TXzONsE {
  width: 100%;
  margin-bottom: 5rem; }

._1lwYJI_sdFC5QBnESfuj_S {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem; }
  @media (max-width: 991px) {
    ._1lwYJI_sdFC5QBnESfuj_S {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }

._20YedZ_LT9S95QJRBHi6dJ {
  font-weight: 600;
  line-height: 1.6;
  color: #051c26;
  margin: 0 0.5rem 0.5rem 0;
  font-size: 2rem; }
  @media (max-width: 991px) {
    ._20YedZ_LT9S95QJRBHi6dJ {
      font-size: 1.75rem;
      line-height: 1.6; } }

.nq7XXdgkqblc-CwjqiYpr {
  font-size: 0.75rem;
  color: #758793; }

._1aUo8y2lW_EUL9iDoJcbk3 {
  margin: 0; }

._1wy3mKnGlxomHTXJ7FxtGY {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin: 0 0.5rem 0.5rem 0; }

._2CvWQhNmVyOmdJFXAdh6O {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._2CvWQhNmVyOmdJFXAdh6O ._jmcuvWexk-s3toWeIHl7 {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #1167f3; }

._3uZoxGY-dzjPrjTEPyC58u {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._3PSJpnGxjVk8DCohHOEbgg {
  color: #fff;
  padding: 0.5rem 1rem;
  background-color: #2892d0;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 1rem; }
  ._3PSJpnGxjVk8DCohHOEbgg:hover {
    background: #2380b6; }
  ._3PSJpnGxjVk8DCohHOEbgg:active {
    background: #217aae; }
