._1pNkCw51FVvCcE1AvR0RM0 {
  position: relative;
  min-width: 200px; }
  ._1pNkCw51FVvCcE1AvR0RM0 .OCHF000s7vBdtYBHqGidJ {
    cursor: pointer;
    width: 1px; }
  ._1pNkCw51FVvCcE1AvR0RM0 ._1SWbaatp1Ac5Q503UVrDzV {
    font-size: 14px;
    color: #5c6066;
    font-weight: 600;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border: 1px solid #e6eaf2;
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  ._1pNkCw51FVvCcE1AvR0RM0 ._3zHW-cMIHTm1LHN15HgO5y {
    font-size: 14px;
    color: #5c6066;
    font-weight: 600;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    top: 30px;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid #f4f5f8;
    border-radius: 3px;
    z-index: 1;
    display: none; }
    @media all and (max-width: 660px) {
      ._1pNkCw51FVvCcE1AvR0RM0 ._3zHW-cMIHTm1LHN15HgO5y {
        width: 100%; } }
    ._1pNkCw51FVvCcE1AvR0RM0 ._3zHW-cMIHTm1LHN15HgO5y._2kyM4786hY89w_Su2qOH4x {
      display: block; }
    ._1pNkCw51FVvCcE1AvR0RM0 ._3zHW-cMIHTm1LHN15HgO5y li {
      color: #2c2c2c;
      padding: 10px 20px;
      cursor: pointer; }
      ._1pNkCw51FVvCcE1AvR0RM0 ._3zHW-cMIHTm1LHN15HgO5y li:hover {
        color: #1167f3; }

.OCHF000s7vBdtYBHqGidJ {
  opacity: 0;
  position: absolute; }

._2c9TIYGv9t3z-R7xBK7nYb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._2c9TIYGv9t3z-R7xBK7nYb:after {
    content: ' ';
    margin-left: 5px; }
  ._2c9TIYGv9t3z-R7xBK7nYb._30xxN3OOdHv4s8Vilqd7V4:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  ._2c9TIYGv9t3z-R7xBK7nYb._3uU_f14pfwphOsoAPm-Jei:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }
