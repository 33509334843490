._2z6lLr-89jg-TRy_JK71bc {
  padding: 0 2rem 6rem; }
  @media (max-width: 991px) {
    ._2z6lLr-89jg-TRy_JK71bc {
      padding: 0; } }
  ._2z6lLr-89jg-TRy_JK71bc h2 {
    color: #fff; }

._2TVxU4xqR947aAHoDIl1D1 {
  max-width: 1400px;
  margin: 0 auto;
  background-color: #161819; }

.O6aVhDl5S2_yEbOk41OyY {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 6rem 3rem;
  grid-gap: 4rem;
  gap: 4rem;
  text-align: center; }
  @media (max-width: 991px) {
    .O6aVhDl5S2_yEbOk41OyY {
      padding: 3rem 2rem;
      grid-gap: 2rem;
      gap: 2rem; } }

._1Gg43m6NAH9IoC7R2Ex9b8 {
  font-size: 1.6rem;
  line-height: 1.7;
  margin: 0; }
  @media (max-width: 991px) {
    ._1Gg43m6NAH9IoC7R2Ex9b8 {
      font-size: 1.4rem; } }

._1DvLtS5JU-_UbtIaa21eTz {
  max-width: 100%;
  height: auto; }
