._3_WkrOMNoj0e2yP9pLr7Xf {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative; }

._2RAUTCe-d2objB-2GH31GQ {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._1fP9xbFjDwcdEN-1CQLd1l {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

._1uxIoylIz1gyiMU7_B0OXa {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._1uxIoylIz1gyiMU7_B0OXa ._1VjkVqxuvkxqWictaXhmgN {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer; }
    ._1uxIoylIz1gyiMU7_B0OXa ._1VjkVqxuvkxqWictaXhmgN:hover {
      border: 1px solid silver; }
    ._1uxIoylIz1gyiMU7_B0OXa ._1VjkVqxuvkxqWictaXhmgN[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  ._1uxIoylIz1gyiMU7_B0OXa .LP3Igb-7nJQrFCBgYS2u3 {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._1uxIoylIz1gyiMU7_B0OXa .LP3Igb-7nJQrFCBgYS2u3:hover {
      background: #34a9e9;
      color: #fff; }

.ihgx7nHpn4uarGV97fT_e {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._2KlFpGdviiVLaFT0ktfFc5 {
  background: #f6f9fb;
  border: #f6f9fb;
  color: #758793;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  max-width: 100%;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5em; }

._3FvxtRA-Y8srC3qj5LzHs2 {
  display: none; }

._2oCkd2s1D5ORw9mlbmFd64 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border: 1px solid #e6eaf2;
  background: #e6eaf2;
  border-radius: 3px;
  font-size: 14px;
  color: #5c6066;
  padding: 10px;
  cursor: pointer; }
  ._2oCkd2s1D5ORw9mlbmFd64 ._1bWV72M2C3Tpk8D29T0O1d {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  ._2oCkd2s1D5ORw9mlbmFd64:hover {
    border: 1px solid silver; }

._1Sj9fenRxFq_TBiWo0S3MX {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiI+PHBhdGggZD0iTTYgMGEuNi42IDAgMDAtLjQyNC4xNzdMMi41IDMuMDgxbC0uMDA3LjAwNkEuMy4zIDAgMDAyLjcgMy42aDIuMXY0LjhhLjYuNiAwIDAwLjYuNmgxLjJhLjYuNiAwIDAwLjYtLjZWMy42aDIuMWEuMy4zIDAgMDAuMjEyLS41MTJsLS4wMTktLjAxOEw2LjQzLjE4MiA2LjQxLjE2M0EuNi42IDAgMDA2IDB6TS42IDEwLjhhLjYuNiAwIDEwMCAxLjJoMTAuOGEuNi42IDAgMTAwLTEuMnoiIGZpbGw9IiM1YzYwNjYiLz48L3N2Zz4=);
  width: 12px;
  height: 12px;
  margin-right: 10px; }

._31RCECibodkTmNZNDm7El_ {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }

._3TS49JTyIR8_gHb2nzvwFi {
  font-size: 14px;
  font-weight: 600;
  color: #051c26;
  margin-bottom: 10px; }

._2Eg8PbwV8XKbLBpCjTumCC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
