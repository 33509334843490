._3b-FThZRJCsS2B0VlesYMI {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; }
  ._3b-FThZRJCsS2B0VlesYMI:before {
    position: absolute;
    content: '';
    background: #848993 0% 0% no-repeat padding-box;
    opacity: 0.7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.F_D282fmLLDLMfJ1XLVSt {
  background: #fff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  max-width: 90%;
  position: relative; }
  .F_D282fmLLDLMfJ1XLVSt ._1VBL0xNVASNm5zRZhKiypw {
    color: #758793;
    position: absolute;
    right: 5px;
    top: 5px;
    border: none;
    background: transparent;
    outline: none;
    font-size: 1.875rem;
    cursor: pointer;
    z-index: 1; }
    .F_D282fmLLDLMfJ1XLVSt ._1VBL0xNVASNm5zRZhKiypw:hover {
      color: #5e6d77; }
    .F_D282fmLLDLMfJ1XLVSt ._1VBL0xNVASNm5zRZhKiypw:active {
      color: #505d66; }
