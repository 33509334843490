._2-zIIy_DaoSe_cQkNp4aP9 {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2-zIIy_DaoSe_cQkNp4aP9 {
      font-size: 2.5rem;
      line-height: 1.7; } }

._16X0milGVVv2wNx7nW9AG_ {
  padding: 5rem 2rem 0; }
  @media (max-width: 991px) {
    ._16X0milGVVv2wNx7nW9AG_ {
      padding: 2rem; } }

.vWST8tgxpzOl3nNs_gylA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 1400px;
  margin: auto; }

._1KfwOWV2BWupFgG2U5G9fA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #1f1e1f;
  border-radius: 10px;
  padding: 0 4rem; }
  @media (max-width: 991px) {
    ._1KfwOWV2BWupFgG2U5G9fA {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      padding: 1.5rem;
      border-radius: 0;
      margin: 0 -2rem; } }
  ._1KfwOWV2BWupFgG2U5G9fA._2uxAq1LO_-14i9fGxU9gE- {
    background-color: #00adef; }
  ._1KfwOWV2BWupFgG2U5G9fA._16KZOiYZR_ue7zHlPrgSfg {
    background-color: black; }

._2cPiOapjgOC5JPJIt8daLk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0 auto; }
  @media (max-width: 991px) {
    ._2cPiOapjgOC5JPJIt8daLk {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      grid-gap: 0;
      gap: 0; } }

._2-zIIy_DaoSe_cQkNp4aP9 {
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
  word-break: break-word; }

._3TjIDn3_BB9xXpPe-w3GVQ {
  font-size: 1.5rem;
  color: #fff;
  text-align: right;
  cursor: pointer; }
  @media (max-width: 991px) {
    ._3TjIDn3_BB9xXpPe-w3GVQ {
      text-align: center;
      font-size: 1.2rem; } }

.eA2BRIkgofrMh8EydkNLR {
  max-width: 100%;
  height: auto; }
