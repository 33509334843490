.INF6EQmttKtSjV3GCeIxu {
  padding: 0 3rem; }
  @media (max-width: 991px) {
    .INF6EQmttKtSjV3GCeIxu {
      padding: 0 1.25rem; } }

._3Jx1xQga61qskj3bcWcuhf {
  background: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px; }

._3pCSuR_6GZADwVL0GPne_6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  padding: 1rem 2rem;
  border-bottom: 1px solid #f4f4f4; }

._3rFvQjqLljKy5wpbU9ZR3- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  background: transparent;
  border: none;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  ._3rFvQjqLljKy5wpbU9ZR3- ._31ysGJpubahXrtiVd7aELE {
    width: 24px;
    margin-right: 1rem; }
  ._3rFvQjqLljKy5wpbU9ZR3- .-JgnZEFkX8S2sr0VxpEs2 {
    font-size: 1.1rem;
    font-weight: 600; }
  ._3rFvQjqLljKy5wpbU9ZR3- ._3OjtE8uj_R-GaheKFzWvdK {
    margin-left: auto;
    text-align: right; }

._3JuYNsxL0q4GXIFzKXd0Qr {
  background: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px;
  padding: 3rem; }

._2gnYrrSGnSR9QwWQxO20lv {
  padding: 1rem 2rem; }
  @media (max-width: 991px) {
    ._2gnYrrSGnSR9QwWQxO20lv {
      padding: 1rem 1.25rem; } }
