._35ZAAGsz0w_dIslryWNssr {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 750px;
  overflow: auto;
  max-height: 80vh; }

._3QYMew8mR3rsEaFtYfEKDl {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

.soYV78P-5bylBPBlh8LNn {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

._2F5dsyxDDJv97Q1BUlGSKb {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._2F5dsyxDDJv97Q1BUlGSKb .lBtQuwuOdZD_yoLBeHmw3 {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer; }
    ._2F5dsyxDDJv97Q1BUlGSKb .lBtQuwuOdZD_yoLBeHmw3:hover {
      border: 1px solid silver; }
    ._2F5dsyxDDJv97Q1BUlGSKb .lBtQuwuOdZD_yoLBeHmw3[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  ._2F5dsyxDDJv97Q1BUlGSKb ._2IvqQO7urz0OCITtd5kjEq {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._2F5dsyxDDJv97Q1BUlGSKb ._2IvqQO7urz0OCITtd5kjEq:hover {
      background: #34a9e9;
      color: #fff; }

._2CXsXfIsBPU4iPRHGz0vm3 {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._2ZRD2Wgvv8eJAFbccF7osW {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 10px 0; }

._1OJYVBLy6NrR2WUSXalN5R {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._1OJYVBLy6NrR2WUSXalN5R ._1Wg0B7yr8sC5wshGPU8LeD {
    font-size: 14px;
    font-weight: 600;
    color: #051c26;
    margin-right: 10px; }
  ._1OJYVBLy6NrR2WUSXalN5R ._1faYAhm0GXP6EsMERH-ILz {
    font-size: 14px;
    font-weight: 600;
    color: #1791d3;
    background: #f6f9fb;
    padding: 5px;
    border: none; }

._1rL6hrXu5PSwP33RreGX4a {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }
  ._1rL6hrXu5PSwP33RreGX4a ._1Wg0B7yr8sC5wshGPU8LeD {
    font-size: 16px;
    font-weight: 700;
    color: #2c2c2c;
    margin-right: 10px; }

._1VeogcKHG8Hn19JgjMh95s {
  color: #328ebd;
  padding: 5px;
  background-color: #318dbc1c;
  border: 1px solid #328ebd;
  border-radius: 3px;
  cursor: pointer; }
  ._1VeogcKHG8Hn19JgjMh95s:hover {
    background-color: rgba(28, 80, 107, 0.109804); }

._3XWM2TifP9A3BceskzRnVY {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem;
  width: 100%; }

.PAHTiglX0t8z9iYBklJ6C {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: auto;
  border-collapse: collapse; }
  .PAHTiglX0t8z9iYBklJ6C ._1rsf6QH1RNpaWkYPVGhsfG {
    font-size: 14px;
    color: #657881;
    border-bottom: 1px solid #758793;
    padding: 10px 20px;
    line-height: 2em; }
  .PAHTiglX0t8z9iYBklJ6C ._3S509Zy1v9hptIlcXPbKN5 {
    color: #2c2c2c;
    font-weight: 700;
    padding-left: 40px;
    position: relative; }
    @media all and (min-width: 1100px) {
      .PAHTiglX0t8z9iYBklJ6C ._3S509Zy1v9hptIlcXPbKN5:before {
        content: '';
        background: #1791d3 0% 0% no-repeat padding-box;
        border-radius: 4px;
        width: 15px;
        height: 15px;
        display: inline-block;
        left: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        position: absolute; }
      .PAHTiglX0t8z9iYBklJ6C ._1teiyuYSEiwNxsUXFrFPUf:before {
        background-color: #00a69c; }
      .PAHTiglX0t8z9iYBklJ6C ._3GMk76Lp1jnd52zIOet-C5:before {
        background-color: #f5371b; }
      .PAHTiglX0t8z9iYBklJ6C ._2Ii8ANrYW-bMuqoiAltm-s:before {
        background-color: #fbb703; } }
  .PAHTiglX0t8z9iYBklJ6C ._3E7hO4swKAhIPyxToRPleG {
    padding: 10px;
    background: #e6eaf2;
    border: none;
    max-width: 50px; }
    .PAHTiglX0t8z9iYBklJ6C ._3E7hO4swKAhIPyxToRPleG:invalid {
      color: #fa1854; }
    .PAHTiglX0t8z9iYBklJ6C ._3E7hO4swKAhIPyxToRPleG[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }

._3B4vVEZNuzTYZkpyvqKAdA {
  border-radius: 3px;
  background-color: #f6f9fb;
  color: #758793;
  padding: 10px;
  width: 100%; }
