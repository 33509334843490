._2EDH1AQG9SwD-KtII3Wdzg a {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3mK4dWMeiZP4WOKlcWvS3t {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3mK4dWMeiZP4WOKlcWvS3t {
      font-size: 2.5rem;
      line-height: 1.7; } }

._3imLUWx2l66_KiBMQEUG3v, ._3imLUWx2l66_KiBMQEUG3v ._2sbBhhyPQWO7TcW4T7y17f, ._2EDH1AQG9SwD-KtII3Wdzg a {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3imLUWx2l66_KiBMQEUG3v, ._3imLUWx2l66_KiBMQEUG3v ._2sbBhhyPQWO7TcW4T7y17f, ._2EDH1AQG9SwD-KtII3Wdzg a {
      font-size: 1.25rem;
      line-height: 1.8; } }

._3yy0BGqHsInFVJ-iRu0phb {
  background: #fff;
  padding: 6rem 2rem; }
  @media (max-width: 991px) {
    ._3yy0BGqHsInFVJ-iRu0phb {
      padding: 3rem 2rem; } }

._3wi8Z27jPgRkqzuiEMofbK {
  max-width: 1400px;
  margin: 0 auto; }

._3mK4dWMeiZP4WOKlcWvS3t {
  text-align: center;
  word-break: break-word;
  margin-bottom: 3rem; }
  @media (max-width: 991px) {
    ._3mK4dWMeiZP4WOKlcWvS3t {
      margin-bottom: 1rem; } }

.eOODBYMmDXuZQ2GwDz23h {
  display: grid;
  grid-gap: 4rem;
  gap: 4rem;
  grid-template-columns: 1fr 1.25fr;
  grid-template-rows: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media (max-width: 991px) {
    .eOODBYMmDXuZQ2GwDz23h {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      gap: 2rem; } }

.ieSRSLJT0DXvcgghr7Rh_ {
  list-style: none;
  padding: 0; }

._3imLUWx2l66_KiBMQEUG3v {
  margin-bottom: 0.5rem;
  padding: 1rem 2rem;
  border-radius: 80px;
  cursor: pointer;
  -webkit-transition: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  -webkit-transition-property: all;
  transition-property: all; }
  @media all and (max-width: 790px) {
    ._3imLUWx2l66_KiBMQEUG3v {
      border-radius: 15px; } }
  ._3imLUWx2l66_KiBMQEUG3v:last-child {
    margin: 0; }
  ._3imLUWx2l66_KiBMQEUG3v._1mG_1SpLjzr8LfoosoAkwW, ._3imLUWx2l66_KiBMQEUG3v:hover {
    background: #e5ebf0; }
  ._3imLUWx2l66_KiBMQEUG3v ._2sbBhhyPQWO7TcW4T7y17f {
    word-break: break-word;
    margin: 0; }

._1ArPbWEzpUUcw43jTHwESv {
  background-color: #e5ebf0;
  padding: 0 1rem;
  min-height: 455px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media all and (max-width: 480px) {
    ._1ArPbWEzpUUcw43jTHwESv {
      min-height: 300px; } }

._2U0-eeAQj2Zmys9qs3AlEI {
  display: none;
  max-width: 100%;
  height: auto; }
  ._2U0-eeAQj2Zmys9qs3AlEI._1mG_1SpLjzr8LfoosoAkwW {
    display: block; }

._2EDH1AQG9SwD-KtII3Wdzg {
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._2EDH1AQG9SwD-KtII3Wdzg a {
    text-align: center;
    border-radius: 80px;
    margin: 0;
    background: #1a70d4;
    background: linear-gradient(127deg, #1a70d4 13%, #2591cf 100%);
    word-break: break-word; }
    ._2EDH1AQG9SwD-KtII3Wdzg a:hover {
      background: linear-gradient(-127deg, #1a70d4 13%, #2591cf 100%); }
    ._2EDH1AQG9SwD-KtII3Wdzg a > div {
      padding: 0 2rem; }
