.ac7n5o8Tv1-gA1HU4t5dJ {
  margin: 2.813rem 3.75rem; }
  @media (max-width: 991px) {
    .ac7n5o8Tv1-gA1HU4t5dJ {
      margin: 1.5rem; } }
  ._33viFAiRgiczQyFMjwWB6i {
    font-size: 1.5rem;
    font-weight: 700;
    color: #051c26;
    display: block;
    margin-bottom: 0.313rem; }
  ._2xaUVkXj2n7nP8oVLx6t0k {
    display: block;
    margin-bottom: 1.25rem; }
  ._22CxwRzD06jWLT4dO0-uPg {
    color: #657881; }
    ._1Rx0NG2L9WXm0mfw1SzvG7 {
      font-weight: bold; }
    ._3zFqo1lPN6JrEcfh5FF2ey {
      color: #ce2a29; }
  ._3mFxgWS65xlMKiSEnHVUon {
    list-style: none;
    margin: 0;
    padding: 0; }
    ._3mFxgWS65xlMKiSEnHVUon li {
      margin-bottom: 1rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
          -ms-flex-align: baseline;
              align-items: baseline; }
      ._3mFxgWS65xlMKiSEnHVUon li span:before {
        margin-right: 0.5rem;
        color: #ce2a29;
        font-size: 0.8rem; }
  ._1vh9GMaQtfy_nxoStfbSQ3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (max-width: 991px) {
      ._1vh9GMaQtfy_nxoStfbSQ3 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-box-align: unset;
            -ms-flex-align: unset;
                align-items: unset; }
        ._1vh9GMaQtfy_nxoStfbSQ3 button {
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          padding: 1rem; }
        ._1vh9GMaQtfy_nxoStfbSQ3 button:last-child {
          margin-bottom: 1rem; } }
    ._1vh9GMaQtfy_nxoStfbSQ3 button:first-child {
      margin-right: 1.25rem; }
      @media (max-width: 991px) {
        ._1vh9GMaQtfy_nxoStfbSQ3 button:first-child {
          margin: 0; } }
  .ac7n5o8Tv1-gA1HU4t5dJ ._3CdGyGADsbSWPZ7c7EXwCz {
    background: transparent;
    border: none;
    color: #2392d0;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    margin-top: 1.25rem;
    width: 100%; }
    .ac7n5o8Tv1-gA1HU4t5dJ ._3CdGyGADsbSWPZ7c7EXwCz:hover {
      color: #46a9e0; }
