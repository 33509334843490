.JlZldH7TxBNffQPZj5VvC {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  overflow: auto; }

._2f5SHA3MKlAHaeDiSDSPlE {
  color: #657881;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: block; }

._3qfk50AaZZehoMMGNVd_Ig {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px; }

._3koyBRc1D0OYXgV5SeOGES {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-bottom: 5px; }
  @media all and (max-width: 660px) {
    ._3koyBRc1D0OYXgV5SeOGES {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  ._3koyBRc1D0OYXgV5SeOGES button,
  ._3koyBRc1D0OYXgV5SeOGES a {
    font-size: 14px;
    color: #ffffff;
    border: none;
    padding: 10px;
    background: linear-gradient(140deg, #3d9ac9, #318dbc);
    border-radius: 3px;
    margin: 0 5px 5px 0;
    cursor: pointer; }
    ._3koyBRc1D0OYXgV5SeOGES button:hover,
    ._3koyBRc1D0OYXgV5SeOGES a:hover {
      background-color: #34a9e9;
      color: #fff; }

._3gGDRTGQO_ChwBP937w7vu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  @media all and (max-width: 660px) {
    ._3gGDRTGQO_ChwBP937w7vu {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }

._3zBTr3Xz2OZiOo5ryQ00_8 {
  font-size: 14px;
  color: #5c6066;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: block;
  margin: 0 10px 5px 0; }
  ._3zBTr3Xz2OZiOo5ryQ00_8 input {
    display: none; }
  ._3zBTr3Xz2OZiOo5ryQ00_8 span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    ._3zBTr3Xz2OZiOo5ryQ00_8 span:after {
      content: ' ';
      width: 0px;
      height: 0px;
      margin-left: 5px; }
  ._3zBTr3Xz2OZiOo5ryQ00_8 input:checked + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  ._3zBTr3Xz2OZiOo5ryQ00_8 input:not(:checked) + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }

._1sqjS6a1Nw4lUB5w97MdJU {
  background: #f6f9fb 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 5px; }
  ._1sqjS6a1Nw4lUB5w97MdJU input {
    border: none;
    background: transparent;
    margin-left: 5px; }

._3a3XC5cP-cXRBJwKg5Pp-z {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem; }

._34-uYLL7Pa7QiOrYvedUvY {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse;
  border-collapse: collapse; }

._1yD8wyGOc1fmLsgrFvSE0L {
  padding: 20px 20px; }
