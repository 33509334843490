._1CJoEVje3Cv_rZA5D9B8GN {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  line-height: 2em;
  color: #758793;
  background: #f6f9fb 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: left;
  position: relative; }
  ._1CJoEVje3Cv_rZA5D9B8GN._3OlINnXtG51bKy9EZhsWlP {
    cursor: pointer; }
  ._1CJoEVje3Cv_rZA5D9B8GN._1orw5vc9xlu0vuenEiFItR:after {
    content: ' ';
    width: 0px;
    height: 0px;
    margin-left: 5px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  ._1CJoEVje3Cv_rZA5D9B8GN.qkJUkZH1W4ryunUCSc6VA:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  ._1CJoEVje3Cv_rZA5D9B8GN._1xAT2rw4g-I1h5T-4S4Nns:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }
  ._1CJoEVje3Cv_rZA5D9B8GN._2XIUEd91dfv6IBKTLmxnoS {
    width: 100px;
    max-width: 100%;
    line-height: 15px; }
  ._1CJoEVje3Cv_rZA5D9B8GN ._3nreKAysh8AEvUfKrt9b9s {
    margin-left: 0.3rem;
    font-size: 0.5rem;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #c0cbd2;
    color: #fff;
    line-height: 15px;
    padding: 0px 5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: help; }
