._1hyH_2h8dHwMVKdinjp5O3, ._1jcz5kzyiSAcXSYOVQidAV {
  font-family: 'Inter', system-ui, sans-serif; }

._1hyH_2h8dHwMVKdinjp5O3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 2rem; }

._3up6TGwmvZbAr-e1-77Dk- {
  margin-bottom: 1.25rem; }

._1jcz5kzyiSAcXSYOVQidAV {
  font-size: 1.5rem; }

._2-8q7Oi5jj9I8DNrYpzMry {
  width: 400px;
  max-width: 100%; }
  @media (max-width: 991px) {
    ._2-8q7Oi5jj9I8DNrYpzMry {
      width: auto; } }

._30wS3eNsVW0pmTaEsYqh7b {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._30wS3eNsVW0pmTaEsYqh7b :first-child {
    margin-right: 0.5rem; }

.lLiZlsUIHlV88egQmZ4L6 {
  background: transparent;
  border: none;
  color: #2392d0;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  margin: 1.25rem auto 0; }
  .lLiZlsUIHlV88egQmZ4L6:hover {
    color: #46a9e0; }

._2_YAi8UlsbCAJQkLb_buLb {
  margin: 1rem 0;
  background: rgba(37, 145, 207, 0.2);
  border: 1px solid #1161fb;
  color: #1161fb;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px; }

._3uq3O_5c_TCIxYco-JIEL7 {
  color: red;
  margin: 1rem 0 0; }

.qr6Z5fvk3UqS7BtGMzpqm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  .qr6Z5fvk3UqS7BtGMzpqm ._3rmpxp-BVCSN9OkAruYUTp {
    display: block;
    margin-right: 0.5rem;
    line-height: 1.4; }
    @media (max-width: 991px) {
      .qr6Z5fvk3UqS7BtGMzpqm ._3rmpxp-BVCSN9OkAruYUTp {
        margin-bottom: 0.5rem; } }
  .qr6Z5fvk3UqS7BtGMzpqm .f6FpXTwRVy9MXK1bV_97y {
    margin: 0;
    padding: 0;
    text-align: left;
    line-height: 1.4; }
    .qr6Z5fvk3UqS7BtGMzpqm .f6FpXTwRVy9MXK1bV_97y:hover {
      text-decoration: underline;
      font-weight: 600;
      color: #2380b6; }
    .qr6Z5fvk3UqS7BtGMzpqm .f6FpXTwRVy9MXK1bV_97y:active, .qr6Z5fvk3UqS7BtGMzpqm .f6FpXTwRVy9MXK1bV_97y:focus {
      color: #217aae; }
