._32wU4-y8NnFYUUwYKyiG4x {
  margin-bottom: 20px;
  overflow: auto;
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px; }

._2OnbYn0JqSnXa9u2EMQjjl {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }
  ._2OnbYn0JqSnXa9u2EMQjjl thead {
    background-color: transparent; }
  ._2OnbYn0JqSnXa9u2EMQjjl tr {
    border: none; }
    @media (max-width: 1240px) {
      ._2OnbYn0JqSnXa9u2EMQjjl tr {
        display: table;
        border-bottom: 1px solid #f4f4f4;
        width: 100%;
        margin-bottom: 1rem; }
        ._2OnbYn0JqSnXa9u2EMQjjl tr th {
          display: none; }
        ._2OnbYn0JqSnXa9u2EMQjjl tr td {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          ._2OnbYn0JqSnXa9u2EMQjjl tr td:last-child {
            padding-bottom: 1rem; } }
    @media (max-width: 1240px) and (max-width: 1240px) {
      ._2OnbYn0JqSnXa9u2EMQjjl tr td {
        padding: 0.5rem 1rem; } }

._2-0OKArZz70HAuPUnMt0e4 {
  color: #62737f; }
  ._2-0OKArZz70HAuPUnMt0e4.lGupIjHa9cpU7zqRh4Ef2 {
    color: #fa1854; }
  ._2-0OKArZz70HAuPUnMt0e4:hover {
    background-color: #f6f9fb; }

._3JFgPhr6PgxmRA09CUjAHR {
  padding: 1rem;
  color: #62737f; }
  ._3JFgPhr6PgxmRA09CUjAHR ._3j7srvPNM1-DuoR-Im4rKg {
    display: none;
    margin-left: 0.5rem; }
    @media (max-width: 1240px) {
      ._3JFgPhr6PgxmRA09CUjAHR ._3j7srvPNM1-DuoR-Im4rKg {
        display: block; } }

.kwihpO-8IghlItaP_EOhP {
  font-size: 1rem;
  font-weight: 600;
  position: relative; }
  .kwihpO-8IghlItaP_EOhP label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative; }
    .kwihpO-8IghlItaP_EOhP label:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #1167f3;
      border-radius: 3px;
      display: inline-block;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }
    .kwihpO-8IghlItaP_EOhP label ._24ubEINBwBUczGLw-f-AU3 {
      display: none; }
      .kwihpO-8IghlItaP_EOhP label ._24ubEINBwBUczGLw-f-AU3:checked + span:before {
        position: absolute;
        content: '\2713';
        background: #1167f3;
        color: #fff;
        height: 20px;
        width: 20px;
        text-align: center;
        line-height: 18px;
        font-size: 12px;
        display: inline-block;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        position: absolute;
        border-radius: 3px; }
    .kwihpO-8IghlItaP_EOhP label span {
      width: 100%;
      text-overflow: ellipsis; }
  .kwihpO-8IghlItaP_EOhP.OqGzbm-9yW79ZbiWNUiBc {
    color: #1167f3; }
  .kwihpO-8IghlItaP_EOhP ._3NiUijjfn8zySX-KKB93_A {
    color: #fa1854; }

.W8OKOTser5HQAhKVMdkcI {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer; }
  .W8OKOTser5HQAhKVMdkcI._2HlC2zj0Q-uuJB2dX7KxN2 {
    color: #fa1854;
    background: #fa18541c 0% 0% no-repeat padding-box;
    border: 1px solid #fa1854; }
    .W8OKOTser5HQAhKVMdkcI._2HlC2zj0Q-uuJB2dX7KxN2:hover {
      background-color: rgba(252, 124, 158, 0.109804); }
  .W8OKOTser5HQAhKVMdkcI[disabled], .W8OKOTser5HQAhKVMdkcI[disabled]:hover {
    background-color: #e6eaf2;
    color: #5c6066;
    border: 1px solid #e6eaf2; }

.aCmF7rn2shnCLxwS23oF2 {
  font-size: 0.8rem; }

._3ELKEtpPZUQ_zhat81O1M2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    ._3ELKEtpPZUQ_zhat81O1M2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
