._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .xAMeWZz0vf8DeIeEMVX-j ._1PtxTb928hMY4UZ79Ww9ZH {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._1cRffKsf5bF9IsQ0WOG5T- {
  position: relative;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: calc(100vh - 80px);
  padding: 4rem 2rem; }
  @media all and (min-width: 1920px) {
    ._1cRffKsf5bF9IsQ0WOG5T- {
      padding: 0 2rem; } }
  @media all and (min-width: 2000px) {
    ._1cRffKsf5bF9IsQ0WOG5T- {
      padding: 4rem 2rem;
      min-height: unset; } }
  ._1cRffKsf5bF9IsQ0WOG5T- ._2hxlOwdYiiRo2L5JRW2wWk {
    color: #030b0d;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center; }
    @media all and (min-width: 802px) {
      ._1cRffKsf5bF9IsQ0WOG5T- ._2hxlOwdYiiRo2L5JRW2wWk {
        font-size: 1.8rem; } }
    @media all and (min-width: 1920px) {
      ._1cRffKsf5bF9IsQ0WOG5T- ._2hxlOwdYiiRo2L5JRW2wWk {
        font-size: 2rem; } }
  ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, auto);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    list-style: none;
    max-width: 1245px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0; }
    @media all and (min-width: 1045px) {
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ {
        grid-template-columns: repeat(3, auto);
        grid-gap: 2rem; } }
    ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp {
      background: #f8fafe;
      border-radius: 10px;
      padding: 2rem;
      color: #030b0d;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      visibility: hidden;
      -webkit-animation-name: fLDScBNfiACfr1U2OtsVu;
              animation-name: fLDScBNfiACfr1U2OtsVu;
      text-align: center; }
      @media all and (min-width: 1045px) {
        ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp {
          text-align: left; } }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp:hover {
        margin-top: -0.313rem;
        margin-bottom: 0.313rem;
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease; }

@-webkit-keyframes _25--0nemSJVzJLqOD7TwIq {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes _25--0nemSJVzJLqOD7TwIq {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp.Dzy_TB4t935PUqvJkTyGx:nth-of-type(1) {
        -webkit-animation-name: _25--0nemSJVzJLqOD7TwIq;
                animation-name: _25--0nemSJVzJLqOD7TwIq;
        -webkit-animation-delay: 0ms;
                animation-delay: 0ms;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        visibility: visible;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both; }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp.Dzy_TB4t935PUqvJkTyGx:nth-of-type(2) {
        -webkit-animation-name: _25--0nemSJVzJLqOD7TwIq;
                animation-name: _25--0nemSJVzJLqOD7TwIq;
        -webkit-animation-delay: 200ms;
                animation-delay: 200ms;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        visibility: visible;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both; }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp.Dzy_TB4t935PUqvJkTyGx:nth-of-type(3) {
        -webkit-animation-name: _25--0nemSJVzJLqOD7TwIq;
                animation-name: _25--0nemSJVzJLqOD7TwIq;
        -webkit-animation-delay: 400ms;
                animation-delay: 400ms;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        visibility: visible;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both; }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp.Dzy_TB4t935PUqvJkTyGx:nth-of-type(4) {
        -webkit-animation-name: _25--0nemSJVzJLqOD7TwIq;
                animation-name: _25--0nemSJVzJLqOD7TwIq;
        -webkit-animation-delay: 600ms;
                animation-delay: 600ms;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        visibility: visible;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both; }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp.Dzy_TB4t935PUqvJkTyGx:nth-of-type(5) {
        -webkit-animation-name: _25--0nemSJVzJLqOD7TwIq;
                animation-name: _25--0nemSJVzJLqOD7TwIq;
        -webkit-animation-delay: 800ms;
                animation-delay: 800ms;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        visibility: visible;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both; }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .Oz-BqOWf4SCNI7kZJEgrp.Dzy_TB4t935PUqvJkTyGx:nth-of-type(6) {
        -webkit-animation-name: _25--0nemSJVzJLqOD7TwIq;
                animation-name: _25--0nemSJVzJLqOD7TwIq;
        -webkit-animation-delay: 1000ms;
                animation-delay: 1000ms;
        -webkit-animation-duration: 1s;
                animation-duration: 1s;
        visibility: visible;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both; }
    ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .xAMeWZz0vf8DeIeEMVX-j {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-weight: 700;
      margin-bottom: 1rem;
      grid-gap: 1rem;
      gap: 1rem; }
      @media all and (min-width: 1045px) {
        ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .xAMeWZz0vf8DeIeEMVX-j {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row;
                  flex-flow: row; } }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .xAMeWZz0vf8DeIeEMVX-j .A3dMX3NBcD85Bkb_d0HB5 {
        max-width: 80px; }
      ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .xAMeWZz0vf8DeIeEMVX-j ._1PtxTb928hMY4UZ79Ww9ZH {
        font-size: 1.1rem;
        margin-bottom: 0; }
        @media all and (min-width: 802px) {
          ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ .xAMeWZz0vf8DeIeEMVX-j ._1PtxTb928hMY4UZ79Ww9ZH {
            font-size: 1.3rem; } }
    ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ ._19ifI0Ms5p1EeDs1_K3yg3 {
      font-size: 1rem;
      line-height: 1.8;
      margin: 0; }
      @media all and (min-width: 802px) {
        ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ ._19ifI0Ms5p1EeDs1_K3yg3 {
          font-size: 1.1rem; } }
      @media all and (min-width: 1920px) {
        ._1cRffKsf5bF9IsQ0WOG5T- ._2-HNBDwm4h4pEKBPLGM7c_ ._19ifI0Ms5p1EeDs1_K3yg3 {
          font-size: 1.15rem; } }
