._3BsCgBuHe417lCD9_V5cvo {
  padding-bottom: 3rem;
  color: #2c2c2c;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A {
    border-collapse: collapse;
    color: #62737f; }
    ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A thead {
      background-color: transparent; }
    ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A tbody {
      border-top: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4; }
    ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A tr {
      border: none; }
      ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A tr td:last-child {
        text-align: right; }
      @media (max-width: 767px) {
        ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A tr {
          display: table;
          border-bottom: 1px solid #f4f4f4;
          width: 100%; }
          ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A tr th {
            display: none; }
          ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A tr td {
            display: block;
            padding: 0.5rem; } }
    @media (max-width: 767px) and (max-width: 991px) {
      ._3BsCgBuHe417lCD9_V5cvo .VMIdS_HW6ve6FOVIaC08A tr td {
        padding: 0.5rem 1rem; } }
  ._15KDYSxk4nI-0CZWRvAYOv {
    opacity: 0.45; }
  ._2d3yK7qwn2hE3SsJlnTjPB {
    font-weight: 700; }
  ._3xFHcopSNL-ocZRMgbdO-m {
    font-weight: 500;
    color: #1167f3; }
  ._3BQibxOXEsklq-3MZmWUHa {
    border-radius: 20px;
    padding: 0.5rem 1rem;
    font-weight: 500;
    opacity: 0.75;
    min-width: 70%;
    display: block;
    text-align: center;
    background-color: #eff0f5;
    font-weight: bold; }
  ._1npBJrb2fIrnk4DNi1OFi {
    margin-top: 1.25rem;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  ._1lF4dq9T9LcKtfE8Pyj9u0 {
    padding: 1.875rem; }

._3R4i-GIafs2eiN_Fj9zX40 {
  color: #ce2a29;
  text-align: center; }

.kyWYQ0WIZCQBk_VaBzqkZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    .kyWYQ0WIZCQBk_VaBzqkZ {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
