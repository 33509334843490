._1KZfEuWcSFxDS-jXvjLdk0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  ._1KZfEuWcSFxDS-jXvjLdk0 ._30vmoypKUSxBMGQBnrJL24 {
    color: #fff;
    background: #2892d0;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    display: block;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto; }
    ._1KZfEuWcSFxDS-jXvjLdk0 ._30vmoypKUSxBMGQBnrJL24:hover {
      background: #2380b6; }
    ._1KZfEuWcSFxDS-jXvjLdk0 ._30vmoypKUSxBMGQBnrJL24:active {
      background: #217aae; }
    ._1KZfEuWcSFxDS-jXvjLdk0 ._30vmoypKUSxBMGQBnrJL24[disabled] {
      -webkit-filter: grayscale(1);
              filter: grayscale(1); }

._17709AOpQ00J7Qzm_60Tyx ._1rLuqaK1V2__5FWzHjLiQP {
  padding: 3rem 2rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative; }

._17709AOpQ00J7Qzm_60Tyx ._2A9lxnjirvqt8X2Rnye_0G {
  font-size: 1.5rem;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 0.625rem; }

._17709AOpQ00J7Qzm_60Tyx ._2fWLLA9L92JAZRl5ST0Hw {
  font-size: 0.875rem;
  color: #657881;
  margin-bottom: 0.625rem; }
  ._17709AOpQ00J7Qzm_60Tyx ._2fWLLA9L92JAZRl5ST0Hw ul li {
    margin-bottom: 0.5rem; }
    ._17709AOpQ00J7Qzm_60Tyx ._2fWLLA9L92JAZRl5ST0Hw ul li strong {
      font-weight: 600; }

._17709AOpQ00J7Qzm_60Tyx .in6Tazl0vhZLmKfQHUq1n {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 1rem 2rem; }
  ._17709AOpQ00J7Qzm_60Tyx .in6Tazl0vhZLmKfQHUq1n ._1KZfEuWcSFxDS-jXvjLdk0 {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 0.875rem;
    color: #858585;
    padding: 0.625rem;
    margin: 0.625rem;
    min-width: 100px;
    cursor: pointer;
    display: block; }
    ._17709AOpQ00J7Qzm_60Tyx .in6Tazl0vhZLmKfQHUq1n ._1KZfEuWcSFxDS-jXvjLdk0:hover {
      background: #f0f0f0; }
    ._17709AOpQ00J7Qzm_60Tyx .in6Tazl0vhZLmKfQHUq1n ._1KZfEuWcSFxDS-jXvjLdk0[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  ._17709AOpQ00J7Qzm_60Tyx .in6Tazl0vhZLmKfQHUq1n .CIqGdkzZ1l7DzBljmiicW {
    background: #2892d0 0% 0% no-repeat padding-box;
    border-color: #2892d0;
    color: #fff; }
    ._17709AOpQ00J7Qzm_60Tyx .in6Tazl0vhZLmKfQHUq1n .CIqGdkzZ1l7DzBljmiicW:hover {
      background: #2380b6; }
    ._17709AOpQ00J7Qzm_60Tyx .in6Tazl0vhZLmKfQHUq1n .CIqGdkzZ1l7DzBljmiicW:active {
      background: #217aae; }

._17709AOpQ00J7Qzm_60Tyx ._2JyIm3fucq1O92E1RUoMla {
  margin: 0.625rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._17709AOpQ00J7Qzm_60Tyx ._2ifKLLQwa-QOtQ6VEGt_6M {
  background: #f6f9fb;
  border: #f6f9fb;
  color: #758793;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  max-width: 100%;
  width: 100%;
  padding: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5em; }
  ._17709AOpQ00J7Qzm_60Tyx ._2ifKLLQwa-QOtQ6VEGt_6M:focus {
    outline: none; }

._17709AOpQ00J7Qzm_60Tyx ._1bdVpQJghSW8Io4gDwjHor {
  display: none; }

._17709AOpQ00J7Qzm_60Tyx ._2VmCWEihZZtIm5K8N_3u3q {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border: 1px solid #e6eaf2;
  background: #e6eaf2;
  border-radius: 3px;
  font-size: 0.875rem;
  color: #5c6066;
  padding: 0.625rem;
  cursor: pointer; }
  ._17709AOpQ00J7Qzm_60Tyx ._2VmCWEihZZtIm5K8N_3u3q ._3_kVglgO4XgzdL8FoKtgce {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  ._17709AOpQ00J7Qzm_60Tyx ._2VmCWEihZZtIm5K8N_3u3q:hover {
    border: 1px solid silver; }

._17709AOpQ00J7Qzm_60Tyx .fqv6FSz3vA3Oq7j6YH9sy {
  width: 12px;
  height: 12px;
  margin-right: 0.625rem; }

._17709AOpQ00J7Qzm_60Tyx ._36f6GZYb7PzAdO5NbBbeyb {
  color: #fa1854;
  font-size: 0.875rem;
  display: block;
  margin: 0.313rem 0; }

._17709AOpQ00J7Qzm_60Tyx .W-bmYU8r6r-_RTHeQaMFd {
  font-size: 0.875rem;
  font-weight: 600;
  color: #051c26;
  margin-bottom: 0.625rem; }

._17709AOpQ00J7Qzm_60Tyx ._3UJn-4JKHRvbQv41ltxI7R {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
