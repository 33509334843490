._2nOxI580a76bLHlyte_ZgN {
  width: 650px;
  max-width: 90%;
  margin: 10rem auto; }

._1LEcNlIscVRYuR4BSZwyaI {
  font-size: 2rem; }

._28hKvOYhrvMXmGGKvxyWUv {
  font-size: 1rem;
  line-height: 1.3rem;
  color: #4B4C4D; }

.sfOLgFY6ulWqO9DF41dIM {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  background-color: #00b4d8;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  padding: 0.85rem;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 300px;
  margin: 2rem auto; }
  .sfOLgFY6ulWqO9DF41dIM:hover {
    color: #fff;
    background-color: #009bb9; }
  .sfOLgFY6ulWqO9DF41dIM:active, .sfOLgFY6ulWqO9DF41dIM:focus {
    color: #fff;
    background-color: #0092af; }
