.nJ5nmiWKwSzSNWq3Koer- {
  font-size: 1rem;
  color: #858585;
  line-height: 1.5rem;
  margin: 1rem 0;
  display: block; }

._1X3PHIReMgtH2tH23nCXBW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 1rem 0; }

.MEDT_xDXWqxKMyahzD--O {
  width: 420px;
  height: 240px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MjggMjQwLjAwMSI+PHBhdGggZD0iTTQyOCAyNDBIMzAyLjIxNGMtMTcuNzg1LTQxLjEyMi01MS40LTY2LjY2Ny04Ny43MTQtNjYuNjY3UzE0NC41NzEgMTk4Ljg3OSAxMjYuNzg2IDI0MEgwVjBoNDI4djI0MHpNMjEzLjkwOSA0M2MtMzQuMjI4IDAtNjIuMDc0IDI2LjkyNS02Mi4wNzQgNjAuMDIxczI3Ljg0NiA2MC4wMjMgNjIuMDc0IDYwLjAyMyA2Mi4wNzMtMjYuOTI2IDYyLjA3My02MC4wMjNTMjQ4LjEzNiA0MyAyMTMuOTA5IDQzeiIgZmlsbD0iI2U2ZWFmMiIvPjwvc3ZnPg==);
  background-size: cover;
  margin: 0 1rem 0 0;
  padding: 0;
  position: relative;
  overflow: hidden; }
  .MEDT_xDXWqxKMyahzD--O ._2_WveWBS_AsNngVGCe9HaV {
    -webkit-filter: 'none';
            filter: 'none';
    position: absolute;
    height: auto; }
    .MEDT_xDXWqxKMyahzD--O ._2_WveWBS_AsNngVGCe9HaV._2GctgnzDXdnvFwBYob7uYS {
      display: none; }
  .MEDT_xDXWqxKMyahzD--O .nJ5nmiWKwSzSNWq3Koer- {
    position: absolute;
    bottom: 0;
    background: rgba(5, 5, 5, 0.4);
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .MEDT_xDXWqxKMyahzD--O .nJ5nmiWKwSzSNWq3Koer- p {
      color: #fff;
      margin: 0;
      padding: 0.75rem 0; }
    .MEDT_xDXWqxKMyahzD--O .nJ5nmiWKwSzSNWq3Koer- img {
      margin: auto 1rem auto 1.25rem; }

._2vcC5Nk0xQHWXXImaqftUz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._2vcC5Nk0xQHWXXImaqftUz ._1CXBIZlA9rxorra2NMYcsq {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 0.5rem; }
    ._2vcC5Nk0xQHWXXImaqftUz ._1CXBIZlA9rxorra2NMYcsq.cFwrHBVIY0-jkySV72xRp {
      margin-bottom: 1rem; }
  ._2vcC5Nk0xQHWXXImaqftUz .dzWa2DGSlazONWGnELJvi {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
    margin-right: 1rem; }
  ._2vcC5Nk0xQHWXXImaqftUz ._3sy5dCOdtPVShY0uHhjQML {
    margin-right: 10px;
    color: #051c26;
    font-weight: 600; }
  ._2vcC5Nk0xQHWXXImaqftUz ._24JmNu18RqVmL18Wk1IQ7q {
    display: block;
    width: 60px;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border: none;
    color: #767676;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 10px;
    margin-bottom: 5px;
    outline: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

._2GpZTXwKVz9h7gOP4sPw63 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -ms-flex-item-align: end;
      align-self: flex-end; }

._22fh_aN_wEYnrSp50x2WgX {
  margin-left: 5px;
  cursor: pointer;
  padding: 1px; }
  ._22fh_aN_wEYnrSp50x2WgX._14KBH0dws90p73QQNrcxqb, ._22fh_aN_wEYnrSp50x2WgX:hover {
    background-color: #1791d3; }
  ._22fh_aN_wEYnrSp50x2WgX ._1bxwDB8NZAdW8iZy1t4KEF {
    display: none; }
  ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV {
    width: 22px;
    height: 20px;
    display: block; }
    ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._1cctr2MeKwFNgZRFfhbiYu {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNWM2MDY2Ij48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTMgMTAuMzc1VjNoNy4zNzVhMS42MjUgMS42MjUgMCAxMTAgMy4yNUg2LjI1djQuMTI1YTEuNjI1IDEuNjI1IDAgMTEtMy4yNSAweiIgZmlsbD0iIzVjNjA2NiIvPjwvc3ZnPg==); }
      ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._1cctr2MeKwFNgZRFfhbiYu._14KBH0dws90p73QQNrcxqb, ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._1cctr2MeKwFNgZRFfhbiYu:hover {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIj48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTMgMTAuMzc1VjNoNy4zNzVhMS42MjUgMS42MjUgMCAxMTAgMy4yNUg2LjI1djQuMTI1YTEuNjI1IDEuNjI1IDAgMTEtMy4yNSAweiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==); }
    ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._2z7Xm4Bp0UqOnx3F0kKyWF {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNWM2MDY2Ij48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTE5IDN2Ny4zNzVhMS42MjUgMS42MjUgMCAxMS0zLjI1IDBWNi4yNWgtNC4xMjVhMS42MjUgMS42MjUgMCAxMTAtMy4yNXoiIGZpbGw9IiM1YzYwNjYiLz48L3N2Zz4=); }
      ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._2z7Xm4Bp0UqOnx3F0kKyWF._14KBH0dws90p73QQNrcxqb, ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._2z7Xm4Bp0UqOnx3F0kKyWF:hover {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIj48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTE5IDN2Ny4zNzVhMS42MjUgMS42MjUgMCAxMS0zLjI1IDBWNi4yNWgtNC4xMjVhMS42MjUgMS42MjUgMCAxMTAtMy4yNXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=); }
    ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV.nBatx9-18WeRMNZ6zgzS9 {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNWM2MDY2Ij48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTMgMTdWOS42MjVhMS42MjUgMS42MjUgMCAxMTMuMjUgMHY0LjEyNWg0LjEyNWExLjYyNSAxLjYyNSAwIDExMCAzLjI1eiIgZmlsbD0iIzVjNjA2NiIvPjwvc3ZnPg==); }
      ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV.nBatx9-18WeRMNZ6zgzS9._14KBH0dws90p73QQNrcxqb, ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV.nBatx9-18WeRMNZ6zgzS9:hover {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIj48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTMgMTdWOS42MjVhMS42MjUgMS42MjUgMCAxMTMuMjUgMHY0LjEyNWg0LjEyNWExLjYyNSAxLjYyNSAwIDExMCAzLjI1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==); }
    ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._3oLk12MVI7ORCVsFxYKz1s {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNWM2MDY2Ij48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTE5IDkuNjI1VjE3aC03LjM3NWExLjYyNSAxLjYyNSAwIDExMC0zLjI1aDQuMTI1VjkuNjI1YTEuNjI1IDEuNjI1IDAgMTEzLjI1IDB6IiBmaWxsPSIjNWM2MDY2Ii8+PC9zdmc+); }
      ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._3oLk12MVI7ORCVsFxYKz1s._14KBH0dws90p73QQNrcxqb, ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._3oLk12MVI7ORCVsFxYKz1s:hover {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIj48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTE5IDkuNjI1VjE3aC03LjM3NWExLjYyNSAxLjYyNSAwIDExMC0zLjI1aDQuMTI1VjkuNjI1YTEuNjI1IDEuNjI1IDAgMTEzLjI1IDB6IiBmaWxsPSIjZmZmIi8+PC9zdmc+); }
    ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._2G2oFQvvRRg7KAQm9yD7eO {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNWM2MDY2Ij48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTYgOS42MjVBMS42MjUgMS42MjUgMCAwMTcuNjI1IDhoNi40YTEuNjI1IDEuNjI1IDAgMDEwIDMuMjVoLTYuNEExLjYyNSAxLjYyNSAwIDAxNiA5LjYyNXoiIGZpbGw9IiM1YzYwNjYiLz48L3N2Zz4=); }
      ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._2G2oFQvvRRg7KAQm9yD7eO._14KBH0dws90p73QQNrcxqb, ._22fh_aN_wEYnrSp50x2WgX ._2_WveWBS_AsNngVGCe9HaV._2G2oFQvvRRg7KAQm9yD7eO:hover {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMCI+PGcgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIj48cGF0aCBkPSJNMCAwaDIydjIwSDB6IiBzdHJva2U9Im5vbmUiLz48cGF0aCBkPSJNLjUuNWgyMXYxOUguNXoiLz48L2c+PHBhdGggZD0iTTYgOS42MjVBMS42MjUgMS42MjUgMCAwMTcuNjI1IDhoNi40YTEuNjI1IDEuNjI1IDAgMDEwIDMuMjVoLTYuNEExLjYyNSAxLjYyNSAwIDAxNiA5LjYyNXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=); }
  ._22fh_aN_wEYnrSp50x2WgX ._1bxwDB8NZAdW8iZy1t4KEF:checked + ._2_WveWBS_AsNngVGCe9HaV {
    background-color: #1791d3; }

.wPnPn_AlJJFBhVFiiG-G ._24JmNu18RqVmL18Wk1IQ7q:invalid {
  border: 1px solid #ce2a29; }

._2yRAY3VDP8aZlxMHaw9Q9J {
  -ms-flex-item-align: end;
      align-self: flex-end; }
