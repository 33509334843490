._1I3HjKG7Z-jgVRppUcVV_8 {
  padding: 3rem 3rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 1024px;
  overflow: auto;
  max-height: 80vh; }

._1mmXRioJnmBGmjxlvrGzeT {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

.EQn-l9H0fj0WuP9JPw0BE {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

._3q-Uws3iBXUXVxD-2ZYxH5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._3q-Uws3iBXUXVxD-2ZYxH5 ._3-16il4kWCmTPPCMcT-w9l._2LGvOtOrQNC6jJvKNK-wAi {
    color: #fa1854;
    background: #fa18541c 0% 0% no-repeat padding-box;
    border: 1px solid #fa1854;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }

._2VLEwFASlU8TN7eQsS_G5Q {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._hZ9O_6OB-71XsmmwVSwv {
  margin-right: 0.5rem; }

._2hw0GrYNtyByeBfUT5zSrT {
  max-height: 50vh;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 2rem 0; }

._3ss2lBqylFQ2o2xVvwzjhq {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }

.N-cUR234WlcJV66WWMnW5 ._14HaWLBPyo8KkRwYeZ_i7v {
  color: #2c2c2c;
  font-size: 0.9rem;
  font-weight: 600;
  margin-right: 0.5rem; }

.N-cUR234WlcJV66WWMnW5 ._2q3Ki79UepoPwnQl8vOM5F {
  color: #758793;
  font-size: 0.8rem;
  border: none;
  cursor: pointer; }

._17i7OujnD1LypF2JEOW3an {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._17i7OujnD1LypF2JEOW3an ._3-16il4kWCmTPPCMcT-w9l:first-child {
    margin-right: 0.5rem; }
