._2x_bwJSRBe0j86EstTDkBt {
  width: 90vw;
  -webkit-transform: translate(5%, 0);
          transform: translate(5%, 0);
  color: #030b0d; }
  ._192u4t_CmeT4QV4zM6JMfu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    grid-gap: 1rem;
    gap: 1rem;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media all and (min-width: 801px) {
      ._192u4t_CmeT4QV4zM6JMfu {
        text-align: left;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row; } }
    ._192u4t_CmeT4QV4zM6JMfu img {
      margin-left: 1rem; }
