.tJtpaAHZtScUlaEY362hU {
  color: #657881;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: block; }

._3F18Jr-Nm6W8cw65nAEiHF {
  color: #657881;
  font-size: 0.8rem;
  display: block;
  line-height: 1.2rem; }

._1VxFyW8ENDuDpQzTiaqjXm {
  color: #657881;
  font-size: 0.8rem;
  font-weight: bold; }

._3E6H5XAA9-Ue6QwcfWSgKV {
  font-size: 1.2rem;
  font-weight: 600;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  margin-bottom: 0.5rem;
  display: block; }

._1IMN4z9PJrj0PJftu5YE7d {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }

._1P8blULH7CNpHGpc8IVtQ4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-bottom: 1rem;
  width: 650px;
  max-width: 100%; }
  ._1P8blULH7CNpHGpc8IVtQ4 .Nj-NFm7PK31hzEjt0Zvxc {
    margin-right: 0.5rem;
    margin-top: 3.125rem; }

._3WDS5ksXVjiihqggNPflsb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  border: 1px solid #e6e6e6;
  margin-right: 2rem; }
  @media (max-width: 991px) {
    ._3WDS5ksXVjiihqggNPflsb {
      margin: 0; } }
  ._3WDS5ksXVjiihqggNPflsb .rrdeB-8LyJj9zzTFN9JgT {
    padding: 0.938rem;
    min-height: 70px;
    cursor: pointer; }
  ._3WDS5ksXVjiihqggNPflsb .p-AYb2PqvR6xD8KQOcgmC {
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block; }
  ._3WDS5ksXVjiihqggNPflsb .tJtpaAHZtScUlaEY362hU {
    line-height: 1.5rem;
    margin: 0; }

._1WQOnTsgIj3fOzFgeLBsTe {
  padding: 0.6rem 2rem;
  font-size: 1rem; }
  @media (max-width: 991px) {
    ._1WQOnTsgIj3fOzFgeLBsTe {
      width: 100%;
      display: block;
      text-align: center; } }

.LDyBtiFe_T65aO5wPaRGh {
  width: 100%;
  border-top: 1px solid #e6e6e6;
  font-size: 0.8rem;
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .LDyBtiFe_T65aO5wPaRGh .NUDI4WyNC1i5CxiS-2Nss {
    list-style: none;
    margin: 0; }
    .LDyBtiFe_T65aO5wPaRGh .NUDI4WyNC1i5CxiS-2Nss li {
      margin-bottom: 0.313rem; }
    .LDyBtiFe_T65aO5wPaRGh .NUDI4WyNC1i5CxiS-2Nss strong {
      margin-right: 1rem;
      width: 65px;
      max-width: 100%;
      display: inline-block; }
  .LDyBtiFe_T65aO5wPaRGh ._2q-qKhx418k79vmW3xAUs4 {
    display: inline-block;
    background-color: #e6eaf2;
    padding: 0.625rem; }

._3fEaoOhsHKweGS3XabRnx3 {
  color: #fa1854;
  font-size: 0.875rem;
  display: block;
  margin: 1rem 0; }
