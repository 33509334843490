.K5VTcn4H4v-DBPU8xINLB {
  max-width: 1200px;
  min-height: calc(100vh - (80px + 3rem));
  padding: 4rem 5rem;
  margin: 0 auto; }
  @media (max-width: 991px) {
    .K5VTcn4H4v-DBPU8xINLB {
      padding: 1.5rem; } }
  .K5VTcn4H4v-DBPU8xINLB ._1N7xp9ZBhniq3L67SfR95D {
    display: inline;
    font-size: 2.5rem; }
    @media (max-width: 991px) {
      .K5VTcn4H4v-DBPU8xINLB ._1N7xp9ZBhniq3L67SfR95D {
        font-size: 1.75rem;
        line-height: 1.6; } }
  .K5VTcn4H4v-DBPU8xINLB ._3AzUPXcnXPLVcR9R7EoND1 {
    display: grid;
    grid-template-columns: repeat(1, auto);
    -webkit-box-pack: normal;
        -ms-flex-pack: normal;
            justify-content: normal;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    grid-gap: 3.5rem;
    gap: 3.5rem;
    margin: 4rem 0; }
    @media all and (min-width: 668px) {
      .K5VTcn4H4v-DBPU8xINLB ._3AzUPXcnXPLVcR9R7EoND1 {
        grid-template-columns: repeat(2, auto);
        -webkit-box-pack: normal;
            -ms-flex-pack: normal;
                justify-content: normal; } }
    @media all and (min-width: 1200px) {
      .K5VTcn4H4v-DBPU8xINLB ._3AzUPXcnXPLVcR9R7EoND1 {
        grid-template-columns: repeat(4, auto);
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
  .K5VTcn4H4v-DBPU8xINLB ._10sGu0RVYeyaS6CyMPLSwG {
    font-size: 1.2rem;
    line-height: 1.2;
    margin-bottom: 2rem; }
  .K5VTcn4H4v-DBPU8xINLB ._2MDzNSVNMIeVpC2HL5bnaE {
    padding: 0; }
  .K5VTcn4H4v-DBPU8xINLB .y3J_0DpCGKFNZiVI6VWfy {
    list-style: none;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    line-height: 1.8; }
    .K5VTcn4H4v-DBPU8xINLB .y3J_0DpCGKFNZiVI6VWfy:last-child {
      margin-bottom: 0; }
    .K5VTcn4H4v-DBPU8xINLB .y3J_0DpCGKFNZiVI6VWfy > a {
      color: #030b0d; }
      .K5VTcn4H4v-DBPU8xINLB .y3J_0DpCGKFNZiVI6VWfy > a:hover {
        color: #00b4d8;
        text-decoration: underline; }
