._3-lOTC-y80tou9scIAGOEo ._5mK7y8XFl_edutSC13_NG {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3-lOTC-y80tou9scIAGOEo ._5mK7y8XFl_edutSC13_NG {
      font-size: 1.25rem;
      line-height: 1.8; } }

._3-lOTC-y80tou9scIAGOEo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 2rem;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center; }
  ._3-lOTC-y80tou9scIAGOEo ._5mK7y8XFl_edutSC13_NG {
    margin-bottom: 2rem;
    word-break: break-word; }
  ._3-lOTC-y80tou9scIAGOEo ._3ne7qnLKVsB5YCYUsyJyA {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    max-width: 950px;
    margin: 0 auto; }
