._13fzT9Lonmk-nl0zMUGXMO {
  width: 100%;
  margin-bottom: 3rem; }

._2HFPCKx2v2cF5Fnw-nOeur {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem; }
  @media (max-width: 1490px) {
    ._2HFPCKx2v2cF5Fnw-nOeur {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }

._2ke_LDnaVHVdEQz0NOc7LR {
  font-weight: 600;
  line-height: 2.2rem;
  color: #051c26;
  margin: 0 0.5rem 0.5rem 0;
  font-size: 2rem; }
  @media (max-width: 991px) {
    ._2ke_LDnaVHVdEQz0NOc7LR {
      font-size: 1.75rem;
      line-height: 1.6; } }
  @media (max-width: 1490px) {
    ._2ke_LDnaVHVdEQz0NOc7LR {
      margin-bottom: 1rem; } }

._2qTDgYu49ctH2OpzxGxO8s {
  font-size: 0.75rem;
  color: #758793; }

.pmVn0WVEH3LOmt8r9VFg0 {
  margin: 0; }

.efeo2M5-t51ppI-5W7gX- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media (max-width: 1490px) {
    .efeo2M5-t51ppI-5W7gX- {
      -ms-flex-item-align: end;
          align-self: flex-end; } }
  @media (max-width: 1240px) {
    .efeo2M5-t51ppI-5W7gX- {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      width: 100%;
      -webkit-box-align: stretch;
          -ms-flex-align: stretch;
              align-items: stretch; } }
  .efeo2M5-t51ppI-5W7gX- ._2pM092Y0rwX4PkDc8wH68h {
    background: #fff;
    -webkit-box-shadow: 2px 4px 10px rgba(232, 234, 237, 0.3);
            box-shadow: 2px 4px 10px rgba(232, 234, 237, 0.3);
    border-radius: 3px;
    padding: 0.625rem;
    font-size: 0.875rem;
    margin-right: 0.5rem; }
    @media (max-width: 1240px) {
      .efeo2M5-t51ppI-5W7gX- ._2pM092Y0rwX4PkDc8wH68h {
        margin: 0 0 0.5rem 0; } }
    .efeo2M5-t51ppI-5W7gX- ._2pM092Y0rwX4PkDc8wH68h input {
      border: none;
      background: transparent;
      margin-left: 0.313rem; }
      .efeo2M5-t51ppI-5W7gX- ._2pM092Y0rwX4PkDc8wH68h input:focus {
        outline: none; }

._1w1Q4elBUOAEmjk2t_Zr57 {
  margin-right: 0.5rem; }
  ._1w1Q4elBUOAEmjk2t_Zr57:last-child {
    margin-right: 0; }
  @media (max-width: 1240px) {
    ._1w1Q4elBUOAEmjk2t_Zr57 {
      margin-bottom: 0.5rem;
      width: 100%; } }
