._2vIbLleB5bchAQYMjp1Hk {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  line-height: 2em;
  color: #758793;
  border-bottom: 1px solid #F4F4F4;
  border-radius: 3px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: left;
  position: relative; }
  ._2vIbLleB5bchAQYMjp1Hk._3BfXhrKzUVoeeOA6-TTtBN {
    cursor: pointer; }
  ._2vIbLleB5bchAQYMjp1Hk._9MYhzcxCHUiLX6HXG4ZH5:after {
    content: ' ';
    width: 0px;
    height: 0px;
    margin-left: 5px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  ._2vIbLleB5bchAQYMjp1Hk.n3NPnmcYQo3ATpmDsnBuF:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  ._2vIbLleB5bchAQYMjp1Hk.Ejc03ICBYA41BORNXqoO1:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }
  ._2vIbLleB5bchAQYMjp1Hk._2mMqZRS7wZjKEHqIw9mKSm {
    width: 100px;
    max-width: 100%;
    line-height: 15px; }
  ._2vIbLleB5bchAQYMjp1Hk ._2JKFyCm-TROIeIfJo8mjbC {
    margin-left: 0.3rem;
    font-size: 0.5rem;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #c0cbd2;
    color: #fff;
    line-height: 15px;
    padding: 0px 5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    cursor: help; }
