._2ofSydjU3cry_ujsBmV-p4 .imHl2S0DSYCKW8CfrPabh {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2ofSydjU3cry_ujsBmV-p4 .imHl2S0DSYCKW8CfrPabh {
      font-size: 2.5rem;
      line-height: 1.7; } }

._2ofSydjU3cry_ujsBmV-p4 ._4gAm-ZTYW5W0eTkHUdW2Q {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2ofSydjU3cry_ujsBmV-p4 ._4gAm-ZTYW5W0eTkHUdW2Q {
      font-size: 1.25rem;
      line-height: 1.8; } }

._2ofSydjU3cry_ujsBmV-p4 ._3_v709uHCg_oq4zvbCoxuc {
  font-size: 1.75rem;
  line-height: 1.8;
  font-weight: bold; }
  @media all and (min-width: 768px) {
    ._2ofSydjU3cry_ujsBmV-p4 ._3_v709uHCg_oq4zvbCoxuc {
      font-size: 2rem;
      line-height: 1.5; } }

._2ofSydjU3cry_ujsBmV-p4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 1200px;
  padding: 2rem 2rem 5rem 2rem;
  margin: 0 auto; }
  @media all and (min-width: 768px) {
    ._2ofSydjU3cry_ujsBmV-p4 {
      padding: 4rem 2rem 6rem 2rem; } }
  ._2ofSydjU3cry_ujsBmV-p4 .imHl2S0DSYCKW8CfrPabh {
    margin-bottom: 1rem; }
  ._2ofSydjU3cry_ujsBmV-p4 ._4gAm-ZTYW5W0eTkHUdW2Q:first-of-type {
    margin-bottom: 0; }
  ._2ofSydjU3cry_ujsBmV-p4 ._3_v709uHCg_oq4zvbCoxuc {
    margin-bottom: 1rem; }
  ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 5rem 0;
    grid-gap: 5rem; }
    @media all and (min-width: 768px) {
      ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        grid-gap: 6rem; } }
    @media (min-width: 640px) and (max-width: 800px) {
      ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; } }
    ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      -ms-flex-negative: 1;
          flex-shrink: 1;
      -ms-flex-preferred-size: 0;
          flex-basis: 0; }
      ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -ms-flex-negative: 1;
            flex-shrink: 1;
        text-align: left;
        padding: 0; }
        ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa ._3h_Lw5zaYAp4EX40hVjpL {
          width: 100%;
          max-width: 65px;
          margin-bottom: 1rem; }
        ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa ._1p10Om8D_QvN4s2GiVjyOF {
          font-size: 1.125rem;
          line-height: 1.6;
          height: calc(100% - 180px); }
          @media all and (min-width: 768px) {
            ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa ._1p10Om8D_QvN4s2GiVjyOF {
              line-height: 2; } }
        ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa ._3XbhpItfTUjODQrp7OB5RX {
          background: #e57e49;
          font-size: 0.5rem;
          color: #fff;
          font-weight: bold;
          line-height: 1;
          letter-spacing: 1px;
          text-transform: uppercase;
          display: inline-block;
          vertical-align: middle;
          padding: 0.125rem 0.125rem 0.125rem 0.25rem;
          position: absolute;
          margin-top: 3.625rem;
          margin-left: 2.438rem;
          border-radius: 3px;
          overflow: hidden; }
        ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa ._2pQOl13zG4EHII--hXJMue {
          background: #e9ebf0;
          color: #030b0d;
          font-weight: 600;
          border-radius: 5px;
          padding: 1rem 2.5rem;
          font-size: 1.125rem; }
          ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa ._2pQOl13zG4EHII--hXJMue:hover {
            background-color: #d7dae4; }
          ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa ._2pQOl13zG4EHII--hXJMue:active, ._2ofSydjU3cry_ujsBmV-p4 ._3_zOj-TgzFiOWGyiFwWu_O .iAcEBKndL3SrwCcw7X6pB .zKqSeH8MnH5__nzQVrDwa ._2pQOl13zG4EHII--hXJMue:focus {
            background-color: #d1d5df; }
  ._2ofSydjU3cry_ujsBmV-p4 ._1FdOc7f2qOTyeAfQnQazSp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
