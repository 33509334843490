._2q-qW7iPXsexJ8DDlVBxxp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._1ILSFl4oSNR7Js5pUXF6oZ {
  margin-bottom: 1rem; }

._2hbiSzv2lNkxehQkTlt9Wm {
  color: #657881;
  font-size: 0.6rem;
  margin-top: 0.5rem;
  display: block; }

._22fUxkUFzuXFWI91d5u6NR {
  margin: 1rem 0;
  max-width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  ._22fUxkUFzuXFWI91d5u6NR ._1o-x-v5UVqCJC48pppvO1N {
    font-size: 14px;
    font-weight: 600;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    margin: 10px 20px;
    display: block; }

._3NcLY3ecIb_C3sGo__DjOV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }

._1CNcGrPKwGvfq2M8_KvaJQ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-bottom: 1rem;
  width: 600px;
  max-width: 100%; }
  ._1CNcGrPKwGvfq2M8_KvaJQ .i5fzwwe1_Fx9t4NDNBIts {
    margin-right: 0.5rem;
    margin-top: 35px; }

.uy5u9nmMbS29OKVX6oyyu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  border: 1px solid #e6e6e6;
  margin-right: 2rem; }
  @media (max-width: 991px) {
    .uy5u9nmMbS29OKVX6oyyu {
      margin: 0; } }
  .uy5u9nmMbS29OKVX6oyyu ._3E9T49dICLJ1P6o-byzmNL {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 60px;
    cursor: pointer; }
  .uy5u9nmMbS29OKVX6oyyu ._3yRsO3bKEjdTmc3e7lIu8S {
    height: 30px;
    width: 30px;
    margin-left: 1rem; }
  .uy5u9nmMbS29OKVX6oyyu ._2rqNPhzqnBfq01vOWVvM7e {
    padding: 15px; }
  .uy5u9nmMbS29OKVX6oyyu ._2Y7joRu1ofBtwV2x-LFcqv {
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block; }
  .uy5u9nmMbS29OKVX6oyyu ._3qMKGshvhVMgEclC7rC3eN {
    line-height: 1.5rem;
    font-size: 0.8rem; }

.sHXSvmyS1F4mVCa3oJbpV {
  width: 100%;
  border-top: 1px solid #e6e6e6;
  font-size: 0.8rem;
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

._3mZQorrFmtBTrIjAZXUv_A {
  font-size: 14px;
  display: block;
  margin: 1rem 0;
  color: #fa1854; }
  ._3mZQorrFmtBTrIjAZXUv_A.ejfSquDxjIaAY9_9X5UpE {
    color: #0a3807; }

.xZXhb6SLb21VZ3Lr-34Ta {
  background: #1791d3 0% 0% no-repeat padding-box;
  color: #ffffff;
  padding: 1rem 2rem; }
  .xZXhb6SLb21VZ3Lr-34Ta:hover {
    background: #34a9e9;
    color: #fff; }

._2q-qW7iPXsexJ8DDlVBxxp:invalid .xZXhb6SLb21VZ3Lr-34Ta {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
