._2ND41Pu5NHeKKx3BhPS1XF {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._15N5JgqXftnwYgsBs7Cc3d {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._15N5JgqXftnwYgsBs7Cc3d {
      font-size: 2.5rem;
      line-height: 1.7; } }

._2bpejmqqfjfQ_cJDYxnmo- {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2bpejmqqfjfQ_cJDYxnmo- {
      font-size: 1.25rem;
      line-height: 1.8; } }

._37j-wMNAOT2Jqw0iRZqtxz {
  padding: 0 2rem 6rem; }
  @media (max-width: 991px) {
    ._37j-wMNAOT2Jqw0iRZqtxz {
      padding: 0 2rem 3rem; } }

.T5csC3f7Rh5ZgFUHwLzBb {
  max-width: 1400px;
  margin: 0 auto; }

._1a1TPPJ8Hc0FOls0DLOKwV {
  display: grid;
  grid-gap: 5rem 3rem;
  gap: 5rem 3rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 1rem; }
  @media all and (max-width: 1180px) {
    ._1a1TPPJ8Hc0FOls0DLOKwV {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      gap: 2rem;
      padding: 0; } }
  @media all and (max-width: 600px) {
    ._1a1TPPJ8Hc0FOls0DLOKwV {
      grid-template-columns: 1fr; } }

._1vOeTImNN4cRKUZZhD1S8g {
  margin-bottom: 2.5rem; }

.Ok4N1lHTgsyMX7tjTZLhj {
  max-height: 100%;
  width: auto; }

._2ND41Pu5NHeKKx3BhPS1XF {
  font-size: 1.6rem;
  line-height: 1.7;
  word-break: break-word;
  color: #fff;
  margin-bottom: 0.5rem; }
  @media (max-width: 991px) {
    ._2ND41Pu5NHeKKx3BhPS1XF {
      font-size: 1.4rem; } }

._2bpejmqqfjfQ_cJDYxnmo- {
  word-break: break-word;
  color: #99a1a7;
  margin: 0; }

._15N5JgqXftnwYgsBs7Cc3d {
  color: #fff;
  text-align: center;
  word-break: break-word;
  margin-bottom: 7rem; }
  @media (max-width: 991px) {
    ._15N5JgqXftnwYgsBs7Cc3d {
      margin-bottom: 3rem; } }
