._16GSax3zg_UlMgjQKu1hcG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

._3MvTdaHgdD8AR5inM3i1_X {
  display: none; }

._1S4F1tc7crJaHAgLXmxcrj {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border: 1px solid #e6eaf2;
  background: #e6eaf2;
  border-radius: 3px;
  font-size: 14px;
  color: #5c6066;
  padding: 10px;
  cursor: pointer;
  -ms-flex-item-align: start;
      align-self: flex-start; }
  ._1S4F1tc7crJaHAgLXmxcrj ._2aUjeLzvdqOSggBBZNa5yT {
    margin-right: .5rem; }
  ._1S4F1tc7crJaHAgLXmxcrj .JopHWPf23YOORnFuTYct1 {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  ._1S4F1tc7crJaHAgLXmxcrj:hover {
    border: 1px solid silver; }

._2D9Yz3myLOPTeCH32F2tEC {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }
