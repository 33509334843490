._2n0DxYwVjhOiuMYcGgDrev {
  padding: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 1024px;
  overflow: auto;
  max-height: 80vh; }

._1_dSoXB_EW4DN240d5HVqz {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._2jrlS2bp8q1yqLph2kdCOd {
  font-size: 12px;
  color: #657881;
  margin-bottom: 10px; }

._1E_Xsy_FRaPyaAIDBHeVZs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._3Tazu1zyhW0uy9vy0GTR_C {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._10kCMhpuzfwkb4t-IoKZOr {
  margin-right: 0.5rem; }

._2DacX6uf3eXDDW3xIJv360 {
  max-width: 50vw;
  max-height: 60vh;
  -ms-flex-item-align: center;
      align-self: center; }

._3iH9qWYp6DPe4o1AbEoRNo {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }

._8_4HeGJ5oeKQMo5uVlXpL ._3BygXYMd7qL5XqP9TTToJo {
  color: #2c2c2c;
  font-size: 0.9rem;
  margin-right: 0.5rem; }

._8_4HeGJ5oeKQMo5uVlXpL ._2pwKG_9kqaWBp0uVxeP5DY {
  color: #758793;
  font-size: 0.9rem;
  border: none;
  cursor: pointer; }

._3JwsT6vNie9DHHBQ_AvnVz {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._3JwsT6vNie9DHHBQ_AvnVz ._24CIL4D4gbuyTRdM2kjkhN {
    min-width: 100px; }
    ._3JwsT6vNie9DHHBQ_AvnVz ._24CIL4D4gbuyTRdM2kjkhN.Nto34uC8_bzsE5ncocsaq {
      color: #fa1854;
      background: #fa18541c 0% 0% no-repeat padding-box;
      border: 1px solid #fa1854;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    ._3JwsT6vNie9DHHBQ_AvnVz ._24CIL4D4gbuyTRdM2kjkhN:first-child {
      margin-right: 0.5rem; }
