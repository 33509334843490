._2Dwu4figvJFMI0FvsaDPzl {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

.WUEsoypHtO3aHNv4CPp64 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  min-height: calc(100vh - 80px); }

._1VwG4ZGRcNSdvwG6SNZDGQ {
  margin: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center; }
  @media (max-width: 767px) {
    ._1VwG4ZGRcNSdvwG6SNZDGQ {
      margin-top: 0; } }
  ._1VwG4ZGRcNSdvwG6SNZDGQ ._1qrUoI808OlbDY8jMp_AtD {
    font-size: 2.5rem;
    margin-bottom: 2.5rem; }
    @media (max-width: 767px) {
      ._1VwG4ZGRcNSdvwG6SNZDGQ ._1qrUoI808OlbDY8jMp_AtD {
        font-size: 1.8rem;
        margin-top: 0; } }
  ._1VwG4ZGRcNSdvwG6SNZDGQ ._3efZqWs2xeVRNBWIX6t-o2 {
    line-height: 1.2;
    margin-bottom: 1.5rem; }
    ._1VwG4ZGRcNSdvwG6SNZDGQ ._3efZqWs2xeVRNBWIX6t-o2 > P {
      font-size: 1.25rem; }
      @media (max-width: 767px) {
        ._1VwG4ZGRcNSdvwG6SNZDGQ ._3efZqWs2xeVRNBWIX6t-o2 > P {
          font-size: 1rem; } }
  ._1VwG4ZGRcNSdvwG6SNZDGQ ._2Kf4bY-O8LM6vNI6LPvXjE {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-height: 150px;
    margin: 0 auto 2.5rem; }

._2Dwu4figvJFMI0FvsaDPzl {
  background: #00b4d8;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5;
  display: block;
  color: #fff; }
  ._2Dwu4figvJFMI0FvsaDPzl:hover {
    background-color: #009bb9;
    color: #fff; }
  ._2Dwu4figvJFMI0FvsaDPzl:active, ._2Dwu4figvJFMI0FvsaDPzl:focus {
    background-color: #0092af;
    color: #fff; }
  @media (max-width: 991px) {
    ._2Dwu4figvJFMI0FvsaDPzl {
      font-size: 1.2rem; } }
