._1m1OCQiqEmb59OvQ6CYcrL {
  position: relative;
  min-width: 200px; }
  ._1m1OCQiqEmb59OvQ6CYcrL .d7iEvpVEVmygGUzHM8bSY {
    cursor: pointer;
    width: 1px; }
  ._1m1OCQiqEmb59OvQ6CYcrL ._2Tw-W1lkEK7M07_TAgt7sm {
    font-size: 0.875rem;
    color: #5c6066;
    font-weight: 600;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border: 1px solid #e6eaf2;
    border-radius: 3px;
    padding: 0.625rem;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  ._1m1OCQiqEmb59OvQ6CYcrL .ZUqly57uS0X5OOCgOvyhk {
    font-size: 0.875rem;
    color: #5c6066;
    font-weight: 600;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    top: 40px;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid #f4f5f8;
    border-radius: 3px;
    z-index: 1;
    display: none; }
    @media all and (max-width: 660px) {
      ._1m1OCQiqEmb59OvQ6CYcrL .ZUqly57uS0X5OOCgOvyhk {
        width: 100%; } }
    ._1m1OCQiqEmb59OvQ6CYcrL .ZUqly57uS0X5OOCgOvyhk._2xixzRGeRUnxRroR77Bn3L {
      display: block; }
    ._1m1OCQiqEmb59OvQ6CYcrL .ZUqly57uS0X5OOCgOvyhk li {
      color: #2c2c2c;
      padding: 0.625rem 1.25rem;
      cursor: pointer; }
      ._1m1OCQiqEmb59OvQ6CYcrL .ZUqly57uS0X5OOCgOvyhk li:hover {
        background-color: #f0f2f7; }

.d7iEvpVEVmygGUzHM8bSY {
  opacity: 0;
  position: absolute; }

._3-X7KD5TVjfSHhL8Py1Jlp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._3-X7KD5TVjfSHhL8Py1Jlp:after {
    content: ' ';
    margin-left: 0.313rem; }
  ._3-X7KD5TVjfSHhL8Py1Jlp._3h_6HyiyYDTWsOBD5-d-Ic:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  ._3-X7KD5TVjfSHhL8Py1Jlp.ERFZG7eABfTeMmR3nRYYC:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }
