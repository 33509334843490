._2MOOmRHAIe4tcelOToZ4LB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-top: -80px;
  padding: 100px 0;
  background: url(/_next/static/images/curves-bottom-51b1856a85538c863580c4bd14d119e9.png) bottom no-repeat;
  background-size: cover; }
  ._5RYpfhwpkh8OxIT4FldyB {
    width: 90%;
    margin: 60px auto 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 4; }
  ._1i-cje_jS-N-KflmQya0jm {
    text-align: center;
    color: #fff;
    margin-bottom: 50px;
    width: 100%;
    z-index: 1; }
    ._1Q8faQykJDSwwREOx6anEW {
      color: #fff;
      font-size: 60px;
      line-height: 1.2em;
      font-weight: 700;
      margin-bottom: 20px; }
      @media all and (max-width: 1100px) {
        ._1Q8faQykJDSwwREOx6anEW {
          font-size: 35px; } }
    ._3rxp2vB9rz2stKd6AiTa0J {
      font-size: 30px;
      line-height: 1.2em;
      font-weight: 400;
      margin-bottom: 20px; }
      @media all and (max-width: 1100px) {
        ._3rxp2vB9rz2stKd6AiTa0J {
          font-size: 20px; } }
  ._1N9PqldF_qE7vX9JXajZse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    position: relative;
    padding: 30px;
    border-radius: 20px;
    background: #000a0f;
    -webkit-box-shadow: 0 77px 50px 0 rgba(0, 0, 0, 0.5);
            box-shadow: 0 77px 50px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 50px; }
    @media all and (max-width: 1100px) {
      ._1N9PqldF_qE7vX9JXajZse {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        background: none;
        -webkit-box-shadow: none;
                box-shadow: none;
        border-radius: 0;
        padding: 20px; } }
  ._3fyfTwjOa2J2CDRqi5eEW7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    margin: 10px auto 20px;
    z-index: 1; }
    .JXXlLpwDglRyomAon7Sa7 {
      text-decoration: none;
      display: inline-block;
      color: #02c9a3;
      font-size: 16px;
      cursor: pointer;
      background: 0 0;
      border: none;
      padding: 0;
      outline: none; }
      .JXXlLpwDglRyomAon7Sa7:not(:last-child):after {
        content: '|';
        color: #fff;
        margin: 10px; }
        @media all and (max-width: 1100px) {
          .JXXlLpwDglRyomAon7Sa7:not(:last-child):after {
            display: none; } }
      @media all and (max-width: 1100px) {
        .JXXlLpwDglRyomAon7Sa7 {
          height: auto;
          height: initial;
          line-height: normal; } }
    @media all and (max-width: 1100px) {
      ._3fyfTwjOa2J2CDRqi5eEW7 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        text-align: center; } }
  ._3DIxHDHYNzk7NIQymXvK7w {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    margin: 10px auto 20px;
    z-index: 1; }
    .dxKzWrUaoQ8vHWNQBoVSV {
      margin-left: 20px; }
      .dxKzWrUaoQ8vHWNQBoVSV:first-child {
        margin-left: 0; }
  ._1pez4IVDO8AMx-81Yc88Co {
    z-index: 1; }
