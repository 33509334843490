.jlj4qU51aTDrKvy6FfnkN {
  border-bottom: 1px solid #e6e6e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row; }

._3I7dRbIde676WjD0vfRkFr {
  color: #4b4c4d;
  opacity: 0.6;
  padding: 0.5rem 0;
  margin-right: 2rem;
  display: block;
  text-align: left;
  font-weight: 600;
  margin-bottom: 3px; }
  ._3I7dRbIde676WjD0vfRkFr:hover, ._3I7dRbIde676WjD0vfRkFr[data-selected] {
    color: #1a1a1a;
    opacity: 1;
    border-bottom: 3px solid #1791d3;
    margin-bottom: 0; }

.BQ8Wl2kBiqDQMPeuiWhh7 {
  padding: 1rem 0;
  max-width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
