.n6Oqjmozy74OccWw-DZVq {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #030b0d;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: calc(100vh - 80px);
  padding: 4rem 2rem; }
  @media all and (min-width: 1920px) {
    .n6Oqjmozy74OccWw-DZVq {
      padding: 0 2rem; } }
  @media all and (min-width: 2000px) {
    .n6Oqjmozy74OccWw-DZVq {
      padding: 4rem 2rem;
      min-height: unset; } }

._29p7WKFsDOEhaqjs5hszS7 {
  margin-bottom: 3rem;
  max-width: 100%;
  height: auto;
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  visibility: hidden;
  -webkit-animation-name: _2FSU-zT5AtS2a8ZufZ1Wvt;
          animation-name: _2FSU-zT5AtS2a8ZufZ1Wvt; }
  ._29p7WKFsDOEhaqjs5hszS7.PIskH3Dlw0DC5SEXxrtmJ {
    -webkit-animation: _1OlXyo1lKAZ3X41pIuyy4- 1s;
            animation: _1OlXyo1lKAZ3X41pIuyy4- 1s;
    visibility: visible;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }

._3aCmTVs-oYkqZeO0GTYJl8 {
  color: #fff;
  width: 790px;
  max-width: 90%;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 3rem;
  visibility: hidden;
  -webkit-animation-name: _2FSU-zT5AtS2a8ZufZ1Wvt;
          animation-name: _2FSU-zT5AtS2a8ZufZ1Wvt;
  font-size: 1.8rem; }
  @media all and (min-width: 390px) {
    ._3aCmTVs-oYkqZeO0GTYJl8 {
      font-size: 1.9rem; } }
  @media all and (min-width: 802px) {
    ._3aCmTVs-oYkqZeO0GTYJl8 {
      font-size: 2.5rem; } }
  @media all and (min-width: 1920px) {
    ._3aCmTVs-oYkqZeO0GTYJl8 {
      font-size: 2.8rem; } }
  ._3aCmTVs-oYkqZeO0GTYJl8._38s0pWR-aKww5QPb6RiaBZ {
    -webkit-animation: _1OlXyo1lKAZ3X41pIuyy4- 1s;
            animation: _1OlXyo1lKAZ3X41pIuyy4- 1s;
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
    visibility: visible;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }

._1EJ8j_9SxBjEkszSp0s_5V {
  background: #00b4d8;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5;
  display: block;
  color: #fff;
  visibility: hidden;
  -webkit-animation-name: _2FSU-zT5AtS2a8ZufZ1Wvt;
          animation-name: _2FSU-zT5AtS2a8ZufZ1Wvt;
  text-align: center; }
  ._1EJ8j_9SxBjEkszSp0s_5V:hover {
    background-color: #009bb9;
    color: #fff; }
  ._1EJ8j_9SxBjEkszSp0s_5V:active, ._1EJ8j_9SxBjEkszSp0s_5V:focus {
    background-color: #0092af;
    color: #fff; }
  @media (max-width: 991px) {
    ._1EJ8j_9SxBjEkszSp0s_5V {
      font-size: 1.125rem; } }
  ._1EJ8j_9SxBjEkszSp0s_5V._3ecz6sQn7pIIYEuZ1AFfLp {
    -webkit-animation: _1OlXyo1lKAZ3X41pIuyy4- 1s;
            animation: _1OlXyo1lKAZ3X41pIuyy4- 1s;
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
    visibility: visible;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }

@-webkit-keyframes _1OlXyo1lKAZ3X41pIuyy4- {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes _1OlXyo1lKAZ3X41pIuyy4- {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }
