._2y5dhKH8V64qusQK7gS6Pq ._1sGpfHg7prc_0xhXQF15dk {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2y5dhKH8V64qusQK7gS6Pq ._1sGpfHg7prc_0xhXQF15dk {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2y5dhKH8V64qusQK7gS6Pq ._1sGpfHg7prc_0xhXQF15dk {
      font-size: 2.5rem;
      line-height: 1.7; } }

._2y5dhKH8V64qusQK7gS6Pq .msLEZbeflTPN3rsSJMxp2 {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2y5dhKH8V64qusQK7gS6Pq .msLEZbeflTPN3rsSJMxp2 {
      font-size: 1.25rem;
      line-height: 1.8; } }

._1vAqMSVmQeMM8yXD5BdG_e {
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._2y5dhKH8V64qusQK7gS6Pq {
  padding: 0;
  text-align: center;
  max-width: 820px;
  padding: 0 3rem;
  margin: 3rem auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media all and (min-width: 768px) {
    ._2y5dhKH8V64qusQK7gS6Pq {
      margin: 6rem auto 12rem;
      padding: 0 6rem; } }
  @media all and (min-width: 1920px) {
    ._2y5dhKH8V64qusQK7gS6Pq {
      max-width: 900px; } }
  ._2y5dhKH8V64qusQK7gS6Pq::before {
    content: '';
    width: 45px;
    height: 92px;
    background-color: #ebeff1;
    position: absolute;
    top: 9rem;
    border-bottom-right-radius: 165px;
    border-top-right-radius: 165px;
    left: 0; }
    @media all and (min-width: 390px) {
      ._2y5dhKH8V64qusQK7gS6Pq::before {
        top: 6rem; } }
    @media all and (min-width: 768px) {
      ._2y5dhKH8V64qusQK7gS6Pq::before {
        width: 59px;
        height: 127px; } }
    @media all and (min-width: 1050px) {
      ._2y5dhKH8V64qusQK7gS6Pq::before {
        width: 115px;
        height: 248px;
        top: 3rem; } }
    @media all and (min-width: 1920px) {
      ._2y5dhKH8V64qusQK7gS6Pq::before {
        width: 155px;
        height: 310px;
        top: 0; } }
  ._2y5dhKH8V64qusQK7gS6Pq::after {
    content: '';
    width: 45px;
    height: 92px;
    background-color: #ebeff1;
    position: absolute;
    top: 9rem;
    border-bottom-left-radius: 165px;
    border-top-left-radius: 165px;
    right: 0; }
    @media all and (min-width: 390px) {
      ._2y5dhKH8V64qusQK7gS6Pq::after {
        top: 6rem; } }
    @media all and (min-width: 768px) {
      ._2y5dhKH8V64qusQK7gS6Pq::after {
        width: 59px;
        height: 127px; } }
    @media all and (min-width: 1050px) {
      ._2y5dhKH8V64qusQK7gS6Pq::after {
        width: 115px;
        height: 248px;
        top: 3rem; } }
    @media all and (min-width: 1920px) {
      ._2y5dhKH8V64qusQK7gS6Pq::after {
        width: 155px;
        height: 310px;
        top: 0; } }
  @media all and (min-width: 281px) {
    ._2y5dhKH8V64qusQK7gS6Pq {
      padding: 0 3rem; } }
  ._2y5dhKH8V64qusQK7gS6Pq .msLEZbeflTPN3rsSJMxp2 {
    font-weight: normal;
    display: block;
    position: relative;
    z-index: 2; }
  ._2y5dhKH8V64qusQK7gS6Pq ._1sGpfHg7prc_0xhXQF15dk {
    position: relative;
    z-index: 2; }

._1_LnZ_ZxSHwMb7iMd8JTcp section:first-child {
  padding-top: 2rem; }
  @media all and (min-width: 768px) {
    ._1_LnZ_ZxSHwMb7iMd8JTcp section:first-child {
      padding-top: 7.5rem; } }

._1_LnZ_ZxSHwMb7iMd8JTcp section:last-child {
  padding-bottom: 2rem; }
  @media all and (min-width: 768px) {
    ._1_LnZ_ZxSHwMb7iMd8JTcp section:last-child {
      padding-bottom: 7.5rem; } }

._1I0kuGygAHe2yUPhbMZ05n {
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 39px;
  margin-top: -5rem;
  z-index: 2; }
  @media all and (min-width: 768px) {
    ._1I0kuGygAHe2yUPhbMZ05n {
      max-width: 140px;
      margin-top: -11rem; } }
