.F3Y17eJednD3rClv6IpBb {
  margin-bottom: 20px;
  overflow: auto;
  position: relative; }

._1pAJ5ACLDpyopGqk1G2Wj_ {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }

._1MwfwNrlQIaz-fqvJ6SzT0 {
  font-size: 14px;
  color: #758793;
  border-bottom: 1px solid #758793;
  padding: 10px 20px;
  line-height: 2em; }

.nQA0dcqV1xTpl2TGal66h {
  color: #2c2c2c;
  font-weight: 700;
  text-align: left;
  position: relative;
  padding-left: 40px; }
  @media all and (min-width: 1100px) {
    .nQA0dcqV1xTpl2TGal66h:before {
      content: '';
      background: #1791d3 0% 0% no-repeat padding-box;
      border-radius: 4px;
      width: 15px;
      height: 15px;
      display: inline-block;
      left: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }
    ._3zXVP53jACJwRt06rD7MUa:before {
      background-color: #00a69c; }
    .xb7jv9LoY5qdVO48NPtaH:before {
      background-color: #f5371b; }
    ._7Cc-QSbDk4-w_tRsWgxcc:before {
      background-color: #fbb703; } }
