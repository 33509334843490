._19-_UvoaWm4DBi3zGUmbyr {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  min-height: 50vh;
  padding: 50px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._19-_UvoaWm4DBi3zGUmbyr * {
    font-family: Poppins; }

._3BIk5e0JIq1Tbe0fzRKTmo {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.24px;
  color: #051c26;
  margin: 0 0 20px 0; }

._1M89hZ5_GfJp8X6Gmknh9s {
  -ms-flex-item-align: center;
      align-self: center; }
