.rRtWkTOKysjWWUhGLLVYL {
  font-family: 'Inter', system-ui, sans-serif; }

.rRtWkTOKysjWWUhGLLVYL {
  background: #fff;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  max-height: 90vh;
  overflow: auto;
  width: 900px;
  max-width: 90vw; }

._2Um8SjAa0pLjaSo6_ap67k {
  padding: 2rem;
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 600; }

._33e1KNjFA7pZ596HeOD1yx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._2bq2yBcN0inxiBPrPUAWFq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem 2rem; }
  ._2bq2yBcN0inxiBPrPUAWFq ._1f7YTibRo-nKwPzpmhnPQo {
    font-weight: 600;
    width: 200px;
    max-width: 100%;
    text-align: right;
    padding-right: 2rem; }
  ._2bq2yBcN0inxiBPrPUAWFq ._1TQv727rIsYiM5h1b02iiG {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    max-width: 100%; }
  ._2bq2yBcN0inxiBPrPUAWFq ._1DCZxRZjc2qaZ99EHNVqpx {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
  ._2bq2yBcN0inxiBPrPUAWFq._1zCn_pcJ9zTUuqzc9TsE4R ._1f7YTibRo-nKwPzpmhnPQo {
    -ms-flex-item-align: start;
        align-self: flex-start; }

._4UL-HjKlePmlE8n-M8z0s {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  border: 1px solid #e6e7e8;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer; }
  ._4UL-HjKlePmlE8n-M8z0s span {
    font-size: 0.9rem;
    font-weight: 600;
    color: #62737f;
    text-align: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 0.625rem; }
    ._4UL-HjKlePmlE8n-M8z0s span:hover {
      background: #f0f0f0; }
    ._4UL-HjKlePmlE8n-M8z0s span:active {
      background: #ebebeb; }
  ._4UL-HjKlePmlE8n-M8z0s input {
    display: none; }
  ._4UL-HjKlePmlE8n-M8z0s input:checked:not([disabled]) + span {
    background-color: #00b4d8;
    color: #fff; }
    ._4UL-HjKlePmlE8n-M8z0s input:checked:not([disabled]) + span:hover {
      background: #009bb9; }
    ._4UL-HjKlePmlE8n-M8z0s input:checked:not([disabled]) + span:active {
      background: #0092af; }

.fuj3kf6GfVtLmIGVb4ZOo {
  width: 100%; }

._2KY-aWfYktUbD9g41YpMUN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 991px) {
    ._2KY-aWfYktUbD9g41YpMUN {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
  ._2KY-aWfYktUbD9g41YpMUN ._1zCn_pcJ9zTUuqzc9TsE4R {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 150px;
    border: 1px solid #e6e7e8;
    border-radius: 3px;
    padding: 0.313rem;
    margin-top: 1rem; }
    ._2KY-aWfYktUbD9g41YpMUN ._1zCn_pcJ9zTUuqzc9TsE4R ._3wioaPEDMPSwUDosRa_LuK {
      margin-left: 1rem; }
    ._2KY-aWfYktUbD9g41YpMUN ._1zCn_pcJ9zTUuqzc9TsE4R ._1xLxSICZtLB_OsaIEEipOa {
      border: none;
      background: none;
      width: 50px;
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto; }
      ._2KY-aWfYktUbD9g41YpMUN ._1zCn_pcJ9zTUuqzc9TsE4R ._1xLxSICZtLB_OsaIEEipOa:focus {
        outline: none; }
  ._2KY-aWfYktUbD9g41YpMUN .C0OIneJE0Q7W1L9GHsE9E {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    text-align: right;
    margin-top: 1rem; }
    ._2KY-aWfYktUbD9g41YpMUN .C0OIneJE0Q7W1L9GHsE9E ._1pot0Czs2tKjMkUVosqG9D {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.5rem; }
    ._2KY-aWfYktUbD9g41YpMUN .C0OIneJE0Q7W1L9GHsE9E ._2q1yO5mUy9ADDwuCCuNrZa {
      font-size: 0.9rem;
      line-height: 1.2rem;
      color: #62737f; }

._3wioaPEDMPSwUDosRa_LuK {
  font-size: 0.9rem;
  color: #62737f;
  opacity: 0.6; }

._2ztDnW2n1mDXrn_fFt2eBS {
  display: block;
  color: #ce2a29;
  text-align: right; }

.Vv3ku8riGcyFYpRJkjsUe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2rem;
  border-top: 1px solid #e6e7e8; }
  .Vv3ku8riGcyFYpRJkjsUe ._2iF8ntrzuENovMcodHp1gy {
    color: #62737f;
    font-size: 0.9rem; }
  .Vv3ku8riGcyFYpRJkjsUe ._1UVWpw7WNwG615fQudrA7q {
    background-color: #2892d0;
    color: #fff;
    font-weight: 600;
    border-radius: 3px;
    border: none;
    padding: 0.625rem; }
    .Vv3ku8riGcyFYpRJkjsUe ._1UVWpw7WNwG615fQudrA7q:hover {
      background: #2380b6; }
    .Vv3ku8riGcyFYpRJkjsUe ._1UVWpw7WNwG615fQudrA7q:active {
      background: #217aae; }
    .Vv3ku8riGcyFYpRJkjsUe ._1UVWpw7WNwG615fQudrA7q[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
