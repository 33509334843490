._1OD6vgbg1vTCXC0-HxA0pl {
  background: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px;
  padding: 1rem; }

._5BiqGOFsi1usvI20DUrV8 {
  color: #051c26;
  font-size: 1.5rem;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem; }
  @media all and (max-width: 653px) {
    ._5BiqGOFsi1usvI20DUrV8 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
  @media (max-width: 991px) {
    ._5BiqGOFsi1usvI20DUrV8 {
      margin-bottom: 1rem; } }
  ._5BiqGOFsi1usvI20DUrV8 ._19o3KHuIVt7yN5SOXuYfpw {
    font-weight: 600;
    line-height: 2;
    color: #051c26;
    margin: 0;
    font-size: 2rem; }
    @media (max-width: 991px) {
      ._5BiqGOFsi1usvI20DUrV8 ._19o3KHuIVt7yN5SOXuYfpw {
        font-size: 1.75rem;
        line-height: 1.6; } }
    @media all and (max-width: 653px) {
      ._5BiqGOFsi1usvI20DUrV8 ._19o3KHuIVt7yN5SOXuYfpw {
        -ms-flex-item-align: start;
            align-self: flex-start; } }
  ._5BiqGOFsi1usvI20DUrV8 ._3zvUBDks7fCWqIW4rhN4q0 {
    color: #fff;
    padding: 0.5rem 1rem;
    background-color: #2892d0;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 1rem;
    font-size: 1rem; }
    ._5BiqGOFsi1usvI20DUrV8 ._3zvUBDks7fCWqIW4rhN4q0:hover {
      background: #2380b6; }
    ._5BiqGOFsi1usvI20DUrV8 ._3zvUBDks7fCWqIW4rhN4q0:active {
      background: #217aae; }
    @media all and (max-width: 653px) {
      ._5BiqGOFsi1usvI20DUrV8 ._3zvUBDks7fCWqIW4rhN4q0 {
        -ms-flex-item-align: end;
            align-self: flex-end; } }

._1OD6vgbg1vTCXC0-HxA0pl {
  background: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px;
  padding: 2rem;
  margin-bottom: 1rem; }
  @media all and (max-width: 653px) {
    ._1OD6vgbg1vTCXC0-HxA0pl {
      padding: 1rem; } }
  ._1OD6vgbg1vTCXC0-HxA0pl .SPdClCkyXmZBG9638TV-S {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  ._1OD6vgbg1vTCXC0-HxA0pl ._19o3KHuIVt7yN5SOXuYfpw {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2;
    color: #051c26;
    margin: 0; }
  ._1OD6vgbg1vTCXC0-HxA0pl ._2IUNCmlptPGFV6wypzrLSr {
    color: #657881;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 0 0.5rem 0; }
  ._1OD6vgbg1vTCXC0-HxA0pl .G7BXMiQSRKZJC1NEbG4qP {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    position: relative;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: auto; }
    @media (max-width: 991px) {
      ._1OD6vgbg1vTCXC0-HxA0pl .G7BXMiQSRKZJC1NEbG4qP {
        width: 100%; }
        ._1OD6vgbg1vTCXC0-HxA0pl .G7BXMiQSRKZJC1NEbG4qP select {
          width: 100%; } }

._3JLqYmPUzmuEaENXSgBgI8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #657881;
  padding: 5rem 0; }
  ._3JLqYmPUzmuEaENXSgBgI8 ._2U5O0iJOwjl-Ed9CParpSa {
    width: 300px;
    max-width: 80%; }
