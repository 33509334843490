.emwEWqggku9TjfnU9XwKO {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

.emwEWqggku9TjfnU9XwKO {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .emwEWqggku9TjfnU9XwKO {
      font-size: 2.5rem;
      line-height: 1.7; } }

._3_uomBhdFORIL_xjrAjXqh {
  padding: 3rem 0;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .emwEWqggku9TjfnU9XwKO {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: block; }
  .F49q7kgB83NwigFwAuEBj {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    list-style: none; }
    @media all and (min-width: 768px) {
      .F49q7kgB83NwigFwAuEBj {
        padding: 2rem 0; } }
    .pC_-yNc4dfVGISkR2tYVy {
      text-align: center;
      margin: 2rem;
      max-height: 87px; }
      @media all and (min-width: 768px) {
        .pC_-yNc4dfVGISkR2tYVy {
          margin: 1rem 1.4rem; } }
      .pC_-yNc4dfVGISkR2tYVy a {
        cursor: auto; }
      .pC_-yNc4dfVGISkR2tYVy img {
        max-height: 60px; }
