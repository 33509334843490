._11yrr7EvkIuYGGK_k4qcNG {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative; }
  ._11yrr7EvkIuYGGK_k4qcNG table {
    border-collapse: collapse;
    color: #62737f; }
    ._11yrr7EvkIuYGGK_k4qcNG table thead {
      background-color: transparent;
      border-bottom: 1px solid #f4f4f4; }
    ._11yrr7EvkIuYGGK_k4qcNG table tbody {
      border-bottom: 1px solid #f4f4f4; }
    ._11yrr7EvkIuYGGK_k4qcNG table tr {
      border: none; }
      @media (max-width: 767px) {
        ._11yrr7EvkIuYGGK_k4qcNG table tr {
          display: table;
          border-bottom: 1px solid #f4f4f4;
          width: 100%; }
          ._11yrr7EvkIuYGGK_k4qcNG table tr th:not(._2_VGMe_QzitAzfP6hIez51) {
            display: none; }
          ._11yrr7EvkIuYGGK_k4qcNG table tr td {
            display: block;
            padding: 0.5rem;
            position: relative; } }
    @media (max-width: 767px) and (max-width: 991px) {
      ._11yrr7EvkIuYGGK_k4qcNG table tr td {
        padding: 0.5rem 1rem; } }
  ._11yrr7EvkIuYGGK_k4qcNG ._2qRRLmrMn4EAFoWI35zNS7 {
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative; }
    ._11yrr7EvkIuYGGK_k4qcNG ._2qRRLmrMn4EAFoWI35zNS7:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #2392d0;
      border-radius: 3px;
      display: inline-block;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }
  ._11yrr7EvkIuYGGK_k4qcNG ._1pz7V3VPJqmZPbVAyEinXA {
    display: none; }
    ._11yrr7EvkIuYGGK_k4qcNG ._1pz7V3VPJqmZPbVAyEinXA:checked + ._2qRRLmrMn4EAFoWI35zNS7:before {
      position: absolute;
      content: '\2713';
      background: #2392d0;
      color: #fff;
      height: 18px;
      width: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 0.75rem;
      display: inline-block;
      left: 1px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }
  ._11yrr7EvkIuYGGK_k4qcNG .T5RMTeRJflYqwlR2xlPbD {
    margin-left: 2.18rem; }
  ._11yrr7EvkIuYGGK_k4qcNG ._20lWMyanBgGBdXnxKo_vHe {
    cursor: pointer;
    color: #ce2a29;
    font-weight: 500;
    background: none;
    border: none;
    padding: 0;
    margin: 0; }
    ._11yrr7EvkIuYGGK_k4qcNG ._20lWMyanBgGBdXnxKo_vHe:hover {
      color: #ce2a29, 70%; }
    ._11yrr7EvkIuYGGK_k4qcNG ._20lWMyanBgGBdXnxKo_vHe[disabled] {
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
      cursor: not-allowed; }
  ._11yrr7EvkIuYGGK_k4qcNG ._17SkkNbJh8yHCYHvIXzOI8 {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 0.625rem; }

._2A7Rmn6QmtDyTV-pBro6lB {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    ._2A7Rmn6QmtDyTV-pBro6lB {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
