._3y6QEssyodpbdCZAXePQcZ {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._1jFQ8QY7tAqxJqF5cNk2gf {
  -webkit-box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1);
          box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1); }

._1jFQ8QY7tAqxJqF5cNk2gf {
  position: relative;
  margin-bottom: 2.5rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  min-height: 150px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

._1jFQ8QY7tAqxJqF5cNk2gf:after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 2rem;
  display: block;
  height: 2.5rem;
  width: 2.5rem;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #fff; }

._1qZ_eAqtlHcMpRki4pWviq {
  margin-bottom: 2.5rem; }

._3j-UarG2bBzxjCHXnBsgjm {
  color: #a9b2be;
  margin-top: auto; }

._2Fe6S489JJT2R1Poh3Hub9 {
  margin-left: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }

._3PWf_BJLbDVIgA_qkaCtcf {
  height: 4rem;
  width: 4rem;
  border-radius: 50%; }

._3PWf_BJLbDVIgA_qkaCtcf {
  margin-right: 1rem;
  display: inline-block; }

._3y6QEssyodpbdCZAXePQcZ {
  font-size: 1rem;
  margin-bottom: 0.5rem; }

._3PofkOpo7PFchBlIs7ulHq {
  margin-bottom: 0;
  font-weight: 600;
  color: #4cc0f8; }
