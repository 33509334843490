._2ULVRS9K9rV5C4008hW1bZ {
  max-width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0 4rem 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: calc(100vh - 80px); }

._1dpheSfEILlTm_JeGAFBmz {
  color: #54b75e;
  font-size: 2rem;
  position: relative;
  margin: 2rem auto;
  text-align: center; }
  @media (max-width: 991px) {
    ._1dpheSfEILlTm_JeGAFBmz {
      font-size: 1.5rem; } }

.GrIC0QaZPxXkoyIryCnzA {
  content: ' ';
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #54b75e;
  background-color: transparent;
  pointer-events: none;
  margin-right: 0.3rem; }
  .GrIC0QaZPxXkoyIryCnzA::before {
    content: ' ';
    display: block;
    width: 3px;
    height: 8px;
    border: solid #54b75e;
    border-width: 0 2px 2px 0;
    margin-left: 0.375rem;
    margin-top: 0.188rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); }

._1vJGFme5akFaTSF4gbHaYy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._3SmLwJK1JdobPm5wUJwKEd {
  height: 220px;
  margin-bottom: 1rem; }
  @media (max-width: 991px) {
    ._3SmLwJK1JdobPm5wUJwKEd {
      height: 125px;
      margin: 0; } }

._2KyE_UfwTBRmbKzkfRX6TE {
  font-size: 1.4rem;
  line-height: 1.8;
  text-align: center;
  display: block;
  margin-bottom: 3rem; }
  @media (max-width: 991px) {
    ._2KyE_UfwTBRmbKzkfRX6TE {
      font-size: 1.2rem; } }

._3UWUN3k-xXkvbpOVJFeV_g {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  background-color: #00b4d8;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  padding: 0.85rem;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 300px;
  margin: 0 auto; }
  ._3UWUN3k-xXkvbpOVJFeV_g:hover {
    color: #fff;
    background-color: #009bb9; }
  ._3UWUN3k-xXkvbpOVJFeV_g:active, ._3UWUN3k-xXkvbpOVJFeV_g:focus {
    color: #fff;
    background-color: #0092af; }
