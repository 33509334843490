._1zDIL63p4Sp5olCb4tN5Rv {
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  grid-gap: 10px;
  gap: 10px; }

._2eNtyXS_SSYFK0wlYO0Zhv {
  padding: 1rem;
  border-radius: 8px;
  background: #f5f7fa;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 20px;
  gap: 20px;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%; }
  ._2eNtyXS_SSYFK0wlYO0Zhv ._3ZQpR8D7u2_BlHCDSp4cd1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    grid-gap: 10px;
    gap: 10px; }

._3T-WGJ4PclmcqDbpWMR4O2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0.25rem; }

._3IEwB0MU8ezf5AHrsm8npa {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2rem; }

._3EY-5BFktSDpTk1EUZF5OU {
  padding: 1rem;
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 20px;
  gap: 20px; }

._3St51galVZdcM6hOJXoJ-0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 20px;
  gap: 20px;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 1rem; }
  ._3St51galVZdcM6hOJXoJ-0 ._1xKegfiAbsbXkoTU3CAZEm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    ._3St51galVZdcM6hOJXoJ-0 ._1xKegfiAbsbXkoTU3CAZEm ._1Z7YrOkxB2H0hlsyAVCSqK {
      border-radius: 50%;
      background: #f5f7fa;
      margin-right: 1rem;
      width: 30px;
      height: 30px;
      display: inline-block;
      line-height: 30px;
      text-align: center; }
    ._3St51galVZdcM6hOJXoJ-0 ._1xKegfiAbsbXkoTU3CAZEm ._11Qu9DT0ENqJHW17477shu {
      line-height: 1.5rem; }

._1csmNUycqv_JzBEzAhlnQ0 {
  margin: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 16px;
  gap: 16px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border: 1px solid #2892d0;
  border-radius: 3px;
  padding: 10px; }
  ._1csmNUycqv_JzBEzAhlnQ0 ._1ejm_opHNGac1MJGDkbrj9 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    grid-gap: 10px;
    gap: 10px; }
    ._1csmNUycqv_JzBEzAhlnQ0 ._1ejm_opHNGac1MJGDkbrj9 ._3sMFzkS6ZVk9EZuBJ62Fth {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      grid-gap: 6px;
      gap: 6px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      ._1csmNUycqv_JzBEzAhlnQ0 ._1ejm_opHNGac1MJGDkbrj9 ._3sMFzkS6ZVk9EZuBJ62Fth button {
        position: relative; }
    ._1csmNUycqv_JzBEzAhlnQ0 ._1ejm_opHNGac1MJGDkbrj9 ._1dg1enzx3-27KQts3yuijr {
      padding: 4px;
      border: 1px solid #2892d0;
      border-radius: 3px;
      color: #2892d0; }

.pa79fZV6g1zecJlK6zsa4 {
  position: absolute;
  bottom: -25px;
  right: 0;
  font-size: 0.8rem;
  padding: 5px 5px 4px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }
  .pa79fZV6g1zecJlK6zsa4:before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 60%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent; }
