._3jtJT5oD9BR-wmLu4U1g3i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._3jtJT5oD9BR-wmLu4U1g3i ._1-zxH4beyY_DYfhuBqsMqA {
    font-size: 14px;
    font-weight: 600;
    color: #051c26;
    margin-bottom: 10px; }
  ._3jtJT5oD9BR-wmLu4U1g3i ._3g3SPJArZ9CY4bwJoA5rKX {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  ._3jtJT5oD9BR-wmLu4U1g3i ._1igUAjSJjbC6Tc3catIPis {
    position: relative;
    margin-right: 0.5rem;
    min-width: 200px; }
  ._3jtJT5oD9BR-wmLu4U1g3i ._2flfVkeEC9UXBdD8Ai3peh {
    position: absolute;
    z-index: -1;
    height: 1px;
    width: 1px; }
  ._3jtJT5oD9BR-wmLu4U1g3i ._30QUWU9gHLuDhN1WWO-jm7 {
    background-color: #e6eaf2;
    display: block;
    padding: 10px 30px 10px 10px;
    min-width: 200px;
    border-radius: 3px;
    font-size: 14px;
    color: #5c6066;
    height: 16px; }
    ._3jtJT5oD9BR-wmLu4U1g3i ._30QUWU9gHLuDhN1WWO-jm7.XBJgsu5LhSJhqixWVXC47:before {
      content: '****************************************************************'; }
  ._3jtJT5oD9BR-wmLu4U1g3i ._2RFjjTNj6Y0fbDgb-q2OVk {
    right: 10px;
    position: absolute;
    font-size: 1rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer; }
  ._3jtJT5oD9BR-wmLu4U1g3i ._1P9gJxJAxdgoNpjCEyciSx {
    height: 36px;
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff;
    margin-right: 0.2rem; }
    ._3jtJT5oD9BR-wmLu4U1g3i ._1P9gJxJAxdgoNpjCEyciSx:hover {
      background: #34a9e9;
      color: #fff; }
  ._3jtJT5oD9BR-wmLu4U1g3i ._3o2uA09zzoje6q4064XrwV {
    position: absolute;
    bottom: -25px;
    right: 0;
    font-size: 0.8rem;
    padding: 5px 5px 4px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
    opacity: 0; }
    ._3jtJT5oD9BR-wmLu4U1g3i ._3o2uA09zzoje6q4064XrwV:before {
      content: ' ';
      position: absolute;
      bottom: 100%;
      /* At the top of the tooltip */
      left: 60%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent black transparent; }
    ._3jtJT5oD9BR-wmLu4U1g3i ._3o2uA09zzoje6q4064XrwV._3OvX_Yy-x0eWg8rxC-AwFv {
      opacity: 1; }
