._2pcJpynIf-utqGHMfxhC1- ._2nG-Du7hSbGvT5FDTBbhLA {
  color: #051c26;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: block;
  position: relative; }

._2pcJpynIf-utqGHMfxhC1- ._1nPmZTiE2ArqFciNTUAfOD {
  background-color: #ebebeb;
  padding: 15px 10px;
  margin-bottom: 1.5625rem;
  color: #565656; }

._2pcJpynIf-utqGHMfxhC1- ._2-24urfQI6jOcptV0Cr5BF {
  color: #657881;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  display: block;
  position: relative; }

._2pcJpynIf-utqGHMfxhC1- .smNAjgCpKKnFnZqfmCLGB {
  border-top: 1px solid #eaeaea;
  padding: 30px 0; }

._2pcJpynIf-utqGHMfxhC1- ._2hBgAyYQHAVydz4Yv0OTtP {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  margin-bottom: 3rem; }
  ._2pcJpynIf-utqGHMfxhC1- ._2hBgAyYQHAVydz4Yv0OTtP ._3yXWO2bydKlR9eLiSLOHvT {
    min-width: 70px; }
  ._2pcJpynIf-utqGHMfxhC1- ._2hBgAyYQHAVydz4Yv0OTtP ._2jYIWGxc3T65NsajOZ7-S4 {
    color: #657881;
    line-height: 1.2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column; }
    ._2pcJpynIf-utqGHMfxhC1- ._2hBgAyYQHAVydz4Yv0OTtP ._2jYIWGxc3T65NsajOZ7-S4 ._2KCA8D1wd98i0R1PM3kY8P {
      color: #051c26;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.5rem; }
    ._2pcJpynIf-utqGHMfxhC1- ._2hBgAyYQHAVydz4Yv0OTtP ._2jYIWGxc3T65NsajOZ7-S4 ._2NZBJLk8JtXcpSIBeExkAd {
      font-size: 0.9rem; }
    ._2pcJpynIf-utqGHMfxhC1- ._2hBgAyYQHAVydz4Yv0OTtP ._2jYIWGxc3T65NsajOZ7-S4 .fjFw9CU9TiUsbii5v02vY {
      opacity: 0.8;
      font-size: 0.8rem; }

._2pcJpynIf-utqGHMfxhC1- ._3281CwH3C7idenArGyjf0E {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 10px 0; }
