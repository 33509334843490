._3K1orsW_LpKDnOhv-89BwM {
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -ms-flex-positive: 1;
      flex-grow: 1;
  color: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: none; }
  ._3K1orsW_LpKDnOhv-89BwM.AsEkEF6Js-wb1QyHkjcp_ {
    display: block; }
  ._3K1orsW_LpKDnOhv-89BwM:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.05); }
  ._1yZ1BOBUFfO5emrTxw76E0 {
    margin: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    height: 65px;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .k0z7OEltDwZn0V6DFOROw, .GePiRo8T4QeOyQrBQv95d {
    color: #fff;
    margin: 0; }
  ._3GdkGqKPVJhc-pnuWTTCJy {
    height: 150px;
    margin-bottom: 2rem; }
  ._2_hjWknlBQ0kAgDdyKl38a {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem; }
    ._1S-NepfChXKD6tO2gtZHZo {
      text-transform: capitalize;
      color: #d6731a; }
    ._18L8K7G9_vUPOk42f6bT3L {
      color: #02af91; }
    .tTdqLmzTPXQeWAOfXAcDp {
      color: #2892d0; }
  .k0z7OEltDwZn0V6DFOROw {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
  ._3AIZzJCFfyqWVb7OsneogV {
    color: #9a9c9d;
    font-size: 1rem;
    margin-bottom: 0;
    position: relative;
    overflow: hidden; }
    ._3AIZzJCFfyqWVb7OsneogV:before {
      width: 100%;
      height: 3px;
      background: red;
      position: absolute;
      -webkit-transform: rotate(-14deg);
              transform: rotate(-14deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      top: calc(50% - 2px);
      left: 0;
      pointer-events: none;
      content: ''; }
  ._2U8EzUq1Gga_bLX5lMpTsQ {
    color: #a9b2be;
    margin-top: 1rem; }
  ._3K1orsW_LpKDnOhv-89BwM._2vQFRIw2PY5e-LHpyEsEGz {
    position: relative;
    -webkit-box-shadow: 0 0 0 5px #d6731a inset;
            box-shadow: 0 0 0 5px #d6731a inset; }
  ._3hP70-DKvDIsdvEIUmeKDS {
    background-color: #d6731a; }
    ._3hP70-DKvDIsdvEIUmeKDS:hover {
      background-color: #e58025; }
    ._3hP70-DKvDIsdvEIUmeKDS:active {
      background-color: #cd6e19; }
    ._9CTQ_2MdI70TWNZm3SArL {
      background-color: #00a69c; }
      ._9CTQ_2MdI70TWNZm3SArL:hover {
        background-color: #00c0b4; }
      ._9CTQ_2MdI70TWNZm3SArL:active {
        background-color: #009c92; }
    .DqATJtrCyiIngEYPSj2Cs {
      background-color: #2892d0; }
      .DqATJtrCyiIngEYPSj2Cs:hover {
        background-color: #399ed9; }
      .DqATJtrCyiIngEYPSj2Cs:active {
        background-color: #268cc7; }
  ._1ZNMZq1FbJjY1EoFbBgrZZ {
    position: absolute;
    top: -32px;
    left: 0;
    right: 0;
    background-color: #d6731a;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    ._2_mXwD1KaDVZNzdDsg1EZQ {
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.2em;
      margin-bottom: 0;
      margin-top: 5px; }
  @media (max-width: 991px) {
    ._3K1orsW_LpKDnOhv-89BwM {
      width: 100%; }
      ._3K1orsW_LpKDnOhv-89BwM:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05); }
      ._3K1orsW_LpKDnOhv-89BwM:last-child {
        margin-top: 2rem; } }
