._2RkPnfvybm2SgWeLGEDRcI {
  background: #030b0d;
  padding: 6rem 2rem 3rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%; }
  ._2RkPnfvybm2SgWeLGEDRcI .mVhICmPhyEMscaF_OHjq3 {
    text-align: center;
    margin-bottom: 0;
    color: #fff; }
    @media (max-width: 991px) {
      ._2RkPnfvybm2SgWeLGEDRcI .mVhICmPhyEMscaF_OHjq3 {
        font-size: 1.75rem;
        line-height: 1.8; } }
  ._2RkPnfvybm2SgWeLGEDRcI ._3ji6ecw51D4wiieDkASMdO {
    color: #fff; }
  ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    list-style: none;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 2rem 0;
    max-width: 1400px;
    margin: 0 auto; }
    @media (max-width: 991px) {
      ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I {
        grid-template-columns: auto;
        grid-gap: 1rem;
        width: 100%;
        padding: 0; } }
    ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o {
      border-radius: 10px;
      padding: 2rem;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      width: 340px; }
      @media (max-width: 991px) {
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o {
          max-width: 100%;
          padding: 2rem 0; } }
      ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .CljcWFC07CqtHqeOx0KrI {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row;
                flex-flow: row;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-bottom: 2rem; }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .CljcWFC07CqtHqeOx0KrI .QjDRBZa-Ps1R1ORnnee-X {
          text-align: center; }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .CljcWFC07CqtHqeOx0KrI ._3Z2q4mBWRfqsp3_D54hf-R {
          height: 40px;
          margin-right: 1rem; }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .CljcWFC07CqtHqeOx0KrI ._3sMpXIhkbrKUqVe6N96RyM {
          max-width: 80px;
          margin-right: 1rem; }
          @media (max-width: 991px) {
            ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .CljcWFC07CqtHqeOx0KrI ._3sMpXIhkbrKUqVe6N96RyM {
              margin: 1rem 0; } }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .CljcWFC07CqtHqeOx0KrI .vQJ2mOUUm9IR-cVEYtpMt {
          font-size: 1.2rem;
          margin-bottom: 0; }
          @media (max-width: 991px) {
            ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .CljcWFC07CqtHqeOx0KrI .vQJ2mOUUm9IR-cVEYtpMt {
              font-size: 1.4rem; } }
      ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .QjDRBZa-Ps1R1ORnnee-X {
        font-size: 1.1rem;
        line-height: 1.8;
        margin-bottom: 2rem;
        height: calc(100% - 215px); }
        @media (max-width: 991px) {
          ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .QjDRBZa-Ps1R1ORnnee-X {
            font-size: 1.2rem; } }
      ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column;
        font-weight: 600; }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 > ._2bS7S-1zqCX_mzMnFtfwhG,
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 > a {
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          text-align: center; }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 a:first-child {
          margin-bottom: 1rem; }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 > div a:first-child {
          margin: 0; }
        @media (max-width: 991px) {
          ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 > div {
            -webkit-box-pack: space-evenly;
                -ms-flex-pack: space-evenly;
                    justify-content: space-evenly; }
            ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 > div a {
              margin: 0; } }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 .z2d1cNZqaGc1dI9vZnpEG {
          background: #233033;
          color: #e9ebf0;
          border-radius: 5px;
          padding: 0.625rem 0.938rem; }
          ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 .z2d1cNZqaGc1dI9vZnpEG:hover {
            background: #1a2528; }
          ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 .z2d1cNZqaGc1dI9vZnpEG:active {
            background: #161f21; }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 ._31Snbw7XozcuGrTUqXGDQS > a {
          text-align: center;
          display: block; }
        ._2RkPnfvybm2SgWeLGEDRcI ._2HVoCRegUzH8Dfv9VOCA3I ._20IA0nNiYRL3H55zrQhP2o .j8neyAEAO0pnd528H6QM9 ._31Snbw7XozcuGrTUqXGDQS ul {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row;
                  flex-flow: row; }
