.XVEZRUnC3mPLFfZdQm8sT {
  text-align: center;
  padding-right: 2rem;
  padding-left: 2rem; }
  .XVEZRUnC3mPLFfZdQm8sT:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.2); }
  ._3rrZEfnp93tuXMRo828mM2 {
    color: #fff;
    font-size: 1.5rem; }
  @media (max-width: 991px) {
    .XVEZRUnC3mPLFfZdQm8sT {
      padding: 2rem; }
      .XVEZRUnC3mPLFfZdQm8sT:first-child {
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2); } }
