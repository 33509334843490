._3EJUQSQOFT7qhCEKUxewri {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 100%; }

._29ezLoYbYb-2JgaAc3OB6h {
  background: #fff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px; }

._3u0rdWc3I4nM8PIQxoXGJq {
  padding: 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
