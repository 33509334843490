.Ta_mrsQDh07RJYIBAtR8g > section {
  padding: 0 2rem 5rem; }
  @media (max-width: 991px) {
    .Ta_mrsQDh07RJYIBAtR8g > section {
      padding: 0 2rem 3rem; } }

._2wdwJsi3xmPDYqqukosYRK {
  margin-top: -6rem; }
  @media (max-width: 991px) {
    ._2wdwJsi3xmPDYqqukosYRK {
      margin-top: 0; } }
