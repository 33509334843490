._3PdqHVDF2NKDeS4u8eixnV {
  padding-bottom: 4rem;
  background: linear-gradient(172deg, #030b0d 50%, #fff 50%);
  background-size: cover; }
  ._33jCwxRGF7hGiJibUIUssI {
    min-height: auto; }
  ._3y24VGpxCZlKcS1WTJ53Tr {
    color: #fff;
    text-align: center; }
  ._1l7N4B781Kpg0n3bi7kyJG {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem; }
  @media (max-width: 767px) {
    ._3PdqHVDF2NKDeS4u8eixnV {
      background: linear-gradient(172deg, #030B0D 20%, #fff 0%, #fff 50%); }
      ._1l7N4B781Kpg0n3bi7kyJG {
        grid-template-columns: 1fr;
        grid-row-gap: 3rem; } }
