._265pS2WEWXBADAnraSr4ah {
  color: #657881;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: block; }

.ApyaTRNDJl0y76WmrzaKX {
  color: #657881;
  font-size: 0.8rem;
  display: block;
  line-height: 1.2rem; }

._2vcPoiuFfMhHu2mKzEbJMN {
  color: #657881;
  font-size: 0.8rem;
  font-weight: bold; }

.ebYCE983nTpH0WqIiszoR {
  font-size: 14px;
  font-weight: 600;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  margin-bottom: 0.5rem;
  display: block; }

._3glFgSY8Fx7Z0W86aQoYV8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }

._2Va2CiJIzy3vlwukfilSMS {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-bottom: 1rem;
  width: 650px;
  max-width: 100%; }
  ._2Va2CiJIzy3vlwukfilSMS ._2Dc524YdfPHuHar7wQJmY8 {
    margin-right: 0.5rem;
    margin-top: 50px; }

._35aqD2FYtQX8lc38nhJcHI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  border: 1px solid #e6e6e6;
  margin-right: 2rem; }
  @media (max-width: 991px) {
    ._35aqD2FYtQX8lc38nhJcHI {
      margin: 0; } }
  ._35aqD2FYtQX8lc38nhJcHI ._1oEMWtYVnQHFWgYUtjFAuc {
    padding: 15px;
    min-height: 70px;
    cursor: pointer; }
  ._35aqD2FYtQX8lc38nhJcHI ._3qz9FRE3YU4dlX-Ep5PVi3 {
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block; }
  ._35aqD2FYtQX8lc38nhJcHI ._265pS2WEWXBADAnraSr4ah {
    line-height: 1.5rem;
    margin: 0; }

._3pYKAQGdcr5BXRNecBq4CE {
  background: #1791d3 0% 0% no-repeat padding-box;
  color: #ffffff;
  padding: 1rem 2rem; }
  ._3pYKAQGdcr5BXRNecBq4CE:hover {
    background: #34a9e9;
    color: #fff; }

._1UsdmL0oygYARRFnR1xR4y {
  width: 100%;
  border-top: 1px solid #e6e6e6;
  font-size: 0.8rem;
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  ._1UsdmL0oygYARRFnR1xR4y ._2fshMhBBeC__4-yQRc0opF {
    list-style: none;
    margin: 0; }
    ._1UsdmL0oygYARRFnR1xR4y ._2fshMhBBeC__4-yQRc0opF li {
      margin-bottom: 5px; }
    ._1UsdmL0oygYARRFnR1xR4y ._2fshMhBBeC__4-yQRc0opF strong {
      margin-right: 1rem;
      width: 65px;
      max-width: 100%;
      display: inline-block; }
  ._1UsdmL0oygYARRFnR1xR4y ._2TByETax2F7HNKKYpv5qtI {
    display: inline-block;
    background-color: #e6eaf2;
    padding: 10px; }

._2MNgsWSIpM4Hh_p8E8xqCw {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 1rem 0; }
