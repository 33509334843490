._3siZZDifiGTkMWNFd3a6gH {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin: 50px 50px 10px 50px;
  display: block; }

.QSGfxZxZu1KWAiNiVewlv {
  padding: 10px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 800px; }
  .QSGfxZxZu1KWAiNiVewlv a {
    color: #49a3d0;
    text-decoration: none; }

.rZKDiBKZClFXoMJT2mOq7 {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px;
  line-height: 1.5em; }

._3Q5y0ScU4G0TAfDBFMhibj {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._3Q5y0ScU4G0TAfDBFMhibj ._1tBFv7OEzkojZppOTRLXBB {
    border-radius: 3px;
    font-size: 14px;
    padding: 10px;
    margin: 10px;
    cursor: pointer; }
