._3NlULQZpWJH0mmzw2gizFW {
  max-width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0 4rem 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: calc(100vh - 80px); }
