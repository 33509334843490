._1xf9DZWKzzKY1SPeEDksIw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 1rem;
  max-width: 100%; }
  ._1xf9DZWKzzKY1SPeEDksIw ._3SpXpzHzQTh81MwZa-a1kP {
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 0.5rem; }

._33ZpbrmaYoAedThzWdzFHM {
  background: none;
  border: 1px solid #b1b4ba;
  padding: 0.5rem;
  border-radius: 3px; }
  @media (max-width: 991px) {
    ._33ZpbrmaYoAedThzWdzFHM[disabled] {
      padding-left: 0; } }
