.CEI0onJzJoONBcOrZcPG2 ._3QFTq3eR5DJ-pXwE0Vl9so {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

@-webkit-keyframes _12qy4kPHg8zEK4Rm1VgLhI {
  0% {
    -webkit-transform: translateX(calc(120px * 8));
            transform: translateX(calc(120px * 8)); }
  100% {
    -webkit-transform: translateX(calc(-130px * 8));
            transform: translateX(calc(-130px * 8)); } }

@keyframes _12qy4kPHg8zEK4Rm1VgLhI {
  0% {
    -webkit-transform: translateX(calc(120px * 8));
            transform: translateX(calc(120px * 8)); }
  100% {
    -webkit-transform: translateX(calc(-130px * 8));
            transform: translateX(calc(-130px * 8)); } }

.CEI0onJzJoONBcOrZcPG2 {
  position: relative;
  background: #fff;
  padding: 2rem 2rem 0;
  grid-gap: 3rem;
  gap: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  word-break: break-word;
  max-width: 100%;
  overflow-x: hidden; }
  @media all and (min-width: 415px) {
    .CEI0onJzJoONBcOrZcPG2 {
      padding: 0; } }
  .CEI0onJzJoONBcOrZcPG2 ._3QFTq3eR5DJ-pXwE0Vl9so {
    color: #7c828d;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 500;
    margin: 0;
    text-align: center; }
    @media (max-width: 991px) {
      .CEI0onJzJoONBcOrZcPG2 ._3QFTq3eR5DJ-pXwE0Vl9so {
        font-size: 1rem; } }
  .CEI0onJzJoONBcOrZcPG2 .UQ9Q1TCyWT4hcov3zeevj {
    -webkit-animation: _12qy4kPHg8zEK4Rm1VgLhI 25s linear infinite;
            animation: _12qy4kPHg8zEK4Rm1VgLhI 25s linear infinite;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(250px * 16);
    list-style: none;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    grid-gap: 5rem;
    gap: 5rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (max-width: 991px) {
      .CEI0onJzJoONBcOrZcPG2 .UQ9Q1TCyWT4hcov3zeevj {
        grid-gap: 1rem;
        gap: 1rem; } }
    @media all and (min-width: 2000px) {
      .CEI0onJzJoONBcOrZcPG2 .UQ9Q1TCyWT4hcov3zeevj {
        grid-gap: 7rem;
        gap: 7rem; } }
    .CEI0onJzJoONBcOrZcPG2 .UQ9Q1TCyWT4hcov3zeevj ._2m7-Z7ROgz2y73rLmCwa61 {
      margin: 1rem;
      max-height: 20px;
      -webkit-transition: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transition-duration: 400ms;
              transition-duration: 400ms;
      -webkit-transition-property: all;
      transition-property: all;
      -webkit-filter: grayscale(1);
              filter: grayscale(1);
      opacity: 0.6; }
      .CEI0onJzJoONBcOrZcPG2 .UQ9Q1TCyWT4hcov3zeevj ._2m7-Z7ROgz2y73rLmCwa61:hover {
        -webkit-filter: grayscale(0);
                filter: grayscale(0);
        opacity: 1; }
      @media all and (min-width: 802px) {
        .CEI0onJzJoONBcOrZcPG2 .UQ9Q1TCyWT4hcov3zeevj ._2m7-Z7ROgz2y73rLmCwa61 {
          max-height: 50px; } }
