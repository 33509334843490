._2JHrOgBPs7hUTM_VxK0KPF {
  position: relative;
  min-width: 200px; }
  ._2JHrOgBPs7hUTM_VxK0KPF ._1d0r4TANcz2yTw5JD1zBEJ {
    cursor: pointer;
    width: 1px; }
  ._2JHrOgBPs7hUTM_VxK0KPF ._1jVsj7Ld8Yr06ugqPrcYex {
    font-size: 0.875rem;
    color: #5c6066;
    font-weight: 600;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border: 1px solid #e6eaf2;
    border-radius: 3px;
    padding: 0.625rem;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    ._2JHrOgBPs7hUTM_VxK0KPF ._1jVsj7Ld8Yr06ugqPrcYex:hover {
      background: #d2d9e8; }
    ._2JHrOgBPs7hUTM_VxK0KPF ._1jVsj7Ld8Yr06ugqPrcYex:active {
      background: #cbd3e4; }
  ._2JHrOgBPs7hUTM_VxK0KPF ._3NP3oSifJBt64Zc0Tyo-QF {
    font-size: 0.875rem;
    color: #5c6066;
    font-weight: 600;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    top: 40px;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid #e6eaf2;
    border-radius: 3px;
    z-index: 1;
    display: none; }
    @media all and (max-width: 660px) {
      ._2JHrOgBPs7hUTM_VxK0KPF ._3NP3oSifJBt64Zc0Tyo-QF {
        width: 100%; } }
    ._2JHrOgBPs7hUTM_VxK0KPF ._3NP3oSifJBt64Zc0Tyo-QF._2e9DL-Rvh947lxcotw8PdZ {
      display: block; }
    ._2JHrOgBPs7hUTM_VxK0KPF ._3NP3oSifJBt64Zc0Tyo-QF li {
      color: #2c2c2c;
      padding: 0.625rem 1.25rem;
      cursor: pointer; }
      ._2JHrOgBPs7hUTM_VxK0KPF ._3NP3oSifJBt64Zc0Tyo-QF li:hover {
        background-color: #f0f2f7; }

._1d0r4TANcz2yTw5JD1zBEJ {
  opacity: 0;
  position: absolute; }

.Q2WeCwtXSvaLdLZYr8Oq2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .Q2WeCwtXSvaLdLZYr8Oq2:after {
    content: ' ';
    margin-left: 0.313rem; }
  .Q2WeCwtXSvaLdLZYr8Oq2.k4p_W2xJjLTBEuEAdgsaI:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  .Q2WeCwtXSvaLdLZYr8Oq2.cy9klGD6YCPiP3tXsICFj:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }
