._27uvBfogR_CgU4LJuzFcR2 {
  margin-bottom: 8rem; }
  ._1OV0z9TfujZCoK5hQe9Bpl {
    margin-top: 5rem;
    margin-bottom: 3rem;
    text-align: center; }
  ._7W7scIlaUGFRexzXWqTA0, ._3okskxgkeiOKO8ALFO5Ego {
    color: #fff; }
  ._7W7scIlaUGFRexzXWqTA0 {
    margin-bottom: 1rem;
    font-size: 2.5rem; }
  ._3okskxgkeiOKO8ALFO5Ego {
    color: #a9b2be; }
  @media (max-width: 991px) {
    ._27uvBfogR_CgU4LJuzFcR2 {
      margin-bottom: 4rem; } }

._1RySQL9q0FR9axzsuUUDag {
  background-color: #22272b;
  border-radius: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-bottom: 2rem;
  min-height: 510px; }
  @media (max-width: 991px) {
    ._1RySQL9q0FR9axzsuUUDag {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      min-height: auto; } }

._17QRyGIYmpmvovfSvM0pVL {
  text-align: center; }
  ._1Ui0fbiAGfT-Pp34xoeAgc {
    margin-bottom: 2rem; }
  ._2yTK31yAxMNnMyyrPRU-Uv:not(:last-child) {
    margin-right: 2rem; }
  ._3gEmRSjhfiooMkPMMMtA8a {
    vertical-align: middle;
    margin-right: 1rem; }
  ._2DMsKq9gRN3j4lcWL0Hs4Y {
    display: inline-block;
    color: #fff; }

._3Jhp_o2lYjIvXhRcXgy0JU {
  -ms-flex-item-align: center;
      align-self: center; }

._2iPe7WSAC8tB8jJRVkxmZH {
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.5rem; }
  ._2iPe7WSAC8tB8jJRVkxmZH .ri76Y-oJQvD3WD3UG30mQ {
    display: block; }

._2KCqPce1fKIPysIbe0Nz3L {
  display: block;
  color: #fff;
  margin-bottom: 1rem; }
