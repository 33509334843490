._20COttDLECjH255CFf76TK {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

.MsZQDMsP5HywIr5i89vqc {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: 600;
  margin-bottom: 1.5rem; }

._3eytQN4lkJ_p0IcAWzTBR8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  ._3eytQN4lkJ_p0IcAWzTBR8 ._1dncKKXBkn3YLB28kw23wh {
    margin-right: 1rem;
    background: transparent;
    padding: 0.5rem 0.6rem;
    border: 1px solid #c7ccda;
    border-radius: 3px;
    min-width: 270px;
    margin-bottom: 1rem; }
    ._3eytQN4lkJ_p0IcAWzTBR8 ._1dncKKXBkn3YLB28kw23wh:focus {
      border: 1px solid #2892d0;
      outline: 0; }
    ._3eytQN4lkJ_p0IcAWzTBR8 ._1dncKKXBkn3YLB28kw23wh::-webkit-input-placeholder {
      color: #c7ccda;
      opacity: 1; }
    ._3eytQN4lkJ_p0IcAWzTBR8 ._1dncKKXBkn3YLB28kw23wh::-moz-placeholder {
      color: #c7ccda;
      opacity: 1; }
    ._3eytQN4lkJ_p0IcAWzTBR8 ._1dncKKXBkn3YLB28kw23wh:-ms-input-placeholder {
      color: #c7ccda;
      opacity: 1; }
    ._3eytQN4lkJ_p0IcAWzTBR8 ._1dncKKXBkn3YLB28kw23wh::-ms-input-placeholder {
      color: #c7ccda;
      opacity: 1; }
    ._3eytQN4lkJ_p0IcAWzTBR8 ._1dncKKXBkn3YLB28kw23wh::placeholder {
      color: #c7ccda;
      opacity: 1; }
  ._3eytQN4lkJ_p0IcAWzTBR8 ._11etSkeD4JkSROzBE7eAT1 {
    margin-bottom: 1rem; }

.KerOjIaRku6Hzc9B2mf53 {
  color: #00bf87; }

._2QrpQI2L0kB8ZMD28ZUSF6 {
  color: #ff5a5a; }
