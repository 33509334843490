.MXyXY7Agme6OPirzUUxE6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 20px; }
  .MXyXY7Agme6OPirzUUxE6:first-child {
    margin-left: 0; }
    @media all and (max-width: 1100px) {
      .MXyXY7Agme6OPirzUUxE6:first-child {
        margin-top: 0; } }
  ._1tjXt0R4PYhvk7sO8OJ4mT {
    display: inline-block;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    margin: 0;
    margin-top: 20px;
    padding: 0;
    background: transparent;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 24px; }
    ._1tjXt0R4PYhvk7sO8OJ4mT:hover {
      cursor: pointer; }
    ._1tjXt0R4PYhvk7sO8OJ4mT:active, ._1tjXt0R4PYhvk7sO8OJ4mT:focus {
      outline: none; }
  @media all and (max-width: 1100px) {
    .MXyXY7Agme6OPirzUUxE6 {
      margin-top: 20px;
      margin-left: 0;
      padding: 20px;
      border-radius: 20px;
      background: #000a0f; } }
