.SCSWj7h1rRNLXNSD2t95d {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 100%; }
  .SCSWj7h1rRNLXNSD2t95d h2 {
    font-size: 1rem;
    color: #404040; }
