._3HBhMarxUWLV0g8PgyH4dN {
  margin: auto;
  margin-bottom: 60px;
  max-width: 800px; }
  ._3bhSPBtD_d7odZ24N9jxaS {
    color: #fff;
    margin-bottom: 4rem;
    text-align: center; }
  ._2yAGARrRzqsPrwyXBsMwwk {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: auto;
    margin-bottom: 30px;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
    .v47BDPdkVCJtA32iLxXKB {
      color: white;
      display: inline-block;
      font-size: 28px;
      line-height: 28px; }
