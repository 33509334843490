._3ooJpPVIjV-zzDySJvDylL {
  border-top: 1px solid #eaeaea;
  padding: 30px 0; }
  ._3ooJpPVIjV-zzDySJvDylL ._2xf5naHVhTCpoBYJrSJCqB {
    background-color: #ebebeb;
    padding: 15px 10px;
    margin-bottom: 1.5625rem;
    color: #565656; }
  ._3ooJpPVIjV-zzDySJvDylL ._24VqbpKpoMzgrmpNWUiers {
    color: #051c26;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: block;
    position: relative; }
  ._3ooJpPVIjV-zzDySJvDylL .mkH7TIg2hROdC6rJ5UMuu {
    margin: 10px 0 20px 0; }
  ._3ooJpPVIjV-zzDySJvDylL ._2_AZzqCo8khRKmoch1aVEN {
    color: red;
    font-size: 14px;
    display: block;
    margin: 5px 0px; }
  ._3ooJpPVIjV-zzDySJvDylL .Pau2_uZDMDLulHOoayXQC {
    color: #657881; }
  ._3ooJpPVIjV-zzDySJvDylL ._2WduyuyWdlpTl5MUcomawm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
