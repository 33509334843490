._3Ctmwd3qbbSKxv374cMK5u {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 500px; }

.rthcnplpkGZxoH0F-iQ7u {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._3q7lfttTrdU_duMvNtheiX {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px;
  line-height: 1.4em; }

._1QlUaXkVSno8bFIz91ma_T {
  color: #fa1854; }

._3e-lNn4NkOsRwuPV22ddci {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._3e-lNn4NkOsRwuPV22ddci ._3TUIuImqp1tOSSjz4LctVy {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    background: #f0f0f0;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer;
    text-decoration: none;
    text-align: center; }
    ._3e-lNn4NkOsRwuPV22ddci ._3TUIuImqp1tOSSjz4LctVy:hover {
      border: 1px solid silver; }
  ._3e-lNn4NkOsRwuPV22ddci .Mxe3ZPdN3AnZAx7UEgJAs {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._3e-lNn4NkOsRwuPV22ddci .Mxe3ZPdN3AnZAx7UEgJAs:hover {
      background: #34a9e9;
      color: #fff; }

._3uIE7ehQfQLBS7oNMhRAex {
  margin: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._3uIE7ehQfQLBS7oNMhRAex ._3q7lfttTrdU_duMvNtheiX,
  ._3uIE7ehQfQLBS7oNMhRAex .gDYKAtC6aNuMjgKDe-Zhu {
    margin: 1rem 0;
    text-align: center; }
