._8WWHCyhg-riYXVbQj0IEa {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._8WWHCyhg-riYXVbQj0IEa {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._8WWHCyhg-riYXVbQj0IEa {
      font-size: 2.5rem;
      line-height: 1.7; } }

.qfe2qhMCgJzY_GYxP6gFr {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .qfe2qhMCgJzY_GYxP6gFr {
      font-size: 1.25rem;
      line-height: 1.8; } }

._1PwnhA9xhrN1KoIPL6eHXO {
  padding: 6rem 2rem 0; }
  @media (max-width: 991px) {
    ._1PwnhA9xhrN1KoIPL6eHXO {
      padding: 0; } }
  ._1PwnhA9xhrN1KoIPL6eHXO p {
    color: #fff;
    margin: 0;
    word-break: break-word; }

.Fv8t7lXhS5udAhk8zenVE {
  max-width: 1400px;
  margin: 0 auto; }
  .Fv8t7lXhS5udAhk8zenVE._2R5najtL7Om8dCTt003I2a {
    background: #1a70d4;
    background: linear-gradient(-36deg, #1a70d4 7%, #2591cf 71%, #2fa6eb 100%); }
    .Fv8t7lXhS5udAhk8zenVE._2R5najtL7Om8dCTt003I2a ._8WWHCyhg-riYXVbQj0IEa:hover {
      -webkit-filter: drop-shadow(0px 1px 4px #030b0d);
              filter: drop-shadow(0px 1px 4px #030b0d); }
  .Fv8t7lXhS5udAhk8zenVE.uvpu-rhOfgXdvL7S60m2z {
    background: #161819; }
    .Fv8t7lXhS5udAhk8zenVE.uvpu-rhOfgXdvL7S60m2z ._8WWHCyhg-riYXVbQj0IEa:hover {
      -webkit-filter: drop-shadow(0px 1px 3px #c564e4);
              filter: drop-shadow(0px 1px 3px #c564e4); }
  .Fv8t7lXhS5udAhk8zenVE._3O9utbcqK_3IrUFCc9tYJ1 {
    background: #02a774;
    border-radius: 20px; }
    @media (max-width: 991px) {
      .Fv8t7lXhS5udAhk8zenVE._3O9utbcqK_3IrUFCc9tYJ1 {
        border-radius: 0; } }
    .Fv8t7lXhS5udAhk8zenVE._3O9utbcqK_3IrUFCc9tYJ1 ._8WWHCyhg-riYXVbQj0IEa:hover {
      -webkit-filter: drop-shadow(0px 1px 4px #030b0d);
              filter: drop-shadow(0px 1px 4px #030b0d); }
    .Fv8t7lXhS5udAhk8zenVE._3O9utbcqK_3IrUFCc9tYJ1 ._3-po5XjVfRTCsqaBki8_To {
      grid-gap: 3rem;
      gap: 3rem; }
      @media (max-width: 991px) {
        .Fv8t7lXhS5udAhk8zenVE._3O9utbcqK_3IrUFCc9tYJ1 ._3-po5XjVfRTCsqaBki8_To {
          grid-gap: 1rem;
          gap: 1rem; } }

._3-po5XjVfRTCsqaBki8_To {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1rem;
  grid-gap: 1rem;
  gap: 1rem; }
  @media (max-width: 991px) {
    ._3-po5XjVfRTCsqaBki8_To {
      padding: 2rem 1rem;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }

._3X5SDsgiad4MbzxBuZR93z {
  max-height: 100%;
  width: auto; }

._8WWHCyhg-riYXVbQj0IEa {
  text-align: center; }

.qfe2qhMCgJzY_GYxP6gFr {
  margin-top: 0.5rem;
  text-align: center; }
