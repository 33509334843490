._3o1Fg-bjgy3f8BKt4lLtgN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

._3MiJeUHCE4iwDjGn_Aqelf {
  margin-bottom: 1rem; }

._3HabhfVyHekL1O5nEDrX80 {
  color: #657881;
  font-size: 0.6rem;
  margin-top: 0.5rem;
  display: block; }
  ._3HabhfVyHekL1O5nEDrX80 > a:hover {
    color: #2380b6;
    text-decoration: underline; }

.izpFVhBnpq2BZ80-sRuX3 {
  margin: 1rem 0;
  max-width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .izpFVhBnpq2BZ80-sRuX3 ._2lzTbuxpGmyZFU_MYN1Oum {
    font-size: 0.875rem;
    font-weight: 600;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    margin: 0.625rem 1.25rem;
    display: block; }
    .izpFVhBnpq2BZ80-sRuX3 ._2lzTbuxpGmyZFU_MYN1Oum > a:hover {
      color: #2380b6;
      text-decoration: underline; }

.qDbGnu1tYK47d30AdrVcP {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }

._23RdddedjPXXcbcUdCBNNW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-bottom: 1rem;
  width: 600px;
  max-width: 100%; }
  ._23RdddedjPXXcbcUdCBNNW ._ZonFL_YCmxGQSPm-RcTu {
    margin-right: 0.5rem;
    margin-top: 2rem; }

._2zgLyISLZnMMLsfJbADlEz {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  width: 100%;
  border: 1px solid #e6e6e6;
  margin-right: 2rem; }
  @media (max-width: 991px) {
    ._2zgLyISLZnMMLsfJbADlEz {
      margin: 0; } }
  ._2zgLyISLZnMMLsfJbADlEz ._1Txko917y09KtrikzlInVt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 60px;
    cursor: pointer; }
  ._2zgLyISLZnMMLsfJbADlEz .WlTozGKu_j_CedXzINhrC {
    height: 30px;
    width: 30px;
    margin-left: 1rem; }
  ._2zgLyISLZnMMLsfJbADlEz ._3LcFd1tN6ULOAPL7xCIjl8 {
    padding: 0.9rem; }
  ._2zgLyISLZnMMLsfJbADlEz ._2LB0kuHcbi3yfNs-iZnSDl {
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block; }
  ._2zgLyISLZnMMLsfJbADlEz ._3BtDUQcdZkWONUYLH6t_FG {
    line-height: 1.5;
    font-size: 0.8rem; }

.MZh1CqEJBgxnOfxWdAecp {
  width: 100%;
  border-top: 1px solid #e6e6e6;
  font-size: 0.8rem;
  padding: 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

._13ZaUEf-XtmQ-EbYfiQ2BS {
  font-size: 0.875rem;
  display: block;
  margin: 1rem 0;
  color: #fa1854; }
  ._13ZaUEf-XtmQ-EbYfiQ2BS._1PcKFV4Z9tsNtOZxLdJGvn {
    color: #0a3807; }

.J07EaD3tKDeFKYXkAQ5tM {
  background: #2892d0 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 1rem 2rem;
  border: unset; }
  .J07EaD3tKDeFKYXkAQ5tM:hover {
    background: #2380b6; }
  .J07EaD3tKDeFKYXkAQ5tM:active {
    background: #217aae; }
  @media (max-width: 991px) {
    .J07EaD3tKDeFKYXkAQ5tM {
      width: 100%;
      display: block;
      text-align: center; } }

._3o1Fg-bjgy3f8BKt4lLtgN:invalid .J07EaD3tKDeFKYXkAQ5tM {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
