._1NBXpA6gJbveZBq3aMcQyJ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 80px); }

._2Nqz_ftadSlX19SbshynGT {
  margin: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center; }
  @media (max-width: 767px) {
    ._2Nqz_ftadSlX19SbshynGT {
      margin-top: 0; } }
  ._2Nqz_ftadSlX19SbshynGT ._3340bd4nA0IUIolVIYC3VW {
    font-size: 2.5rem;
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      ._2Nqz_ftadSlX19SbshynGT ._3340bd4nA0IUIolVIYC3VW {
        font-size: 1.8rem;
        margin-top: 0; } }
  ._2Nqz_ftadSlX19SbshynGT ._3xdF6ynrhniV7lzICTYE4K {
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 1.25rem;
    font-size: 1.5rem; }
    @media (max-width: 767px) {
      ._2Nqz_ftadSlX19SbshynGT ._3xdF6ynrhniV7lzICTYE4K {
        font-size: 1rem; } }
  ._2Nqz_ftadSlX19SbshynGT ._3qi0XheXXyT_Y4eSLIICVO {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-height: 140px;
    margin: 2.5rem auto; }

._2p72oUkJO3JiDvVNG7Rss5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  background-color: #00b4d8;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  padding: 0.85rem;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 300px;
  margin: 0 auto; }
  ._2p72oUkJO3JiDvVNG7Rss5:hover {
    color: #fff;
    background-color: #009bb9; }
  ._2p72oUkJO3JiDvVNG7Rss5:active, ._2p72oUkJO3JiDvVNG7Rss5:focus {
    color: #fff;
    background-color: #0092af; }
