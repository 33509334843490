._1aD4wRhJyg1CporySd5rOw {
  padding: 50px 50px 30px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 1024px; }

._2RBFJ0DIvZGalDvvIFjSl4 {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 15px; }

.ATrTiy2d3dYyRTo58QBiq {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px; }

.AafzzO6Uq34tRcaVHNBAI {
  font-weight: bold; }

._2V3Gup5rI9WTth-ZSt6oFL {
  color: red; }

._2CZ0NLcFJB4BREYWgjDeQ2 {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._2CZ0NLcFJB4BREYWgjDeQ2 ._3k-1fXnfFZQaD7LWoIRrmj {
    font-size: 14px;
    padding: 10px;
    margin: 10px; }
