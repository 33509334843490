.OKscAwwjFszAxkfTWV5lI {
  padding: 2rem;
  width: 700px;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  @media (max-width: 991px) {
    .OKscAwwjFszAxkfTWV5lI {
      min-width: auto; } }

.n0PDKg5TpS8P96o8W7N3n {
  color: #051c26;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2rem; }

._2X9MAAKek9nfYZIvyxEMN1 {
  color: #657881;
  line-height: 1.5rem; }
