._2TLklHpnvwDPIL-OLvrgW8 {
  -webkit-box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1);
          box-shadow: 0 10px 20px rgba(3, 11, 13, 0.1); }

._2TLklHpnvwDPIL-OLvrgW8 {
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 2rem; }
  .jEVRRfzaEn6iNXQ_t7JpO {
    margin: 20px auto; }
  ._3asSwX-0DeD9-I5e7GKQyL {
    color: #ce2a29;
    text-align: center; }
  ._2x5RYcFKb0D3r7TOhhAFdk {
    color: #1db953;
    text-align: center; }
