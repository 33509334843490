._2VAwgzruiofWgtn24gc3zD {
  color: #657881;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: block; }

._2SOvQYud9412KY5iLWkTzA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px; }

.Jrj1LcLz_yXcO-dK8gXla {
  background: linear-gradient(140deg, #3d9ac9, #318dbc); }
  .Jrj1LcLz_yXcO-dK8gXla:hover {
    background-color: #34a9e9;
    color: #fff; }

._2ezctARTDsrEofHo7YydXx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-bottom: 5px; }
  @media all and (max-width: 660px) {
    ._2ezctARTDsrEofHo7YydXx {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  ._2ezctARTDsrEofHo7YydXx button,
  ._2ezctARTDsrEofHo7YydXx a {
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    border: none;
    padding: 10px;
    background-color: #1791d3;
    border-radius: 3px;
    margin: 0 5px 5px 0;
    cursor: pointer; }
    ._2ezctARTDsrEofHo7YydXx button:hover,
    ._2ezctARTDsrEofHo7YydXx a:hover {
      background-color: #34a9e9; }
    ._2ezctARTDsrEofHo7YydXx button ._3kKsu1XR3yjk85F-4gmNwj,
    ._2ezctARTDsrEofHo7YydXx a ._3kKsu1XR3yjk85F-4gmNwj {
      margin-right: 5px;
      height: 14px; }

.P98HnahL3rAyiIDpV4Bn4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  @media all and (max-width: 660px) {
    .P98HnahL3rAyiIDpV4Bn4 {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }

.EIYVBcuUC1SxxB0blQuC6 {
  font-size: 14px;
  color: #5c6066;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: block;
  margin: 0 10px 5px 0; }
  .EIYVBcuUC1SxxB0blQuC6 input {
    display: none; }
  .EIYVBcuUC1SxxB0blQuC6 span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .EIYVBcuUC1SxxB0blQuC6 span:after {
      content: ' ';
      width: 0px;
      height: 0px;
      margin-left: 5px; }
  .EIYVBcuUC1SxxB0blQuC6 input:checked + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  .EIYVBcuUC1SxxB0blQuC6 input:not(:checked) + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }

._3LAWufXloBfRSYixx93M0O {
  background: #f6f9fb 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 5px; }
  ._3LAWufXloBfRSYixx93M0O input {
    border: none;
    background: transparent;
    margin-left: 5px; }

._24Z_Bs2hmAc9I_YAkjKnHw {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem; }

._3T5a8j9N157pWDjEg034N_ {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }

._3s2SojnMGD1Zmuw9oD98YC {
  padding: 20px 20px; }
