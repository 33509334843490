._28w8hwuLrsmn37Gd_L23D8 {
  margin-top: 4rem;
  margin-bottom: 4rem; }
  ._3LSenjlcyocMK8GNSnDdo9 {
    color: #fff;
    text-align: center;
    margin-bottom: 4rem; }
  ._3pmx0ONta31_sSScCUD3B0 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
    padding: 0; }
    ._1ePtuiW6_T0Y4hP7rXNnxU {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 328px;
      height: 382px;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative;
      margin: 0;
      padding: 2rem;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      text-align: center;
      border: 2px solid #2892d0; }
      ._1ePtuiW6_T0Y4hP7rXNnxU:before, ._1ePtuiW6_T0Y4hP7rXNnxU:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0; }
      ._1ePtuiW6_T0Y4hP7rXNnxU:before {
        border: 24px solid;
        border-color: #2892d0 transparent transparent #2892d0;
        bottom: -48px; }
      ._1ePtuiW6_T0Y4hP7rXNnxU:after {
        border: 20px solid;
        border-color: #030b0d transparent transparent #030b0d;
        bottom: -38px; }
      ._13kNdZPqNlBc0uzkbAyGbr {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        width: 90px;
        height: 90px;
        position: absolute;
        margin: 0;
        padding: 0;
        top: -45px;
        left: 50%;
        margin-left: -45px;
        border-radius: 50%;
        font-size: 36px;
        color: #FFFFFF;
        font-weight: bold;
        pointer-events: none;
        background-color: #2892d0; }
      ._2bfn3Fal15oK-NXlwuaXIX {
        height: 200px;
        width: 100%;
        margin-top: 4rem;
        margin-bottom: 2rem; }
      ._2T3EtJLChU7xC2sDYZ6_jy {
        text-align: center;
        color: #FFFFFF;
        line-height: 1.8;
        padding-bottom: 2rem; }
    @media (max-width: 991px) {
      ._3pmx0ONta31_sSScCUD3B0 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        width: auto; }
        ._1ePtuiW6_T0Y4hP7rXNnxU:first-child {
          margin-top: 3rem; }
        ._1ePtuiW6_T0Y4hP7rXNnxU:not(:last-child) {
          margin-bottom: 6rem; } }
