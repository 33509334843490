._3khpEgJEPzxt5mQuAsmY8g .INNc6WTUoYjNMNUzsWcaJ {
  margin-bottom: 2rem; }

._3khpEgJEPzxt5mQuAsmY8g ._2AEb0IviB7AO0zVHZgxt1L {
  margin: 2.5rem 0 1rem 0; }

._3khpEgJEPzxt5mQuAsmY8g ._3X4AxYWINOaYRK-4o2Y0gP {
  margin-bottom: 1rem;
  display: block; }

._3khpEgJEPzxt5mQuAsmY8g ._1z6-8rdmfLI-Arvlz2p5u9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 1rem;
  font-size: 14px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

._3khpEgJEPzxt5mQuAsmY8g ._1yuSbsp26szk5vmXWpqYDz {
  font-weight: 600;
  margin: 0 0 0.5rem;
  display: block; }

._3khpEgJEPzxt5mQuAsmY8g ._2gPzc45RhXSwcX_DAWajUx {
  background-color: #f6f9fb;
  border: none;
  padding: 10px;
  border-radius: 3px;
  color: #5c6066;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 1.2rem; }
  ._3khpEgJEPzxt5mQuAsmY8g ._2gPzc45RhXSwcX_DAWajUx.AERFQ2UTR828nwk83TSpw {
    padding: 0;
    height: 1px;
    width: 1px; }

._3khpEgJEPzxt5mQuAsmY8g ._32vCGgeoIihoccO9VAJjRN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  ._3khpEgJEPzxt5mQuAsmY8g ._32vCGgeoIihoccO9VAJjRN ._2gPzc45RhXSwcX_DAWajUx {
    padding-right: 35px;
    text-overflow: ellipsis; }

._3khpEgJEPzxt5mQuAsmY8g .R-4x0ADzWBy1EtxoSoE0M {
  right: 10px;
  position: absolute;
  font-size: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer; }

._2dnBcN-IszgnrPqKgN9BXg {
  position: absolute;
  bottom: -25px;
  right: 0;
  font-size: 0.8rem;
  padding: 5px 5px 4px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
  opacity: 0; }
  ._2dnBcN-IszgnrPqKgN9BXg:before {
    content: ' ';
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 60%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent; }
  ._2dnBcN-IszgnrPqKgN9BXg.eC-QM5YeGJbIQeqUyrG1F {
    opacity: 1; }

._3pnt7tmZOXgQYy3G8k3mO6:disabled {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  cursor: not-allowed; }
