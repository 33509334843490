._3S5BdZeMZ4DAItfACDNU6P {
  margin: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._2aA00b_amjzxIJI31MRVCI,
._2bfCiIFumS060AqwqLIJxf {
  margin: 1rem; }

._33GYk8ju0NdUvz0f_zmNxB {
  margin: 0 1rem; }

._2bfCiIFumS060AqwqLIJxf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  ._2bfCiIFumS060AqwqLIJxf :first-child {
    margin-right: 1rem; }
  ._2bfCiIFumS060AqwqLIJxf ._3UHKYVJsg0Bs-5jVOUAwvj:disabled {
    -webkit-filter: grayscale(1);
            filter: grayscale(1); }

.YeVYOwkndekPDPItt0hI5 {
  margin: 0.5rem; }

.n1nSEpTSETTUgJVQAwNGO {
  background: transparent;
  border: none;
  color: #2392d0;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  margin: 20px auto 0; }
  .n1nSEpTSETTUgJVQAwNGO:hover {
    color: #46a9e0; }

._2xPhTz8qUVJVrNgozBusTX {
  color: #ce2a29;
  margin: 1rem; }
