._1XDXu4AEP88xdJ_yJLElJp {
  display: none;
  width: 20px;
  height: 19px;
  padding: 1.25rem;
  position: fixed;
  top: 12%;
  left: 0;
  margin: 0 1rem 0 0;
  padding: 0;
  z-index: 51;
  cursor: pointer;
  -webkit-transition: top 0.5s ease;
  transition: top 0.5s ease;
  background-color: unset;
  border: unset; }
  ._1XDXu4AEP88xdJ_yJLElJp:hover, ._1XDXu4AEP88xdJ_yJLElJp:active, ._1XDXu4AEP88xdJ_yJLElJp:focus {
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none; }
  ._2NPTKcYNUhAjedBaDz4WjX {
    display: block;
    width: 100%;
    height: 35px;
    margin: 0.375rem 0;
    background: #00b4d8;
    -webkit-transform: none;
            transform: none;
    opacity: 1;
    border-radius: 0 5px 5px 0;
    -webkit-transition: width 0.25s linear, opacity 0.25s linear, -webkit-transform 0.25s linear;
    transition: width 0.25s linear, opacity 0.25s linear, -webkit-transform 0.25s linear;
    transition: transform 0.25s linear, width 0.25s linear, opacity 0.25s linear;
    transition: transform 0.25s linear, width 0.25s linear, opacity 0.25s linear, -webkit-transform 0.25s linear; }
    ._2NPTKcYNUhAjedBaDz4WjX::before {
      content: '';
      position: absolute;
      left: 8px;
      top: 23px;
      display: block;
      border-right: 3px solid #fff;
      border-bottom: 3px solid #fff;
      width: 5px;
      height: 5px;
      -webkit-transition: 0.25s;
      transition: 0.25s;
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
              transform: translate(-50%, -50%) rotate(-45deg); }
  ._1nKcIFeMnDLARTHqZ2igAx ._2NPTKcYNUhAjedBaDz4WjX {
    width: 50px; }
    ._1nKcIFeMnDLARTHqZ2igAx ._2NPTKcYNUhAjedBaDz4WjX::before {
      left: 39px;
      -webkit-transform: translate(-50%, -50%) rotate(135deg);
              transform: translate(-50%, -50%) rotate(135deg); }
  @media (max-width: 991px) {
    ._1XDXu4AEP88xdJ_yJLElJp {
      display: block; } }

._1xpSzCslQHu21VIODnlna8 {
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  ._1xpSzCslQHu21VIODnlna8 span {
    color: #fff;
    z-index: 60; }
  ._1xpSzCslQHu21VIODnlna8 ._1GF-NukKt1lZ6Dt5u5Ljst {
    display: none; }
    @media (max-width: 991px) {
      ._1xpSzCslQHu21VIODnlna8 ._1GF-NukKt1lZ6Dt5u5Ljst {
        display: block; } }
  @media (max-width: 991px) {
    ._1xpSzCslQHu21VIODnlna8 {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 60;
      background: #030b0d;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 1.5rem 1rem; } }

.ZFc6IJCrIiaD-xKn29UmC {
  color: #fff;
  background: none;
  border: none;
  font-weight: 500;
  cursor: pointer; }
