._3fqPR_ATEZPGUCZPi6yQwo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin-bottom: 10px;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin: 0; }

.S0nGy8ePeOWOgjjHtlSVi {
  color: #000;
  position: relative;
  padding-left: 25px; }
  .S0nGy8ePeOWOgjjHtlSVi:before {
    content: "";
    height: 18px;
    width: 18px;
    border: 1px solid #3c404d;
    border-radius: 3px;
    display: block;
    position: absolute;
    left: 0;
    cursor: pointer; }

._3EqJ1wtiM8r1eujDgTqzyl {
  width: 5px;
  top: 0;
  margin-left: 5px;
  position: absolute;
  opacity: 0; }
  ._3EqJ1wtiM8r1eujDgTqzyl:checked + span:before {
    content: "\2713";
    background: #2392d0;
    border: 1px solid #2392d0;
    color: #fff;
    text-align: center;
    line-height: 18px;
    font-size: 12px; }
  ._3EqJ1wtiM8r1eujDgTqzyl[disabled] + span:before {
    background: transparent;
    border: 1px solid #3c404d;
    color: #3c404d; }
