._3pxuoQp_60-gzn3pJ1r23D {
  font-size: .8rem;
  font-weight: 400;
  color: #657881;
  border-bottom: 1px solid #758793;
  padding: 20px 10px;
  line-height: 2em;
  position: relative; }
  ._3pxuoQp_60-gzn3pJ1r23D ._3LIs-baU_K1wrD8bmw8xWt {
    display: none; }
    ._3pxuoQp_60-gzn3pJ1r23D ._3LIs-baU_K1wrD8bmw8xWt:checked + ._3wzDKfRdRHIObuSc1zuQds:before {
      position: absolute;
      content: '\2713';
      background: #2392d0;
      color: #fff;
      height: 18px;
      width: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      display: inline-block;
      left: 21px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }

._2TzXvo2WSAq3P9xVqLRn2C {
  margin: 0; }

._3Fo97TZkyjftr5iLvxzS57 {
  background-color: #f3fbff; }

._2E1N8eGz_o0zHGt8sYoPes {
  position: relative; }
  ._2E1N8eGz_o0zHGt8sYoPes label {
    padding-left: 45px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    ._2E1N8eGz_o0zHGt8sYoPes label:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #2392d0;
      border-radius: 3px;
      display: inline-block;
      left: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }

._24GMmwQt9YA-hHC7sSJpGv._70NLVLeDBCluZnyqPJLGS, ._24GMmwQt9YA-hHC7sSJpGv._7K5NhUJwFm9cnfoVy0BVZ:not(._221kVVwTDVCJpvXz8lC7Ip) {
  color: #1791d3;
  font-weight: 600; }

._24GMmwQt9YA-hHC7sSJpGv._7K5NhUJwFm9cnfoVy0BVZ._221kVVwTDVCJpvXz8lC7Ip, ._24GMmwQt9YA-hHC7sSJpGv._2qXYdGj6RGwPky3ucewl6Q {
  font-size: 0.8rem; }

._24GMmwQt9YA-hHC7sSJpGv .ip2UcUlgcpNmQTbftmOEw {
  background: #e6eaf2 0% 0% no-repeat padding-box;
  border-radius: 3px;
  border: none;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%; }

._24GMmwQt9YA-hHC7sSJpGv ._2Yr5NkqzQ1UjLnm7_FCuXx {
  display: none; }

._24GMmwQt9YA-hHC7sSJpGv ._1IVrTBGSCmWABJ_AkHv5xy {
  color: #fa1854; }

._1UcUKjIlumfAVk7SNqtcYK {
  font-size: .8rem;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer; }
  ._1UcUKjIlumfAVk7SNqtcYK.sZ-5ubHmw7l4NHkIdvEe3 {
    color: #328ebd;
    background-color: #318dbc1c;
    border: 1px solid #328ebd; }
    ._1UcUKjIlumfAVk7SNqtcYK.sZ-5ubHmw7l4NHkIdvEe3:hover {
      background-color: rgba(28, 80, 107, 0.109804); }
  ._1UcUKjIlumfAVk7SNqtcYK._3EWjAE7wcJ3QoQQ3s4s5x_ {
    color: #fa1854;
    background: #fa18541c 0% 0% no-repeat padding-box;
    border: 1px solid #fa1854; }
    ._1UcUKjIlumfAVk7SNqtcYK._3EWjAE7wcJ3QoQQ3s4s5x_:hover {
      background-color: rgba(252, 124, 158, 0.109804); }
  ._1UcUKjIlumfAVk7SNqtcYK[disabled], ._1UcUKjIlumfAVk7SNqtcYK[disabled]:hover {
    background-color: #e6eaf2;
    color: #5c6066;
    border: 1px solid #e6eaf2; }
