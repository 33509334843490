._1Cxrr1WRLZ3nORE9hySmSA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin-top: 0 2rem;
  min-height: calc(100vh - 80px); }
  ._2rLlHvLrh3HA_C7RRCRCsu {
    background: none; }
  .n7__ZHQkBCpvng93rPoNI {
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 4;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    align-items: center; }
  ._2RqKTazOZTJ1xh7kx6_o_M {
    text-align: center;
    width: 100%;
    z-index: 1; }
    ._2W05C2Z226MAfzpdpZQJTn {
      line-height: 1.2;
      font-weight: 700;
      margin: 2rem 0; }
      @media all and (max-width: 1100px) {
        ._2W05C2Z226MAfzpdpZQJTn {
          font-size: 1.8rem; } }
    ._3-SZjDAUS65QX-XfNWuB_0 {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      min-width: 225px; }
    ._1KsYkjwxpqDlqZWQ6lgfyI {
      line-height: 1.2;
      font-weight: 400;
      margin-bottom: 1.25rem;
      font-size: 1.2rem; }
      @media (max-width: 767px) {
        ._1KsYkjwxpqDlqZWQ6lgfyI {
          font-size: 1rem; } }
    ._2r9CikvnL2MfdBJuHDwZFt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 100%;
      border-radius: 5px;
      color: #fff;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      border: none;
      padding: 0.85rem;
      text-decoration: none;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      max-width: 300px;
      margin: 0 auto; }
      ._2r9CikvnL2MfdBJuHDwZFt:hover, ._2r9CikvnL2MfdBJuHDwZFt:active, ._2r9CikvnL2MfdBJuHDwZFt:focus {
        color: #fff; }
  ._2Uhb2FurQ1DVOGFB7zX-V_ {
    z-index: 1; }

._1Mgcsc58Yc25ji2NPL4p8F {
  font-weight: bold; }

._2N06Rbc8bL-fgWsg4rG97O {
  color: #00a69c; }
  ._2N06Rbc8bL-fgWsg4rG97O:hover, ._2N06Rbc8bL-fgWsg4rG97O:active, ._2N06Rbc8bL-fgWsg4rG97O:focus {
    color: #00877f; }

._2dUexnCJrsJt3exT1ZUcwt {
  color: #2591cf; }
  ._2dUexnCJrsJt3exT1ZUcwt:hover, ._2dUexnCJrsJt3exT1ZUcwt:active, ._2dUexnCJrsJt3exT1ZUcwt:focus {
    color: #207fb5; }

._2WLJsyUUU_dBkhQL6clYt9 {
  color: #f5371b; }
  ._2WLJsyUUU_dBkhQL6clYt9:hover, ._2WLJsyUUU_dBkhQL6clYt9:active, ._2WLJsyUUU_dBkhQL6clYt9:focus {
    color: #e7270a; }

._1NkWoPqrtHzQ8jmCOHOXwl {
  color: #fbb703; }
  ._1NkWoPqrtHzQ8jmCOHOXwl:hover, ._1NkWoPqrtHzQ8jmCOHOXwl:active, ._1NkWoPqrtHzQ8jmCOHOXwl:focus {
    color: #dda103; }

.MQvI_Z8rJduHjEEpp4939 {
  background-color: #00a69c; }
  .MQvI_Z8rJduHjEEpp4939:hover {
    background-color: #00c5b9; }
  .MQvI_Z8rJduHjEEpp4939:active {
    background-color: #00877f; }

._2JO58QlcF1SXr_KQJ9dZ0f {
  background-color: #2591cf; }
  ._2JO58QlcF1SXr_KQJ9dZ0f:hover {
    background-color: #379fdb; }
  ._2JO58QlcF1SXr_KQJ9dZ0f:active {
    background-color: #207fb5; }

._1n0bQPnDMFJnglV6ix-ARE {
  background-color: #f5371b; }
  ._1n0bQPnDMFJnglV6ix-ARE:hover {
    background-color: #f65138; }
  ._1n0bQPnDMFJnglV6ix-ARE:active {
    background-color: #e7270a; }

._26q2BYG5SL8LttsfXfad1A {
  background-color: #fbb703; }
  ._26q2BYG5SL8LttsfXfad1A:hover {
    background-color: #fcc020; }
  ._26q2BYG5SL8LttsfXfad1A:active {
    background-color: #dda103; }

._1r6HPFD7wFXmw6GLod4dE6 {
  position: absolute;
  left: 0;
  top: 57%;
  width: 100%;
  max-width: 75px; }
  @media (max-width: 991px) {
    ._1r6HPFD7wFXmw6GLod4dE6 {
      max-width: 25px;
      top: 58%; } }

._3jl_fvcLDAPV2mUOqfe2J6 {
  position: absolute;
  right: 0;
  top: 28%;
  width: 100%;
  max-width: 115px; }
  @media (max-width: 991px) {
    ._3jl_fvcLDAPV2mUOqfe2J6 {
      max-width: 40px;
      top: 15%; } }
