._2L-Dvj5FOtiA77LmNhg8NN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 11.563rem;
  padding-bottom: 9.063rem;
  margin-top: -5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease; }
  ._18tmKxLbxQaPjX9Ff5VYSG {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    padding: 0 5rem;
    max-width: 1400px;
    position: relative;
    z-index: 5; }
    @media all and (max-width: 1200px) {
      ._18tmKxLbxQaPjX9Ff5VYSG {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 0 1.25rem; } }
  ._3rgY37IYBAceYG_N15UwfE {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    .qJLjklOfy8Y_431YXMVyp {
      width: 95%;
      display: inline-block; }
    @media all and (max-width: 1600px) {
      ._3rgY37IYBAceYG_N15UwfE {
        overflow: hidden; } }
    @media all and (max-width: 1200px) {
      ._3rgY37IYBAceYG_N15UwfE {
        display: none; } }
  ._2x7A69GB3zihY0jRCZzJV0 {
    display: inline-block;
    height: auto;
    margin-bottom: 1.25rem; }
    @media all and (max-width: 1200px) {
      ._2x7A69GB3zihY0jRCZzJV0 {
        text-align: center; } }
  ._1Lgcvsazxq8LRrIfIhNTcS, ._2HPsoJyXH9dnKJE8snU29H {
    font-size: 2.5rem;
    line-height: 1.7;
    margin: 0;
    padding: 0; }
    @media all and (max-width: 1200px) {
      ._1Lgcvsazxq8LRrIfIhNTcS, ._2HPsoJyXH9dnKJE8snU29H {
        text-align: center; } }
    @media (max-width: 991px) {
      ._1Lgcvsazxq8LRrIfIhNTcS, ._2HPsoJyXH9dnKJE8snU29H {
        font-size: 1.75rem;
        line-height: 1.8; } }
  ._1HiQuYeMnux_9WkMRo161W {
    margin: 0;
    padding: 0;
    margin-top: 2.188rem; }
  ._1HiQuYeMnux_9WkMRo161W, ._1cqcpWYZ-TYCL89Gkxk0ec {
    font-size: 1.25rem;
    line-height: 1.8; }
    @media all and (max-width: 1200px) {
      ._1HiQuYeMnux_9WkMRo161W, ._1cqcpWYZ-TYCL89Gkxk0ec {
        text-align: center; } }
    @media all and (max-width: 790px) {
      ._1HiQuYeMnux_9WkMRo161W, ._1cqcpWYZ-TYCL89Gkxk0ec {
        font-size: 1.125rem;
        line-height: 1.6; } }
  ._1cqcpWYZ-TYCL89Gkxk0ec {
    color: #f9f9f9; }
  .w6VgpxZ4gjuwU8Ww4qB9C {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0.938rem; }
    ._1cqcpWYZ-TYCL89Gkxk0ec {
      margin: 0;
      padding: 0;
      margin-top: 0.938rem;
      font-size: 1.25rem;
      line-height: 1.5; }
      ._1cqcpWYZ-TYCL89Gkxk0ec:before {
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #f9f9f9;
        margin-right: 0.5rem;
        content: ''; }
      ._1cqcpWYZ-TYCL89Gkxk0ec:first-child {
        margin-top: 0; }
      @media all and (max-width: 790px) {
        ._1cqcpWYZ-TYCL89Gkxk0ec {
          font-size: 1rem;
          line-height: 1.375; } }
  ._1ahasrT0M0xGo4bJFoKd9 {
    margin-top: 0.5rem; }
  ._2ea_VtCHx0kTtyZTgHfcLB {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 2.5rem; }
    ._1C___S1H7h-KU-EAGUEbKP:first-child {
      margin-left: 0; }
      @media all and (max-width: 750px) {
        ._1C___S1H7h-KU-EAGUEbKP:first-child {
          margin-top: 0; } }
    ._1C___S1H7h-KU-EAGUEbKP:last-child:not(:first-child) {
      margin-left: 1rem; }
    @media all and (max-width: 750px) {
      ._1C___S1H7h-KU-EAGUEbKP {
        margin-top: 1.25rem;
        margin-left: 0; } }
    @media all and (max-width: 1200px) {
      ._2ea_VtCHx0kTtyZTgHfcLB {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }
  ._3NnZycA5XSOTpJZW3QAqIt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 625px; }
    @media all and (max-width: 1200px) {
      ._3NnZycA5XSOTpJZW3QAqIt {
        margin-bottom: 1.5rem; } }
  @media (max-width: 991px) {
    ._2L-Dvj5FOtiA77LmNhg8NN {
      padding-top: 6.25rem;
      padding-bottom: 3.75rem; } }
