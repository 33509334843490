._3IqU0emgW2I9ZSfD2Sqo3f .jq1pDeJct_NwaQm-wP__z {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3IqU0emgW2I9ZSfD2Sqo3f .jq1pDeJct_NwaQm-wP__z {
      font-size: 2.5rem;
      line-height: 1.7; } }

._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A ._1mmkVAzYJQ4UuzKAK05vLW ._3kdG8vjXGhlhIVI_yFo0lz {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A ._1mmkVAzYJQ4UuzKAK05vLW ._3kdG8vjXGhlhIVI_yFo0lz {
      font-size: 1.25rem;
      line-height: 1.8; } }

._3IqU0emgW2I9ZSfD2Sqo3f {
  padding: 3rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%; }
  @media all and (min-width: 768px) {
    ._3IqU0emgW2I9ZSfD2Sqo3f {
      padding: 6rem 2rem; } }
  ._3IqU0emgW2I9ZSfD2Sqo3f .jq1pDeJct_NwaQm-wP__z {
    text-align: center; }
  ._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 475px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 2.5rem 0 0 0; }
    @media all and (min-width: 790px) {
      ._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A {
        margin: 2.5rem auto; } }
    ._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A ._1mmkVAzYJQ4UuzKAK05vLW {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      text-align: center;
      margin-bottom: 2rem; }
      ._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A ._1mmkVAzYJQ4UuzKAK05vLW ._1GpkD8DogPUqJNsy7xRjlD {
        font-size: 1.375rem; }
      ._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A ._1mmkVAzYJQ4UuzKAK05vLW ._3kdG8vjXGhlhIVI_yFo0lz {
        margin: 0; }
      @media all and (min-width: 790px) {
        ._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A ._1mmkVAzYJQ4UuzKAK05vLW {
          text-align: left;
          margin-bottom: 0; } }
    @media all and (min-width: 790px) {
      ._3IqU0emgW2I9ZSfD2Sqo3f ._3gypWtWwZ24dmJ1MCmH92A {
        display: grid;
        max-width: 980px;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4vh 8vw; } }
