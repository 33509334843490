._1SEd68N30XPDrIv5BTSJPp ._6jG4rGB9rwWqdtUJ9bMEm {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._1SEd68N30XPDrIv5BTSJPp ._6jG4rGB9rwWqdtUJ9bMEm {
      font-size: 2.5rem;
      line-height: 1.7; } }

._1SEd68N30XPDrIv5BTSJPp ._1wdWkFCi0d6H46nQOnp1qC {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._1SEd68N30XPDrIv5BTSJPp ._1wdWkFCi0d6H46nQOnp1qC {
      font-size: 1.25rem;
      line-height: 1.8; } }

._3QoJ7pGUhSLIAoErArzaN4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  margin: 0 0 8rem;
  padding: 0 1.25rem; }
  @media (max-width: 991px) {
    ._3QoJ7pGUhSLIAoErArzaN4 {
      margin-bottom: 4rem; } }

.WpirffDgEqa-iWLL23tFR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  grid-gap: 4.5rem;
  gap: 4.5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2rem;
  background-color: #1e2639;
  border-radius: 12px; }
  @media all and (min-width: 1094px) {
    .WpirffDgEqa-iWLL23tFR {
      padding: 5rem 0 5rem 5rem;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row; } }

._1SEd68N30XPDrIv5BTSJPp {
  margin: 0 auto;
  max-width: 655px;
  width: 100%; }
  ._1SEd68N30XPDrIv5BTSJPp ._6jG4rGB9rwWqdtUJ9bMEm,
  ._1SEd68N30XPDrIv5BTSJPp ._2-wh9HGy6v4QiheLP3z8xT,
  ._1SEd68N30XPDrIv5BTSJPp ._1wdWkFCi0d6H46nQOnp1qC {
    color: #fff; }
  ._1SEd68N30XPDrIv5BTSJPp ._2-wh9HGy6v4QiheLP3z8xT {
    font-size: 1.375rem;
    line-height: 1.5; }

._1L7Tc9dfmTCMrXIhbUL14i {
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 1.5rem;
  gap: 1.5rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  text-align: center;
  padding: 0;
  list-style: none; }
  @media all and (min-width: 390px) {
    ._1L7Tc9dfmTCMrXIhbUL14i {
      grid-template-columns: repeat(2, auto); } }
  @media all and (min-width: 656px) {
    ._1L7Tc9dfmTCMrXIhbUL14i {
      grid-template-columns: repeat(3, auto); } }
  @media all and (min-width: 780px) {
    ._1L7Tc9dfmTCMrXIhbUL14i {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  ._1L7Tc9dfmTCMrXIhbUL14i ._32B7eBz-RqBOYVXJbMrpwW .OCyKimxIqrKoC2BdHoTgZ,
  ._1L7Tc9dfmTCMrXIhbUL14i ._32B7eBz-RqBOYVXJbMrpwW ._3YILkIfqnXp29-JwvpuUPb {
    color: #fff; }
  ._1L7Tc9dfmTCMrXIhbUL14i ._32B7eBz-RqBOYVXJbMrpwW ._3YILkIfqnXp29-JwvpuUPb {
    font-size: 1rem;
    line-height: 1.5; }
  ._1L7Tc9dfmTCMrXIhbUL14i ._32B7eBz-RqBOYVXJbMrpwW:hover ._3YILkIfqnXp29-JwvpuUPb {
    color: #00b4d8; }

._3nv2DIayhx74s2VW1rDNj1 {
  display: none; }
  ._3nv2DIayhx74s2VW1rDNj1 ._22ie02oUE4UbaMsXvFnt1V {
    display: inline-block;
    width: 100%; }
  @media all and (min-width: 1094px) {
    ._3nv2DIayhx74s2VW1rDNj1 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
