._2BseptFigFzSacRYA5oX9X, .MYuZcH0ME1jQtaoJwp2bN a {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._2XhduI848dtp6pSejftt7H {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100vh - 80px);
  padding: 2rem; }
  @media all and (min-width: 802px) {
    ._2XhduI848dtp6pSejftt7H {
      padding: 0 2rem 4rem; } }

._2E5hg1ioT56qKYz9By4Kxc {
  max-width: 1340px;
  margin: 0 auto;
  text-align: center;
  min-height: 68vh; }
  @media all and (min-width: 415px) {
    ._2E5hg1ioT56qKYz9By4Kxc {
      min-height: unset; } }

.qZ0e3lNg94YPLZaku9DFI {
  max-width: 750px;
  margin: 0 auto 1rem;
  font-size: 1.4rem;
  background: #fff;
  z-index: 2;
  word-break: break-word; }
  @media all and (min-width: 390px) {
    .qZ0e3lNg94YPLZaku9DFI {
      font-size: 1.8rem; } }
  @media all and (min-width: 802px) {
    .qZ0e3lNg94YPLZaku9DFI {
      font-size: 2.5rem; } }
  @media all and (min-width: 1920px) {
    .qZ0e3lNg94YPLZaku9DFI {
      font-size: 2.8rem;
      margin: 0 auto 1.5rem; } }

.VvqTjQDpfQO_cGVH7i8hr {
  margin: 0;
  font-size: 1.2rem;
  word-break: break-word; }
  @media all and (min-width: 802px) {
    .VvqTjQDpfQO_cGVH7i8hr {
      font-size: 1.5rem; } }
  @media all and (min-width: 1920px) {
    .VvqTjQDpfQO_cGVH7i8hr {
      font-size: 1.7rem; } }

._2ijpOazkBFckh05aNAzgYl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media all and (min-width: 390px) {
    ._2ijpOazkBFckh05aNAzgYl {
      grid-gap: 1rem;
      gap: 1rem; } }
  @media all and (min-width: 953px) {
    ._2ijpOazkBFckh05aNAzgYl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      grid-gap: 3rem;
      gap: 3rem; } }

._2BseptFigFzSacRYA5oX9X {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  padding: 0.7rem 0.75rem;
  font-size: 1.25rem; }
  ._2BseptFigFzSacRYA5oX9X ._39nLrw-UKE8EzskE_kPylK {
    height: 25px; }
    @media all and (min-width: 390px) {
      ._2BseptFigFzSacRYA5oX9X ._39nLrw-UKE8EzskE_kPylK {
        height: 30px; } }
  ._2BseptFigFzSacRYA5oX9X .vcoNvBSsQXlI493dHXrlt {
    font-size: 0.938rem; }
    @media all and (min-width: 802px) {
      ._2BseptFigFzSacRYA5oX9X .vcoNvBSsQXlI493dHXrlt {
        font-size: 1.2rem; } }

.MYuZcH0ME1jQtaoJwp2bN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  text-align: center;
  -webkit-transition: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition-duration: 400ms;
          transition-duration: 400ms;
  -webkit-transition-property: all;
  transition-property: all; }
  .MYuZcH0ME1jQtaoJwp2bN a {
    border-radius: 80px;
    padding: 0.8rem 2rem !important;
    font-size: 1rem; }
    @media all and (min-width: 802px) {
      .MYuZcH0ME1jQtaoJwp2bN a {
        font-size: 1.125rem; } }
  @media all and (min-width: 802px) {
    .MYuZcH0ME1jQtaoJwp2bN {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media all and (min-width: 1920px) {
    .MYuZcH0ME1jQtaoJwp2bN {
      grid-gap: 1.5rem;
      gap: 1.5rem; } }

.peWxOZV5uKpZXuq67Hs8D video {
  max-width: 100%;
  max-height: 207px; }
  @media all and (min-width: 802px) {
    .peWxOZV5uKpZXuq67Hs8D video {
      max-height: 264px; } }
  @media all and (min-width: 1920px) {
    .peWxOZV5uKpZXuq67Hs8D video {
      max-height: 420px; } }

._1yubsUDq1HKtBPZB3-PZCt {
  min-height: 88px; }
