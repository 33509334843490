._2ftZMWP4UvPNzv8nU2kA-u {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._3FqFVnFYrJTK3MDPI4NNq4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 2rem; }
  ._2ftZMWP4UvPNzv8nU2kA-u {
    padding: 0.8rem 2rem;
    color: #fff;
    background-color: transparent; }
    ._2ftZMWP4UvPNzv8nU2kA-u:first-child {
      border-radius: 0.5rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none; }
    ._2ftZMWP4UvPNzv8nU2kA-u:last-child {
      border-radius: 0.5rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none; }
    ._q-AQouGBJK7fJ4jRSLGA {
      background-color: #d6731a;
      border: 1px solid #d6731a; }
    ._2TTD4WbxYrniGgGLk-3j8T {
      background-color: #00a69c;
      border: 1px solid #00a69c; }
    ._17Sf4UsTVfcVAzwZ_PCatP {
      background-color: #2892d0;
      border: 1px solid #2892d0; }
