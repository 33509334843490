._30zkjDnjwFXgc5xGqSGfrC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 3.125rem;
  max-width: 1400px;
  margin-top: 100px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._1eK0GmbS1hzw3ehlkUYl3a, .Y9Shbg9oDzkLiGPstxQpT {
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 2.5rem; }
    ._1eK0GmbS1hzw3ehlkUYl3a:first-child, .Y9Shbg9oDzkLiGPstxQpT:first-child {
      margin-top: 0; }
  ._1eK0GmbS1hzw3ehlkUYl3a {
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: normal;
    color: #2392d0;
    font-family: 'Poppins', sans-serif; }
  .Y9Shbg9oDzkLiGPstxQpT {
    font-size: 1.125rem;
    line-height: 1.5;
    color: #fff; }
    .Y9Shbg9oDzkLiGPstxQpT > span {
      display: block; }
    .Y9Shbg9oDzkLiGPstxQpT > span:nth-child(2) {
      margin: 1rem 0; }
