._1CxZMklijHA49DuaR1M8li {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

.oqRtci0WJYsxd9DoO0Ied, ._2JHocjvl381NykqA9DYFYW {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .oqRtci0WJYsxd9DoO0Ied, ._2JHocjvl381NykqA9DYFYW {
      font-size: 1.25rem;
      line-height: 1.8; } }

._16S05VdF72vE5QCii19BNB {
  font-size: 1.75rem;
  line-height: 1.8;
  font-weight: bold; }
  @media all and (min-width: 768px) {
    ._16S05VdF72vE5QCii19BNB {
      font-size: 2rem;
      line-height: 1.5; } }

._1Ve0YgH8k4LpUOTlXGjKLY {
  background: #030b0d;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 100%;
  margin-top: -0.063rem;
  padding: 2rem; }
  @media all and (min-width: 768px) {
    ._1Ve0YgH8k4LpUOTlXGjKLY {
      padding: 4.5rem 2rem; } }
  ._1RQM84LbXUF7Mgoi17V8RZ {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
    margin: 0; }
    .oqRtci0WJYsxd9DoO0Ied {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-top: 1.25rem;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      .oqRtci0WJYsxd9DoO0Ied:first-child {
        margin-top: 0; }
  .oqRtci0WJYsxd9DoO0Ied {
    color: #b3b3b3;
    word-break: break-word; }
    .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx {
      border: none;
      background: transparent;
      font-size: 1.125rem;
      color: #fff;
      outline: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      @media (max-width: 991px) {
        .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx {
          font-size: 1rem; } }
      .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx ._21Wxdi4ieh6b-Go0uKyx1r {
        border-radius: 50%;
        background-color: #00b4d8;
        width: 35px;
        height: 35px;
        line-height: 35px;
        margin-right: 0.5rem; }
      .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx:hover {
        color: #f5f5f5; }
        .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx:hover ._21Wxdi4ieh6b-Go0uKyx1r {
          background-color: #009bb9; }
      .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx:active, .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx:focus {
        color: #a9b2be; }
        .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx:active ._21Wxdi4ieh6b-Go0uKyx1r, .oqRtci0WJYsxd9DoO0Ied ._2WFd1PUAgkXdBBWs7k7WSx:focus ._21Wxdi4ieh6b-Go0uKyx1r {
          background-color: #0092af; }
  ._1CxZMklijHA49DuaR1M8li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    grid-gap: 1.5rem; }
    @media (max-width: 991px) {
      ._1CxZMklijHA49DuaR1M8li a {
        padding: 0 1.5rem;
        font-size: 1rem; } }
  ._3TgN6iiU6KytBC8peyGPII {
    list-style-type: disc;
    padding-left: 1.375rem; }
    ._2JHocjvl381NykqA9DYFYW {
      font-weight: 700;
      color: #e9ebf0; }
      ._2JHocjvl381NykqA9DYFYW:first-child {
        margin-top: 1.5rem; }
      ._3_UitbfSkTr6SKK7Kjwz-q {
        font-weight: normal;
        color: #b3b3b3;
        margin-top: 0 !important; }
  ._1RQM84LbXUF7Mgoi17V8RZ:first-child {
    margin-top: 0; }
  ._2smERMdNsGWsPlFQQMuaCF {
    background: #fff; }
    ._2smERMdNsGWsPlFQQMuaCF ._16S05VdF72vE5QCii19BNB, ._2smERMdNsGWsPlFQQMuaCF .oqRtci0WJYsxd9DoO0Ied {
      color: #030b0d; }
  ._1Z-di654ob2DNnanrTd3AF {
    background: #f2f4f6; }
    ._1Z-di654ob2DNnanrTd3AF ._16S05VdF72vE5QCii19BNB, ._1Z-di654ob2DNnanrTd3AF .oqRtci0WJYsxd9DoO0Ied {
      color: #030b0d; }
  ._2XypJQZ98EJ_szd3lr5JQa {
    background: #0f191e; }
  ._2cT3NQGIznTy1xLHnddQdk {
    background: -webkit-gradient(linear, left top, right top, color-stop(40%, #fcfcfc), color-stop(60%, #030b0d));
    background: linear-gradient(90deg, #fcfcfc 40%, #030b0d 60%);
    padding: 0 0 0 2rem; }
    @media all and (min-width: 768px) {
      ._2cT3NQGIznTy1xLHnddQdk {
        padding: 0 2rem; } }
  ._1kPakvZygASG9r0ZFOOm9d {
    background: -webkit-gradient(linear, left top, right top, color-stop(60%, #030b0d), color-stop(40%, #fcfcfc));
    background: linear-gradient(90deg, #030b0d 60%, #fcfcfc 40%);
    padding: 0 2rem 0 0; }
    @media all and (min-width: 768px) {
      ._1kPakvZygASG9r0ZFOOm9d {
        padding: 0 2rem; } }
  .-vyW0nor363llw7D3sWZL .-yKWibPRSOosH-3JGGTrE {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; }
    @media all and (min-width: 768px) {
      .-vyW0nor363llw7D3sWZL .-yKWibPRSOosH-3JGGTrE {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
                flex-direction: row-reverse; } }
  .-vyW0nor363llw7D3sWZL ._1uaG1Gh7N3Su9s5WjsoIUv {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .-vyW0nor363llw7D3sWZL ._3fukl5_N19kUh9KmOkSxY8 {
    margin-left: 1rem; }
  .-yKWibPRSOosH-3JGGTrE {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    align-items: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-width: 1800px;
    margin: auto; }
    @media all and (min-width: 768px) {
      .-yKWibPRSOosH-3JGGTrE {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
  ._2ADFO8XZDsvVa0zhHgTl_Q {
    background: #fcfcfc;
    border-radius: 0;
    padding: 2rem 2rem 2rem 0; }
    ._2ADFO8XZDsvVa0zhHgTl_Q ._16S05VdF72vE5QCii19BNB, ._2ADFO8XZDsvVa0zhHgTl_Q .oqRtci0WJYsxd9DoO0Ied {
      color: #030b0d; }
    @media all and (min-width: 768px) {
      ._2ADFO8XZDsvVa0zhHgTl_Q {
        padding: 3.5rem 0;
        border-radius: 0 30px 30px 0; } }
  ._1s17ZRnvQ8Y_aNyID8n_Bg {
    background: #fcfcfc;
    border-radius: 0;
    padding: 2rem 0 2rem 2rem; }
    ._1s17ZRnvQ8Y_aNyID8n_Bg ._16S05VdF72vE5QCii19BNB, ._1s17ZRnvQ8Y_aNyID8n_Bg .oqRtci0WJYsxd9DoO0Ied {
      color: #030b0d; }
    @media all and (min-width: 768px) {
      ._1s17ZRnvQ8Y_aNyID8n_Bg {
        padding: 3.5rem 0;
        border-radius: 30px 0 0 30px; } }
  ._11kCtMa7ALPJrU9HpgU6bs {
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 25px;
    margin-top: -11rem; }
    @media all and (min-width: 768px) {
      ._11kCtMa7ALPJrU9HpgU6bs {
        max-width: 75px; } }
  ._1V1lpl7DgnmWTZ5hn1pXUZ {
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 25px;
    margin-top: 15rem; }
    @media all and (min-width: 768px) {
      ._1V1lpl7DgnmWTZ5hn1pXUZ {
        max-width: 75px; } }
  ._26mJvt-3O087ZeC_7A1mlf {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 25px;
    margin-top: 13rem; }
    @media all and (min-width: 768px) {
      ._26mJvt-3O087ZeC_7A1mlf {
        max-width: 75px; } }
  .qv9cGI-4TM1qzRJd3UZ0M {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 25px;
    margin-top: -20rem; }
    @media all and (min-width: 768px) {
      .qv9cGI-4TM1qzRJd3UZ0M {
        max-width: 75px; } }
  ._1uaG1Gh7N3Su9s5WjsoIUv, ._1cVgCNFwQnoj9XygfTJVGw {
    margin: 1rem;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    @media all and (min-width: 768px) {
      ._1uaG1Gh7N3Su9s5WjsoIUv, ._1cVgCNFwQnoj9XygfTJVGw {
        width: 40%; } }
  ._3fukl5_N19kUh9KmOkSxY8 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    color: #e9ebf0;
    width: 100%;
    margin: 0 1rem 1rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    z-index: 1; }
    @media all and (min-width: 768px) {
      ._3fukl5_N19kUh9KmOkSxY8 {
        margin: 4rem;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        width: 550px;
        max-width: 50%; } }
  ._16S05VdF72vE5QCii19BNB {
    color: #e9ebf0;
    word-break: break-word; }
