._2GuFg7Vk4ItmXKCE8rgId2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.9rem;
  color: #758793;
  margin: 1rem 1rem 1rem 0;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    ._2GuFg7Vk4ItmXKCE8rgId2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column;
      margin: 1rem 0 0; } }

.MgQBqFvjFPmdD2ofw_Jon {
  margin: 0 1rem 0 0; }
  @media (max-width: 991px) {
    .MgQBqFvjFPmdD2ofw_Jon {
      margin: 0 0 1rem 0; } }

._11Fn5EFqiXMEGTPJrq6AJc {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 0;
  padding: 0; }
  @media (max-width: 991px) {
    ._11Fn5EFqiXMEGTPJrq6AJc {
      margin-bottom: 0.5rem; } }

._3OIyCERd-R9gpFAt6l1Imk,
._13jURd2V4NLSlF8CElQHix,
._9WHffBdxoQS6S8madp3xS,
.Vblv-QQPTjqFaR3dZV-1_,
._3F7w69SWOg5t12KIdmwtsY,
._3uakO5bfkeGCTggGJKFTVS {
  margin: 0 2px;
  line-height: 29px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  width: 29px;
  height: 29px;
  border: 1px solid #e6e9eb;
  background: #ffffff;
  border-radius: 3px; }
  ._3OIyCERd-R9gpFAt6l1Imk a,
  ._13jURd2V4NLSlF8CElQHix a,
  ._9WHffBdxoQS6S8madp3xS a,
  .Vblv-QQPTjqFaR3dZV-1_ a,
  ._3F7w69SWOg5t12KIdmwtsY a,
  ._3uakO5bfkeGCTggGJKFTVS a {
    color: #758793;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: block; }
  ._3OIyCERd-R9gpFAt6l1Imk._3uakO5bfkeGCTggGJKFTVS,
  ._13jURd2V4NLSlF8CElQHix._3uakO5bfkeGCTggGJKFTVS,
  ._9WHffBdxoQS6S8madp3xS._3uakO5bfkeGCTggGJKFTVS,
  .Vblv-QQPTjqFaR3dZV-1_._3uakO5bfkeGCTggGJKFTVS,
  ._3F7w69SWOg5t12KIdmwtsY._3uakO5bfkeGCTggGJKFTVS,
  ._3uakO5bfkeGCTggGJKFTVS._3uakO5bfkeGCTggGJKFTVS {
    color: #758793;
    cursor: not-allowed; }

._3OIyCERd-R9gpFAt6l1Imk:hover,
.Vblv-QQPTjqFaR3dZV-1_ {
  border: 1px solid #1791d3; }
  ._3OIyCERd-R9gpFAt6l1Imk:hover a,
  .Vblv-QQPTjqFaR3dZV-1_ a {
    color: #1791d3; }

._3uakO5bfkeGCTggGJKFTVS {
  color: #758793; }
