.Wzf5GqQ_Xvizl92JcyTer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0 5rem;
  min-height: calc(100vh - (80px + 3rem));
  max-width: 1400px;
  position: relative;
  z-index: 1;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto; }
  ._3XUHnAaMV3OeOaEOAEcvHk {
    background-color: #030b0d; }
  ._2dsFGBr_SvOZPzsAMgkUqv {
    background-color: #fff; }
  .Wzf5GqQ_Xvizl92JcyTer:after {
    height: 1000px;
    width: 1000px;
    border-radius: 25%;
    display: block;
    content: ' ';
    position: absolute;
    top: -500px;
    right: -500px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background-color: #2892d0; }
    @media (max-width: 991px) {
      .Wzf5GqQ_Xvizl92JcyTer:after {
        display: none; } }
  ._1A2D1siR141KlbHZrOc8MT:after {
    background-color: #9146ff; }
    @media all and (max-width: 1179px) {
      ._1A2D1siR141KlbHZrOc8MT:after {
        background-color: #fff;
        display: unset; } }
  .c_dZ9V-ra3YLVaT_HWY2t:after {
    background-color: #4267b2; }
  .gtAeDv5JvhQwztbiwa58L:after {
    background-color: #00b8f2; }
  ._1sq7EHqkBjpU4eavg_2n7P:after {
    background-color: #ff0000; }
  ._3b0RigLhdV9KgyB5xk4hKK:after {
    background-color: #e95d3a; }
  ._2TQbUsx1jGVr7iZl5b2vJ- {
    outline: none; }
  @media (max-width: 991px) {
    .Wzf5GqQ_Xvizl92JcyTer {
      padding: 0 1.25rem; }
      .Wzf5GqQ_Xvizl92JcyTer:after {
        top: -500px;
        right: -500px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }

._1adIAnW17X6gtck4x4spfe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-top: 2rem;
  padding-bottom: 2rem;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  .OdQC1SnCUPdeXtpZHdIHA, ._2N23bZfnwgwDFqhNAXDjpg {
    z-index: 1; }
  .OdQC1SnCUPdeXtpZHdIHA {
    padding-right: 4rem; }
  ._2N23bZfnwgwDFqhNAXDjpg {
    -ms-flex-item-align: end;
        align-self: flex-end; }
  ._1Sbv9U_rO1xbFPY3_OyRCO {
    max-width: 560px;
    height: 600px; }
  ._3BR3uowCktg339bt5ho0wr, ._2F2_YHhse9bHmTv5uCTzJH {
    color: #fff; }
  ._3BR3uowCktg339bt5ho0wr > span {
    display: block; }
  ._3BR3uowCktg339bt5ho0wr > span:first-child {
    margin-bottom: 1rem; }
  ._2F2_YHhse9bHmTv5uCTzJH {
    font-size: 1.5rem;
    color: #a9b2be;
    line-height: 1.6; }
  ._20VFiuOs8p053dGXunDRxe {
    margin-bottom: 2rem; }
    ._20VFiuOs8p053dGXunDRxe img {
      height: 100%;
      max-height: 100px; }
  ._3UtTqI3_NSipZW4wUc49Rb {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem; }
  @media (max-width: 991px) {
    ._3SW63XyP0MAW-uIOBiQ2f1 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
    ._2N23bZfnwgwDFqhNAXDjpg {
      display: none; }
    .OdQC1SnCUPdeXtpZHdIHA {
      padding-right: 0; } }
  @media (max-width: 767px) {
    ._3UtTqI3_NSipZW4wUc49Rb {
      width: 100%;
      margin-bottom: 1rem; } }
