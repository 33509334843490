._3HTAhX-fZVMCCKGxy7UA8 {
  font-size: 1.75rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._3HTAhX-fZVMCCKGxy7UA8 {
      font-size: 2.5rem;
      line-height: 1.7; } }

._2pap6PqSQAocCKhpq0yS6H {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

._3HTAhX-fZVMCCKGxy7UA8 {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 3rem 0 0 0; }
  @media all and (min-width: 768px) {
    ._3HTAhX-fZVMCCKGxy7UA8 {
      padding: 6rem 0 3rem 0; } }

.CSCxVntM2LsmzZTUY6NA4 {
  width: 100%;
  background: #030b0d; }
  .CSCxVntM2LsmzZTUY6NA4 section:last-child {
    padding-bottom: 3rem; }
    @media all and (min-width: 768px) {
      .CSCxVntM2LsmzZTUY6NA4 section:last-child {
        padding-bottom: 6rem; } }
