._2R420uG6d8SJ8frZbGexpJ {
  margin-bottom: 20px;
  overflow: auto;
  position: relative;
  background: #ffffff;
  border: 1px solid #eaeaea;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
          box-shadow: 0px 2px 10px 10px rgba(223, 226, 231, 0.05);
  border-radius: 6px; }

._3mG4l84IUFvDptTTrTl6Nh {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }
  ._3mG4l84IUFvDptTTrTl6Nh thead {
    background-color: transparent; }
  ._3mG4l84IUFvDptTTrTl6Nh tr {
    border: none; }
    @media (max-width: 1240px) {
      ._3mG4l84IUFvDptTTrTl6Nh tr {
        display: table;
        border-bottom: 1px solid #f4f4f4;
        width: 100%;
        margin-bottom: 1rem; }
        ._3mG4l84IUFvDptTTrTl6Nh tr th {
          display: none; }
        ._3mG4l84IUFvDptTTrTl6Nh tr td {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          ._3mG4l84IUFvDptTTrTl6Nh tr td:last-child {
            padding-bottom: 1rem; } }
    @media (max-width: 1240px) and (max-width: 1240px) {
      ._3mG4l84IUFvDptTTrTl6Nh tr td {
        padding: 0.5rem 1rem; } }

._1qHnTRdFI5JJC-XEjNVO_k {
  color: #62737f; }
  ._1qHnTRdFI5JJC-XEjNVO_k._2vO6uKLYUcjcD02P64Wmi {
    color: #fa1854; }
  ._1qHnTRdFI5JJC-XEjNVO_k:hover {
    background-color: #f6f9fb; }

._1PNFdo-gMASFs8aFHliySo {
  padding: 1rem; }
  ._1PNFdo-gMASFs8aFHliySo .RiB2veN2n8R-_PQRwQRdZ {
    display: none;
    margin-left: 0.5rem; }
    @media (max-width: 1240px) {
      ._1PNFdo-gMASFs8aFHliySo .RiB2veN2n8R-_PQRwQRdZ {
        display: block; } }

._31_PLYXG_pt3GeZbD-Alh_ {
  font-size: 1rem;
  font-weight: 600;
  position: relative; }
  ._31_PLYXG_pt3GeZbD-Alh_.wgmRIBraQTZk8YN9t17V- {
    color: #1167f3; }
  ._31_PLYXG_pt3GeZbD-Alh_ ._3GDHykNlx9jITT5rGGBQnx {
    color: #fa1854; }

._3fHl5o0EvDYFJS0cb-5-rH {
  font-weight: 600;
  font-size: 1rem; }
  ._3fHl5o0EvDYFJS0cb-5-rH.wgmRIBraQTZk8YN9t17V- {
    color: #1f8e0d; }
  ._3fHl5o0EvDYFJS0cb-5-rH._2vO6uKLYUcjcD02P64Wmi {
    color: #fa1854; }

._2iFtMXpo_7yuZmNEegxtSI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    ._2iFtMXpo_7yuZmNEegxtSI {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-flow: column;
              flex-flow: column; } }
