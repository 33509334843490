._1faRjDbwf8-vKjYh4YkUe9 {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; }
  ._1faRjDbwf8-vKjYh4YkUe9:before {
    position: absolute;
    content: '';
    background: #848993 0% 0% no-repeat padding-box;
    opacity: 0.7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

._2H8wqXE2xIMAVAusZn_tzY {
  background: #ffffff 0% 0% no-repeat padding-box;
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  max-width: 90%;
  position: relative; }
  ._2H8wqXE2xIMAVAusZn_tzY ._1aQADrpA8pjbI6cFP9Y1Dw {
    color: #758793;
    position: absolute;
    right: 5px;
    top: 5px;
    border: none;
    background: transparent;
    outline: none;
    font-size: 30px;
    cursor: pointer;
    z-index: 1; }
