._1SREZmKInY42kLkzosbKjZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
  padding: 8.125rem 1.25rem 0 1.25rem;
  margin-top: -5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  text-align: center; }
  ._1SREZmKInY42kLkzosbKjZ._1O0cSsnedvlwxN51feRChe {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; }
    ._1SREZmKInY42kLkzosbKjZ._1O0cSsnedvlwxN51feRChe ._2Zs3npv-hzJrEFXhP6mqf7 {
      margin-bottom: 0; }
    ._1SREZmKInY42kLkzosbKjZ._1O0cSsnedvlwxN51feRChe ._3HNUGOINUamuCdTCiQUr02 {
      margin-bottom: 5rem; }
  ._1SREZmKInY42kLkzosbKjZ ._2Zs3npv-hzJrEFXhP6mqf7 {
    margin-bottom: 5rem;
    width: 100%;
    max-width: 700px;
    font-size: 1.75rem;
    line-height: 1.6; }
    @media all and (min-width: 768px) {
      ._1SREZmKInY42kLkzosbKjZ ._2Zs3npv-hzJrEFXhP6mqf7 {
        font-size: 3rem;
        line-height: 1.5; } }
  ._1SREZmKInY42kLkzosbKjZ ._3HNUGOINUamuCdTCiQUr02 {
    width: 100%;
    max-width: 310px;
    margin-bottom: 3rem; }
    @media all and (min-width: 768px) {
      ._1SREZmKInY42kLkzosbKjZ ._3HNUGOINUamuCdTCiQUr02 {
        max-width: 480px; } }
