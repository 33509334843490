.CkSvSVEDBXQ6DNqfmX0FP {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  overflow: auto; }

._11msTpUas-404hJvenTOYR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px; }

._3jdtzqb1RTZ4APXi6ILTex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%; }

._2ZRyjUyvBHkM5fl8_B4QuO {
  font-size: 14px;
  color: #ffffff;
  border: none;
  padding: 10px;
  background-color: #1791d3;
  border-radius: 3px;
  margin: 0 5px 5px 0;
  cursor: pointer; }
  ._2ZRyjUyvBHkM5fl8_B4QuO:hover {
    background-color: #34a9e9; }

._3TKN4s_hKZZvwxhJ2NPEzY {
  font-size: 14px;
  color: #5c6066;
  background: #e6eaf2 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: block;
  margin: 0 10px 5px 0; }
  ._3TKN4s_hKZZvwxhJ2NPEzY input {
    display: none; }
  ._3TKN4s_hKZZvwxhJ2NPEzY span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    ._3TKN4s_hKZZvwxhJ2NPEzY span:after {
      content: ' ';
      width: 0px;
      height: 0px;
      margin-left: 5px; }
  ._3TKN4s_hKZZvwxhJ2NPEzY input:checked + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  ._3TKN4s_hKZZvwxhJ2NPEzY input:not(:checked) + span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }

._3jdtzqb1RTZ4APXi6ILTex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  @media all and (max-width: 660px) {
    ._3jdtzqb1RTZ4APXi6ILTex {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }

._25UCyFpKKTDKEEzGezVfd8 {
  background: #f6f9fb 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 5px; }
  ._25UCyFpKKTDKEEzGezVfd8 input {
    border: none;
    background: transparent;
    margin-left: 5px; }

._2FftFaT-gNLdDPYdOEBt4X {
  overflow: auto;
  position: relative;
  margin-bottom: 1rem; }

._2LGj8EfdkEfsNfw43oZ8yI {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  position: relative;
  width: 100%;
  border-collapse: collapse; }

._31_-b0H87TJbco9JMbb2dk {
  padding: 20px 20px;
  font-size: 14px;
  font-weight: 400;
  color: #657881;
  border-bottom: 1px solid #758793;
  padding: 20px 20px;
  line-height: 2em;
  position: relative; }

._1WlJ6u00W07wMXBBOogttq._2N3wA-syRi3dj_K59zy_Bm, ._1WlJ6u00W07wMXBBOogttq._2hC4BRlcOFHDM5PLoyKix7:not(._3GY6U4eTdOm5HxjO6SU6F) {
  color: #1791d3;
  font-weight: 600; }

._1WlJ6u00W07wMXBBOogttq._1LWYps0c5hMPUp_peoyOCh:not(._3GY6U4eTdOm5HxjO6SU6F), ._1WlJ6u00W07wMXBBOogttq._2hC4BRlcOFHDM5PLoyKix7._3GY6U4eTdOm5HxjO6SU6F, ._1WlJ6u00W07wMXBBOogttq._3B3R47b8Lo5HrTLb1Pihp3 {
  font-style: italic; }
