._1fJsIP8LYSm3YG0nHnfVTx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  ._1fJsIP8LYSm3YG0nHnfVTx .IQIT82mAbQhVMpi49Sxv1,
  ._1fJsIP8LYSm3YG0nHnfVTx ._3MuEUz-TrZiwg9FQOgVwFL {
    color: #fff;
    background: #2892d0;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    display: block; }
    ._1fJsIP8LYSm3YG0nHnfVTx .IQIT82mAbQhVMpi49Sxv1:hover,
    ._1fJsIP8LYSm3YG0nHnfVTx ._3MuEUz-TrZiwg9FQOgVwFL:hover {
      background: #2380b6; }
    ._1fJsIP8LYSm3YG0nHnfVTx .IQIT82mAbQhVMpi49Sxv1:active,
    ._1fJsIP8LYSm3YG0nHnfVTx ._3MuEUz-TrZiwg9FQOgVwFL:active {
      background: #217aae; }
    ._1fJsIP8LYSm3YG0nHnfVTx .IQIT82mAbQhVMpi49Sxv1[disabled],
    ._1fJsIP8LYSm3YG0nHnfVTx ._3MuEUz-TrZiwg9FQOgVwFL[disabled] {
      -webkit-filter: grayscale(1);
              filter: grayscale(1); }
  ._1fJsIP8LYSm3YG0nHnfVTx .IQIT82mAbQhVMpi49Sxv1 {
    margin-right: 0.063rem;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto; }

.ej6JdAIpws5OQihuYB5UR ._1AC5iEqgAA3nAl5KBT_HQN {
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative; }

.ej6JdAIpws5OQihuYB5UR ._2H1Vtjcq3e-EfC3dseZ9Tq {
  font-size: 1.5rem;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 0.625rem; }

.ej6JdAIpws5OQihuYB5UR ._2P4Kw2lIIvxF5UHcfnq4LX {
  font-size: 0.875rem;
  color: #657881;
  margin-bottom: 0.625rem; }

.ej6JdAIpws5OQihuYB5UR ._3JQuvn40xcUS6tjdM3iXKG {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 1rem 2rem; }
  .ej6JdAIpws5OQihuYB5UR ._3JQuvn40xcUS6tjdM3iXKG ._1fJsIP8LYSm3YG0nHnfVTx {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    font-size: 0.875rem;
    color: #858585;
    padding: 0.625rem;
    margin: 0.625rem;
    min-width: 100px;
    cursor: pointer;
    display: block; }
    .ej6JdAIpws5OQihuYB5UR ._3JQuvn40xcUS6tjdM3iXKG ._1fJsIP8LYSm3YG0nHnfVTx:hover {
      background: #f0f0f0; }
    .ej6JdAIpws5OQihuYB5UR ._3JQuvn40xcUS6tjdM3iXKG ._1fJsIP8LYSm3YG0nHnfVTx[disabled] {
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%);
      cursor: not-allowed; }
  .ej6JdAIpws5OQihuYB5UR ._3JQuvn40xcUS6tjdM3iXKG ._5exQXG1VgS3u7IwulMBUh {
    background: #2892d0 0% 0% no-repeat padding-box;
    color: #fff;
    border-color: #2892d0; }
    .ej6JdAIpws5OQihuYB5UR ._3JQuvn40xcUS6tjdM3iXKG ._5exQXG1VgS3u7IwulMBUh:hover {
      background: #2380b6; }
    .ej6JdAIpws5OQihuYB5UR ._3JQuvn40xcUS6tjdM3iXKG ._5exQXG1VgS3u7IwulMBUh:active {
      background: #217aae; }

.ej6JdAIpws5OQihuYB5UR ._3WUJ6lKX-6jwohTKSLlkK7 {
  margin: 0.625rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline; }

.ej6JdAIpws5OQihuYB5UR ._1X0yCLFvyXfLW0QrMTTFPP {
  background: #f6f9fb;
  border: #f6f9fb;
  color: #758793;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
  max-width: 100%;
  width: 100%;
  padding: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5em; }
  .ej6JdAIpws5OQihuYB5UR ._1X0yCLFvyXfLW0QrMTTFPP:focus {
    outline: none; }

.ej6JdAIpws5OQihuYB5UR ._2JDE9tBsjdIaLkGEy7CKs4 {
  display: none; }

.ej6JdAIpws5OQihuYB5UR ._1lzDxF5_R98BmULvFzgDsp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border: 1px solid #e6eaf2;
  background: #e6eaf2;
  border-radius: 3px;
  font-size: 0.875rem;
  color: #5c6066;
  padding: 0.625rem;
  cursor: pointer; }
  .ej6JdAIpws5OQihuYB5UR ._1lzDxF5_R98BmULvFzgDsp:hover {
    background: #d2d9e8; }
  .ej6JdAIpws5OQihuYB5UR ._1lzDxF5_R98BmULvFzgDsp:active {
    background: #cbd3e4; }
  .ej6JdAIpws5OQihuYB5UR ._1lzDxF5_R98BmULvFzgDsp ._2NZzBQ03o2m8drT9fOOtrR {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.ej6JdAIpws5OQihuYB5UR ._3E3iXHEWSuBjDbql3aj3K7 {
  width: 12px;
  height: 12px;
  margin-right: 0.625rem; }

.ej6JdAIpws5OQihuYB5UR ._270r1m9sHrBqCpfR3siqz8 {
  color: #fa1854;
  font-size: 0.875rem;
  display: block;
  margin: 0.313rem 0; }

.ej6JdAIpws5OQihuYB5UR ._2uBLdKBcNYgTfGs-OGG_o6 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #051c26;
  margin-bottom: 0.625rem; }

.ej6JdAIpws5OQihuYB5UR ._2dTUOh6535lZd7rJnX1qeF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
