._2XPrTDURhNNQnonih5rldv {
  padding-top: 100px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMzI4LjIyIiBoZWlnaHQ9IjI4NDEuNDg2Ij48cGF0aCBkYXRhLW5hbWU9IlBhdGggMTQ0OSIgZD0iTTEzMjguMjIgMTQwLjQ4MlMxMTYyLjA5OC03NC44NiA5NTQuOTYgMjcuNjgycy02Ny42NzkgMjIxLjQ5NC0xNjIuMDIgNTI5LjEyNi0zMDcuNjMxIDIwMC45ODYtNDk0LjI2MSAyNzguOTE5LTIzMy44IDQxOC4zNzktMjguNzEyIDU5NC43NTQgMTEwLjgyMSAyNDUuNjExLTI0LjYxIDM2NS4wNTctMzg5Ljc4MiA0MjUuMjU4LTEzOS40NTkgNzA5LjYgNDU4LjMyMyAzMDguMzQ0IDU1OC4zMjMgMzIwLjM0NCAzNTEuOSAxNiA2NjQgMTZ6IiBmaWxsPSIjMDYxMTE0Ii8+PC9zdmc+) no-repeat right bottom;
  background-size: 800px; }
  ._1SLyySQYX7KoFgeFbhYAcq > * {
    width: 300px;
    max-width: 100%;
    height: auto;
    margin-bottom: 2rem; }
  .P0LolgHGLaL_hp3UtyYwK {
    color: #fff;
    margin-bottom: 5rem; }
    @media (max-width: 767px) {
      .P0LolgHGLaL_hp3UtyYwK {
        margin-bottom: 2rem; } }
  ._1DxdnwfpcsXMvsMnplueQO {
    grid-template-columns: 1fr 1fr; }
  ._3fHC0pbBdkag2NdVkNxdei {
    grid-template-columns: 1fr 1fr 1fr; }
  ._1DxdnwfpcsXMvsMnplueQO, ._3fHC0pbBdkag2NdVkNxdei {
    display: grid;
    text-align: center; }
    @media (max-width: 767px) {
      ._1DxdnwfpcsXMvsMnplueQO, ._3fHC0pbBdkag2NdVkNxdei {
        grid-template-columns: 1fr; } }
  ._1j4iZohjFq-Q51TkLWo6MH {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOTc3LjA5NCIgaGVpZ2h0PSI4NDEuODc4Ij48cGF0aCBkPSJNMTY5Ny4xNDEgNDIyLjQ1NWMtODIuODc0IDAtMTQ3LjU1Ni02NS42OTMtMTQ3LjU1Ni0xNDkuNTc3czY1LjY5My0xNDcuNTU2IDE0Ny41NTYtMTQ3LjU1NmM4My44ODUgMCAxNDguNTY3IDY0LjY4MiAxNDguNTY3IDE0OC41NjcgMS4wMTEgODMuODg1LTY0LjY4MiAxNDguNTY3LTE0OC41NjcgMTQ4LjU2N20yNzMuODg4LTMwMy4yVjE2LjE3MWgtMTgwLjkwN0MxNzYwLjgxMyA2LjA2NCAxNzI5LjQ4MyAwIDE2OTcuMTQxIDAgMTU0MS41IDAgMTQxOC4yIDExOC4yNDcgMTQxOC4yIDI3My44ODhjMCAxNTQuNjMgMTIyLjI5IDI3My44ODggMjc4Ljk0MSAyNzMuODg4IDE1NS42NDEgMCAyNzguOTQxLTExOC4yNDcgMjc4Ljk0MS0yNzMuODg4IDAtMzIuMjY4LTE3LjEtOTUuNDc3LTE3LjEtOTUuNDc3cy0xNC40MjktMzEuNTgtMjUuMzUyLTQ2LjAxNXptLTI3My44ODggNTk2LjI4OWMtODIuODc0IDAtMTQ3LjU1Ni02NC42ODItMTQ3LjU1Ni0xNDcuNTU2aC0xMzAuMzc0YzAgMTU0LjYzMSAxMjIuMjkgMjczLjg4OCAyNzguOTQxIDI3My44ODggMTU1LjY0MSAwIDI3OC45NDEtMTE4LjI0NyAyNzguOTQxLTI3My44ODhoLTEzMC4zNzRjLTEuMDExIDgyLjg3NC02Ni43IDE0Ny41NTYtMTQ5LjU3NyAxNDcuNTU2bS04MDYuMTA5LTE0LjM2Yy0xMy4yNTQtMS40OC0yNi41LTMuMDQyLTM5Ljc2My00LjQyLTIwLjY2LTIuMTQ1LTQxLjMyMy00LjI3NC02Mi4wMTItNi4xYTExLjYwOSAxMS42MDkgMCAwMC03LjgxMSAyLjM3M3EtNjkuNzM4IDYwLjE2Ny0xMzkuMjIgMTIwLjYzNGMtMS4zNDEgMS4xNjQtMi43ODIgMi4yMTUtNC45MiAzLjkwN2wxNS4yNjYtMTQzLjAyMkwwIDU5NC41NTdWMjRoNi42ODNhNDEuNDQyIDQxLjQ0MiAwIDAwNC43NTIgMS4xcTU1LjUxOSA2LjM2MSAxMTEuMDQ3IDEyLjY1NSA2MS44ODIgNy4wNjIgMTIzLjc2MSAxNC4xNjIgNjAuNzgyIDYuOTY2IDEyMS41NjIgMTMuOTQ1IDYwLjc3NyA2Ljk0NCAxMjEuNTYzIDEzLjgzNSA1NS41MzEgNi4zMjcgMTExLjA0OSAxMi42NjUgNTYuMzQ1IDYuNDQ2IDExMi42ODYgMTIuOTM0IDU2LjM1NiA2LjQ1NSAxMTIuNzE1IDEyLjg3MWMyMS43MzEgMi40NTggNDMuNDc2IDQuOCA2NS4yMTUgNy4xODd6TTg5LjM1NiA1MTguOTM2bDcwNC40MzMgODIuODExVjIwNS44NjhMODkuMzU2IDEyNC43MzJ6IiBmaWxsPSIjMDcxMTE3Ii8+PC9zdmc+) no-repeat center bottom; }
  ._1DxdnwfpcsXMvsMnplueQO {
    margin-top: 3rem; }
    @media (max-width: 767px) {
      ._2Lv8GlA30_3HiJEAUxmyL2:last-child {
        margin-top: 2rem; } }
  .-ZwBvh1_1I8Z-hE4RxzOc {
    color: #fff;
    font-size: 1.5rem; }
  ._2KWD_UV0HzXsIkkRSOgDql > img {
    height: 400px; }
  @media (max-width: 767px) {
    ._3IblKzz24S7p5Z5m0VO9J7 {
      margin-top: 4rem; }
      ._2KWD_UV0HzXsIkkRSOgDql > img {
        height: 200px; } }
  .WTGaeiozUhD4RB4X4IDu7 {
    margin-top: 10rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    width: 100%;
    max-width: 980px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    z-index: 1; }
    ._3iLPeRaG6eb4VtOhO_KEr8 {
      background-color: #0A1B20;
      padding: 2rem;
      border-radius: 1rem;
      max-width: 400px; }
      ._2vlzPQ42EwyO42ym_NEY3z {
        background-color: #081418; }
      .MYhw5zmgoAZpH2iEL1-Bw {
        color: #2392d0;
        text-align: center; }
      ._3t9oBDTtHLQ4IzjY_rZ7C2 {
        margin-top: 40px; }
        ._1g9DFQgiFf-bc5meVD9LSx, ._27ZC8OKooWJg2m9ZR7Noc2 {
          color: #FFFFFF; }
        ._1g9DFQgiFf-bc5meVD9LSx {
          font-weight: bold;
          margin-bottom: 0.5rem; }
          ._1g9DFQgiFf-bc5meVD9LSx:first-child {
            margin-top: 0; }
        ._27ZC8OKooWJg2m9ZR7Noc2:not(:last-child) {
          margin-bottom: 2rem; }
    ._2NgZ2i6Fl11dxzsdtcBy1r {
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none; }
      ._2rqlSQf_Feaua9oPTsr1Jv, .Fw9dCNDmDd7V5yXBxkdn6, ._30lrWiuYTEn48MvHCV62lD {
        position: absolute; }
      ._30lrWiuYTEn48MvHCV62lD {
        top: 50%;
        right: -100px;
        width: 200px; }
      ._2rqlSQf_Feaua9oPTsr1Jv {
        top: -50px;
        left: -50px;
        width: 150px; }
      .Fw9dCNDmDd7V5yXBxkdn6 {
        top: 50%;
        left: -50px;
        width: 100px;
        z-index: -1; }
    @media (max-width: 991px) {
      ._30lrWiuYTEn48MvHCV62lD {
        top: auto;
        bottom: 0;
        right: 0; } }
    @media (max-width: 767px) {
      .WTGaeiozUhD4RB4X4IDu7 {
        grid-template-columns: 1fr; }
        ._3iLPeRaG6eb4VtOhO_KEr8 {
          max-width: 100%; }
        .Fw9dCNDmDd7V5yXBxkdn6, ._30lrWiuYTEn48MvHCV62lD {
          display: none; } }
  ._gaqVJnvU2gQeaV0ePKub {
    padding-top: 12rem;
    padding-bottom: 4rem;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    color: #a9b2be;
    text-align: center;
    line-height: 1.6; }
