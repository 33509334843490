._1KE-8PrVMArM4fx-a8RNIO {
  position: relative;
  min-width: 200px; }
  ._1KE-8PrVMArM4fx-a8RNIO ._2wd0ledHtIJJsfH9-lDvZw {
    cursor: pointer;
    width: 1px; }
  ._1KE-8PrVMArM4fx-a8RNIO ._1Jr7lC4MenMJrmyvbqm82- {
    font-size: 14px;
    color: #5c6066;
    font-weight: 600;
    background: #e6eaf2 0% 0% no-repeat padding-box;
    border: 1px solid #e6eaf2;
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  ._1KE-8PrVMArM4fx-a8RNIO ._1HwCpEch0utXY2QGmpywzl {
    font-size: 14px;
    color: #5c6066;
    font-weight: 600;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    top: 30px;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid #f4f5f8;
    border-radius: 3px;
    z-index: 1;
    display: none; }
    @media all and (max-width: 660px) {
      ._1KE-8PrVMArM4fx-a8RNIO ._1HwCpEch0utXY2QGmpywzl {
        width: 100%; } }
    ._1KE-8PrVMArM4fx-a8RNIO ._1HwCpEch0utXY2QGmpywzl._1YDSqCX4uGMoZBv_ICGOZl {
      display: block; }
    ._1KE-8PrVMArM4fx-a8RNIO ._1HwCpEch0utXY2QGmpywzl li {
      color: #2c2c2c;
      padding: 10px 20px;
      cursor: pointer; }
      ._1KE-8PrVMArM4fx-a8RNIO ._1HwCpEch0utXY2QGmpywzl li:hover {
        color: #1167f3; }

._2wd0ledHtIJJsfH9-lDvZw {
  opacity: 0;
  position: absolute; }

.LUHj6_ta3-xLbdLGITPlP {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .LUHj6_ta3-xLbdLGITPlP:after {
    content: ' ';
    margin-left: 5px; }
  .LUHj6_ta3-xLbdLGITPlP._94zGW_dHMJlC-HyotOKiN:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f; }
  .LUHj6_ta3-xLbdLGITPlP._2jSZ5OyRn3v9aeAHrPPSsg:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #2f2f2f; }
