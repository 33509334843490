._3ST99-MfRTQkH0f_c0mfes {
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  padding: 0 20px;
  max-width: 600px;
  font-weight: 500;
  color: #2392d0;
  font-size: 32px;
  line-height: 40px;
  text-align: center; }
  @media all and (max-width: 800px) {
    ._3ST99-MfRTQkH0f_c0mfes {
      font-size: 26px;
      line-height: 32px; } }
  @media all and (max-width: 600px) {
    ._3ST99-MfRTQkH0f_c0mfes {
      font-size: 24px;
      line-height: 28px; } }

._3p7f825dWoqcOhoRFKzqv5 {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 30px;
  width: 1200px;
  max-width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  ._3LFC-dnowDwymzINvl5bsJ {
    display: block;
    width: 100%;
    max-width: 15%;
    position: relative;
    margin-top: 20px;
    margin-left: 20px; }
    ._5LbOh9LcPLjKDorov9_LD {
      display: block;
      width: 100%;
      height: auto; }
    ._1a9h6x2m88vIS9P7AZyWO8 {
      display: block;
      width: 80%;
      left: 10%;
      margin: 0;
      padding: 0;
      bottom: 10px;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      position: absolute; }
    @media all and (max-width: 1000px) {
      ._3LFC-dnowDwymzINvl5bsJ {
        max-width: 20%; } }
    @media all and (max-width: 800px) {
      ._3LFC-dnowDwymzINvl5bsJ {
        max-width: 25%; } }
    @media all and (max-width: 600px) {
      ._3LFC-dnowDwymzINvl5bsJ {
        max-width: 50%; } }
    @media all and (max-width: 400px) {
      ._3LFC-dnowDwymzINvl5bsJ {
        max-width: 90%;
        margin-left: 0; } }

._2-De_wi6Lr30JU-6kvJr4m {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 50px; }

._1bk41CVQTOPWLpEg21u2pA {
  display: block;
  width: 100%;
  height: 150px; }

._2WJX6TVWWjQB16ZUinjcmW {
  display: block;
  padding: 0 20px;
  margin: 0;
  margin-top: 30px;
  text-align: center;
  color: #FFFFFF;
  font-size: 18px;
  line-height: 22px; }
