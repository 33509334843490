._2uIFEftKgDeKHWD-2BILtv {
  font-size: 1.125rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    ._2uIFEftKgDeKHWD-2BILtv {
      font-size: 1.25rem;
      line-height: 1.8; } }

.wKORdk1oEdI8_zpXxITTy {
  background: #fff;
  min-width: 494px;
  border-radius: 12px; }

._9xMCETqRCGXa2dnrXhaYF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 2rem; }

._3Zg-SmoAyYTBJ05iWIpKy_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  grid-gap: 0.5rem;
  gap: 0.5rem; }

._1NhCC7ZpdYRbYMNg5FbDMX {
  border-radius: 50%;
  max-height: 100%;
  width: auto; }

._1B65fMzbfqaJYtpnKw0aW1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._1B65fMzbfqaJYtpnKw0aW1 ._2OEFyxdfNvsRpQLUQGQLtI {
    margin: 0;
    font-weight: 600; }
    ._1B65fMzbfqaJYtpnKw0aW1 ._2OEFyxdfNvsRpQLUQGQLtI:hover {
      text-decoration: underline; }
  ._1B65fMzbfqaJYtpnKw0aW1 ._1qsf7xJHJzIpTUoVLgRZAI {
    color: #8698a9; }

._3K2SLl2ddfcSToVStOf9Y5 {
  margin-left: auto; }

._2uIFEftKgDeKHWD-2BILtv {
  margin: 0; }

._38TRCB4hqA-3ejZMGUHQfG {
  color: #8698a9; }
