.Iimxcq0aZTV2kqgjETojD {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  max-width: 1400px;
  margin: 70px auto; }
  ._1fLpap1yCZynORNRd9ASgX, .ZayifG1rvpKeUtQV-v_Td {
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px; }
    ._1fLpap1yCZynORNRd9ASgX:first-child, .ZayifG1rvpKeUtQV-v_Td:first-child {
      margin-top: 0; }
  ._1fLpap1yCZynORNRd9ASgX {
    font-size: 26px;
    line-height: 32px;
    font-weight: normal;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; }
  .ZayifG1rvpKeUtQV-v_Td {
    font-size: 18px;
    line-height: 26px; }
  ._2y0KClKjrxuFJF0ngfedxZ {
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: auto; }
  ._3_Nf2Hw2zoVzwWbP3Wq4Fc {
    margin-top: 30px; }
  ._2ImW3pLF1Q2cwO_dIXR42H {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media all and (max-width: 980px) {
      ._2ImW3pLF1Q2cwO_dIXR42H:first-child {
        margin-top: 0; } }
    ._9oL9Vx_sUGUP2ikK5Asa {
      width: 40%;
      max-width: 520px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-flex: 0;
          -ms-flex-positive: 0;
              flex-grow: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0; }
      @media all and (max-width: 800px) {
        ._9oL9Vx_sUGUP2ikK5Asa {
          width: 60%; } }
      @media all and (max-width: 600px) {
        ._9oL9Vx_sUGUP2ikK5Asa {
          width: 80%; } }
    @media all and (max-width: 980px) {
      ._2ImW3pLF1Q2cwO_dIXR42H {
        margin-top: 30px; } }
  ._3tQHCLUtgxx6-w4v1f5mmj {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 50px; }
    .KqSbL662d5rlguQYiDA45, ._3QOhHDY9Yf6IwXcb6-SV7y {
      display: block;
      color: #FFFFFF;
      font-weight: normal;
      margin: 0;
      padding: 0;
      text-align: center; }
    .KqSbL662d5rlguQYiDA45 {
      font-size: 50px;
      line-height: 50px;
      letter-spacing: 2px;
      font-family: 'Poppins', sans-serif; }
    ._3QOhHDY9Yf6IwXcb6-SV7y {
      font-size: 26px;
      line-height: 32px; }
    ._2mslSq7NSFvVlEOK6yf2Bb {
      max-height: 100px;
      height: 100%; }
    ._3tQHCLUtgxx6-w4v1f5mmj:before, ._3tQHCLUtgxx6-w4v1f5mmj:after {
      display: block;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      pointer-events: none;
      content: ''; }
    ._3tQHCLUtgxx6-w4v1f5mmj:before {
      width: 193px;
      height: 23px;
      top: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkzIiBoZWlnaHQ9IjIzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0uNS41bDE5MiAyMiIgc3Ryb2tlPSIjRkZGIiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PC9zdmc+); }
    ._3tQHCLUtgxx6-w4v1f5mmj:after {
      width: 193px;
      height: 39px;
      bottom: 0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkzIiBoZWlnaHQ9IjM5IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0uNS41bDE0NiAxN20wIDB2MjFtMCAwbDIyLTE3bTAgMGwyNCAyIiBzdHJva2U9IiNGRkYiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=); }
  ._1EOegRKjPLUUjh6wCu-o9v {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 30px; }
  ._3nZR2RRmY6pQqqgHxHG_s7 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0;
    padding: 0;
    margin-top: 30px; }
    ._3dUsYcI3dvXXnApSWB2u2n {
      display: block;
      margin: 0;
      padding: 0;
      margin-top: 10px;
      text-align: center; }
      ._3dUsYcI3dvXXnApSWB2u2n:first-child {
        margin-top: 0; }
      ._3dUsYcI3dvXXnApSWB2u2n > a {
        font-size: 18px;
        line-height: 24px;
        color: #20a393;
        text-decoration: none; }
        ._3dUsYcI3dvXXnApSWB2u2n > a:hover, ._3dUsYcI3dvXXnApSWB2u2n > a:active {
          color: #27c5b2;
          text-decoration: none; }
  @media all and (max-width: 980px) {
    .Iimxcq0aZTV2kqgjETojD {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-top: 10px; } }
