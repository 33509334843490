._2DdjQt3gqVvUxc_SQTSgWn {
  font-size: 14px;
  font-weight: 400;
  color: #657881;
  border-bottom: 1px solid #758793;
  padding: 20px 20px;
  line-height: 2em;
  position: relative; }
  ._2DdjQt3gqVvUxc_SQTSgWn._1JmLEk16ziZDcissYNNESd {
    color: #1791d3;
    font-weight: 600; }
  ._2DdjQt3gqVvUxc_SQTSgWn ._16ipaAAUcvq-8ADeJHq-RJ {
    display: none; }
    ._2DdjQt3gqVvUxc_SQTSgWn ._16ipaAAUcvq-8ADeJHq-RJ:checked + ._2fGislwPy8YOn72IxrQaXI:before {
      position: absolute;
      content: '\2713';
      background: #2392d0;
      color: #fff;
      height: 18px;
      width: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      display: inline-block;
      left: 21px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }

._1JmLEk16ziZDcissYNNESd {
  position: relative; }
  ._1JmLEk16ziZDcissYNNESd label {
    padding-left: 45px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    ._1JmLEk16ziZDcissYNNESd label:before {
      content: '';
      height: 18px;
      width: 18px;
      border: 1px solid #2392d0;
      border-radius: 3px;
      display: inline-block;
      left: 20px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      position: absolute; }

._3uvuKB-F9zHrovCYF6zGPg {
  background-color: #f3fbff; }

._1K4oWEXHQLn0m6XZtyLGeT {
  display: block;
  color: #fa1854; }
