._15HFFRAvTPKVAerHs9LUVW {
  padding: 50px 50px 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  position: relative;
  max-width: 500px; }

._2yjGYt9MBSCSeZ-BuZj9U6 {
  font-size: 24px;
  font-weight: 700;
  color: #051c26;
  margin-bottom: 10px; }

._18kGZBWUEn6vdqwPrHoo-x {
  font-size: 14px;
  color: #657881;
  margin-bottom: 10px;
  line-height: 1.4em; }

._3kuz4pPxrLOFOEtjkQTEvC {
  color: #fa1854; }

._14KWMZDG15Avc1Ctsuvvuy {
  background-color: #f6f9fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 10px 50px; }
  ._14KWMZDG15Avc1Ctsuvvuy .iGQFM81o6_OBBj8mVHp7 {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    font-size: 14px;
    color: #858585;
    background: #f0f0f0;
    padding: 10px;
    margin: 10px;
    min-width: 100px;
    cursor: pointer;
    text-decoration: none;
    text-align: center; }
    ._14KWMZDG15Avc1Ctsuvvuy .iGQFM81o6_OBBj8mVHp7:hover {
      border: 1px solid silver; }
  ._14KWMZDG15Avc1Ctsuvvuy ._30OKzgYzFZAhblG_OkC3C_ {
    background: #1791d3 0% 0% no-repeat padding-box;
    color: #ffffff; }
    ._14KWMZDG15Avc1Ctsuvvuy ._30OKzgYzFZAhblG_OkC3C_:hover {
      background: #34a9e9;
      color: #fff; }

._2Wg_u5pOlBwuuXhA2EPPJA {
  margin: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  ._2Wg_u5pOlBwuuXhA2EPPJA ._18kGZBWUEn6vdqwPrHoo-x,
  ._2Wg_u5pOlBwuuXhA2EPPJA ._2Sjee5EEdL1Q6RB52YaFz8 {
    margin: 1rem 0;
    text-align: center; }
