._2wYNVy4yIxY6dF4zJiiWNv, ._3XLGhJghwDwbfb0aBjuUHJ {
  font-weight: 700;
  font-family: 'Poppins', system-ui, sans-serif; }

._39NUwuPHGthjk4Q8MsyExt {
  background: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  max-width: 1300px;
  padding: 9rem 2rem 6rem;
  margin: 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (max-width: 991px) {
    ._39NUwuPHGthjk4Q8MsyExt {
      padding: 2rem; } }

._-1kzrqKIvDxXQOsUTD3mH {
  text-align: center;
  margin-bottom: 2.5rem; }
  @media (max-width: 991px) {
    ._-1kzrqKIvDxXQOsUTD3mH {
      font-size: 1.75rem;
      line-height: 1.8; } }

._2y7wYMztxJKbEwjW11NgOt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  list-style: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  grid-gap: 1rem;
  gap: 1rem; }
  @media (max-width: 991px) {
    ._2y7wYMztxJKbEwjW11NgOt {
      grid-gap: 0.4rem;
      gap: 0.4rem; } }

._2wYNVy4yIxY6dF4zJiiWNv {
  text-transform: capitalize;
  margin-bottom: 3rem;
  cursor: pointer; }
  ._2wYNVy4yIxY6dF4zJiiWNv > a {
    padding: 0.6rem;
    border-radius: 10px;
    font-size: 1.1rem; }
    @media (max-width: 991px) {
      ._2wYNVy4yIxY6dF4zJiiWNv > a {
        padding: 0.3rem;
        border-radius: 5px;
        font-size: 0.8rem; } }
  ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(1) > a {
    color: #00b4d8;
    background-color: #e0faff;
    border: 2px solid #e0faff; }
    ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(1) > a:hover {
      background-color: #c1f5ff;
      border: 2px solid #00b4d8; }
  ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(1) ._1biM-QdSX7DiXYMgvVcH-5 {
    border: 2px solid #00b4d8; }
  ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(2) > a {
    color: #ea4335;
    background-color: #ffe8e7;
    border: 2px solid #ffe8e7; }
    ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(2) > a:hover {
      background-color: #ffcbc8;
      border: 2px solid #ea4335; }
  ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(2) ._1biM-QdSX7DiXYMgvVcH-5 {
    border: 2px solid #ea4335; }
  ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(3) > a {
    color: #3d9fd8;
    background-color: #e2f4ff;
    border: 2px solid #e2f4ff; }
    ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(3) > a:hover {
      background-color: #c3e8ff;
      border: 2px solid #3d9fd8; }
  ._2wYNVy4yIxY6dF4zJiiWNv:nth-child(3) ._1biM-QdSX7DiXYMgvVcH-5 {
    border: 2px solid #3d9fd8; }

._3dMT-Pn5U8QQAaUg2ueggN {
  padding: 1rem 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem; }
  @media (max-width: 991px) {
    ._3dMT-Pn5U8QQAaUg2ueggN {
      grid-template-columns: 1fr;
      padding: 0; } }

._38I6psOGO9_dzTW3v-w9Df {
  -ms-flex-item-align: stretch;
      align-self: stretch;
  padding: 1rem; }
  ._38I6psOGO9_dzTW3v-w9Df:hover ._1hM4vb5EHH88HhP9EI6kG2 {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7); }
  ._38I6psOGO9_dzTW3v-w9Df:hover ._3XLGhJghwDwbfb0aBjuUHJ {
    color: #00b4d8; }
  @media (max-width: 991px) {
    ._38I6psOGO9_dzTW3v-w9Df {
      padding: 1rem 0; } }

._2PDfcFxDomCqAncjrtFNl9 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }

._1hM4vb5EHH88HhP9EI6kG2 {
  max-width: 100%;
  margin-bottom: 1rem;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
  aspect-ratio: attr(width)/attr(height); }
  @media (max-width: 991px) {
    ._1hM4vb5EHH88HhP9EI6kG2 {
      width: 100%;
      height: auto; } }

._3XLGhJghwDwbfb0aBjuUHJ {
  display: block;
  text-decoration: none;
  color: #030b0d;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem; }

._309fjP1pNGAALRrlBaoMfl {
  display: block;
  font-size: 1rem;
  color: #16191c;
  margin-bottom: 1rem; }
  ._309fjP1pNGAALRrlBaoMfl ._3R4ZLwzjRxyxYCa_EgEekW,
  ._309fjP1pNGAALRrlBaoMfl ._1t2jVYxZldVzSMKNn2GT3O {
    margin-right: 1rem;
    opacity: 0.6; }

._1MjUkcbcDIy6CL6ceM4wmQ {
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  color: #16191c;
  word-break: break-word;
  margin-top: 1.2rem; }

.BbKZ1UJkG3Lp8LzUxcIX3 {
  text-align: center;
  margin-top: 3rem; }
  @media (max-width: 991px) {
    .BbKZ1UJkG3Lp8LzUxcIX3 {
      margin: 3rem auto; } }
  .BbKZ1UJkG3Lp8LzUxcIX3 > a {
    font-weight: 600;
    font-size: 1.125rem;
    color: #00b4d8;
    padding: 0.5rem 1rem;
    border: 1px solid #00b4d8;
    border-radius: 5px; }
    .BbKZ1UJkG3Lp8LzUxcIX3 > a:hover {
      color: #fff;
      background-color: #009bb9; }
    .BbKZ1UJkG3Lp8LzUxcIX3 > a:active, .BbKZ1UJkG3Lp8LzUxcIX3 > a:focus {
      color: #fff;
      background-color: #0092af; }
