.VTnXOOZFHeripoupxPHaQ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column; }
  ._15itYIkXA-CkzNB8QmyRA7 ._2b9-vwYFcjk0viIXxMJU2G, ._15itYIkXA-CkzNB8QmyRA7 .f0kewfK3-to_7NupASwUf {
    color: #030b0d; }
  ._2Kza4I9lgoqjy_166CS4Af ._2b9-vwYFcjk0viIXxMJU2G {
    color: #fff; }
  ._2Kza4I9lgoqjy_166CS4Af .f0kewfK3-to_7NupASwUf {
    color: #a9b2be; }
  ._2pd-MlVQiSFtm-ZUKX80K4 {
    min-height: calc(100vh - 80px); }
  ._2-o_m6m-_udO5hQC8YwkCV {
    outline: none; }
  ._2hkcfH-9ZP2gq40leGI1Yj {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-top: 2rem;
    padding-bottom: 2rem;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    @media (max-width: 991px) {
      ._2hkcfH-9ZP2gq40leGI1Yj {
        padding-bottom: 0; } }
  ._3BN_L1AodIgX6HyUgVmjgj, ._2YL6eHoFb5ufvTXk6dkacY {
    z-index: 1; }
  ._3BN_L1AodIgX6HyUgVmjgj {
    padding-right: 4rem; }
  ._2YL6eHoFb5ufvTXk6dkacY {
    -ms-flex-item-align: center;
        align-self: center; }
  ._1azxPkr5w2Bg2LGdc5ll3a {
    max-width: 100%;
    width: 1200px; }
  .f0kewfK3-to_7NupASwUf {
    font-size: 1.25rem;
    line-height: 1.8; }
  ._30eNEBCbe3ISiJDefaPCw5 {
    margin-bottom: 2rem; }
    ._30eNEBCbe3ISiJDefaPCw5 img {
      height: 100%;
      max-height: 100px; }
  ._1Dx-RrZ-o16sujjZwwovt0 {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 1rem; }
  @media all and (max-width: 1179px) {
    ._2YL6eHoFb5ufvTXk6dkacY {
      display: none; } }
  @media (max-width: 991px) {
    ._2hkcfH-9ZP2gq40leGI1Yj {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
    ._3BN_L1AodIgX6HyUgVmjgj {
      padding-right: 0; }
    .f0kewfK3-to_7NupASwUf {
      font-size: 1.125rem;
      color: #fff;
      min-height: 210px; }
    ._2b9-vwYFcjk0viIXxMJU2G {
      font-size: 1.75rem;
      line-height: 1.6;
      min-height: 150px;
      margin-bottom: 1rem; } }
  @media (max-width: 767px) {
    ._1Dx-RrZ-o16sujjZwwovt0 {
      margin-bottom: 0; } }
  @media (min-width: 800px) and (max-width: 960rem) {
    ._2b9-vwYFcjk0viIXxMJU2G, .f0kewfK3-to_7NupASwUf {
      min-height: unset; }
    ._2b9-vwYFcjk0viIXxMJU2G {
      font-size: 2.5rem;
      line-height: 1.7; } }
