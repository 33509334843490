._2J9M4J246gcmWTWSDNMoqk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

._3hakR5RwjCdZjEQq8XsnmX {
  display: none; }

._3coqGaDDW1fb5EbsOInwVZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  border: 1px solid #e6eaf2;
  background: #e6eaf2;
  border-radius: 3px;
  font-size: 14px;
  color: #5c6066;
  padding: 10px;
  cursor: pointer;
  -ms-flex-item-align: start;
      align-self: flex-start; }
  ._3coqGaDDW1fb5EbsOInwVZ ._1ZjmVYd-iuIQcebex0LMwb {
    margin-right: .5rem; }
  ._3coqGaDDW1fb5EbsOInwVZ ._222K3xfIEaoImRGT1s66Lt {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  ._3coqGaDDW1fb5EbsOInwVZ:hover {
    border: 1px solid silver; }

._2gw-ppi1BZ7kyTmwP4FqHA {
  color: #fa1854;
  font-size: 14px;
  display: block;
  margin: 5px 0; }
