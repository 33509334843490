._3eszYsi0tYg3L-GV22KBph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0rem 2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: -webkit-gradient(linear, left top, left bottom, from(#030b0d), color-stop(66%, #030b0d), color-stop(66%, #030b0d), color-stop(60%, #0e1618), to(#0e1618));
  background: linear-gradient(to bottom, #030b0d 0%, #030b0d 66%, #030b0d 66%, #0e1618 60%, #0e1618 100%);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  margin-top: -0.063rem; }
  ._3eszYsi0tYg3L-GV22KBph p {
    font-size: 1.125rem;
    line-height: 2;
    margin-bottom: 0; }
    ._3eszYsi0tYg3L-GV22KBph p > a {
      display: block;
      font-weight: 500; }
      ._3eszYsi0tYg3L-GV22KBph p > a:hover {
        text-decoration: underline; }
  @media (max-width: 991px) {
    ._3eszYsi0tYg3L-GV22KBph ._3lA0DNcYbGKl8XkqiQwtZh {
      font-size: 1.75rem;
      line-height: 1.8;
      text-align: center;
      margin-bottom: 1rem; } }
  ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA {
    padding: 3rem 0;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._3lA0DNcYbGKl8XkqiQwtZh {
      text-align: center;
      margin-bottom: 0;
      color: #fff; }
    ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._2WO9YHtv1sSypobQ7ve-c5 {
      color: #fff; }
    ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      list-style: none;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 2rem 0; }
      @media (max-width: 991px) {
        ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h {
          grid-template-columns: auto;
          grid-gap: 1rem;
          width: 100%;
          padding: 0; } }
      ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h ._3RN-etdeLneRs-VKCFSpv1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        padding: 2rem 3.2rem;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        width: 100%;
        max-width: 480px; }
        @media (max-width: 991px) {
          ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h ._3RN-etdeLneRs-VKCFSpv1 {
            padding: 0;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            text-align: center; } }
        ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h ._3RN-etdeLneRs-VKCFSpv1 ._32YO0EYffYxzm70MFDsZD2 {
          width: 60px;
          margin: 0 auto; }
          @media (max-width: 991px) {
            ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h ._3RN-etdeLneRs-VKCFSpv1 ._32YO0EYffYxzm70MFDsZD2 {
              width: 80px;
              margin: 3rem 0; } }
        ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h ._3RN-etdeLneRs-VKCFSpv1 ._1kW4y4MhUadmTFIXUj665 {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-flow: column;
                  flex-flow: column;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          margin-left: 2rem; }
          @media (max-width: 991px) {
            ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h ._3RN-etdeLneRs-VKCFSpv1 ._1kW4y4MhUadmTFIXUj665 {
              margin: 0; } }
          ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._1jQm9BLXAei1HkH1pXEK4h ._3RN-etdeLneRs-VKCFSpv1 ._1kW4y4MhUadmTFIXUj665 h5 {
            font-size: 1.2rem; }
    ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._18zfex4VDSjnhUjwO7r2bn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 5rem 0;
      list-style: none; }
      @media (max-width: 991px) {
        ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._18zfex4VDSjnhUjwO7r2bn {
          grid-template-columns: auto;
          grid-gap: 1rem;
          width: 100%;
          padding: 0; } }
      ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._18zfex4VDSjnhUjwO7r2bn ._3RN-etdeLneRs-VKCFSpv1 {
        text-align: center;
        margin: 1rem;
        max-height: 63px; }
        @media (max-width: 991px) {
          ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._18zfex4VDSjnhUjwO7r2bn ._3RN-etdeLneRs-VKCFSpv1 {
            margin: 2rem; } }
        ._3eszYsi0tYg3L-GV22KBph ._1ffZnDZPKowd_hxKHi7dHA ._18zfex4VDSjnhUjwO7r2bn ._3RN-etdeLneRs-VKCFSpv1 ._32YO0EYffYxzm70MFDsZD2 {
          max-height: 30px; }
  ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow {
    padding-bottom: 3rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; }
    ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow .lfHDzDmvupcFD5hx1m0v3 {
      background: #fff;
      border-radius: 10px;
      padding: 6rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row;
              flex-flow: row;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      @media (max-width: 991px) {
        ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow .lfHDzDmvupcFD5hx1m0v3 {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-flow: column;
                  flex-flow: column;
          padding: 2rem; } }
    ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow ._1kW4y4MhUadmTFIXUj665 {
      color: #030b0d;
      margin-bottom: 0;
      margin-right: auto;
      width: 50%; }
      @media (max-width: 991px) {
        ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow ._1kW4y4MhUadmTFIXUj665 h2 {
          text-align: center;
          font-size: 1.5rem;
          margin: 1rem 0; } }
      ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow ._1kW4y4MhUadmTFIXUj665 p {
        font-size: 1.125rem; }
      @media (max-width: 991px) {
        ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow ._1kW4y4MhUadmTFIXUj665 {
          margin: 1rem;
          width: 100%; } }
    ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow ._32YO0EYffYxzm70MFDsZD2 {
      width: 50%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-radius: 8px; }
      @media (max-width: 991px) {
        ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow ._32YO0EYffYxzm70MFDsZD2 {
          width: 100%; } }
    ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow ._3lYhIfZULtHS2WJ0AxCEz6 {
      margin-right: 4rem; }
      @media (max-width: 991px) {
        ._3eszYsi0tYg3L-GV22KBph ._3cb115vSHQOzPRt26ceUow ._3lYhIfZULtHS2WJ0AxCEz6 {
          margin-right: 0; } }
