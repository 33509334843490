._3Y3XZOk8A_GP9JEfViFxq_ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin: 0 auto;
  padding: 4rem 1rem;
  width: 785px; }
  ._2j7ypT0t6gt7J70vFYdHXu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    grid-gap: 2rem;
    gap: 2rem; }
    @media (max-width: 991px) {
      ._2j7ypT0t6gt7J70vFYdHXu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-flow: column;
                flex-flow: column; } }
  ._1cRmGbZLHwhaskJ5I4w1e3 {
    margin: 0; }
    @media (max-width: 991px) {
      ._1cRmGbZLHwhaskJ5I4w1e3 {
        font-size: 1.75rem; } }
  ._3jDdlsO-J7CihWojgO0dXv {
    max-width: 400px; }
  @media (max-width: 991px) {
    ._3Y3XZOk8A_GP9JEfViFxq_ {
      padding: 0 0.25rem; } }

._2PQuUSUUqGpXj6CzL6Dwhv {
  margin-bottom: 3rem; }

._1od1LsGJQjGdePvcbOE3kT {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  ._1od1LsGJQjGdePvcbOE3kT .kLC9hVgwA8mh5vcFLMiJA {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 10px;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%; }
